/* varbiles */
$icon-teaser-padding: 10px;
$icon-teaser-bg-color-white: #ffffff;
$icon-teaser-bg-color-grey: #fafafa;
$icon-teaser-hover-box-shadow: 0 12px 16px rgba(0, 0, 0, 0.2);
$icon-teaser-simple-hover-box-shadow: 0 0 0 1px #00a758 inset;

$icon-teaser-quicklink-height: 162px;

/*  default style */
//.icon-teaser {
//    height: auto;
//    .cmp-icon-teaser {
//        position: relative;
//        padding: $icon-teaser-padding 0;
//        height: auto;
//        &:hover .cmp-icon-teaser__wrapper {
//            background-color: dark(7);
//            cursor: pointer;
//            margin-top: 0;
//            margin-bottom: 0;
////            border-top: solid 1px #ededed;
//            -webkit-box-shadow: none;
//            -moz-box-shadow: none;
//            box-shadow: none;
//        }
//        &__wrapper {
//            display: flex;
//            height: auto;
//            background-color: dark(6);
//            border: solid 1px #ededed;
//            -moz-transition: all 0.25s;
//            -webkit-transition: all 0.25s;
//            -o-transition: all 0.25s;
//            transition: all 0.25s;
//        }
//        &__container {
//            position: relative;
//            width: 100%;
//            overflow: hidden;
//        }
//        &__content {
//            position: relative;
//            top: auto;
//            bottom: auto;
//            padding: 0;
//            margin: 30px 20px 30px 30px;
//        }
//        &__icon {
//            display: inline-block;
//            width: 50px;
//            height: 50px;
//            margin-right: 30px;
//        }
//        &__title-icon {
//            display: flex;
//            -webkit-box-align: start;
//            -webkit-align-items: flex-start;
//            -moz-box-align: start;
//            -ms-flex-align: start;
//            align-items: flex-start;
//            -webkit-flex-shrink: 0;
//            -ms-flex-negative: 0;
//            flex-shrink: 0;
//            height: 50px;
//            overflow: hidden;
//            margin-bottom: 10px;
//            > span {
//                width: 100%;
//                font-weight: 600;
//                line-height: 1.63;
//                color: #282b3e;
//                letter-spacing: normal;
//                font-style: normal;
//                font-stretch: normal;
//                font-size: 16px;
//                font-size: 1rem;
//                display: inline-block;
//                //Applies to Normal/Default style
//                padding-right: 30px;
//                &::before {
//                    position: absolute;
//                    background: url($images_path_coremfc + 'arrow-right-white.svg') no-repeat center;
//                    background-size: 20px;
//                    content: '';
//                    width: 28px;
//                    height: 28px;
//                    display: inline-block;
//                    right: 0px;
//                    background-color: $icon-teaser-bg-color-white;
//                }
//                
//            }
//        }
//        &__title {
//            display: none;
//            color: #282b3e;
//        }
//        &__description {
//            color:#282b3e;
//            font-size: 1.125rem;
//            line-height: 1.44;
//            font-weight: 300; // ARPW-1387: issue 10-13,25-27 - due to zeplin guideline update. change from normal to light
//            display: block;
//            height: 52px;
//            overflow: hidden;
//            margin-left: 80px;
//            margin-right: 20px;
//            p,
//            h1,
//            h2,
//            h3,
//            h4,
//            h5,
//            h6,
//            .h0,
//            .h1,
//            .h2,
//            .h3,
//            .h4,
//            .h5,
//            .h6 {
//                margin: 0;
//            }
//        }
//    }
//    @media (max-width: 768px) {
//        & + & .cmp-icon-teaser__wrapper {
//            border-top: none;
//        }
//        .cmp-icon-teaser {
//            padding: 0px;
//            &__description {
//                margin-right: 0px;
//            }
//        }
//    }
//}
//
///* type.less */
.icon-teaser.cmp-icon-teaser--quicklink {
    .cmp-icon-teaser {
        &:hover .cmp-icon-teaser__wrapper {
            background-color: #ffffff;
            cursor: pointer;
            margin-top: 0;
            margin-bottom: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        &__wrapper {
            height: $icon-teaser-quicklink-height;
            background-color: $icon-teaser-bg-color-grey;
            -moz-transition: none;
            -webkit-transition: none;
            -o-transition: color 0 ease-in;
            transition: none;
            border: solid 1px #ededed;
            
            @media (max-width: 1025px)and (min-width:768px) {
                height: 220px;
            }
        }
        &__content {
            margin: 30px 40px 25px 40px;
        }
        &__icon {
//            display: block;
            width: 50px;
            height: 50px;
            margin-right: 20px;
            margin-bottom: 20px;
            display: inline-block;
            position: relative;
        }
        &__title-icon {
//            margin-bottom: 40px;
                width: 50px;
                margin-right: 20px;
                margin-bottom: 20px;
                display: inline-block;
                position: relative;
            
            @media (max-width: 1025px)and (min-width:768px) {
                display: block;    
                width: 30px;
                margin-right: 10px;
                margin-bottom: 10px;
            }
            > span {
                display: none !important;
            }
        }
        &__title {
            margin: 0;
            display: flex;
            align-items: center;
            height: 23px;
            //Applies to Normal/Default style
//            background-image: url($images_path_coremfc+'arrow-right-white.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: top left;
            padding-left: 30px;
            margin-left: 75px;
            
            @media (max-width: 1025px) and (min-width:768px) {
                margin-left: 0;
            }
            
            > .cmp-content-teaser__title-link {
                font-size: 14px;
                font-size: 0.875rem;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: normal;
            }
        }
        &__description {
            margin: 0 0 15px 0;
            height: auto;
            max-height: 55px;
            width: calc(100% - 75px);
            display: inline-block;
            position: relative;
            vertical-align: top;
            
            
            @media (max-width: 1025px) and (min-width:768px) {
                width: 100%;
            }
        }
        
        @media (max-width: 1025px) and (min-width:768px) {
            width: 100%;
        }
        @media (max-width: 768px) {
            border-top: none;
            &__wrapper {
                height: auto;
                border: solid 1px #ededed;
            }
            &__content {
                margin: 40px 20px 40px 40px;
            }
            &__content-wrapper {
//                .flex-direction-column();
                flex-direction: column;
            }
            &__icon {
                margin-right: 0px;
            }
            &__title-icon {
                width: 50px;
                margin-right: 20px;
                margin-bottom: 20px;
                display: inline-block;
                position: relative;
            }
            &__description {
                width: calc(100% - 75px);
                margin-bottom: 20px;
                display: inline-block;
                position: relative;
                vertical-align: top;
            }
            &__title {
                margin-left: 74px;
                height: unset;
                min-height: 23px;
                max-height: 55px;
            }
        }
    }
    // remove top border if 2 consecutive quicklink
    @media (max-width: 768px) {
        & + & .cmp-icon-teaser__wrapper {
            border-top: none;
        }
    }
}
/** Icon teaser type - Simple **/

.icon-teaser.cmp-icon-teaser--simple {
    .cmp-icon-teaser {
        &:hover .cmp-icon-teaser__wrapper {
            margin-top: 0;
            margin-bottom: 0;
            /*-webkit-box-shadow: @icon-teaser-simple-hover-box-shadow;
            -moz-box-shadow: @icon-teaser-simple-hover-box-shadow;
            box-shadow: @icon-teaser-simple-hover-box-shadow;*/
//            border-color: $text-color-tab-border;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        &__wrapper {
            /*-moz-transition: none;
                -webkit-transition: none;
                -o-transition: color 0 ease-in;
                transition: none;*/
            -moz-transition: all 0.25s;
            -webkit-transition: all 0.25s;
            -o-transition: all 0.25s;
            transition: all 0.25s;
        }
        &__title-icon span {
            padding-right: 0px;
            &::before {
                background: none;
                background-size: 0px;
                width: 0px;
                height: 0px;
                display: none;
                right: 0px;
                background-color: none;
            }
        }
    }
}
/* for developing only*/
//.aem{
//    &.icon-teaser .cmp-icon-teaser{
//        padding: 0;
//    }
//}
/* for developing only*/

