.funds {
  margin-top: 0px;

  @media #{mediaUp($break-point-1920)} {
      padding: 0;
  }
    
    .fund-compare-btn-scetion{
        text-align: right;
        align-self: flex-start;
        margin-left: auto;
        padding-left: 15px;
        

        @media #{mediaMobile()} {
          padding-left: $grid-indentSmallPx;
          padding-right: $grid-indentSmallPx;
          padding-top: $grid-indentSmallPx;
            margin-left: 0;
            width: 100%;
            align-items: stretch;
        }
        
        .fund-compare-btn{
            cursor: pointer;
            @media #{mediaMobile()} {
                width: 100%;
                box-sizing: border-box;
                text-align: center;
            }
        }
    }

  & .funds-filters {
    position: relative;
    display: flex;
    align-items: flex-end;
//    background-color: dark(7);
      
      @media #{mediaMobile()} {
          flex-wrap: wrap;
      }
  }

  & .funds-wrapper-content {
    transition: transform 500ms ease;

    @media #{mediaMobile()} {
      padding-bottom: 0;
      transform: translate3d(0, 0px, 0);
    }
  }

  @media #{mediaMobile()} {
    margin-top: 30px;
  }
}
.bottom-btn-link{
    display: flex;
    align-items: flex-end;
    justify-content: right;    
    margin-top: 50px;
    
    @media #{mediaMobile()} {
        flex-wrap: wrap;
    }
    
    a{
        width: 100%;
    }
}
.compare-bottom-btn{
    align-self: flex-end;
    margin-left: 0;
    text-align: right;
    width: 100%;
    flex: 1 0;
    
    @media #{mediaMobile()} {
        min-width: 100%;
        flex: 0;
        margin: 0;
        padding: 0 40px;
        text-align: center;
    margin-top: 20px;
            box-sizing: border-box;
    }
    
        .fund-compare-btn{
            
            @media #{mediaMobile()} {
                width: 100%;
            }
        }
}

.details-bottom-btn{
    
    @media #{mediaMobile()} {
        min-width: 100%;
        flex: 0;
        margin: 0;
        padding: 0 40px;
        text-align: center;
            box-sizing: border-box;
    }
    
    .col{
        margin: 0;
    }
    
    .button.button-info{
        text-align: center;
    }
    
}
/* update the white box style */
.funds-page .responsivegrid.cmp-layout-container--full-width{
    position: inherit;
}