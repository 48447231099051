.styled-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 0;
  
  &.has-side-image {
    margin-right: $side-image-width;

    @media #{mediaDown($break-point-1024)} {
      margin-right: 0;
    }
  }

  @media #{mediaDown($break-point-1280)} {
    margin-left: 0;
  }

  @media #{mediaDown($break-point-1024)} {
    margin-right: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  overflow: hidden;

  & h1 {
    margin-top: $grid-indentLargePx;
    font-size: 40px;
    line-height: 1.25;

    @media #{mediaUp($break-point-1920)} {
      font-size: 50px;
      line-height: 1.2;
    }

    @media #{mediaDown($break-point-1920)} {
      margin-top: 70px;
    }

    @media #{mediaMobile()} {
      margin-top: 30px;
      font-size: 30px;
      line-height: 38px;
    }
  }
}

.wrapper-top {
  position: relative; // #TODO: ${props => (!props.version2 ? 'relative' : 'static')};
}

.container-wrapper {
  display: flex;
  min-height: 100vh;
}
