$right-border-color: rgba(dark(2), 0.1);
/* overright the clientlib-base.css  */
@media(max-width:768px) {
      .aem-Grid>.aem-GridColumn.aem-GridColumn--phone--hide.right-panel-override {
        display: block;
    }
}

.right-panel { // Right
  position: relative;
  display: flex;
  background: dark(6);
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px solid $right-border-color;
    z-index: 2;
  }

  & p {
    font-size: 18px;
    margin: 0;
    font-family: 'Manulife JH Sans',sans-serif;
  }

  @media #{mediaUp($break-point-1280)} {
    position: relative; // #TODO: ${props => (!props.version2 ? 'absolute' : 'fixed')};
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    background: dark(7);
  }

}

.right-content { // RightContent
  position: sticky;
  top: 0;
  width: 100%;
  height: 565px;
  overflow-x: hidden;
  overflow-y: auto;

  &__wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  @media #{mediaDown($break-point-768)} {
    height: auto;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid rgba(66, 69, 89, 0.1);
      z-index: 1;
    }
  }
}

.right-scrollbar { // StyledScrollbar
  $scrollWidth: 17px;
  position: absolute;
  top: 0;
  left: 0;
  right: -#{$scrollWidth};
  bottom: -#{$scrollWidth};
  overflow: scroll;
  padding-right: $scrollWidth;

  &::-webkit-scrollbar {
    width: 0;
  }

  &:not(.view) {
    display: none;
  }

    @media #{mediaMobile()} {
        padding-right: 0px;
    }
  @media #{mediaDown($break-point-768)} {
      padding-right: 0;
    &.view {
      position: relative !important;
      margin-bottom: 0 !important;
      overflow: hidden !important;
    }
  }
}
