.column-container{
    margin: 0;
}
.colunm-header-section{
    background: dark(7);
    padding: 20px 0;
    font-weight: 600;
    border-bottom: 2px solid dark(5);
}

.colunm-list-item{
    padding: 25px 0;
    border-bottom: 2px solid dark(5);
    &:hover{
        background: dark(5);
        
        .pink-arrow{
            img{
                padding: 0;
            }
        }
    }
}

.item-details{
    display: flex;
    align-items: center;
}

.colunm-list-date{
    color: dark(4);
}

.column-list-detail{
    flex:1;
}

.pink-arrow{
    flex: 0;
    padding-left: 10px;
    
    img{
        padding: 2px;
        width: 22px;
        height: 22px;
        display: block;
        
    }
}
.colunm-list-item-link{
    text-decoration: none;
    color: inherit;
    
}