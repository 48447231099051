.search-result {
  margin-bottom: 50px;

  &__filters {
    h1 {
      margin-top: 80px;
      margin-bottom: 20px;
      font-size: 40px;
    }

    .filter-search {
      border-color: $chart-circleBorderColor;
    }

    .filter-search-input {
      font-size: 24px;
    }
  }

  &__header {
    h1 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 600;
    }
  }

  &-title {
    position: relative;
    z-index: 12;

    .tile {
      background: none;
    }
  }

  .highlight {
    font-weight: bold;
  }

  .tags-filter-button {
    margin-top: 10px;
    font-size: 16px;
  }

  .services-list{
    font-size: 16px;

    &-heading {
      margin-right: 25px;
      font-size: 16px;

      span {
        border-bottom: 1px solid $color-green;
      }
    }

    &-subHeading {
      margin-right: 25px;
      font-size: 16px;
    }

    &-funds-tag, &-date{
      font-size: 16px;
    }
  }

  & .fund-tile {
    position: relative;
    z-index: 12;
  }

  .tile {
    &-icon-video {
      min-width: 20px;
      min-height: 20px;
      position: absolute;
      bottom: 20px;
      left: 20px;
    }

    &-date {
      margin-bottom: 10px;
    }
  }

  .fund-tile__col {
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  @media #{mediaUp($break-point-768)} {

    .services-list-heading,
    .services-list-subHeading {
      margin-right: 25px;
    }

    .fund-tile__col {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  @media #{mediaUp($break-point-1280)} {
    .margin-bottom-medium {
      margin-bottom: 20px;
    }

    .fund-tile__col {
      flex-basis: 85%;
      max-width: 100%;
    }
  }

  @media #{mediaDown($break-point-768)} {
    .search-result__filters h1 {
      margin-top: 50px;
    }
    .margin-bottom-medium {
      margin-bottom: 20px;
    }

    .fund-tile__col {
      flex-basis: 100%;
      max-width: 100%;
    }

    .services-list-heading,
    .services-list-subHeading {
      margin-right: 0;
    }

    .filter-search-field {
      height: 45px;
    }
  }
}
