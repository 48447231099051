$list-blue: #000060, #000082, #00009a, #0000c1, #1e1ee5, #2d69ff, #76b0ff, #c1d8f7;

$list-coral: #c14a36, #dc5a44, #ec6453, #ff7769, #f69082, #fcaca1, #f6ccc7, #f6dcd8;

$list-dark: #282b3e, #34384b, #424559, #5e6073, #8e90a2, #ededed, #fafafa, #ffffff;

$list-gold: #a75900, #ce7612, #e38400, #f49600, #f9ab2e, #fcc457, #f8d38a, #fbe9c6;

$list-green: #004427, #036a3d, #06874e, #00a758, #00c46e, #5cd790, #ace5c4, #caeed9;

$list-turquoise: #0b9189, #08a298, #05b2a7, #06c7ba, #28d7cb, #6ae7df, #9df3ed, #c5f4f1;

$list-violet: #0f011d, #1d0833, #260b42, #361558, #533573, #8877ab, #beb4d3, #d9d2e8;

@function get-color($list, $index) {
  $index: $index + 1;
  @return nth($list, $index);
}

@function blue($index) {
  @return get-color($list-blue, $index);
}

@function coral($index) {
  @return get-color($list-coral, $index);
}

@function dark($index) {
  @return get-color($list-dark, $index);
}

@function gold($index) {
  @return get-color($list-gold, $index);
}

@function green($index) {
  @return get-color($list-green, $index);
}

@function turquoise($index) {
  @return get-color($list-turquoise, $index);
}

@function violet($index) {
  @return get-color($list-violet, $index);
}

@function chart-color($index) {
  @return get-color($list-chart-color, $index);
}

$color-blue: blue(3);
$color-coral: coral(3);
$color-dark: dark(0);
$color-gold: gold(3);
$color-green: green(3);
$color-turquoise: turquoise(3);
$color-violet: violet(3);
$color-white: dark(7);
$color-grey: dark(5);

$article-color-blue: $color-blue;
$article-color-violet: $color-violet;
$article-color-green: $color-green;

$background-color: dark(7);
$line-top-color: rgba(66, 69, 89, 0.1);
$button-hover-background-color: coral(2);
$nav-back-button-arrow-color: $color-coral;
$button-background-color: coral(3);
$header-background-color-small: dark(7);
$border-color: rgba(26, 27, 35, 0.1);
$header-background-color-small: dark(7);

// chart colors
$list-chart-color: green(4), coral(1), blue(3);
$chart-circleBorderColor: rgba(37, 38, 45, 0.2);
$chart-tooltipBackgroundColor: rgba(16, 17, 24, 0.9);
$chart-tooltipFontColor: $color-white;

// menu
$menu-itemHoverBackgroundColor: dark(0);

// colors
$text-color: $color-dark;

$download-button-background-color:rgb(255, 93, 85);
$download-button-hover-background-color:rgb(248, 69, 60);

