$color-date-in-range: #00c46e;
$color-date-selected: #06874e;
$color-date-text: #424559;
.details-datepicker{
    z-index: 107;
}
.ui-widget{
    &.ui-widget-content{
        margin-top:20px;
        .ui-datepicker-next.ui-state-hover, .ui-datepicker-prev.ui-state-hover{
            border: 0;
            background: transparent;
        }
    }
    &-header{
        background: transparent;
        border: 0;
        .ui-datepicker-next{
            
            .ui-icon{
                background-image: url($images_path+"arrow-right.png");
            }
        }
        .ui-icon{
            background-image: url($images_path+"arrow-left.png");
            background-size: cover;
            background-position: center;
        }
    }


}
.ui-state-default, .ui-widget-content .ui-state-default{
    border: 0;
    background: transparent;
    text-align: center;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    line-height: 25px;
}
.ui-state-default.ui-state-hover{
    background: green(3);
    color: dark(7);
    border-radius: 100%;
    width: 30px;
    height: 30px;
}
.ui-state-active, .ui-widget-content .ui-state-active{
    background: green(3);
    border-radius: 100%;
    width: 30px;
    height: 30px;
    
}


.ui-widget{
    &.ui-widget-content{
        margin-top:20px;
        .ui-datepicker-next.ui-state-hover, .ui-datepicker-prev.ui-state-hover{
            border: 0;
            background: transparent;
        }
    }
    &-header{
        background: transparent;
        border: 0;
        .ui-datepicker-next{
            
            .ui-icon{
                background-image: url($images_path+"arrow-right.png");
            }
        }
        .ui-icon{
            background-image: url($images_path+"arrow-left.png");
            background-size: cover;
            background-position: center;
        }
    }


}
.ui-state-default, .ui-widget-content .ui-state-default{
    border: 0;
    background: transparent;
    text-align: center;
}
.ui-state-default.ui-state-hover{
    background: green(3);
    border-radius: 50px;
    color: dark(7);
}
.ui-state-active, .ui-widget-content .ui-state-active{
    background: green(3);
    border-radius: 50px;
    
}


.date-picker {
  position: relative;
//  margin: 0 #{-$grid-sideMargin_px};
//
//  @media #{mediaMobile()} {
//    margin: 0 #{-$grid-sideMargin_320_px};
//    margin-left: calc(-#{$grid-sideMargin_320_px} - env(safe-area-inset-left));
//    margin-right: calc(-#{$grid-sideMargin_320_px} - env(safe-area-inset-right));
//  }
//
//  @media #{mediaUp($break-point-1920)} {
//    margin: 0 #{-$grid-sideMargin_1920_px};
//  }
  &-column {
    display: flex;
    align-items: center;
    flex: 0 1 auto;


    @media #{mediaDown($break-point-1024)} {
      flex-basis: 100%;
      justify-content: center;
    }
  }
}

.date-icon {
  margin-right: 25px;

  @media #{mediaMobile()} {
    display: none;
  }
}

.date-bar {
  position: relative;
  padding: 40px 0;
  background: $dateRangePicker-backgroundColor;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    border-top: 1px solid $dateRangePicker-border;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-top: 1px solid $dateRangePicker-border;
  }

  // @media #{mediaMobile()} {
  //   justify-content: center;
  // }

  .row {
    justify-content: space-between;    
//    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and #{mediaUp($break-point-1440)}{
//      justify-content: flex-start;
//    }
  }

  .container {
    padding-right: 0;
    padding-left: 0;
    @media #{mediaMobile()} {
      margin: 0px 0px;
    }
  }

  
}

.bar-scroll {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 0px;
  margin-bottom: 0px;
}

.date-input {
  cursor: pointer;
  background-color: $dateRangePicker-bgColor;
  padding: 5px 5px;

  @media #{mediaUp($break-point-768)} {
    padding: 10px 15px;
  }

  @media #{mediaUp($break-point-1440)} {
    padding-left: 40px;
    padding-right: 40px;
  }


  svg {
    transform: rotate(90deg);
    margin-top: 2px;
    vertical-align: top;
  }

  

  &-wrapper {
    position: relative;
    margin-left: 9px;
    font-size: 14px;
    line-height: 24px;
    float: left;

    
    &.active {
      &::before {
        border-bottom: 2px dashed transparent;
      }
    }

    &:first-of-type {
      margin-left: 0;
    }

    & > input {
      max-width: 3em;
      margin: 0;
      padding: 0;
      background: none;
      border: none;
      border-radius: 0;
      outline: none;
      color: $dateRangePicker-color;
      font-family: $font-regular;
      font-size: 14px;
      line-height: 24px;
      -webkit-appearance: none;
      float: left;
      box-shadow: none;
      cursor: pointer;

      &.inactive {
        color: $dateRangePicker-inactiveColor;
      }
    }

    & > span {
      color: $dateRangePicker-color;
      float: left;
      cursor: pointer;

      &.inactive {
        color: $dateRangePicker-inactiveColor;
      }
    }
    
    

    @media #{mediaMobile()} {
      &,
      & > input {
        font-size: 12px;
      }
    }
  }

    
  input.input-date-range{
    border: none;
    background: none;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
      &:focus{
        background: none;
        outline: none;
      }
      @media #{mediaMobile()} {
          width: 80%;
      }
  }
}

.date-separator {
  width: 19px;
  margin: 0 18px;
  border-top: 2px solid $dateRangePicker-color;

  @media #{mediaMobile()} {
    width: 10px;
  }
}

.date-shortcut {
  
  padding: 10px 25px;
  margin: 0px;
  background-color: $dateRangePicker-bgColor2;
  border: 0;
  border-radius: 0;
  outline: 0;
  
  color: $dateRangePicker-color;
  font-family: $dateRangePicker-fontFamily;
  font-size: 12px;
  cursor: pointer;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  word-break: keep-all;

  &.active {
    color: $dateRangePicker-activeColor;
    pointer-events: none;
  }

  + .date-shortcut {
    border-left: 1px solid $dateRangePicker-color;
  }

  @media #{mediaDown($break-point-1280)} {    
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
  }
  

  

  @media #{mediaUp($break-point-1440)} {    
    padding-left: 40px;
    padding-right: 40px;
  }

  

  &-column {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex: 0 1 auto;
    align-items: center;

    

    @media #{mediaMobile()} {
      overflow-x: scroll;
    }

    @media #{mediaDown($break-point-1280)} {     
      margin-top: 20px;
      flex-basis: 100%;
    }

//    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) and #{mediaUp($break-point-1440)}{
//      flex-grow: 1;
//      -ms-flex-pack: end;
//    }
  }



}

.date-calendar {
  margin-top: -1px;
  background: $dateRangePicker-backgroundColor;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: max-height ease-in 200ms;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid $dateRangePicker-border;
  }

  &.active {
    max-height: 500px;
    height: auto;
  }
}

.date-range-picker {
  transform: translate3d(0, -50px, 0);
  opacity: 0;
  transition: transform 500ms ease, opacity 250ms ease;
    padding: 40px 0 40px;
  width: 100%;

  &.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }

  .ui-datepicker-inline {
    width: auto !important;
    border: none !important;    

    * {
      font-family: $font-regular;
    }

    &::after,
    &::before {
      content: none;
    }

    &.ui-widget-content {
      display: flex !important;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: center;
      max-width: 90%;
      margin: 0 auto;
      position: relative;

      @media #{mediaMobile()} {
        flex-direction: column;
      }
    }

    .ui-datepicker-group {
      width: auto !important;
    }

    .ui-datepicker-buttonpane {
      display: none;
    }

    .ui-datepicker-title {
      position: relative;
      background: $color-white;
      border: none;
      margin: 0;
      text-align: left;
      height: 80px;
      visibility: collapse;

      .ui-datepicker-month,
      .ui-datepicker-year {
        position: absolute;
        left: 0;
        visibility: visible;
        display: inline-block;
      }

      .ui-datepicker-year {
        top: 0;
        font-size: 22px;
        line-height: 26px;
        font-family: $font-regular;
        opacity: 0;

        &.show {
          opacity: 1;
        }
      }

      .ui-datepicker-month {
        bottom: 0;
        font-weight: 400;
        // font-size: 12px;
      }
    }

    .ui-datepicker-header {
      background: $color-white;
      border: none;
      width: 100%;
      max-width: 260px;
      margin: 0 20px 0;

      @media #{mediaUp($break-point-1280)} {
        max-width: 300px;
      }
    }

    .ui-datepicker-calendar {
      width: 100% !important;
      max-width: 260px;

      thead {
        th {
          opacity: 0.5;
        }
      }

      tbody {
        .ui-state-highlight,
        .ui-widget-content .ui-state-highlight,
        .ui-widget-header .ui-state-highlight {
          background-color: $color-white;
          color: $color-date-text;
        }

        .ui-state-default {
          position: relative;
          background-color: $color-white;
          border: none;
          padding: 4px 11px;
          margin: 0 -1px;
          text-align: center;
          vertical-align: middle;
          color: $color-date-text;
          line-height: 13px;
          font-size: 12px;
          width: 40px;
        }

        .ui-state-active {
          color: $color-date-text;
        }

        .init-range {
          position: relative;
        }

        .ui-state-hover,
        .init-range .ui-state-default,
        .end-range .ui-state-default,
        .start-range .ui-state-default,.ui-state-active {
          color: $color-white;
          font-weight: bold;
          background-color: transparent;
          z-index: 1;

          &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            width: 21px;
            height: 21px;
            border-radius: 50%;
            background-color: $color-date-selected;
            transform: translateX(-50%);
            z-index: -1;
          }
        }

        .start-range .ui-state-default,
        .end-range .ui-state-default {
          &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: $color-date-in-range;
            z-index: -1;
          }
        }

        .start-range .ui-state-default {
          &::before {
            left: 50%;
          }
        }

        .end-range .ui-state-default {
          &::before {
            right: 50%;
          }
        }

        .in-range .ui-state-default {
          position: relative;
          background-color: $color-date-in-range;
          color: $color-white;
        }
          
      }

      @media #{mediaUp($break-point-1280)} {
        max-width: 300px;
      }
    }

    .ui-datepicker-header{
      position: static;
    }

    .ui-datepicker-prev,
    .ui-datepicker-next {      
      position: absolute;
      top: calc(50% - 20px);
      right: auto;
      left: auto;
      width: 30px;
      height: 40px;
      margin-top: 0;
      background-size: 12px 20px;
      color: inherit;
      outline: none;
      display: inline-block;
      background-position: 50%;
      background-repeat: no-repeat;
      cursor: pointer;
      background-color: dark(5);
     display: flex;

      span {
        display: none;
      }

      &:hover {
        background-color: $color-white;
        border: none;
        outline: none;
      }
    }

    .ui-datepicker-prev {
      left: 0;
      margin-right: 0;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUBAMAAABL3sEiAAAAJ1BMVEUAAABVVapER1xDRltDRltDRVtERlpCRVpDRllCRVlDRllDRlpCRVnaCnosAAAADHRSTlMAA1ZXX2t0oqOm/P2ih2lqAAAAN0lEQVQI12NgwAAeAiCSZc9EEOV95jiYc2YxmHPKAM6JBnPYzoA5TBAxqAqYEkzucSSjoRYhAAC6URyN4glPGAAAAABJRU5ErkJggg==");
    }

    .ui-datepicker-next {
      right: 0;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUBAMAAABL3sEiAAAAAXNSR0IArs4c6QAAACdQTFRFAAAAVVWqREdcQ0ZbQ0ZbQ0VbREZaQkVaQ0ZZQkVZQ0ZZQ0ZaQkVZ2gp6LAAAAAx0Uk5TAANWV19rdKKjpvz9oodpagAAADZJREFUCNdjYEABjC1gSvK0A4iqObMFRFmdAXOZ1+DiKoCpMwlgwa1wJVAVUPVQw6BGQy1CAQDQEBxvDVLpwwAAAABJRU5ErkJggg==");
    }
  }

  @media #{mediaDown($break-point-1024)} {
    border-bottom: 1px solid $dateRangePicker-border;
  }
}
/* override coremfc */
.ui-datepicker-prev, .ui-datepicker-next{
    display: flex;
}

.ui-datepicker-month, .ui-datepicker-year{
    display: inline-block;
}
.ui-datepicker .ui-datepicker-title{
    min-height: auto;
    font-weight:bold;
}
.ui-datepicker table thead{
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}