.gallery {
  position: relative;
  min-height: 604px;// #TODO ${props => props.height}px;
  color: dark(7);
  background-color: dark(3);
  overflow: hidden;

  @media #{mediaUp($break-point-1280)} {
    margin-right: 300px;
  }

  @media #{mediaUp($break-point-1920)} {
    margin-right: 400px;
  }
}

.gallery-header {
  margin-top: 100px;
  font-size: 50px;
  line-height: 50px;
  white-space: pre-line;

  @media #{mediaUp($break-point-1920)} {
    margin-top: 200px;
    font-size: 72px;
    line-height: 75px;
  }

  @media #{mediaUp($break-point-1280)} {
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -1.5px;
  }

  @media #{mediaMobile()} {
    font-size: 34px;
    letter-spacing: -0.7px;
  }
}

.gallery-text {
  margin-top: 30px;
  margin-bottom: 100px;
  font-size: 15px;
  line-height: 26px;
  white-space: pre-line;

  @media #{mediaUp($break-point-1920)} {
    margin-top: 60px;
    font-size: 22px;
    line-height: 34px;
  }

  @media #{mediaDown($break-point-1024)} {
    margin-bottom: $grid-indentMediumPx;
  }
}

.gallery-button {
  @extend .button;
  margin-bottom: 130px;

  @media #{mediaUp($break-point-1920)} {
    margin-bottom: 290px;
  }

  @media #{mediaDown($break-point-1024)} {
    margin-bottom: 100px;
  }
}

.slider {
  display: flex;
  background-color: dark(2);

  &-control {
    position: absolute;
    bottom: 40px;
    display: flex;
    align-items: baseline;
    z-index: 3;

    & > p:last-child {
      margin-right: 0;
    }
  }
}

.slide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: dark(0);
  opacity: 0.2;
}

.gallery-wrapper {
  padding-top: $grid-indentLargePx;
  opacity: 0;
  transform: translateX(0);
  transition: transform 700ms ease, opacity 700ms ease;
  // #TODO transform ${props => (props.forward ? '700ms ease' : '1000ms ease-out')}, opacity 700ms ease;
  transition-delay: 200ms; // #TODO ${props => (props.forward ? 200 : 100)}ms;

  @media #{mediaDown($break-point-1920)} {
    padding-top: 70px;
  }

  @media #{mediaMobile()} {
    padding-top: 30px;
    transition-delay: 0ms;
  }
}

.slide { //Slide
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  &:first-child {
    transform: translateX(0);

    & .slider-wrapper:first-child {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &-count { //SlideCount
    margin-right: 45px;
    font-family: $font-bold;
    font-weight: bold;
    color: dark(7);
    cursor: pointer;
    transition: transform 400ms ease;
    transform-origin: bottom;
  }
}

.progress-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.bar { //Bar
  width: 100%;
  height: 100%;
  transform-origin: left;
  transition: transform 100ms linear;
  background-color: green(3);
}
