.insights-header {
  display: flex;
  align-items: flex-end;
  padding: 0 0 $grid-indentSmallPx;

  @media #{mediaMobile()} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
  }

  & > div,
  & > h1 {
    flex: 1 0 auto;
  }
}

.insights-filter {
  flex: 0 0 auto;
  margin-left: auto;
  justify-content: flex-end;

  @media #{mediaMobile()} {
    justify-content: flex-start;
    height: auto;
    margin: 30px 0 0;
  }
}
