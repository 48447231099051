.top-links-wrapper { // LinksWrapper
  display: flex;
  flex-direction: column;

  & > a { // StyledLink override Link component
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    min-height: 90px; // #TODO ${props => (!props.version2 ? '90px' : '120px')};
    padding: 20px 30px 20px 90px; // #TODO ${props => (!props.version2 ? '20px 30px 20px 90px' : '35px 30px 35px 90px')};
    margin: 0;
    color: dark(0);
    text-decoration: none;

    & + a {
      margin: 0;
    }

    & > span {
    position: absolute;
    display: inherit;
    }

    &:hover {
      &::after {
        background: dark(6);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 60px;
    }

    &.calendar {
      &::before {
        background-color: #361558;
      }
    }

    &.notification {
      &::before {
        background-color: #00a758;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-bottom: 1px solid rgba(66, 69, 89, 0.1);
      z-index: -1;
    }

  @media #{mediaBetween($break-point-768, $break-point-1280)} {
      width: 50%;

      &::after {
        border: none;
      }
    }

    @media #{mediaUp($break-point-1920)} {
      min-height: 90px;// # TODO ${props => (!props.version2 ? '90px' : '165px')};
      padding: 20px 30px 20px 90px; // #TODO ${props => (!props.version2 ? '20px 30px 20px 90px' : '40px 40px 40px 110px')};
      font-size: 16px;

      &::before {
        width: 70px;
      }
    }
  }

  @media #{mediaBetween($break-point-768, $break-point-1280)} {
    position: relative;
    flex-direction: row;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid rgba(66, 69, 89, 0.1);
    }
  }
}

.top-links-icon {
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;

  @media #{mediaUp($break-point-1920)} {
    width: 70px;
  }
}
