.document-download-section{
    display: flex;
    justify-content: flex-end;
    padding-bottom: 30px;
    
    @media #{mediaMobile()} {
        padding-bottom: 30px;
    }
    .cart-btn{
        cursor: pointer;
        &:first-child{
            padding-right: 10px;
        }
    }
    .cart-download-btn{
        
        border: 1.5px solid coral(3);
    }
    .clear-doc-btn{
        padding: 19px 30px;
        border: 1.5px solid coral(3);
        
        &:hover{
            background: coral(3);
            color: dark(7);
        }
    }
}
.filter-btn{
    
    @media #{mediaMobile()} {
        width: 100%;
        padding-top: 10px;
    }
    .compare-filter-btn{
        @media #{mediaMobile()} {
            width: 100%;
            text-align: center;
        }
        
        &.dim{
            cursor: none;
            pointer-events: none;
            color: dark(7);
            border:none;
        }
    }
}

.document-filter-input{
    position: absolute;
    padding: 10px 30px 10px 10px;
    top: 13px;
    width: calc(100% - 80px);
    left: 25px;
    z-index: 100;
    background: dark(6);
    border: 0;
    color: dark(0);
    font-size: 16px;
    display: none;
    
    &.active {
        display: block;
    }
    
    &:focus{
        outline: none;
    }
}
.document {
//    margin-top: $grid-indentLargePx;
    min-height: 100vh;
            
    @media #{mediaMobile()} {
        margin-top: 0;
    }
    
    .no-result-section{
        display: none;
    }
    
    .compare-filter-btn{
        cursor: pointer;
    }

    .document-filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
        
        @media #{mediaDown($break-point-1024)} {
            width: 100%;
        }
        @media #{mediaMobile()} {
            flex-wrap: wrap;
            width: 100%;
        }

    }
  
    .document-header{
        position: relative;
        padding: 30px 0 35px;
        font-size: 40px;
        line-height: 1;
    }
    
    .result{
        margin-bottom: $grid-indentLargePx;
    }

    .result-item{
//        border-top: 2px solid dark(5);
//        padding: 15px 0;
        padding-right: 20px;
        position: relative;
        display: flex;
        width: 100%;
        align-items: stretch;
        
        &::before, &:after{
            content: '';
            position: absolute;
            left: calc(-30px);
            right: calc(-30px);
            z-index: -1;
        }
        &::before{
            border-top: 2px solid dark(5);
            top:0;
        }
        &:last-child::after{
            
            border-bottom: 2px solid dark(5);
            bottom: 0;
        }
        &:hover{
            &::after{
                background: dark(5);
                height: 100%;
            }

            .document-title{
                color:dark(1);
            }
            
            .document-icon{
                path{
                    &:first-child{
                        stroke:dark(1);
                        fill:none;
                    }
                    fill:dark(1);
                }
            }
            
            .document-arrow{
                circle{
                    fill:$color-coral;
                }
                path{
                    fill:#fff;
                }
            }
        }
        
        
        &.hidden{
            display: none;
        }
        
        a {
            display: flex;
            text-decoration: none;
            color:dark(4);
            width: 100%;
            padding: 15px 0;
            
        }
        
    }
    
    .doc-checkbox-section{
        position: relative;
            width: 15px;
            height: 15px;
        
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            z-index: 10;
            margin: 0;
            width: 20px;
            height: 20px;
        }
        
        .checkmark {
            position: absolute;
            height: 18px;
            width: 18px;
//            background-color: red;
            border: 2px solid dark(4);
            &:after{
                content: "";
                position: absolute;
                display: none;
                left: 4.5px;
                top: -0.5px;
                width: 8px;
                height: 14px;
                border: solid green(3);
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
        
        &:hover{
            input ~ .checkmark {
//              background-color: #ccc;
                border: 2px solid  green(3);
            }
        }
        input:checked ~ .checkmark {
//          background-color: green(3);
            border: 2px solid  green(3);
        }
        input:checked ~ .checkmark:after {
          display: block;
        }
    }
    .document-icon{
        display: inline-block;
        vertical-align: text-bottom;
        width: 25px;
        height: 25px;
        display: flex;
        max-width: 30px;
//        flex-grow: 1;
//        flex-shrink: 0;
        flex:1 0 30px;
        margin-top: -5px;
        
        path{
            &:first-child{
                stroke:dark(4);
                fill:none;
            }
            fill:dark(4);
        }
    }
    .document-title{
        display: inline-block;
        font-size: 18px;
        line-height: 20px;
        display: flex;
//        flex-basis: auto;
//        flex-grow: 2;
        flex: 2 1 auto;
        
    }
    .document-description{
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .document-arrow{
        display: inline-block;
        margin: 5px 0;
        display: flex;
//        flex-basis: 30px;
        max-width: 30px;
        vertical-align: middle;
        margin: auto 0;
//        flex-grow: 1;
//        flex-shrink: 0;
        flex: 1 0 30px;
        
    }
    
    .filter-type-span{
        & > svg{
            width:25px;
            height: 25px;
        }
    }
    
    .documnet-filiter{
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 0;
        height: 60px;
        z-index: 2;
//        border-top: solid 3px green(3);
//        border-left: solid 3px green(3);
        border-bottom: dashed 3px dark(0);
        background-color: transparent;
        transition: flex 500ms ease;
        cursor: pointer;
        margin-right: 30px;
        
        &:last-child{
            margin-right: 0;
        }
        
        
        @media #{mediaMobile()} {
            min-width: 100%;
            margin-right: 0px;
        }
        
        &.filter-type-field{
            &.active{
                .select-items-doc{
                    display: block;
                }

                .filter-type-span{
//                    background: green(3);

                    & > svg{
                        & > path:first-child{
                            fill:none;
                            stroke:green(3);
                        }
                        & > path{
                            fill:green(3);
                        }
                    }
                }


                .filter-arrow-span{
                    transform: rotate(270deg);
                }
            }
            
            .select-items-doc{
                @media #{mediaMobile()} {
//                    left:calc(-100% - 46px);
                }
            }
            
        }
        
        &.filter-fund-field {
                    
            @media #{mediaMobile()} {
                z-index: 3;
            }
            &.active{
                
                .select-items-doc{
                    display: block;
                }

                .filter-fund-span{
//                    background: green(3);


                    & > svg{
                        & > path{
                            fill:green(3);
                            stroke:green(3);
                        }
                    }
                }

                .filter-arrow-span{
                    transform: rotate(270deg);
                }
            }
        }
        &.filter-provider-field {
                    
            @media #{mediaMobile()} {
                z-index: 10;
            }
            &.active{
                
                .select-items-doc{
                    display: block;
                }

                .filter-provider-span{
//                    background: green(3);


                    & > svg{
                        & > path{
                            fill:green(3);
                            stroke:green(3);
                        }
                    }
                }

                .filter-arrow-span{
                    transform: rotate(270deg);
                }
            }
        }
    }
    
    .filter-provider-field{
        flex: 0.8 0;
    }
    .filter-type-field {
        flex: 0.8 0;
    }
    
    .dim{
        
        pointer-events: none;
        color: dark(4);
        border-color: dark(4);
        
        .filter-arrow-span{
            svg{
                path{
                    fill:dark(4);
                }
            }
        }
        &.filter-type-field{
            .filter-type-span{

                & > svg{
                    & > path:first-child{
                        fill:none;
                        stroke:dark(4);
                    }
                    & > path{
                        fill:dark(4);
                    }
                }
            }
            
        }
        
        &.filter-fund-field {

            .filter-fund-span{
                & > svg{
                    & > path{
                        fill:dark(4);
                        stroke:dark(4);
                    }
                }
            }
        }
        
    }
    
}
/*doc msg box overlay*/
.doc-msg-overlay-section{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    z-index: 1007;
    top: 0;
    left: 0;
    display: none;
}
.doc-overlay-msg-box{
    background: #fff;
    width: 40%;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    min-height: 30vh;
    padding: 50px 40px;
    
    
    @media #{mediaMobile()} {
        width: 80%;
    }
    
    .doc-msg{
        padding-bottom: 50px;
    }
    
    .button-info{
        cursor: pointer;
    }
}
