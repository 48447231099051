.filter-pin-button {
  width: 30px;
  height: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;

  @media #{mediaMobile()} {
    width: 40px;
    min-width: 40px;
  }
}

.filter-pin-number {
  margin-left: 5px;
  opacity: 0.5;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;
  color: dark(0);
}

.filter-field {
  position: relative;
  display: flex;
  align-items: center;
//  flex: 2 0;
    flex: 0 1 auto;
  height: 40px;
  padding: 0 20px 0 0;
  z-index: 2;
//  border-top: solid 3px green(3);
//  border-right: solid 3px green(3);
//  border-bottom: solid 3px green(3);
//  border-left: solid 3px green(3);
  border: solid 2px dark(5);
  border-radius: 30px;
//  background-color: dark(6);
  background-color: dark(7);
  transition: flex 500ms ease;
  cursor: pointer;
    
    .group-active &{
        min-width: 60px;
        
        .filter-pin-text{
            display: none;
            @media #{mediaMobile()} {
                display: block;
            }
        }
        .filter-pin-number{
            display: none;
        }
    }

  @media #{mediaMobile()} {
    flex: 1 0;
      margin-bottom: 5px;
  }
}

.filter-pin-text {
  margin-left: 10px;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;

  @media #{mediaMobile()} {
//    display: none;
  }
}

.filter-field-pin {
  @media #{mediaMobile()} {
    display: flex;

    &.collapsed {
      display: none;
    }
  }

  & > .filter-pin-text {
    @media #{mediaDown($break-point-1280)} {
      // #TODO: opacity: ${props => (props.collapsed ? 0 : 1)};
      // #TODO: transition-delay: ${props => (props.collapsed ? 0 : 300)}ms;
    }
  }

  & > .filter-pin-button {
    background-color: transparent;

    & > svg path {
        &:last-child{
            stroke: dark(2);
            fill: dark(2);
        }
    }

    @media #{mediaMobile()} {
      background-color: transparent;
    }
  }

  @media #{mediaMobile()} {

    &.filtered-by-pin {
      background-color: green(3);
    }

    & > .filter-pin-number {
      color: dark(0);
      opacity: 0.5;

      .filtered-by-pin {
        color: dark(7);
        opacity: 1;
      }
    }
  }

    &:hover{
      border: solid 2px green(3);
    }    
  &.active {
      border: solid 2px green(3);
      background: green(3);
      color: dark(7);
        & > button {
    //      background-color: green(3);

            & > svg path {
                green: dark(3);
                
                &:last-child{
                    stroke: dark(7);
                    fill: dark(7);
                }
            }
        
        }
      
        .filter-pin-number{
            color: dark(7);
        }
  }
}