.fund-sort {
  //margin-top: 30px;
  padding: 30px 0;
  border-bottom: 2px solid rgba(66, 69, 89, 0.1);

  &__item {
    margin: 5px 10px 10px 0px;
    display: inline-block;
  }

  &__label,
  &__action {
    display: inline-block;
    vertical-align: middle;
  }

  &__label {
    margin-right: 6px;
  }

  &__action {
    line-height: 0;
    font-size: 0;
    min-width: 90px;
  }

  &__sort-link {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 2px #00AA59;
    border-radius: 100%;
    position: relative;
    margin: 0 1px;
    box-sizing: border-box;
    cursor: pointer;

    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 5px;
      left: 4px;
      width: 8px;
      height: 9px;
      background-size: contain;
      background-repeat: no-repeat;
    }

    &::before {
      background-image: url($images_path + "arrow-filter-sort.svg");
    }

    &::after {
      opacity: 0;
      background-image: url($images_path + "arrow-filter-active.svg");
    }

    &--down {
      &::after,
      &::before {
        transform: rotate(180deg);
        top: 2px;
      }
    }

    &.active {
      border: 2px solid transparent;
      background-color: #00AA59;

      &::before {
        opacity: 0;
      }

      &::after {
        opacity: 1;
      }
    }
  }
}
