.side-image {
  position: fixed;
  width: 15vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
  right: 0;

  & > div {
    position: sticky;
    top: 0;
    right: 0;
    height: 100vh;
    background-size: cover;
    background-color: dark(5);
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  @media #{mediaDown($break-point-1024)} {
    display: none;
  }
}