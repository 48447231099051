/* Carousel styles */

.aem-GridColumn.carousel
{
    // Cancels out clientlib-site/site/css/grid.less padding set on all 'aem-gridColumn'
    padding: 0;
    position: relative;

    .cmp-carousel__videolayer {
        position: absolute;
        left: 0; top: 0; right: 0; bottom: 0;
        background-color: black;
    }

    &.hidden {
		height: 0;
        overflow: hidden;
    }
}

.cmp-carousel__videolayer
{
    transition: opacity 0.25s;
    opacity: 0;
    pointer-events: none;
    z-index:3;

    .close-button {
        height: 40px;
        width: 40px;
        position: absolute; top: 0; right: 0;
        box-sizing: border-box;
        line-height: 40px;
        display: inline-block;
        background-color: black;
        cursor: pointer;
        z-index:4;
    }
    .close-button:before, .close-button:after {
        transform: rotate(-45deg);
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1.5px;
        margin-left: -12.5px;
        display: block;
        height: 3px;
        width: 25px;
        background-color: #fff;
        transition: all 0.25s ease-out;
    }
    .close-button:after {
        transform: rotate(-135deg);
    }
    .close-button:hover:before, .close-button:hover:after {
        transform: rotate(0deg);
        background-color: red;
    }

    #cmp-carousel__ytplayer,
    .cmp-carousel__ytplayer {
        position: absolute;
        top: 75px; left: 0; right: 0; bottom: 75px;
        width: 100%;
        height: calc(100% - 150px);
    }
}

.cmp-carousel__videolayer.active {
    opacity: 1;
    pointer-events: auto;
}
@media (max-width: 1024px) {
    .aem-GridColumn.carousel {
        width: 100vw !important;
    }
}

//Phone breakpoint
@media (max-width: 767px) {
     .aem-GridColumn.carousel {
         height: 460px;
         overflow: hidden;
     }
     .cmp-carousel:not(.no-js) {
         height: 100%;
     }
     html[class*='aem-AuthorLayer'] {
       .aem-GridColumn.carousel {
         height: 645px;
       }
     }
}

.cmp-carousel:not(.no-js) {
    max-height: 645px;
}

.cmp-carousel
{
    // Slick JS Overrides
    .slick-dots
    {
        bottom: 40px;
        text-align: left;
        padding: 0 70px;

        button::before {
            color: white;
            opacity: 0.5;
            font-size: 30px;
        }

        li.slick-active button::before {
            opacity: 1;
            color: white;
        }
    }

    // 1920px Desktop carousel treatment
    @media (min-width: 1920px)
    {
        .slick-dots {
            padding: 0 90px;
        }
    }

    // Mobile carousel treatment
    @media (max-width: 1024px)
    {
        .slick-dots {
            padding: 0 30px;
        }
    }

} // .cmp-carousel

.cmp-carousel__slide{
    position: relative;
    display: block;
    height: 645px;
    max-height: 645px;
    padding: 120px 80px 40px;
    color: dark(7);
    display: flex;
    flex-direction: column;

    // absolute - exists outside of document flow - does not take/add any height
    .cmp-carousel__imagelayer{
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
        text-align: center;
        overflow: hidden;

        img {
            position: relative;
            left:auto; top: auto; bottom: auto;
            width: 100%;
            height: 100%;
            transform: none;
            object-fit: cover;
            font-family: 'object-fit: cover;'; // IE fallback for object-fit
            
            @media (min-width: 1920px) {
                &.cmp-carousel__image-desktop {
                    display: block;
                }
                &.cmp-carousel__image-mobile {
                    display: none;
                }
            }
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            left: 0; right: 0; top: 0; bottom: 0;

            background-color: dark(3);
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .cmp-carousel__contentlayer {
        position: relative;
        height: 100%;
    }

    .cmp-carousel__slideTitle {
        max-width: 84.617%;
        width: 84.617%;
        max-height: 204px;
        display: block;
        overflow: hidden;
        word-break: break-word;

        p {
          font-weight: 300;
        }

        b {
          font-weight: 600;
        }
    }

    .cmp-carousel__slideTitle,
    .cmp-carousel__slideSubtitle {
        width: 100%;
        max-width: 100%;
        p {
            margin: 0;
        }
    }

    .cmp-carousel__slideTitle {
        font-size: 3.625rem;
        line-height: 1.17;
    }
    .cmp-carousel__slideSubtitle {
        margin-top: 20px;
        font-size: 1rem;
        line-height: 1.63;
        word-break: break-word;
    }
    .cmp-carousel__slideCTA {
        display: inline-block;
        margin-top: 40px;
    }

    .cmp-carousel__playVideoBtn {
        width: 80px; height: 80px;
        margin-top: 40px;
        cursor: pointer;
        background: transparent url($images_path_coremfc+'carousel-video-play-initial.svg') no-repeat center / contain;
    }
    .cmp-carousel__playVideoBtn:hover,
    .cmp-carousel__playVideoBtn:focus
    {
        background-image: url($images_path_coremfc+'carousel-video-play-hover.svg');
    }

    @media (min-width: 1920px)
    {
        padding: 120px 100px 40px;
    }

    // Mobile carousel treatment
    @media (max-width:1024px)
    {
        padding: 100px 40px 40px;

        .cmp-carousel__slideTitle {
            font-size: 2.25rem;
            max-height: 168px;
            display: block;
            overflow: hidden;
        }
        .cmp-carousel__slideSubtitle {
            display: none;
        }
    }
} // .cmp-carousel__slide


// Default Variables

$slick-loader-path: "./";
$slick-arrow-color: white;
$slick-dot-color: black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "←";
$slick-next-character: "→";
$slick-dot-character: "•";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    min-height: 1px;
    
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}

.cmp-carousel {
  &--billboard {
//    .extend-left();

    &.aem-GridColumn {
      padding-right: 10px;
    }

    .cmp-carousel:not(.no-js) {
        max-height: 400px;
        height: 400px;
    }

    .cmp-carousel {
      &__slide {
        height: 400px;
        max-height: 400px;
        padding: 40px 85px 40px 40px;

        .cmp-carousel {
          &__slideTitle {
            width: 98.667%;
            max-width: 98.667%;
            max-height: 116px;
            font-size: 3rem;
            font-weight: 300;
            line-height: 1.21;
          }

          &__slideSubtitle {
            width: 98.685%;
            max-width: 98.685%;
          }

          &__slideCTA {
            margin-top: 20px;

            .cmp-text__cta--primarybtn {
              padding-top: 16.5px;
              padding-bottom: 16.5px;
            }
          }

          &__playVideoBtn {
            margin-top: 20px;
          }
        }
      }

      &__imagelayer {
        background-color: dark(3);
      }
    }

    .slick-dots {
      padding: 0 30px;
    }
  }
}
