.filter-search-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  padding-left: 10px;
min-width: 30px;
}

.filter-search-field {
  position: relative;
  display: flex;
  align-items: center;
  flex: 2 0;
//    flex: 0;
  height: 40px;
  z-index: 2;
//  border-top: solid 3px green(3);
//  border-left: solid 3px green(3);
//  border-bottom: solid 3px green(3);
  border: solid 2px dark(5);
  border-radius: 30px;
  background-color: dark(7);
  transition: flex 500ms ease;
  cursor: pointer;
    
    &:hover{
        border-color: green(3);
    }

  & > label {
    width: 100%;
    padding: 20px 20px 20px 0;
    cursor: pointer;
  }

  @media #{mediaMobile()} {
    flex: 1 0;
  }
}

.filter-search {
  z-index: 1;
  border-color: dark(5);
  background-color: dark(7);
  transition: flex 500ms ease;
  cursor: pointer;
//  flex: 1 0;
    flex: 0 1 auto;
    
    .group-active &{
        min-width: 60px;
        z-index: 1;
        width: 60px;
    }
    

  & > label {
    display: flex;
    align-items: center;
    height: 100%;
  }

  & > svg {
    min-width: 20px;
    cursor: pointer;
  }
//
//  @media #{mediaUp($break-point-1920)} {
//    flex: 2 0;
//  }

  @media #{mediaDown($break-point-1024)} {
    flex: 0 0 60px;
  }

  @media #{mediaMobile()} {
    transition: flex 0ms;
    flex: 1 0;
  }

  &.disabled {
    border-color: dark(5);
  }

  &.expanded {
    border-color: green(3);
    flex: 4 0;
      
      .filter-search-input{
          width: 100%;    
      }
      

    @media #{mediaUp($break-point-1920)} {
      flex: 4 0;
    }

    @media #{mediaDown($break-point-1024)} {
      flex: 4 0;
    }

    @media #{mediaMobile()} {
      flex: 4 0;
    }
  }
}

.filter-search-input {
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  margin-left: 10px;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
    
    .group-active &{
        width: 100%;
    }
    @media #{mediaMobile()} {
        width: 100%;
    }

  &[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

    @media #{mediaMobile()} {
        width: 100%;
    }
  @media #{mediaDown($break-point-1024)} {
//    margin-left: 0;
//
//    &::-webkit-input-placeholder {
//      color: transparent;
//    }
//
//    &:-moz-placeholder {
//      color: transparent;
//    }
//
//    &::-moz-placeholder {
//      color: transparent;
//    }
//
//    &:-ms-input-placeholder {
//      color: transparent;
//    }
  }
}

.filter-search-button-reset {
  position: relative;
  left: -15px;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  opacity: 0;

  &.active {
    opacity: 1;
  }
}