.bottom-links-wrapper { // RightLinksWrapper
  position: relative;
  display: flex;
  flex-direction: column;

  & > a {
    position: relative;
    align-items: center;
    flex-wrap: wrap;
    min-height: 120px;
    width: 100%;
    margin: 0;
    display: flex; // #TODO ${props => (!props.version2 ? 'flex' : 'inline-block')};
    //padding: 30px 0 30px 30px;// #TODO ${props => (!props.version2 ? '30px 0 30px 30px' : '30px')};
    padding: 30px;
    color: dark(2);
    text-decoration: none;
    transition: opacity 200ms ease;

    & + a {
      margin: 0;
    }

    &:hover {
      opacity: 1 !important;
    background: dark(7);

      &::before {
        background: dark(6);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      border-right: 1px solid rgba(66, 69, 89, 0.1);
      border-bottom: 1px solid rgba(66, 69, 89, 0.1);
      z-index: -1;
    }

    @media #{mediaUp($break-point-1920)} {
      padding: 30px; // #TODO ${props => (!props.version2 ? '30px' : '40px')};
    }

    @media #{mediaUp($break-point-1280)} {
      &::before {
        border-right: none;
      }
    }

    @media #{mediaDown($break-point-1280)} {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &::before {
        border-bottom: none;
      }
    }

    @media #{mediaBetween($break-point-768, $break-point-1280)} {
//      flex: 0 0 260px;
//      width: 260px;
        width: 100%;
        box-sizing: border-box;
    }

    @media #{mediaMobile()} {
      flex: 0 0 270px;
      width: 270px;
    }
  }

  @media #{mediaDown($break-point-768)} {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }

  @media #{mediaBetween($break-point-768, $break-point-1280)} {
//    width: calc(100vw - #{$nav-size_px});
    width: 100%;
  }

  @media #{mediaMobile()} {
    width: auto;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.bottom-links-icon { // BottomLinks Icon
  display: flex;
  flex: 0 0 auto;
  margin-left: auto;// #TODO ${props => (!props.version2 ? 'auto' : 0)};

    @media #{mediaBetween($break-point-768, $break-point-1280)} {
        margin-top: 5px;
    }
  @media #{mediaDown($break-point-1280)} {
    margin-top: auto;
    margin-left: 0;
  }
  
    @media #{mediaMobile()} {
        margin-left: auto;
    }

  & > svg {
    margin-right: 25px;
  }

  & > p {
    font-size: 12px;
    letter-spacing: 1px;
    color: dark(2);

    @media #{mediaUp($break-point-1280)} {
      display: none;
    }
      
      @media #{mediaMobile()} {
          display: none;
      }
  }
}

.bottom-links-title { // BottomLinks BlockTitle
  margin-bottom: 10px;
  color: dark(4);
  font-size: 12px;

  @media #{mediaUp($break-point-1920)} {
    margin-bottom: 30px;
    font-size: 16px;
  }
}
.bottom-links-date{
    width: 100%;
    align-self: stretch;
  margin-top: 0;
  margin-right: 10px; // #TODO ${props => (!props.version2 ? 10 : 0)}px;
  margin-bottom: 0;
    p{
        font-size: 16px !important;
    }
}
.bottom-links-text { // BottomLinks BlockText
  align-self: stretch;
  margin-top: 0;
  margin-right: 10px; // #TODO ${props => (!props.version2 ? 10 : 0)}px;
  margin-bottom: 0; // #TODO ${props => (!props.version2 ? 0 : styles.grid.indentSmallPx)}px;
  color: #282b3e;
  font-size: 1.125rem;
  line-height: 1.44;
  font-weight: 300;

    
    @media #{mediaBetween($break-point-768, $break-point-1280)} {
        min-width: 100%;
        margin: 0;
        padding: 0;
    }

  & > p {
    margin: 0;
  }

  @media #{mediaUp($break-point-1920)} {
    margin-bottom: 30px;
  }
}
