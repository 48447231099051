.tabs-wrapper {
  background-color: dark(7);

  @media #{mediaUp(1024)} {
    padding: 0 $grid-sideMargin_px;
  }

  @media #{mediaUp(1920)} {
    padding: 0 $grid-sideMargin_1920_px;
  }

  @media #{mediaDown(1024)} {
    height: 122px;
  }
}
.scroll{
       @media #{mediaMobile()} {
           overflow-x: scroll !important;
            &::after,&::before{
                content: " ";
                z-index: 10;
                display: block;
                position: absolute;
                background: rgba(255, 255, 255, 0.6);
            }
            &.tabs::after{
                height: 38px;
                width: 10%;
                right: 20px;
                background: linear-gradient(to right,rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
            }
            &.tabs::before{
                height: 38px;
                width: 10%;
                left: 20px;
                background: linear-gradient(to left,rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1));
            }
        
           &.graph-wrapper::after{
               height:35px;
               width: 20%;
               right: 0px;
               top:-2px;
               background: linear-gradient(to right, rgba(255, 255, 255, 0.3), #fafafa 90%);
           }
           &.graph-wrapper::before{
               height:35px;
               width: 15%;
               left: 0px;
               top:-2px;
               background: linear-gradient(to left, rgba(255, 255, 255, 0.3), #fafafa 90%);
           }
           
           &.date-shortcut-column::after{
                background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 60%);
                height: 38px;
                width: 15%;
                right: 0px;
           }
           &.date-shortcut-column::before{
                background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 60%);
                height: 38px;
                width: 15%;
                left: 0px;
           }
           
           
           
           &.hidden-before::before{
               display: none;
           }
           &.hidden-after::after{
               display: none;
           }
           
           &.destop::before,&.desktop::after{
               display: none;
           }
    }
}

.tabs {
  display: flex;
  justify-content: space-between;
  box-pack: justify;
  -webkit-box-pack: justify;
  padding-top: $grid-indentLargePx;
    
    .hidden{
        display: none !important;
    }

  @media #{mediaDown($break-point-1024)} {
    padding: $grid-indentLargePx 0 10px;
    // white-space: nowrap;
    // overflow: auto;
    overflow-x: scroll;
  }

 
    
  & > p {
    position: relative;
    cursor: pointer;
    width: 13%;
    border: 1px solid $color-grey;
    text-align: center;
    padding: 15px 0;
      margin: 0;
      flex-basis: 100%;
    

      @media #{mediaDown($break-point-1280)} {
          
        width: auto;
        padding: 0 7px;
        padding-top:5px;
        padding-bottom: 8px;
          
      }
    @media #{mediaDown($break-point-1024)} {
        display: inline-block;
        margin-right: 20px;
        flex: 1 0 auto;
        padding: 0 10px;
        padding-top:5px;
        padding-bottom: 8px;
    }

    @media #{mediaUp($break-point-1024)} {
      &:hover span {
        opacity: 1;
      }
    }

    &.active {
      pointer-events: none;
      background: dark(6);

      & > span {
        opacity: 1;
      }
    }
  }

  & > p span {
    position: absolute;
    bottom:0px;
    left:0;
    display: block;
    opacity: 0;
    width: 100%;
    height: 4px;
    background-color: green(3);
    transition: opacity 500ms ease;
  }
}

.hidden-tab {
  display: none;
}
