// Manulife JH Sans & Serif

@font-face {
  font-family: 'Manulife JH Sans Light';
  src: url($font_path + 'manulife/manulife-jh-sans-light.woff2') format('woff2'),
    url($font_path + 'manulife/manulife-jh-sans-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans Regular';
  src: url($font_path + 'manulife/manulife-jh-sans-regular.woff2') format('woff2'),
    url($font_path + 'manulife/manulife-jh-sans-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans Bold';
  src: url($font_path + 'manulife/manulife-jh-sans-bold.woff2') format('woff2'),
    url($font_path + 'manulife/manulife-jh-sans-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Serif Italic';
  src: url($font_path + 'manulife/manulife-jh-serif-italic.woff2') format('woff2'),
    url($font_path + 'manulife/manulife-jh-serif-italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

// Noto Sans CJK JP

//@font-face {
//  font-family: 'Noto Sans CJK JP Thin';
//  font-style: normal;
//  font-weight: 100;
//  font-display: swap;
//  src: url($font_path + 'noto-sans/noto-sans-cjk-jp-thin.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Noto Sans CJK JP Light';
//  font-style: normal;
//  font-weight: 400;
//  font-display: swap;
//  src: url($font_path + 'noto-sans/noto-sans-cjk-jp-light.otf') format('opentype');
//}
//
//@font-face {
//  font-family: 'Noto Sans CJK JP Bold';
//  font-style: normal;
//  font-weight: 900;
//  font-display: swap;
//  src: url($font_path + 'noto-sans/noto-sans-cjk-jp-bold.otf') format('opentype');
//}


// VN-vi font Noto Sans
 

@font-face {
  font-family: 'Noto Sans VN vi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.ttf') format('truetype'),
       url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.woff') format('woff'),
       url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Noto Sans Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-700.ttf') format('truetype'),
       url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-700.woff') format('woff'),
       url($font_path + 'noto-sans-vn/noto-sans-v8-vietnamese_latin-700.woff2') format('woff2');
}
