.filter-select-checkbox{
    .select-items-doc{
        /*
        input[type="checkbox"] {
            display:none;
        }
        
        input[type="checkbox"] + label span{
            display:inline-block;
            width:15px;
            height:15px;
            background:url(/images/tickbox.png) no-repeat;
            background-size: contain;
            margin-right: 5px;
            cursor:pointer;
        }
        input[type="checkbox"]:checked + label span {
            background:url(/images/tickbox_tick.png) no-repeat;
            background-size: contain;
        }
        */
        

        div{
            padding: 20px 15px;
            padding-left: 35px;
            display: block;

            &.active-ele{
                background: dark(5);
                border-left: 0;
                padding-left: 35px;
            }

            &:hover{
                background: dark(5);
                border-left: 0;
                padding-left: 35px;
            }
        }
        
        .check-container{
            position: relative;
        }
        input{
            position: absolute;
            opacity: 0;
            cursor: pointer;
            z-index: 10;
            top: 22px;
            left: 10px;
            margin: 0;
            width: 20px;
            height: 20px;
        }
        .checkmark {
            position: absolute;
            top: 22px;
            left: 10px;
            height: 18px;
            width: 18px;
//            background-color: red;
            border: 2px solid dark(4);
        }
        
        .check-container:hover input ~ .checkmark {
//          background-color: #ccc;
                border: 2px solid  green(3);
        }
        
        .check-container input:checked ~ .checkmark {
//          background-color: green(3);
            border: 2px solid  green(3);
        }
        
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .check-container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .check-container .checkmark:after {
            left: 4.5px;
            top: -0.5px;
            width: 8px;
            height: 14px;
            border: solid green(3);
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        
    }
}