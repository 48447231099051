.services-notice-header {
  display: flex;

  @media #{mediaMobile()} {
    flex-direction: column;

    & button {
      margin-bottom: 10px;
    }
  }

  & > div {
    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 0;

    @media #{mediaMobile()} {
      justify-content: flex-start;
    }
  }

  & > div,
  & > h1 {
    flex: 1 0 auto;
  }
}
