
//.aem-Grid>.aem-GridColumn.aem-GridColumn--default--none{
//    min-width:30%;
//}


#overlay {
    width:100%;
    height:100vh; 
    background-color:dark(0);
    position: fixed; 
    left:0; 
    right:0; 
    z-index: 1004; 
    display: none;
}
#overlay.active {display:block;  }

.video-player-youtube {
  & iframe {
    width: 100%;
    height: 100%;
  }
}

.video-player-close-button {
  position: absolute;
//  bottom: 100px;
  top:10px;
  right: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  pointer-events: none;

  & > svg {
  }

  & > span {
    margin: 0 10px 0 5px;
    line-height: 29px;
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &.hidden {
    display: none;
  }
}

/* For perview only */
.content-teaser{
    .cmp-content-teaser__header{
        min-height: 0px;
        margin: 0;
    }
    .insight-date{
        color: #282b3e;
        min-height: 20px;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 10px 0;
        overflow: hidden;
        max-height: 52px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: -o-ellipsis-lastline;
    }
    
    .insight-icon {
            width: 40px;
            height: 40px;
            background: url("icon/html-page-black.svg");
            z-index: 10;
            position: absolute;
            right: 30px;
            top:20px;

            &.html-page{
                background: url("icon/html-page-black.svg");
            }

            &.pdf-file{
                background: url("icon/pdf-file-black.svg");
            }

            &.video-file{
                background: url("icon/video-black.svg");
            }
    }
}

.cmp-content-teaser {

    &--blue,
    &--cyan,
    &--jade,
    &--orange,
    &--purple {
        .insight-date{
            color:#ffffff;
        }
        
        .insight-icon {
            background: url("icon/html-page-white.svg");
            
            
            &.html-page{
                background: url("icon/html-page-white.svg");
            }

            &.pdf-file{
                background: url("icon/pdf-file-white.svg");
            }

            &.video-file{
                background: url("icon/video-white.svg");
            }
        }
    }
}
.cmp-content-teaser {

    &__image--background {
        .insight-date{
            color:#ffffff;
        }
        
        .insight-icon {
            background: url("icon/html-page-white.svg");
            
            
            &.html-page{
                background: url("icon/html-page-white.svg");
            }

            &.pdf-file{
                background: url("icon/pdf-file-white.svg");
            }

            &.video-file{
                background: url("icon/video-white.svg");
            }
        }
    }
}

/* For perview only END */