.article-header-image {
  height: calc((100vw - (290px + #{$nav-size_1920_px})) / 3);
  background: url("/images/home-gallery.jpg") 50% 50% no-repeat;
  background-size: cover;
  background-position: center;

  @media #{mediaDown($break-point-1920)} {
    height: calc((100vw - (290px + #{$nav-size_px})) / 2);
  }

  @media #{mediaDown($break-point-1280)} {
    height: calc((100vw - #{$nav-size_px}) / 2);
  }

@media #{mediaMobile()} {
    height: calc(100vw / 2);
  }
}

.article-date-header {
  margin-bottom: 10px;

  & + h1 {
    margin-top: 0;
  }
}

.article-video-container {
  height: 518px;

@media #{mediaMobile()} {
    height: 156px;
  }

  @media #{mediaDown($break-point-1024)} {
    height: 295px;
  }

  @media #{mediaDown($break-point-1280)} {
    height: 400px;
  }
}

.article-number {
  font-size: 150px;
  font-family: $font-bold;
  font-weight: bold;
  line-height: 1;

  @media #{mediaDown($break-point-1920)} {
    font-size: 129px;
    line-height: 1.01;
  }

  @media #{mediaDown($break-point-1280)} {
    font-size: 113px;
    line-height: 0.97;
  }

  @media #{mediaDown($break-point-1024)} {
    font-size: 80px;
    line-height: 0.75;
  }

@media #{mediaMobile()} {
    margin-bottom: 30px;
    font-size: 90px;
    line-height: 0.67;
  }
}
