.contacts {
  & .content {
    display: flex;
    padding-bottom: 0;

    @media #{mediaMobile()} {
      flex-direction: column;
    }
  }

  .wrapper-contacts {
    position: relative;
    flex: 0 0 auto;
    width: 320px;
    height: 100vh;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      border-right: 1px solid #eee;
    }

    @media #{mediaDown(1920)} {
      width: 290px;
    }

    @media #{mediaMobile()} {
      height: auto;
      width: 100%;
    }

    @media #{mediaMobile()} {
      position: initial;

      &::before {
        content: none;
      }
    }
  }

  .contacts-info {
    width: 100%;
    margin-top: $nav-size_px;
    padding: 30px 0 30px 30px;
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;

    @media #{mediaMobile()} {
      margin-top: 0;
      padding: 30px 0 $grid-indentMediumPx 20px;
    }

    @media #{mediaUp($break-point-1920)} {
      margin-top: $nav-size_1920_px;
    }
  }

  .contacts-header {
    margin-bottom: 6px;
    font-size: 16px;
    line-height: 1.75;

    @media #{mediaMobile()} {
      margin-bottom: 40px;
      margin-left: env(safe-area-inset-left);
      font-size: 30px;
      font-family: $font-light;
      font-weight: 300;
      line-height: normal;
    }
  }

  .contacts-address {
    margin-bottom: 30px;
    font-size: 14px;
    font-family: $font-light;
    font-weight: 300;
    line-height: 1.71;

    & > span {
      display: block;
    }

    @media #{mediaMobile()} {
      margin-left: env(safe-area-inset-left);
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .contacts-label {
    display: none;
    font-size: 9px;
    font-family: $font-regular;
    font-weight: normal;
    letter-spacing: 1px;

    @media #{mediaMobile()} {
      display: block;
      margin-left: env(safe-area-inset-left);
    }
  }

  .phone-wrapper {
    display: flex;

    @media #{mediaMobile()} {
      margin-left: env(safe-area-inset-left);
    }
  }

  .contacts-phone {
    margin-left: 18px;
    font-size: 18px;
    color: #00a758;
    text-decoration: none;

    @media #{mediaMobile()} {
      margin-left: 0;
    }
  }

  .icon-wrapper {
    @media #{mediaMobile()} {
      display: none;
    }
  }

  .open-map {
    display: none;
    width: 100%;
    background: none;
    border: 0;
    border-bottom: solid 1px #eee;
    outline: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 20px 0 20px 20px;
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;

    @media #{mediaMobile()} {
      display: flex;

      & > svg {
        margin-left: env(safe-area-inset-left);
      }
    }

    & .minus {
      display: none;
    }

    &.opened {
      & .minus {
        display: block;
      }

      & .plus {
        display: none;
      }
    }
  }

  .open-map-text {
    margin-left: 20px;
    font-size: 12px;
    font-family: $font-light;
    font-weight: 300;
    letter-spacing: 1px;
  }

  .map-container {
    flex: 1 0 auto;
    background-color: grey;

    @media #{mediaMobile()} {
      height: 506px;
      display: none;

      &.expanded {
        display: block;
      }
    }
  }

  .contacts-map {
    width: 100%;
    height: 100%;
  }
}
