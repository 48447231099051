.fund-details-ditributor-tab-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  & > svg {
    margin-right: 20px;
  }
}

.distributor-header {
  @media #{mediaMobile()} {
    .fund-details-ditributor-tab-header {
      margin-top: 50px;
    }

    &:first-of-type {
      .fund-details-ditributor-tab-header {
        margin-top: 0;
      }
    }
  }
}
