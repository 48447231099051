.profile-selection-popup.fullscreen{
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.6);
    top: 0;
    left: 0;
    z-index: 1007;
    display: none;
}
.profile-selection-popup {
    .profile-section-box{
        background: #fff;
        width: 40%;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        min-height: 30vh;
        padding: 50px 40px;
        
        @media #{mediaMobile()} {
            width: 80%;
        }
        ul{
            width: auto;
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li{
            width: auto;
            padding: 10px 0;
            
            a{
                color: inherit;
            }
            
            span{
                cursor: pointer;
                &:hover{
                    border-bottom: 2px solid green(3);
                }
            }
            
            &.active{
                span{
                    border-bottom: 2px solid green(3);
                }
            }
        }
    }
    .profile-title{
        font-size: 30px;
        line-height: 34px;
        padding-bottom: 10px;
    }
}
