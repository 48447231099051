.button {
  display: inline-block;
  padding: 20px 50px 20px 30px;
  color: dark(2);
  font-family: $button-font-family;
  font-size: $button-font-size;
  letter-spacing: 1px;
  line-height: 1;
  text-decoration: none;

  &:hover {
    color: #fff;

    svg g {
      fill: dark(2);
    }
  }

  & > svg {
    margin-right: 10px;
    vertical-align: middle;
  }

  &.button-info {
    padding: 19px 30px;
    background-color: $button-background-color;
    color: #fff;

    &:hover {
      background-color: $button-hover-background-color;
    }
  }

  &.button-download {
    color: #fff;
    background-color: $download-button-background-color;
    min-width: 150px;
    white-space: normal;
    border-width: 0px;
    border-style: none;
    border-color: initial;
    border-image: initial;
    border-radius: 0px;
    padding: 20px;

    &:hover {
      background-color: $download-button-hover-background-color;
      border-color: $download-button-hover-background-color;
    }
  }
}
