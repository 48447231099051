.img-figure {
  margin: 0;

  &-medium {
    margin: 50px 0 0;
  }

  &-small {
    margin: 20px 0 0;
  }

  & img {
    margin-bottom: 0;

    &.image-margin {
      margin-bottom: $grid-indentSmallPx;
    }
  }
}

.hide-mobile {
  @media #{mediaMobile()} {
    display: none;
  }
}

.img-border{
    
    &-grey{
        border:2px solid dark(5);
    }
    
    img{
        display: block;
    }
}


.text-center{
    
    div{
        float: none !important;
        display: inline-block !important;
    }
    
    .image{
        float: none !important;
        display: inline-block !important;
        margin: 5px 0;
    }
}

