.animated-height {
  &.active {
    height: auto;
    display: block;
  }

  &:not(.active) {
    height: 0;
    display: none !important;
  }
}