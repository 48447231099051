.chart-container{
    width: 100%;
    min-height: 600px;
    overflow: hidden;
}
.bar-label{
//    word-break: normal;
    word-wrap: break-word;
     white-space:normal;
    width: 200px;
    
  @media #{mediaDown($break-point-1024)} {
    width: 110px;
    }
    
}
/*

g.highcharts-range-selector-group{
    transform: translate(0, -10px);
}
*/

.handle-bar-section{
    display: flex;
    width: 100%;
  @media #{mediaDown($break-point-1024)} {
        flex-wrap: wrap;
    }
}
.date-section{
    width: 35%;
    display: flex;
    justify-content: flex-end;
    
    @media #{mediaDown($break-point-1024)} {
        width: 100%;
        text-align: left;
        margin-top: 10px;
        padding-right: 15px;
        justify-content: center;
    }
    @media #{mediaMobile()} {
        padding-right: 0;
    }
    
    svg{
        vertical-align: bottom;
        margin-right: 5px;
    }
    .line-break{
        font-size: 25px;
        font-weight: 600;
        padding: 0 10px;
        margin: 10px 0;
    }
    .datepick{
        border-bottom: 1px dashed dark(0);
        position: relative;
        margin: 10px 0;
    }
    .datepick-input {
        width: 100%;
        border: 0;
        background: transparent;
        font-size: 14px;
        line-height: 25px;
        text-align: center;
        position: relative;
        z-index: 100;
        cursor: pointer;
        &:focus{
            outline: none;
        }
            
    }
    .date-arrow-span {
        position: absolute;
        right: 0;
        svg{
            transform: rotate(90deg);
        }
    }
}
.period-btn-section{
    display: flex;
    width: 65%;
    z-index: 10;
    overflow-x: auto;
    align-items: flex-end;
  @media #{mediaDown($break-point-1024)} {
        width: 100%;
    }
    
    .period-btn{
        border: 1px solid dark(5);
        border-radius: 20px;
        padding: 10px 25px;
        background: dark(7);
        margin-bottom: 10px;
        cursor: pointer;
        font-size: 14px;
        white-space: nowrap;

        & + .period-btn {
            margin-left: 10px;
        }
        
        &:hover{
            background: green(3);
            color: dark(7);
        }
        
        &.active{
            pointer-events: none;
            background: green(3);
            color: dark(7);
        }
    }
}

.tooltip{
    position: relative;
    .baseline-section {
        width: auto;
    }
    .baseline{
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
    }
}

