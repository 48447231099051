.article-1-header {
  margin-top: $nav-size_1920_px;
  margin-bottom: 10px;
  @media #{mediaDown($break-point-1920)} {
    margin-top: $nav-size_px;
  }

  @media #{mediaMobile()} {
    margin-top: 30px;
  }

  & + h1 {
    margin-top: 0;
  }
}

.article-1-text {
  margin-top: $grid-indentMediumPx;
}
