.fund-details-characteristics-text {
  margin-top: $grid-indentMediumPx;
  margin-bottom: $grid-indentSmallPx;

  &.no-body {
    margin-top: 0;
    margin-bottom: -20px;
  }
}
.js-container-chart{
//    display: table-cell;
    /* position: absolute; */
    width: 100%;
    
    @media #{mediaDown($break-point-1024)} {
        display: inherit;
        width: auto;
    }
}
.char-chart-title{
    padding-left: 10px;
    font-size: 22px;
    line-height: 32px;
}
.title-section {
    display: flex;
    background: #fff;
    padding: 10px 0;
    border-bottom: 1px solid dark(5);
    .char-name-title{
        padding-left: 10px;
        width: 200px;
        min-width: 200px;
        
        @media #{mediaDown($break-point-1024)} {
        width: 110px;
        min-width: 110px;
            
        }
    }
    .char-weight-title{
        padding-left: 3%;
        width: 100%;
        
        @media #{mediaDown($break-point-1024)} {
            padding-left: 4%;
        }
    }
}
.char-table-section{
    padding-bottom: 20px;
    
    .char-table-tilte{
        font-size: 22px;
        line-height: 32px;
        padding-left: 10px;
        padding-bottom: 15px;
    }
    
    .char-table{
        .table-line{
            display: flex;
            
            &:nth-child(odd){
                background: dark(5);
            }
            &:first-child{
                background-color: dark(7);
            }
            
            div{
                width: 50%;
                padding-left: 10px;
            }
        }
        .table-title{
            background-color: dark(7);
            padding-top:20px;
            padding-bottom:20px;
        }
        .table-name{
            padding-top:15px;
            padding-bottom:15px;
            
            
        }
        .table-weight{
            padding-top:15px;
            padding-bottom:15px;
        }
    }
}