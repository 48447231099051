.tile {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: calc(100% / 3 - #{$articles-gutter_px});
  max-width: calc(50% - #{$articles-gutter_px});
  margin: 10px;
  padding: 30px;
  background-color: #fff;
  color: $color-dark;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;

  @media #{mediaUp($break-point-1920)} {
    padding: 50px;
    max-width: calc(50% - #{$articles-gutter_px});
  }

  @media #{mediaBetween($break-point-768, $break-point-1024)} {
    flex: 1;
    min-width: calc(50% - #{$articles-gutter_px});
    max-width: 50%;
  }

  @media #{mediaDown($break-point-768)} {
    flex: 1 0 auto;
    width: calc(100% - #{$articles-gutter_px});
    height: calc((100vw - #{$grid-sideMargin_320_px} * 2));
    max-width: none;
    padding: $grid-indentSmallPx;
    color: $color-dark;
  }

  &.type-simple {
    border: solid 1px $color-grey;    
  }
  
  &.large-tile {
    flex: 10;
    min-width: calc(50% - #{$articles-gutter_px});
    max-width: calc(100% / 3 * 2 - #{$articles-gutter_px});

    @media #{mediaUp($break-point-1920)} {
      max-width: calc(100% / 3 * 2 - #{$articles-gutter_px});
    }

    @media #{mediaBetween($break-point-768, $break-point-1024)} {
      min-width: 100%;
    }

    @media #{mediaDown($break-point-768)} {
      max-width: none;
      flex: 1 0 auto;
    }
  }

  &.only-video {
    padding: 20px;

    @media #{mediaUp($break-point-1920)} {
      padding: 30px;
    }

    @media #{mediaDown($break-point-768)} {
      height: calc((100vw - #{$grid-sideMargin_320_px} * 2) / 1.7777777);
    }
  }

  &.type-image-1, &.type-image-2 {
    padding-right: calc((100% / 3 - #{$articles-gutter_px}) / 3);

    @media #{mediaUp($break-point-1920)} {
      padding-right: calc((100% / 3 - #{$articles-gutter_px}) / 3);
    }

    @media #{mediaBetween($break-point-768, $break-point-1024)} {
      padding-right: calc((100% / 2 - #{$articles-gutter_px}) / 3);
    }

    @media #{mediaDown($break-point-768)} {
      padding-right: calc((100% - #{$articles-gutter_px}) / 3);
    }
  }

  &.type-video {
    color: $color-white;
  }

  &.type-image-1, &.type-image-2 {
    & .tile-title {
      max-width: none;
      margin-top: auto;
      font-size: 20px;
      line-height: 28px;

      @media #{mediaUp($break-point-1920)} {
        max-width: none;
        font-size: 24px;
        line-height: 30px;
      }

      @media #{mediaDown($break-point-1280)} {
        max-width: none;
        font-size: 20px;
        line-height: 28px;
      }
    }
  }

  &.type-image-1, &.type-color {
    color: $color-white;
  }

//  &.type-image-1, &.type-image-2, &.type-video {
//    & .tile-sub-title {
//      display: none;
//    }
//  }


  &.type-image-1, &.type-image-2, &.type-color, &.type-video {
     @media #{mediaDown($break-point-768)} {
      color: $color-white;
    }
  }

  &.type-color {

    &-blue {
      background-color: $article-color-blue;
    }

    &-violet {
      background-color: $article-color-violet;
    }

    &-green {
      background-color: $article-color-green;
    }
  }

  & .hidden {
    display: none;
  }

  &.hidden {
    display: none;
  }
}

.tile-date {
  margin-bottom: $grid-indentSmallPx;
  font-size: 12px;
  line-height: 1;
  z-index: 1;

  @media #{mediaMobile()} {
    margin-bottom: 5px;
  }

  @media #{mediaUp($break-point-1920)} {
    max-width: 450px;
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.tile-icon{
    width: 40px;
    height: 40px;
    background: url($images_path + "insights/icon/html-page-black.svg");
    z-index: 10;
    position: absolute;
    right: 30px;
    top:27px;
    @media #{mediaMobile()} {
        right: 20px;
    }
    
    &.html-page{
        background: url($images_path + "insights/icon/html-page-black.svg");
        
        &.black{
            background: url($images_path + "insights/icon/html-page-black.svg");
        }
        
        &.white{
            background: url($images_path + "insights/icon/html-page-white.svg");
        }
    }
    
    &.pdf-file{
        background: url($images_path + "insights/icon/pdf-file-black.svg");
        
        &.black{
            background: url($images_path + "insights/icon/pdf-file-black.svg");
        }
        
        &.white{
            background: url($images_path + "insights/icon/pdf-file-white.svg");
        }
    }
    
    &.video-file{
        background: url($images_path + "insights/icon/video-black.svg");
        
        &.black{
            background: url($images_path + "insights/icon/video-black.svg");
        }
        
        &.white{
            background: url($images_path + "insights/icon/video-white.svg");
        }
    }
}

.tile-title {
  position: relative;
  max-width: 100%;
  //max-height: 192px;
  font-size: 32px;
  line-height: 48px;
  overflow: hidden;
  z-index: 1;

  @media #{mediaUp($break-point-1920)} {
    //max-height: 192px;
    font-size: 40px;
    line-height: 68px;
  }

  @media #{mediaDown($break-point-1280)} {
    //max-height: 152px;
    font-size: 24px;
    line-height: 38px;
  }

  @media #{mediaMobile()} {
    .has-color-image & {
      margin-top: auto;
      order: 1;
    }
  }
}

.tile-sub-title {
  max-width: 100%;
  margin-top: auto;
  font-size: 20px;
  line-height: 28px;
  z-index: 1;
    margin: 0;

  @media #{mediaMobile()} {
//    display: none;
  }

  @media #{mediaDown($break-point-1280)} {
//    max-width: 200px;
  }

  @media #{mediaUp($break-point-1920)} {
//    max-width: 450px;
    font-size: 24px;
    line-height: 30px;
  }
}

.tile-icon-video {
  margin-top: auto;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;

  &--large {
    width: 80px;
    height: 80px;
  }


  @media #{mediaMobile()} {
    &--large {
      width: 60px;
      height: 60px;
    }
  }
}

.tile-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @media #{mediaUp($break-point-1920)} {
    bottom: 0;
  }

  @media #{mediaDown($break-point-1024)} {
    bottom: 0;
  }

  @media #{mediaMobile()} {
    left: 0;
    bottom: 0;
    background-position: 50% 50%;
  }

  .type-image-2 & {
    left: 75%;
    @media #{mediaMobile()} {
      left: 0;
    }
  }

  .has-color-image & {
    top: auto;
    bottom: -32%;
    left: auto;
    width: 50%;
    height: 114%;
    background-size: contain;

    @media #{mediaUp($break-point-1920)} {
      bottom: -34%;
    }

    @media #{mediaDown($break-point-1024)} {
      bottom: -28%;
    }

    @media #{mediaMobile()} {
      position: relative;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background-position: -17% 50%;
    }
  }
}
