.fund-risk-main-title, .fund-risk-text {
  margin-bottom: $grid-indentSmallPx;

  & > p {
    margin: 0;
  }

  & + ul {
    margin-top: 0;
  }
}

.fund-risk-text {
  margin-top: $grid-indentSmallPx;
}

.fund-risk-main-title {
  margin-top: $grid-indentMediumPx;
  & > h2 {
    margin-bottom: 0;
  }
}
