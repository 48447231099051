.container {
  margin: 0 $grid-sideMargin_px;

  @media #{mediaMobile()} {
    margin: 0 $grid-sideMargin_320_px;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  @media #{mediaUp($break-point-1920)} {
    margin: 0 $grid-sideMargin_1920_px;
  }
}

.row {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  min-height: 0;
  margin-top: 0;
  margin-right: -$grid-gutter_px/2;
  margin-left: -$grid-gutter_px/2;
  margin-bottom: 0;

  &.margin-bottom-small {
    margin-bottom: $grid-indentSmallPx;
  }

  &.margin-bottom-medium {
    margin-bottom: $grid-indentMediumPx;
  }

  &.margin-bottom-large {
    margin-bottom: $grid-indentLargePx;
  }

  &.margin-top-small {
    margin-top: $grid-indentSmallPx;
  }

  &.margin-top-medium {
    margin-top: $grid-indentMediumPx;
  }

  &.margin-top-large {
    margin-top: $grid-indentLargePx;
  }

  &.line-top, &.line-bottom {
    position: relative;
    z-index: 0;
  }

  &.padding-normal {
    padding: 20px 0;
  }

  &.padding-medium {
    padding: 30px 0;
  }

  &.padding-large {
    padding: 50px 0;
  }

  @media #{mediaMobile()} {
    margin-right: $grid-gutter_320_px/2;
    margin-left: $grid-gutter_320_px/2;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: calc(#{-($grid-sideMargin_px - $grid-gutter_px/2)});
    right: calc(#{-($grid-sideMargin_px - $grid-gutter_px/2)});
    z-index: -1;

    @media #{mediaUp($break-point-1920)} {
      left: #{-($grid-sideMargin_1920_px - $grid-gutter_px/2)};
      right: #{-($grid-sideMargin_1920_px - $grid-gutter_px/2)};
    }

    @media #{mediaMobile()} {
      left: #{-($grid-sideMargin_320_px - $grid-gutter_320_px/2)};
      left: calc(#{-($grid-sideMargin_320_px - $grid-gutter_320_px/2)} - env(safe-area-inset-left));
      right: #{-($grid-sideMargin_320_px - $grid-gutter_320_px/2)};
      right: calc(#{-($grid-sideMargin_320_px - $grid-gutter_320_px/2)} - env(safe-area-inset-right));
    }
  }

  &.line-top {
    &::before {
      top: 0;
      border-top: 1px solid $line-top-color;
    }
  }

  &.line-bottom {
    &::after {
      bottom: 0;
      border-bottom: 1px solid $line-top-color;
    }
  }
}

.col {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: $grid-gutter_px/2;
  padding-left: $grid-gutter_px/2;

  @media #{mediaMobile()} {
    padding-right: $grid-gutter_320_px/2;
    padding-left: $grid-gutter_320_px/2;

    &.hidden-small {
      display: none;
    }
  }
}

.row, .col {
  &.margin-top-small {
    margin-top: $grid-indentSmallPx;
  }

  &.margin-top-medium {
    margin-top: $grid-indentMediumPx;
  }

  &.margin-top-large {
    margin-bottom: $grid-indentLargePx;
  }

  &.margin-bottom-small {
    margin-bottom: $grid-indentSmallPx;
  }

  &.margin-bottom-medium {
    margin-bottom: $grid-indentMediumPx;
  }

  &.margin-bottom-large {
    margin-bottom: $grid-indentLargePx;
  }
}

.filter-row {
  &.hidden {
    display: none;
  }
}

// Grids
@include generate-columns($break-point-320);
@include generate-columns($break-point-414);
@include generate-columns($break-point-568);
@include generate-columns($break-point-768);
@include generate-columns($break-point-1024);
@include generate-columns($break-point-1280);
@include generate-columns($break-point-1920);

// Offsets
@include generate-columns-offsets($break-point-320);
@include generate-columns-offsets($break-point-768);
@include generate-columns-offsets($break-point-1024);
@include generate-columns-offsets($break-point-1280);
@include generate-columns-offsets($break-point-1920);

// Orders
@include generate-columns-order($break-point-320, -2);
@include generate-columns-order($break-point-320, -1);
@include generate-columns-order($break-point-768, 0);
@include generate-columns-order($break-point-1024, 0);

