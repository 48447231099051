.fund-tile {
  @extend .row;

  &__col {
    @extend .col;
    @extend .col-12-320px;
    @extend .col-6-768px;
    @extend .col-3-1024px;
//
//    & .tile-sub-title {
//      display: none;
//    }

    &-type-1,
    &-type-2 {
      & .tile-date,
      & .tile-title,
      & .tile-sub-title {
        display: block;
      }
    }

    &-type-1,
    &-type-2 {
      @extend .col-6-1024px;
    }

    &-type-3 {
      @extend .col-4-768px;
      @extend .col-4-1024px;
    }
//    
//      .tile-icon-video{
//          margin: 0;
//          position: absolute;
//          right: 30px;
//      }
    
      .tile-date{
          width: calc(100% - 40px);
      }
  }

  &__item {
    position: relative;
    width: 100%;
    padding-bottom: 66.67%;
    margin: 10px 0;
  }

  &__container,
  &__image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  & .tile {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
  }

@media #{mediaDown($break-point-1440)} {
    &__col {
      & .tile-title {
        font-size: 20px;
      }
    }
  }

}

.activitystream {
    .tile{
        
//        @media #{mediaDown($break-point-1440)} {
//            padding: 10px;
//        }
    }
    .tile-title{
        font-size: 22px;
        line-height: 28px;
        margin: 0;
        @media #{mediaDown($break-point-1440)} {
            font-size: 16px;
            line-height: 20px;
        }
        
    }
}