.compare-page-title{
    margin:  $grid-sideMargin_px 0;
    font-size: 30px;
}
.compare-bar{
    background: green(3);
    color: dark(7);
    display: flex;
    margin: 0 -30px;
    padding: 10px 30px;
    
    @media #{mediaMobile()} {
        flex-wrap: wrap;
    }
}
.compare-details{
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media #{mediaMobile()} {
        width: 100%;
        justify-content: space-between;
    }
}
.compare-reset-btn{
    margin-left: 30px;
    background: dark(7);
    padding: 10px 50px;
    color: dark(0);
    cursor: pointer;
}
.document-filters-section{
    position: relative;
    display: flex;
    align-items: stretch;
    width: 40%;
    z-index: 1001;
    &.dim{
         cursor: no-drop;
    }
    
    @media #{mediaMobile()} {
        width: 100%;
    }
}
.compare-filters {
//    position: relative;
    display: flex;
    align-items: stretch;
//    width: 40%;
    z-index: 1007;
    width: 100%;
    &.dim{
        pointer-events: none;   
    }
    

        @media #{mediaDown($break-point-1024)} {
            width: 100%;
        }
        @media #{mediaMobile()} {
            flex-wrap: wrap;
            width: 100%;
        }

    .documnet-filiter{
        position: relative;
        display: flex;
        align-items: center;
        flex: 1 0;
        height: 60px;
        z-index: 2;
    //        border-top: solid 3px green(3);
    //        border-left: solid 3px green(3);
//        border-bottom: dashed 3px dark(7);
        background-color: transparent;
        transition: flex 500ms ease;
        cursor: default;
        margin-right: 30px;

        &:last-child{
            margin-right: 0;
        }


        @media #{mediaMobile()} {
            min-width: 100%;
            margin-right: 0px;
        }


        &.filter-fund-field {
            
            .filter-arrow-span{
                svg{
                    circle{
                        fill: #ffffff;
                    }
                    path{
                        fill:green(3);
                    }
                }
            }
            

            @media #{mediaMobile()} {
                z-index: 3;
            }
            .select-items-doc{
                div{
                    cursor: pointer;
                }
                .added{
                    cursor: default;
                    background: dark(6);
                    color: dark(4);
                    pointer-events: none;
                    
                    &::before{
                        content: 'added';
                        background: green(3);
                        color: dark(7);
                        margin-right: 5px;
                        font-size: 12px;
                        padding: 2px 5px;
                    }
                    &:hover{
                        cursor: default;
                        border-left: 0;
                        padding-left: 15px;
                    }
                }
                .dim{
                    cursor: default;
                    background: dark(6);
                    color: dark(4);
                    pointer-events: none;
                    
                    &:hover{
                        cursor: default;
                        border-left: 0;
                        padding-left: 15px;
                    }
                }
            }
            &.active{

                .select-items-doc{
                    display: block;
                }

                .filter-fund-span{
    //                    background: green(3);


                    & > svg{
                        & > path{
                            fill:green(3);
                            stroke:green(3);
                        }
                    }
                }

                .filter-arrow-span{
                    transform: rotate(270deg);
                    right: 0px;
                }
            }
        }
    }
    .filter-icon-span{
//        margin: 0 10px;
    }
    .select-field{
        border-bottom: dashed 3px dark(7);
        padding: 10px 30px 10px 0;
    }
    .filter-arrow-span{
        right: 5px;
    }
    .select-items-doc{
        width: calc(100% + 6px - 25px);
        color: dark(0);
        left: 17px;
        max-height: 35vh;
        overflow-y: auto;
    }
}

.compare-chart-section{
    margin-top: 50px;
}

.compare-chart-details-section{
    margin: 30px 0;
           
        @media #{mediaDown($break-point-1024)} {
            overflow-x: auto;
        }
    .chart-details-header{
        display: flex;
        width: 100%;
        background: dark(7);
        font-weight: 600;
        
        .section-header{
            border-bottom: 1px solid dark(4);
            display: inline-block;
        }
        .fund-name-section {
            background: #fff;
            padding: 10px;
            padding-bottom: 0;
            padding-left: 0;
        }    
        .fund-details-section {
            background: #fff;
            padding: 10px;
            padding-bottom: 0;
        }
        .performance-section {
            background: #fff;
            padding: 10px;
            padding-bottom: 0;
        }
    }
    .chart-details-sub-header{
        display: flex;
        font-weight: 600;
        background: dark(7);
        .fund-name-section {
            background: #fff;
            padding: 10px;
            padding-top: 20px;
        }    
        .fund-details-section {
            background: #fff;
            padding: 10px;
            padding-top: 20px;
        }
        .performance-section {
            background: #fff;
            padding: 10px;
            padding-top: 20px;
        }
    }
    .chart-details-item{
        display: flex;
        padding: 10px 0;        

        .fund-name-section {
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: green(3);
            color: dark(0);
            text-decoration: none;
                        
        }
    }
    .asOfDate-section {
        font-size: 14px;
        font-weight: bold;
        padding: 20px 0;
    }
    .fund-remove-section {
        padding-right: 20px;
        min-width: 40px;

        .btn-remove-fund {
            cursor: pointer;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }
    .fund-name-section{
        width: 20%;
        position: relative;
        padding-right: 5px;
        line-height: 22px;
        
        .baseline{
            width: calc(100% - 10px);
            height: 2px;
            position: absolute;
            bottom: -5px;
            left: 0;
        }
        
        @media #{mediaDown($break-point-1024)} {
            min-width: 200px;
        }
    }
    .fund-details-section{
        width: 30%;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
                
        @media #{mediaDown($break-point-1024)} {
            min-width: 300px;
        }
        
        .details-sub-header{
            width: calc(100% / 3);
             
        }
    }
    .performance-section{
        width: 50%;
        display: flex;
        padding-left: 10px;
        
        @media #{mediaDown($break-point-1024)} {
            min-width: 400px;
        }
        
        .performance-sub-header{
            width: calc(100% / 5);
        }
    }
    
}


.compare-filter-input{
    position: absolute;
    padding: 10px 30px 10px 0;
    top: 10px;
    width: calc(100% - 80px);
    left: 20px;
    z-index: 100;
    background: green(3);
    border: 0;
    color: dark(7);
    display: none;
    &.active{
        display: block;
    }
    &:focus{
        outline: none;
    }
}

.compare-fund-container{
    min-height: 500px;
}

.compare-chart-details-section {
    &.show-volatility {
        .fund-volatility-section {
            display: flex;
        }
        .fund-details-section {
            width: 25%;
        }
        .performance-section {
            width: 35%;
        }
    }
    .fund-volatility-section {
        display: none;
        width: 20%;
        @media #{mediaDown($break-point-1024)} {
            min-width: 200px;
        }

        .details-sub-header {
            width: 50%;
        }
    }
    .fund-volatility-head {
        background: #fff;
        padding: 10px;
        padding-top: 20px;
    }
}