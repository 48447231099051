.tags-filter-wrapper {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  flex-wrap: wrap;

   @media #{mediaMobile()} {
    width: auto;
    justify-content: flex-start;
    margin: 30px 0;
  }

  & > button:last-child {
    margin-right: 0;
  }
}

.tags-filter-button {
  flex: 0 0 auto;
  margin-right: 10px;
  padding: 14px 30px;
  color: dark(2);
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  outline: 0;
  border-radius: 21px;
  border: solid 1px #d8d8d8;
  background-color: transparent;

  @media #{mediaDown($break-point-1920)} {
    padding: 9px $grid-indentSmallPx;
  }

  &:hover {
    border: solid 1px green(3);
  }

  & > span {
    margin-left: 5px;
    opacity: 0.4;
  }

  &.active {
    border: solid 1px green(3);
    background-color: green(3);
    color: dark(7);
  }
}
