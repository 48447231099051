.link {
  // display: flex;
  // flex-wrap: wrap;
  display: block;
  width: fit-content;
  margin-top: 30px;
  padding-left: 0;
  font-size: 12px;
  font-family: $font-regular;
  font-weight: normal;
  line-height: 20px;
  color: dark(2);
  text-decoration: none;

  & > svg {
    position: absolute;
    left: 0;
    min-width: 20px;
  }

  h1 + & {
    margin-top: $grid-indentMediumPx;
  }

  p > & {
    margin: 0;
  }

  & + a {
    margin-top: $grid-indentSmallPx;
  }

  & + p {
    font-size: 12px;
    line-height: 1.67;
  }

  &:hover > span::before {
    opacity: 0;
  }

    
    &.download{
        @media #{mediaDown($break-point-568)} {
            background: $color-coral;
            text-align: center;
            padding: 5px 10px;
            margin-right: 0 !important;
            
            &:hover {
              background: $button-hover-background-color;
            }
        }
         & >span{
            background: $color-coral;
            padding: 5px 20px;
            border-bottom: none;
            color:$color-white;

            @media #{mediaDown($break-point-568)} {
                background: none;
                padding: 0;
            }
             
            &:hover {
              background: $button-hover-background-color;
            }
          }
    }
    
  & > span {
    position: relative;
    border-bottom: 1px solid green(4);
    display: inline;
    transition: border-bottom 400ms ease;
    // display: inline-block;

    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: 0;
    //   display: block;
    //   width: 100%;
    //   height: 1px;
    //   background-color: green(4);
    //   opacity: 1;
    //   transition: opacity 400ms ease;
    // }
  }

  &.not-underline {
     & > span {
      border-bottom: none;
      // &::before {
      //   display: none;
      // }
    }
  }

  @media #{mediaUp($break-point-1920)} {
    font-size: 14px;
    line-height: 1.71;
  }

  // & > * {
  //   display: inline-block;
  // }

  &.icon {
    position: relative;
    position: relative;
    padding-left: 30px;
  }

  &.inline {
    // display: inline-flex;
    display: inline-block;
    margin-right: $grid-indentSmallPx;
  }

  &.underline {
    display: block;
  }
}
