.cookie {
  position: fixed;
  left: 80px;
  bottom: 0;
  right: 0;
  padding: 30px 60px;
  color: white;
  background-color: #282b3e;
  z-index: 999;
  display: none;
  opacity: 1;

  &.show {
    opacity: 1;
    display: block;
  }

  &--wrapper {
    display: flex;
    align-items: center;
  }

  &__message {
    margin-right: 10px;

    span {
      font-size: 14px;
    }

    a {
      color: white;
      text-decoration: none;
      padding-bottom: 2px;
      border-bottom: 1px solid white;
    }
  }

  p,
  b,
  a,
  span,
  input {
    font-size: 14px !important;
  }

  &__action {
    margin-left: auto;

    & input {
      border: 1px solid white;
      outline: none;
      color: white;
      padding: 15px 30px;
      background-color: #282b3e;
      border-radius: 0;
      -webkit-appearance: none;
      font-size: 14px;
    }
  }

  @media #{mediaDown($break-point-1920)} {
    padding: 30px 20px;

    p,
    b,
    a,
    span,
    input {
      font-size: 12px !important;
    }
  }

  // using break point of coremfc (screen width <= 768 is mobile)
  @media (max-width: 768px) {
    left: 0;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    left: 80px;
  }
}
