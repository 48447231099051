.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-overflow {
  overflow: hidden;
}

.global-padding-bottom {
  padding-bottom: $grid-indentLargePx !important;

  @media #{mediaDown($break-point-1920)} {
    padding-bottom: 70px !important;
  }

  @media #{mediaMobile()} {
    padding-bottom: 30px !important;
  }
}

.margin-header {
//  margin-top: #{$grid-indentLargePx - 20px};
    margin-top: 0;

//  @media #{mediaDown($break-point-1920)} {
//    margin-top: 50px;
//  }

  @media #{mediaMobile()} {
    margin-top: 10px;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  border: 10px solid rgba(#006940, 0.2);
  border-top: 10px solid #006940;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 0.6s linear infinite; /* Safari */
  animation: spin 0.6s linear infinite;

  &.hide {
    display: none;
  }

  &.show {
    display: block;
  }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.invisible {
  opacity: 0 !important;
  z-index: -1;
}

.visible {
  opacity: 1 !important;
  z-index: 1;
}



.d-1440-none {
  @media #{mediaDown($break-point-1440)} {
    display: none !important;
  }
}
.d-1280-none {
  @media #{mediaDown($break-point-1280)} {
    display: none !important;
  }
}
.d-1024-none {
  @media #{mediaDown($break-point-1024)} {
    display: none !important;
  }
}
.d-768-none {
  @media #{mediaDown($break-point-768)} {
    display: none !important;
  }
}
.d-none {
  display: none !important;
}

.text-center {
  text-align: center;
}

.center-ele{
    float: none !important;
    display: inline-block !important;
}

/* update the footer logo size */
.xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--copyright.big-footer-image .cmp-image__image, .footer .aem-GridColumn.xfpage-footer__layoutcontainer--copyright.big-footer-image .cmp-image__image{
    width: 220px;
    height: 40px;
}
/* override the orderlist style */

.ol-num{
    &.text{
        ol{
            counter-reset: none;
        }
        
    }
    
    ol:first-of-type{
        counter-reset: item-counter;
    }
}

/* update the table style */
.cmp-text ul.datatable-rowHeader li{
    padding: 10px 20px;
    font-size: 1.5rem;
    font-weight: normal;
    
  @media #{mediaDown($break-point-768)} {
    font-size: 0.875rem;
      
  }
}
.cmp-text ul.datatable-rowHeader li:first-child{
    font-weight: 600;
}

.cmp-text ul.datatable-rowHeader h5{
    margin: 0;
}
.text li a{
    display: inline-block;
}
.cmp-text table td:first-child{
    font-weight: normal;
}

/* update the banner teaser */
.bannerteaser .cmp-teaser__description{
    max-height: 210px;
    
  @media #{mediaDown($break-point-768)} {
    max-height: 160px;
    -webkit-line-clamp: 5;
  }
}


.cmp-bannerteaser{
    
    &--blue,
    &--green,
    &--purple,
    &--purple-dark,
    &--orange,
    &--cyan,
    &--carol {
        .cmp-bannerteaser__wrapper {
        	border: 0;
        }

        .cmp-teaser {
            &__description,
            &__uppertext,
            &__subtext,
            &__action-link a {
        		color: dark(7) !important;
            }
        }

        &.cmp-bannerteaser {
            &--belt,
            &--adbanner {
                .cmp-bannerteaser .cmp-teaser {
                    &__action-link {
                        a {
                            color: dark(7) !important;
                            &:before {
//                                background: url($images_path_coremfc+'arrow-circle-white.svg') no-repeat center
                            }
                        }
                    }
                }
            }

            &--notice__contact,
            &--notice__product {
                .cmp-bannerteaser .cmp-teaser {
                    &__action-link {
                        a {
                            border: 0;
                        }
                    }
                }
            }
        }
    }
    
    &--carol &__wrapper {
        background-color: #FF7769;
    }
}
.text li a{
    display: inline-block;
}
.cmp-text table td:first-child{
    font-weight: normal;
}
/* update the alignment of the fund doc items */
.funddocuments .aem-GridColumn{
    padding: 0;
}

/* over css for the logo in 2nd nav bar*/


.cmp-navigation__submenu .cmp-navigation__submenu-logo{
    padding-left: 5px;
    
    
  @media #{mediaUp($break-point-1920)} {
    padding-left: 25px;
  }
    
}

//.cmp-header .cmp-header__container-logo{
//    padding: 15px 0 15px 40px;
//    
//  @media #{mediaUp($break-point-1920)} {
////    padding-left: 5px;
//  }
//}


.cmp-navigation__secondary .cmp-navigation__submenu-logo{
    padding-left: 5px;
    
  @media #{mediaUp($break-point-1920)} {
    padding-left: 25px;
  }
}
/*
.cmp-template--left-rail .cmp-navigation__submenu .cmp-navigation__submenu-logo .header-logo{
	display:none
}*/
.cmp-template--left-rail .cmp-header .cmp-header__container-logo{
	padding-left: 5px;
      
  @media (max-width: 1024px){
      padding-left: 40px;
  }
  @media #{mediaUp($break-point-1920)} {
    padding-left: 25px;
  }
}
/* formula class for formula in the investchoice */
.invest-form-formula{
        display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 auto;
    text-align: left;
}
.formula-block{
    padding: 10px 10px;
}
.formula-block.formula-block-left{
    padding-left: 0;
}
.formula-block p:not(.formula-divider){
	padding-top: 1px;
}
.formula-block p{
	padding-bottom:0;
}
.formula-block p.smaller-screens{
	display:none;
}
.formula-block.formula-block-right{
    padding-right: 0;
    margin-top: 3px;
}
.formula-block .formula-divider{
	padding-bottom: 2px;
    border-bottom: 1px solid #333333;
}
@media only screen and (max-width: 1024px){
	.formula-block p.smaller-screens {
		display: block;
		text-align: center;
	}
	.formula-block p.desktop{
		displa:none;
	}
}

.padding-bottom-20{
    padding-bottom: 20px;
}
.padding-top-20{
    padding-top: 20px;
}
.padding-left-10{
    padding-left: 10px;
}
.padding-right-10{
    padding-right: 10px;
}
#cmp-navigation__mobile .cmp-navigation__mobile-logo{
    padding-top: 15px;
}
#cmp-navigation__mobile .cmp-navigation__mobile-logo .mobile-logo{
    height: 30px;
}

.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line .js-profile-update{
    color:#fff;
    cursor: pointer;
}
.cmp-header .cmp-header__dropdown-component-options.cmp-header__dropdown-component--expanded .js-profile-update{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -ms-flex;
    display: flex;
    padding: 19px 20px;
    height: 60px;
    margin: 0;
    align-items: center;
    color: #282b3e;
    text-decoration: none;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
    
    &:hover{
        background-color: #fafafa;
    }
}
.dropdown-menu > li > .js-profile-update{
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    color: #282b3e;
    white-space: nowrap;
}
.custom-selectbox-nav .dropdown-menu li .js-profile-update{
    height: 80px;
    width: 100%;
    line-height: 1.43;
    color: #282b3e;
    font-weight: inherit;
    display: table;
    white-space: normal;
    cursor: pointer;
}
/* drop down of log in */
.cmp-header__nav-login{
    position: relative;
    z-index: 1;
}
.cmp-header__nav-login .btn.btn-secondary{
    border: 0;
    background: none;
    font-weight: normal;
    
    &:hover{
        color: inherit;
    }
}

div.cmp-header__nav-login[aria-expanded="true"] .icon-dropdown::before{
    transform: rotate(-90deg);
}
.cmp-header__nav-login .btn.btn-secondary:hover .icon-dropdown::before{
    background: url($images_path_coremfc+'arrow-right-navy-hover.svg') no-repeat center;
}
.cmp-header__nav-login ul{    
    position: absolute;
    top: 38px;
    right: 0;
    z-index: 10;
    margin: 0;
    padding: 0;
    display: none;
    overflow-y: auto;
    list-style-type: none;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
    -moz-box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
    -webkit-box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
}

.cmp-header__nav-login ul li{
    background-color: #fff;
    border: 1px solid #ededed;
    min-width: 125px;
    max-height: 300px;
    z-index: 1;
    padding: 23px 20px;
    margin: 0;
    height: 60px;
    cursor: pointer;
}
.cmp-header__nav-login ul li:hover{
    background-color: #fafafa;
}
.cmp-header__nav-login ul li.active{
    border-left: 5px solid #00a758;
}
.cmp-header__nav-login ul li a{
    color: #282b3e;
    white-space: nowrap;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}
/* update mobile login dropdown */
#cmp-navigation__mobile.open-profile{
    overflow: unset;
}
#cmp-navigation__mobile .cmp-navigation__mobile-contactusheader{
    width: calc(100% - 60px);
    display: none;
}
#cmp-navigation__mobile .cmp-navigation__mobile-user{
    cursor: pointer;
}
ul.profile-mobile-drop-down{
    position: fixed;
    z-index: 1006;
    top: 60px;
    left: 0;
    width: calc(100% - 60px);
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.1);
    list-style-type: none;
    margin: 0;
    padding: 0;
    
}
ul.profile-mobile-drop-down li{
    height: 60px;
    padding: 23px 20px;
    background: #fff;
    border: 1px solid #ededed;
    cursor: pointer;
}
ul.profile-mobile-drop-down li:hover{
    background-color: #fafafa;
}
ul.profile-mobile-drop-down li a{
    color: inherit;
}
ul.profile-mobile-drop-down li.active{
    border-left: 5px solid #00a758;
}

/* update the mobile profile selection */
.custom-selectbox-nav .dropdown-menu.custom-selectbox-nav{
    flex-wrap:nowrap;
    overflow-x: auto;
}
.custom-selectbox-nav .dropdown-menu.custom-selectbox-nav .business-line{
    min-width: 50%;
}

/* override aem quick link personalisation style */
.homepage-top-area .aem-GridColumn.target{
    padding: 0;
}
/* update the style for fund risk overlay*/
.ui-dialog .ui-dialog-content.cmp-internal-link-disclaimer__modal{
    overflow: hidden !important;
    background: #fff !important;
    padding: 0;
    max-width: 80vw;
}
.cmp-link-disclaimer__modal .cmp-link-disclaimer__modal-footer{
    border: 0;
    @media #{mediaDown($break-point-768)} {
        max-width: 80vw;
    }
}

/* updte style for target mode component */
.target.parbase{
    margin: 0;
    padding: 0
}

/* update logo */
.cmp-template--home-page .cmp-header {
  .cmp-header__container-logo {
    padding: 0 0 0 40px;
    height: 80px;
    display: flex;
    align-items: center;
  }
  .white-logo {
    height: auto;
    font-size: 0;
    line-height: 0;
  }

  .cmp-header.navbar-sticky .header-logo {
    max-height: 33px;
    height: auto;
  }
}
#cmp-navigation__mobile .cmp-navigation__mobile-logo.has-logo {
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 0;
  line-height: 0;
  height: auto;

  .header-logo {
      height: auto;
  }
  .mobile-logo {
    height: auto;
    max-width: 160px;
  }
}
.container-inner-full.insurance-homepage .cmp-header.navbar-sticky .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line .js-profile-update {
  color: #202336;
}
.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line:not(:first-child) {
  padding-left: 7px;
}
.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line:not(:last-child) {
  padding-right: 7px;
}

/*override fund datepicker*/
.ui-datepicker .ui-datepicker-header{
    margin-bottom: 0;
}
@media screen and (max-width: 768px){
    .xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--terms .cmp-text p{
        line-height: 25px;
    }
}

@media screen and (min-width: 769px){
    .xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--copyright .text:first-child{
        padding: 0
    }
}

/* override the style of Coremfc dialog*/

.ui-widget.ui-widget-content:not(.ui-datepicker) {
    height: 100vh !important;
    margin: 0;
    background: none;
}
