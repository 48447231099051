.block-text {
  margin-top: 0;

  h2 {
    margin-top: $grid-indentMediumPx;
    margin-bottom: $grid-indentSmallPx;
    font-family: $font-regular;
    font-weight: normal;
    line-height: 32px;
  }

  & > p {
    margin-top: 30px;
    color: dark(0);
  }

  sup {
    font-size: 12px;
    line-height: 2;
  }

  a {
    position: relative;
    display: inline-block;
    font-weight: inherit;
    line-height: inherit;
    color: inherit;
    text-decoration: none;

    &:hover::before {
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: green(4);
      opacity: 1;
      transition: opacity 400ms ease;
    }
  }

  &.text-center-align {
    & > p {
      text-align: center;
    }
  }

  &.text-left-align {
    & > p {
      text-align: left;
    }
  }

  &.text-right-align {
    & > p {
      text-align: right;
    }
  }

  &.text-justify-align {
    & > p {
     text-align: justify;
   }
  }

  &.text-left-border {
    margin-top: $grid-indentMediumPx;

    & > p {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        display: flex;
        align-items: center;
        left: -20px;
        width: 6px;
        height: 100%;
        background-color: green(3);
      }
    }

    & + p {
      margin: $grid-indentSmallPx 0;
    }
  }

  &.bold-header {
    h2 {
      font-family: $font-bold;
      font-weight: bold;
    }
  }

  &.theme-bold {
    & > p {
      margin-top: 0;
      font-size: 50px; /*70px*/
      font-family: $font-bold;
      font-weight: bold;
      line-height: 1;

      @media #{mediaUp($break-point-1920)} {
        &.column-0 {
          font-size: 90px;
        }
      }

//      @media #{mediaUp($break-point-1280)} {
//        font-size: 60px;
//      }

      @media #{mediaMobile()} {
        margin-bottom: 30px;
      }
    }
  }
}
