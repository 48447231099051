.fund-details-line{
    border-left: 1px solid $color-grey;
    margin: 0;
    padding-bottom: 60px;
    
    @media #{mediaDown($break-point-1024)} {
        border-left: none;
        padding-bottom: 30px;
    }
    
}
.fund-details-header {
  div > & {
    margin-top: -5px;
    margin-bottom: 30px;
  }
}
.details-info{
    text-align: right;
    @media #{mediaMobile()} {
        text-align: left;
    }
    
    &-box{
        p{
            margin-top: 0;
        }
        .infobox-header{
            p{
                color: dark(4);      
            }
        }
    }
}

.fund-details-column-header {
  width: 100%;
    margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  font-family: $font-light;
  font-weight: 300;
  color: dark(0);
    
}
.fund-details-asofDate{
    font-size: 14px;
    line-height: 20px;
    margin-top: 0;
    margin-bottom: 5px;
}

.fund-details-currency {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.6px;
  font-family: $font-light;
//  font-weight: 300;
}

.fund-details-icon-fund {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}

.fund-details-fund-text {
  font-size: 14px;
  line-height: 14px;
  font-family: $font-regular;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
    margin: 0;
}

.fund-details-pin {
  display: none;

  &:checked  + svg path {
    stroke: green(3);
    fill:green(3);
  }
}

.fund-details-wrapper {
  position: relative;
  padding-top: $grid-indentLargePx;
  background-color: #fff;

    .header-page & {
            padding-top: 30px;
    }
  @media #{mediaDown($break-point-1920)} {
    padding-top: 70px;
  }

  @media #{mediaMobile()} {
    padding-top: 30px;
  }
}

.fund-details-fund-value {
  display: inline-block;
  font-size: 50px;
  line-height: 1;
margin: 0;
    

  @media #{mediaDown($break-point-1280)} {
//    width: 100%;
//    margin-right: 0;
//    margin-bottom: $grid-indentSmallPx;
  }

  @media #{mediaDown($break-point-1024)} {
    width: auto;
    margin-top: 0;
    margin-right: 70px;
  }

  @media #{mediaMobile()} {
    margin-right: $grid-indentSmallPx;
    padding-top: 20px;
      position: relative;
      font-size: 36px;
      
      &.front{
          .fund-details-currency {
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              left: 0;        
          }
      }
  }
    @media (max-width: 320px){
        font-size: 30px;
    }
    
 
}
.fund-details-stats-section{
    display: flex;
    margin-bottom: 7px;
    
}
.fund-details-stats {
  display: inline-block;

  @media #{mediaDown($break-point-1024)} {
    margin-bottom: $grid-indentMediumPx;
  }
}

.fund-details-change {
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.fund-details-icon-back {
  margin-top: 0;
  margin-bottom: $grid-indentMediumPx;

  @media #{mediaDown($break-point-1024)} {
    margin-bottom: $grid-indentSmallPx;
  }
}

.fund-details-checkbox {
  display: flex;
    margin-top: 10px;

  @media #{mediaMobile()} {
//    margin-top: 30px;
  }

  & > svg {
    cursor: pointer;
  }

  & > span {
    padding-left: 10px;
    cursor: pointer;
  }
}

.fund-details-text {
  @media #{mediaMobile()} {
    margin-bottom: 30px;
    display: inline-block;
  }
}
.fund-details-margin{
    margin: 0;
    padding-bottom: 60px;
    
    @media #{mediaDown($break-point-1024)} {
        padding-bottom: 30px;
    }
}

.yield-cal-btn{
    background: $color-coral;
    padding: 10px 20px;
    border-bottom: none;
    color: $color-white;
//    float: right;
//    margin-left: 165px;
    &.link{
        &.inline{
            margin-right: 0;
        }
    }

    &>span{
        border-bottom: none;
    }

    &:hover {
      background: $button-hover-background-color;
    }
}

.hidden {
  display: none;
}

.funddetailsdocuments {
  
  
  .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {      
    
    @media #{mediaMobile()} {
      width: 50%;
    }
  }
  .aem-Grid.aem-Grid--default--12 > &.aem-GridColumn.aem-GridColumn--default--3 {      
    
    @media #{mediaMobile()} {
      width: 50%;
    }
  }


}


.rdb-box{
    padding: 20px 30px;
    background: dark(5);
    margin-bottom: 10px;
    
    a{
        text-decoration: none;
        border-bottom: 1.5px solid green(3);
        color: inherit;
        font-weight: bold;
        
        &:hover{
            text-decoration: none;
            border-bottom: none;
            color:green(3);
        }
    }
}