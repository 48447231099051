.calendar-row {
  @media #{mediaMobile()} {
    display: none;
  }
}

.calendar-animated-content {
  display: none;
  transition: height 400ms ease 0ms;

  @media #{mediaMobile()} {
    display: block;
  }

  h2 {
    margin-top: $grid-indentSmallPx;
  }
}

.calendar-text {
  h2 {
    display: none;
  }

  @media #{mediaMobile()} {
    h2 {
      display: block;
    }
  }
}

.calendar-dates {
  $basedLineHeight: 22px;

  width: 100%;
  display: flex;
  flex-direction: row;

  & > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    list-style: none;
    padding: 0px;
    margin: 0px;

    & li {
      font-family: $font-light;
      font-weight: 300;
      line-height: 22px;
      
      &:not(:last-child) {
        padding-right: 5px;
      }
      
    }

    @include generate-height-dates-box();
  }

  & > p {
    width: 100%;
    font-family: $font-light;
    font-weight: 300;

    & .date-footnote {
      width: 100%;
      margin: 10px 0;
      display: inline-block;
    }
  }
}

.page-calendar {
  & .container {
    &.footnotes-page {
      & .col {
        @extend .col-12-320px;
        @extend .col-10-1280px;
        @extend .col-offsets-1-1280px;
        @extend .col-offsets-2-1920px;
      }
    }
  }
}
