
.chart-wrapper {
  position: relative;
  padding-top: $grid-indentMediumPx;

  .container > & {
    @media #{mediaMobile()} {
      padding-top: 30px;
  
    }
  }
}

.chart-legend, 
.chart-legend--unit {
  display: flex;  
  padding: 0 0px;
  font-size: 12px;
  line-height: 14px;

  &--unit {
    margin-top: $grid-indentSmallPx;
    padding-bottom: $grid-indentSmallPx;
    justify-content: space-between;
  }
}
.chart-legend {
  @media #{mediaMobile()} {
    flex-direction: column;
    padding: 0 0;
  }
}

@media #{mediaUp($break-point-768)} {
  .chart-axis-label { 

    &--left {
      margin-left: 30px;
    }

    &--right {
      margin-right: 20px;
    }
  }
}

.chart-label {
  & + & {
    margin-left: $grid-indentSmallPx * 2; 
  }
  @media #{mediaMobile()} {
    & + & {
      margin-left: 0px; 
      margin-top: $grid-indentSmallPx;
    }
  }

  > span {
    padding-bottom: 2px;
  }
}

.chart-svg {
  font-size: 12px;

  .line {
    fill: none;
    stroke-width: 2px;
  }

  .axis-x {
    .domain {
      stroke: #eee;
    }
  }

  .axis-y {
    .domain {
      stroke: none;
    }

    .tick {
      font-size: 10px;
      opacity: 0.7;
    }
  }

  .grid {
    .domain {
      stroke: none;
    }
  }
}

.chart-footnote {
  padding: 0 26px;
  font-size: 12px;
}




/* override chartist */
@media #{mediaMobile()} {
  #ct-chart {
 
    margin: 0px -20px;
  }

  .ct-label {
    font-size: 11px;
  }
}


.ct-label {
  font-family: $font-light;
}



.ct-area,
.ct-line {
  pointer-events: none;
}

// add chartlist style



.ct-label.ct-horizontal {
  padding-top: 10px;
}

.ct-grid.ct-horizontal {
  stroke-width: 0;
}

.ct-grid.ct-vertical {
  stroke-dasharray: 0;
  stroke-width: 0.5;
}


.ct-label {
  color: rgb(40, 43, 62);
  font-weight: 500;
  font-size: 12px;
}


.ct-grids {
  overflow: hidden;
  position: relative;
}

.ct-chart {
  position: relative;
  margin-top: 100px;
  height: 450px;
  @media #{mediaMobile()} {
    margin-top: 60px;
  }
}



.ct-chart-line.ct-chart-line  {

   
  .ct-line {
    stroke-width: 2px;
  }
  

  .ct-point {
    stroke-width: 0px;
    display: none;  
  
  
    &.ct-point--hover {            
      display: block;
    }
  }

}


  
.ct-series-a .ct-area {
  // fill: chart-color(0);
  // fill-opacity: 1;
  fill: rgb(0,170,48);
  fill-opacity: 0.7;
}
.ct-series-a .ct-point {
  fill: chart-color(0);
  stroke: chart-color(0);

  &.ct-point--hover {   
    fill: url($pattern_path + "#ChartPointGradient-a");;
  }

}
.ct-series-a .ct-line {
  fill: transparent;
  stroke: transparent;
}

.ct-series-c .ct-point {
  fill: chart-color(1);
  stroke: chart-color(1);
  
  &.ct-point--hover {   
    fill: url($pattern_path + "#ChartPointGradient-c");;
  }
}
.ct-series-c .ct-line {
  stroke: chart-color(1);
}

.ct-series-b .ct-point {
  fill: chart-color(2);
  stroke: chart-color(2);
  
  &.ct-point--hover {   
    fill: url($pattern_path + "#ChartPointGradient-b");;
  }
}
.ct-series-b .ct-line {
  stroke: chart-color(2);
}
.ct-series-b .ct-area, .ct-series-c .ct-area {
  fill: transparent;
  fill-opacity: 0;
}

.crosshairCursor-x {
  margin-left: -20px;
  z-index: 0 !important;
  @media #{mediaUp($break-point-768)} {
    margin-left: -110px;
  }
  @media #{mediaUp($break-point-1920)} {
    margin-left: -140px;
  }
  height: calc(100% - 60px);
  bottom: 60px;

}

.chartist-tooltip {
  z-index: 10000;
  text-align: center;
  padding: 5px 10px;
  background: dark(5);

  &--right::before {
    right: 0.25em;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      right: 2px;
    }
  }
  &--left::before {
    left: 0.5em;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      left: 10px;
    }
  }
  &::before {
    color: dark(5);
    font-size: 2em;
    // margin-top: -4px;
    line-height: 0.5em;
    text-shadow: none;
    transform: none;
    z-index: 1000;
  }

  @media #{mediaMobile()} {
    padding: 2px 5px;
  }

  &__title {
    font-size: 9pt;
    font-family: $font-light;
    font-weight: 300;
    line-height: 1.25;

    @media #{mediaMobile()} {
      font-size: 6pt;
    }
  }

  &__value {
    font-size: 18pt;
    font-family: $font-regular;
    font-weight: normal;
    line-height: 1.25;

    @media #{mediaMobile()} {
      font-size: 11pt;
    }
  }

  td {
    padding: 5px 10px;
    vertical-align: bottom;
    text-align: center;

    @media #{mediaMobile()} {
      padding: 2px 5px;
    }

    > div {
      padding-bottom: 5px;

      @media #{mediaMobile()} {
        padding-bottom: 2px;
      }
    }
  }
}


