// break points
$break-point-320: 320px;
$break-point-375: 375px;
$break-point-414: 414px;
$break-point-568: 568px;
$break-point-768: 768px;
$break-point-896: 896px;
$break-point-1024: 1024px;
$break-point-1280: 1280px;
$break-point-1440: 1440px;
$break-point-1600: 1600px;
$break-point-1920: 1920px;

// fonts
$font-light: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
$font-regular: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
$font-bold: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;

$width-nav: 70px;
$side-image-width: 15vw;

$grid-columns: 12;
$grid-gutter_px: 20px;
$grid-gutter_320_px: 0px;
$grid-indentSmallPx: 20px;
$grid-indentMediumPx: 50px;
$grid-indentLargePx: 80px;
$grid-sideMargin_px: 30px;
$grid-sideMargin_320_px: 20px;
$grid-sideMargin_1920_px: 60px;

$button-font-family: $font-regular;
$button-font-size: 12px;

$nav-zIndex: 15;

$nav-backButton-arrowColor: $color-coral;
$nav-backButton-height_px: 70px;

$nav-subMenu-backgroundColor: dark(7);
$nav-subMenu-backgroundColorSmall: dark(6);
$nav-subMenu-borderColor: rgba(dark(2), 0.1);
$nav-subMenu-fontFamily: $font-regular;
$nav-subMenu-fontSize: 14px;
$nav-subMenu-headerBackgroundColorSmall: dark(7);
$nav-subMenu-itemActiveArrowColor: green(3);
$nav-subMenu-itemBandWidth: 10px;
$nav-subMenu-itemBandBackgroundColor: green(3);
$nav-subMenu-itemHeightSmall: 65px;
$nav-subMenu-itemHoverBackgroundColor: dark(6);
$nav-subMenu-lineHeight: 22px;
$nav-subMenu-selectedItemFontSize: 30px;
$nav-subMenu-textColor: dark(0);
$nav-subMenu-transitionDurationMs: 800ms;
$nav-subMenu-transitionDelayMs: 500ms;
$nav-subMenu-transitionEasing: ease;
$nav-subMenu-width: 290px;
$nav-subMenu-width_1920: 320px;

$nav-menu-text-color: $color-white;

$nav-size_px: 70px;
$nav-size_320_px: 60px;
$nav-size_1920_px: 80px;
$nav-backgroundColor: dark(1);
$nav-overlayColor: rgba(dark(0), 0.5);

$nav-menu-backgroundColorSmall: dark(6);
$nav-menu-transitionDurationMs: 800ms;
$nav-menu-transitionEasing: ease;
$nav-menu-textColor: $color-white;
$nav-menu-dividerColor: dark(0);
$nav-menu-dividerColorSmall: rgba(dark(2), 0.1);
$nav-menu-itemHoverBackgroundColor: dark(0);
$nav-menu-fontSize: 9px;
$nav-menu-fontSizeSmall: 14px;
$nav-menu-fontSizeXXLarge: 9px;
$nav-menu-iconSize: 30px;
$nav-menu-iconSize_320: 26px;
$nav-menu-iconSize_1920: 34px;
$nav-menu-itemHeight: 60px;
$nav-menu-itemHeightSmall: 90px;
$nav-menu-itemHeightXXLarge: 100px;
$nav-menu-textColor_320: $color-dark;

$nav-logo-backgroundColor: green(3);
$nav-logo-color: $color-white;
$nav-logo-height: 30px;
$nav-logo-height_320: 20px;
$nav-logo-height_1920: 34px;
$nav-logo-width: 28px;
$nav-logo-width_320: 18px;
$nav-logo-width_1920: 32px;

// Chart
$chart-fontFamily: $font-regular;
$chart-ticksSizeY_px: 10px;
$chart-ticksSizeX_px: 12px;

// Funds
$funds-top-panel-heightPx: 60px;

// Article
$articles-gutter_px: 20px;

// Date range picker
$dateRangePicker-activeColor: green(3);
$dateRangePicker-backgroundColor: $color-white;
$dateRangePicker-border: rgba(dark(0), 0.1);
$dateRangePicker-calendarRangeColor: green(4);
$dateRangePicker-calendarRangeDateColor: green(2);
$dateRangePicker-color: dark(1);
$dateRangePicker-bgColor2: dark(5);
$dateRangePicker-bgColor: dark(6);
$dateRangePicker-fontFamily: $font-regular;
$dateRangePicker-inactiveColor: dark(4);

// coremfc
//== Variables used in LESS files

//== Grid,screen sizes (See also grid.less)
$grid-gutter-width: 20px;
$screen-sm-min: 768px;       //Phone/tablet/desktop dividing lines
$screen-md-min: 992px;
$screen-lg-min: 1024px;
$screen-large-desktop: 1920px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

// Colors
$text-color-primary: #424559;
$text-color-secondary: #00AA59;

// Color palletes
$text-color-gunpowder: #424559;
$text-color-jade: #00BF66;
$text-color-brightgray: #34384B;
$text-color-evonyclay: #282B3E;
$text-color-alabaster: #FAFAFA;
$text-color-white: #FFFFFF;
$text-color-gallery: #EDEDED;
$text-color-greenhaze: #00A758;
$text-color-tab-item: #282B3E;
$text-color-tab-border: #00A758;
$text-color-darkgray: #202336;
$text-color-blue: #0000c1;
$text-color-text-component: #202336;
$text-color-black: #000000;
$text-color-green: #06874e;
$text-color-red: #c14a36;
$text-color-subgray: #5e6073;
$text-color-manatee: #8E90A2;
$text-color-grey: #767676;


$bg-white: #FFFFFF;
$bg-grey: #FAFAFA;
$bg-cyan: #0b9189;
$bg-green: #06874e;
$bg-greenhaze: $text-color-greenhaze;
$bg-turquoise: #07857d;
$bg-orange: #a75900;
$bg-blue: #0000c1;
$bg-violet: #533573;
$bg-tomato: #ff5d55;

$border_color_lightgray: #EDEDED;
$border_color_darkgray: #8e90a2;
$border_color_greenhaze: #00A758;
$border_color_green: #06874e;
$border_color_gainsboro: #D8D8D8;
$border_color_valencia:  #DC5A44;

$btn-primary-bg-color: #ec6453;


// Layout
$layout-bg-color: $text-color-alabaster;
$layout-bg-color-secondary: $text-color-white;


// Footer
$footer-bg-color: $text-color-evonyclay;
$footer-divider-color: #1E212F;
$footer-color-quicklink: #c2c3c9;


//List
$list-title-color: $text-color-evonyclay;
$list-main-border: #F1F1F1;
$list-cell-border: #ECECEC;

//Map
$color-cyangreen: #19C675;
$map-color-lightgray: #707381;


// Fonts

$font-weight-light: 300;

$font-weight-normal: 400;

$font-weight-medium: 500;

$font-weight-semibold: 600;

$font-weight-bold: 700;

 

$font-family-primary: 'Manulife JH Sans', sans-serif;

$font-family-secondary: 'Manulife JH Serif', serif;

 

// Headings

$h1-font-size: 36px;

$h2-font-size: 24px;

$h3-font-size: 20px;

$h4-font-size: 18px;

$h5-font-size: 16px;

$h6-font-size: 12px;

$h1-font-supersize: 36px;

 

//Fontsize

$px: 16rem;

 

//Navigation

 

$nav-bg-color: $text-color-brightgray;

$nav-bg-hover: $text-color-evonyclay;

$nav-bg-logo: $text-color-greenhaze;

$nav-divider: #2F3246;

$nav-bg-active: #1a1c28;

$nav-sub-bg: $text-color-white;

$nav-sub-border: $text-color-gallery;

$nav-sub-hover: $text-color-alabaster;

$nav-sub-color: #202336;

$nav-bg-mobile: $text-color-brightgray;

$nav-border-mobile: $text-color-gallery;

$nav-bg-mobile-menu: #1A1C28;

 

//Zindex

$zindex-sub-navbar: 1000;

$zindex-header: 1001;

$zindex-carouselvideo: 1002;

$zindex-headersticky: 1003;

$zindex-navbar: 1002;

$zindex-navbar-mobile: 1003;

$zindex-sub-navbar-mobile: 1004;

$zindex-top-navbar-mobile: 1005;

 

 

//Fixed values

$header-height: 80px;

$nav-width: 80px;

$nav-secondary-width: 310px;

$nav-secondary-large-width: 350px;

$image-blade-width: calc((100% - 390px) * .15);

$image-blade-large-width: calc((100% - 430px) * .15);

$right-rail-image-blade: calc((100% - 80px) * .15);

 

//Margins

$grid-default-margin: 40px;

$grid-mobile-margin: 20px;

$grid-large-desktop-margin: 60px;

 

$grid-margin: $grid-default-margin;

 

//Buttons

$btn-primary: #EC6453;

$btn-primary-hover: #DC5A44;

$grid-default-gutter: 20px;

$grid-mobile-gutter: 4px;

$btn-back-color: #202336;

 

// page margin

$page-top-margin: 0;

$page-bottom-margin: 50px;

 

//Custom Select Dropdown

$select-dashed-border: #979797;

$select-text-color: #8e90a2;

$select-li-selected: #f5f5f5;

 

$emphasis-color: #ff6b59;

$action-color: $emphasis-color;

$text-color: #333;

$second-text-color: lighten($text-color, 30%);

 

$base-color: #f0f0f0;

$base-color--dark: darken($base-color, 15%);

$base-color--light: lighten($base-color, 5%);

 

$bg-color: white;

 

// cta link

$cta-link-font-size: 14px;

$cta-link-line-height: 20px;

 

/** Mixins **/

 

.border-box {

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */

  -moz-box-sizing: border-box; /* Firefox, other Gecko */

  box-sizing: border-box; /* Opera/IE 8+ */

}

 

.component {

  border: dashed 1px #ccc;

  border-radius: 2px;

  padding: .5rem;


  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */

  -moz-box-sizing: border-box; /* Firefox, other Gecko */

  box-sizing: border-box; /* Opera/IE 8+ */

}

 

// default top and bottom padding of components

$default-vertical-padding: 10px;

 

// Browser Prefixes - Which CSS prefixes should be used?

$webkit: true;

$moz: false;

$ms: false;

$o: false;