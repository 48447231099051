//== Variables used in LESS files

//== Grid,screen sizes (See also grid.less)
$grid-gutter-width: 20px;
$screen-sm-min: 768px;       //Phone/tablet/desktop dividing lines
$screen-md-min: 992px;
$screen-lg-min: 1024px;
$screen-large-desktop: 1920px;

$screen-xs-max: ($screen-sm-min - 1);
$screen-md-max: ($screen-lg-min - 1);

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

// Colors
$text-color-primary: #424559;
$text-color-secondary: #00AA59;

// Color palletes
$text-color-gunpowder: #424559;
$text-color-jade: #00BF66;
$text-color-brightgray: #34384B;
$text-color-evonyclay: #282B3E;
$text-color-alabaster: #FAFAFA;
$text-color-white: #FFFFFF;
$text-color-gallery: #EDEDED;
$text-color-greenhaze: #00A758;
$text-color-tab-item: #282B3E;
$text-color-tab-border: #00A758;
$text-color-darkgray: #202336;
$text-color-blue: #0000c1;
$text-color-text-component: #202336;
$text-color-black: #000000;
$text-color-green: #06874e;
$text-color-red: #c14a36;
$text-color-subgray: #5e6073;
$text-color-manatee: #8E90A2;
$text-color-grey: #767676;


$bg-white: #FFFFFF;
$bg-grey: #FAFAFA;
$bg-cyan: #0b9189;
$bg-green: #06874e;
$bg-greenhaze: $text-color-greenhaze;
$bg-turquoise: #07857d;
$bg-orange: #a75900;
$bg-blue: #0000c1;
$bg-violet: #533573;
$bg-tomato: #ff5d55;

$border_color_lightgray: #EDEDED;
$border_color_darkgray: #8e90a2;
$border_color_greenhaze: #00A758;
$border_color_green: #06874e;
$border_color_gainsboro: #D8D8D8;
$border_color_valencia:  #DC5A44;

$btn-primary-bg-color: #ec6453;


// Layout
$layout-bg-color: $text-color-alabaster;
$layout-bg-color-secondary: $text-color-white;


// Footer
$footer-bg-color: $text-color-evonyclay;
$footer-divider-color: #1E212F;
$footer-color-quicklink: #c2c3c9;


//List
$list-title-color: $text-color-evonyclay;
$list-main-border: #F1F1F1;
$list-cell-border: #ECECEC;

//Map
$color-cyangreen: #19C675;
$map-color-lightgray: #707381;


// Fonts
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

$font-family-primary: 'Manulife JH Sans', sans-serif;
$font-family-secondary: 'Manulife JH Serif', serif;

// Headings
$h1-font-size: 36px;
$h2-font-size: 24px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 12px;
$h1-font-supersize: 36px;

//Fontsize
$px: 16rem;

//Navigation

$nav-bg-color: $text-color-brightgray;
$nav-bg-hover: $text-color-evonyclay;
$nav-bg-logo: $text-color-greenhaze;
$nav-divider: #2F3246;
$nav-bg-active: #1a1c28;
$nav-sub-bg: $text-color-white;
$nav-sub-border: $text-color-gallery;
$nav-sub-hover: $text-color-alabaster;
$nav-sub-color: #202336;
$nav-bg-mobile: $text-color-brightgray;
$nav-border-mobile: $text-color-gallery;
$nav-bg-mobile-menu: #1A1C28;

//Zindex
$zindex-sub-navbar: 1000;
$zindex-header: 1001;
$zindex-carouselvideo: 1002;
$zindex-headersticky: 1003;
$zindex-navbar: 1002;
$zindex-navbar-mobile: 1003;
$zindex-sub-navbar-mobile: 1004;
$zindex-top-navbar-mobile: 1005;


//Fixed values
$header-height: 80px;
$nav-width: 80px;
$nav-secondary-width: 310px;
$nav-secondary-large-width: 350px;
$image-blade-width: calc((100% - 390px) * .15);
$image-blade-large-width: calc((100% - 430px) * .15);
$right-rail-image-blade: calc((100% - 80px) * .15);

//Margins
$grid-default-margin: 40px;
$grid-mobile-margin: 20px;
$grid-large-desktop-margin: 60px;

$grid-margin: $grid-default-margin;

//Buttons
$btn-primary: #EC6453;
$btn-primary-hover: #DC5A44;
$grid-default-gutter: 20px;
$grid-mobile-gutter: 4px;
$btn-back-color: #202336;

// page margin
$page-top-margin: 0;
$page-bottom-margin: 50px;

//Custom Select Dropdown
$select-dashed-border: #979797;
$select-text-color: #8e90a2;
$select-li-selected: #f5f5f5;

$emphasis-color: #ff6b59;
$action-color: $emphasis-color;
$text-color: #333;
$second-text-color: lighten($text-color, 30%);

$base-color: #f0f0f0;
$base-color--dark: darken($base-color, 15%);
$base-color--light: lighten($base-color, 5%);

$bg-color: white;

// cta link
$cta-link-font-size: 14px;
$cta-link-line-height: 20px;

/** Mixins **/

/* coremfc text component style */

.border-box {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.component {
  border: dashed 1px #ccc;
  border-radius: 2px;
  padding: .5rem;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

// default top and bottom padding of components
$default-vertical-padding: 10px;

// Browser Prefixes - Which CSS prefixes should be used?
$webkit: true;
$moz: false;
$ms: false;
$o: false;

$resourcePath: '/apps/coremfc/clientlibs/clientlib-site/resources/';


/* Text default styling */

.text {
	color: $text-color-gunpowder;


    h1 {
    	b {
    		font-weight: $font-weight-semibold;
    	}
    }

	.cmp-text {
		padding-top: 10px;
		padding-bottom: 10px;

        > :first-child {
            margin-top: 0;
        }

		> :last-child {
			margin-bottom: 0;
		}
	}

    > :first-child {
        margin-top: 0;
    }

    // Header in text spacing
    /*h1 {
        margin: 80px 0 0px;
    }
    h1 + p {
        margin: 20px 0 0px;
    }
    h2, h3, h4 {
        margin: 60px 0 0px;
    }
    h2 + p, h3 + p, h4 + p {
        margin: 10px 0 0px;
    }
    h5 {
        margin: 30px 0 0px;
    }
    h5 + p {
        margin: 10px 0 0px;
    }
    h6 {
        margin: 20px 0 0;
    }
	h6 + p {
        margin: 10px 0 0px;
    }
    h4, h5, h6 {
        font-weight: $font-weight-semibold;
    }*/

    //ARPW-772 / ARPW-441
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        margin-bottom: 20px;

        & + p {
			 margin-top: -10px;
        }
    }

    h1 {
        margin-top: 80px;

        & + p {
			margin: 10px 0px 6px 0px;
        }
    }

    h4, .h4,
    h5, .h5,
   	h6, .h6 {
        font-weight: $font-weight-semibold;
    }

	h2, .h2,
    h3, .h3,
   	h4, .h4 {
        margin-top: 60px;
    }

    h5, .h5,
    h6, .h6 {
		margin-top: 20px;
    }
    //ARPW-772 / ARPW-441

    + .image {
		margin-top: 60px;
        margin-bottom: 60px;
    }

	&.cmp-quicklink-type {
		margin-bottom: 2px;
		padding: 0;

        &.aem-GridColumn.aem-GridColumn--default--3 {
            width: auto;
        }

        p {
            margin-top: 2px;
            margin-bottom: 0;
        }

        .cmp-text {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

	p {
	    margin: 0 0 10px;
		color: $text-color-evonyclay;
		font-size: 1rem;
		font-weight: $font-weight-light;
		line-height: 26px;
		letter-spacing: normal;

		&:last-child {
			margin-bottom: 0;
		}
	}
    
    .footnote-class {
        font-size: 12px;
        line-height: 22px;
    }

	a {
		color: $text-color-evonyclay;
		position: relative;
		font-size: 13px;
		letter-spacing: 0.3px;
		line-height: 18px;
		padding-bottom: 2px;
		margin: 8px 0;

		.cmp-text__cta {
			&--primarybtn,
			&--secondarybtn, 
			&--whitebtn, 
			&--quicklink {
				@media (max-width: $screen-sm-min) {
					width: 100%;
				}
			}
		}
	}

	.cmp-text__cta {
		&--primarybtn,
		&--secondarybtn, 
		&--whitebtn, 
		&--quicklink {
			a {
				@media (max-width: $screen-sm-min) {
					width: 100%;
				}
			}
		}
	}

	b > a,
	a > b {
		color: $text-color-gunpowder;
		font-weight: $font-weight-medium;
		font-size: 1rem;
		line-height: 20px;
		letter-spacing: normal;
	}

	table {
		width: 100%;
		td {
			padding: 8px 0;
		}
	}

	ol, ul {
		padding-left: 0;

		li {
			line-height: 1.5rem;
			min-height: 1.5rem;
			font-weight: $font-weight-light;
			color: $text-color-evonyclay;
			position: relative;
			margin-bottom: 10px;
		}
	}

	ol {
		list-style: none;
		counter-reset: item-counter;
		
		li {
			padding-left: 25px;
		}
		
		li::before {
			content: counter(item-counter) '.';
			counter-increment: item-counter;
			width: 25px;
			display: inline-block;
			font-weight: $font-weight-semibold;
			font-size: 1rem;
			color: $text-color-darkgray;
			position: absolute;
    		left: 0;
		}
	}

	ul li {
		display: block;
		list-style: none;
		padding-left: 25px;

		&:before {
			content: "\25A0";
    		color: #00bf66;
    		margin-right: 0.875rem;
			position: absolute;
    		left: 0;
		}

        &.has-custom-style {
            padding-left: 0px;

            &:before {
                content: "";
            }
        }
	}

    &.txt-cmp__back {
		margin-bottom: 0px;
	    padding-left: 20px;
    	padding-top: 25px;
    	padding-bottom: 25px;

        p {
			margin-bottom: 0px;
    		height: 20px;
        }

        a {
            font-size: 16px;
            line-height: 20px;
            height: 20px;
            padding-bottom: 0px;
            color: #202336;
            margin-top: 0px;
    		margin-bottom: 0px;

            &:before{
                -ms-transform: rotate(180deg); /* IE 9 */
                -webkit-transform: rotate(180deg); /* Safari 3-8 */
                transform: rotate(180deg);
            }
        }
    }

    &.border-bottom {
		border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: $border_color_lightgray;
    }

    .cmp-text__cta--secondarylink {
		a, b {
			font-size: 0.875rem;
			line-height: 1.29;
			font-style: normal;
			letter-spacing: normal;
			color: $text-color-text-component;
		}
		b {
			font-weight: $font-weight-semibold;
		}
	}
}

.text.primarylink-right {
	.cmp-text {
		.cmp-text__cta--primarylink {
			padding-left: 0;
			padding-right: 70px;
			width: 100%;
			font-size: 22px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.45;
			letter-spacing: normal;

			&:before {
				position: relative;
				float: right;
				top: 4px;
			}
		}

	}
}

.text .cmp-text  hr {
	height: 1px;
	background-color: $text-color-gallery;
	border: 0;
}
