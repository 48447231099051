.compare-fund-section{
    text-align: right;
    @media #{mediaMobile()} {
        text-align: center;
    }
}
.js-tab-graph-detail-msg{
    display: none;
}
.compare-fund{
    background: $color-coral;
    color: dark(7);
    font-size: 14px;
    padding: 10px 20px;
    span{
        border-bottom: 0;
    }
    
    @media #{mediaMobile()} {
        width: 60%;
    }
}
.right-align-class{
    @media #{mediaMobile()} {
        text-align: right;
    }
}
.graph-header-basic-price {
  margin-top: $grid-indentMediumPx;
  margin-bottom: $grid-indentSmallPx;
    
    @media #{mediaMobile()} {
        margin-top: 0;
        padding-top: $grid-indentMediumPx;
    }
    
}

.graph-fund-value {
  font-size: 32px;
  line-height: 36px;
  font-family: $font-regular;
  font-weight: normal;

}

.graph-fund-currency {
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: $font-light;
  font-weight: 300;
}

.graph-fund-text-heading {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 1px;
  color: dark(0);
  white-space: nowrap;
  align-self: flex-start;
}

.graph-fund-text {
  font-size: 14px;
  line-height: 14px;
  font-family: $font-regular;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
  align-self: flex-end;
}

.graph-wrapper {
  display: flex;
  padding-bottom: 10px;
  overflow: auto;
    align-items: stretch;

  & > div {
    position: relative;
    flex: 1 0;
    text-align: right;
    width: 14%;
    display: flex;
    flex-wrap: wrap;
      
      & > p{
          width: 100%;
      }

    @media #{mediaMobile()} {
      min-width: 80px;
    }

    &::after {
      content: '';
      position: relative;      
    }

    &:last-child {
      flex: 0 0 auto;

      &::after {
        display: none;
      }

      @media #{mediaMobile()} {
        flex: 1 0;
      }
    }
  }
}

.graph-fund-block {
  position: relative;
  flex: 1 0;

  @media #{mediaDown($break-point-1024)} {
    height: 40px;
  }
}

.graph-year-block {
  position: relative;
  flex: 1 0;

  @media #{mediaDown($break-point-1024)} {
    height: 40px;
  }
}

.year-returns {
    display: flex;
    padding-bottom: 10px;
    overflow: auto;
    justify-content: flex-start;
  

  & > div {
    position: relative;
    text-align: right;
    width: 20%;

    @media #{mediaMobile()} {
      min-width: 80px;
    }

    &::after {
      content: '';
      position: relative;
    }

    &:last-child {
      flex: 0 0 auto;

      &::after {
        display: none;
      }

      @media #{mediaMobile()} {
        flex: 1 0;
      }
    }
  }
}

.graph-link {
  margin-top: 101px;

  @media #{mediaDown($break-point-1024)} {
    margin-top: 50px;
  }
}

.chart-section.chart1{
    
    @media #{mediaMobile()} {
        padding-top: $grid-indentMediumPx;
    }
}