.distribution-info{
    margin: 0 -30px;
    padding-top: 50px;
    background-color: dark(7);
    @media #{mediaUp($break-point-1920)} {
        margin:0 -60px;
    }
}
.fund-details-distribution-info {
  margin-top: $grid-indentSmallPx;
  margin-bottom: $grid-indentMediumPx;  
}

.distribution-row{
    border-bottom: 1px solid dark(6);
    margin: 0px -30px;
    padding: 20px 30px;
    display: inline-block;
    width: calc(100% + 60px);
    background: dark(6);
    &:nth-child(even){
        background: dark(5);
    }
    
    @media #{mediaUp($break-point-1920)} {
        margin: 0px -60px;
        width: calc(100% + 120px);
        padding: 20px 60px;
    }
    
    @media #{mediaDown($break-point-1280)} {
        width: auto;
        min-width: calc(150px * 9);
    }
    
    @media #{mediaMobile()} {
//        width: fit-content;
        width: auto;
        min-width: calc(150px * 9);
    }
}

.distribution-info-fund-value {
  font-size: 32px;
  line-height: 36px;
  font-family: $font-regular;
  font-weight: normal;
}

.distribution-info-currency {
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: $font-light;
  font-weight: 300;
}
.js-info-header{
    &.active{
        position: fixed;
    }
}
.distribution-info{
    @media #{mediaDown($break-point-1280)} {
        overflow-x: auto;
    }
}

.info-header{
    display: flex;
    width: calc(100% + 60px);
    padding: 0 30px;
    padding-bottom: 20px;
    
    @media #{mediaUp($break-point-1920)} {
        padding: 0 60px;
    }
    
    @media #{mediaMobile()} {
        width: auto;
        min-width: calc(150px * 9)
    }
    
    p{
        width:calc(100% / 10);
        padding-right: 10px;
        &:last-child{
            padding-right: 0px;
        }
        
        @media #{mediaDown($break-point-1280)} {
            min-width: 150px;
        }
    }
}
.info-details{
    display: inline-flex;
    width: 100%;
    padding: 0 30px;
    
    @media #{mediaUp($break-point-1920)} {
        padding: 0 60px;
    }
    
    div{
        width:calc(100% / 10);
        padding-right: 10px;
        &:last-child{
            padding-right: 0;
        }
        
        @media #{mediaDown($break-point-1280)} {
            min-width: 150px;
        }
    }
}