.custom-marker {
  position: relative;

  & .manulife-icon {
    transform: translate3d(-50%, -100%, 0);
    cursor: pointer;
  }

  & .marker-tooltip {
    position: absolute;
    top: -$grid-indentSmallPx;
    background-color: rgba(35, 37, 55, 0.9);
    width: 220px;
    padding: 30px 45px 30px 30px;
    color: #fff;
    font-size: 16px;
    transform: translate3d(-50%, -93%, 0);
    opacity: 0;
    transition: transform 600ms ease-out, opacity 300ms ease-out;
    z-index: -1;
    pointer-events: none;

    &.opened {
      transform: translate3d(-50%, -100%, 0);
      opacity: 1;
      pointer-events: auto;
    }
  }

  & .marker-button-close {
    position: absolute;
    top: -$grid-indentSmallPx;
    right: -20px;
    width: 10px;
    height: 10px;
    background: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    outline: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;

    & svg {
      display: block;
    }
  }

  & .marker-title {
    width: 146px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 1.33;
    white-space: pre-line;
  }

  & .marker-text {
    font-size: 14px;
    font-family: $font-light;
    font-weight: 300;
    line-height: 1.43;
    white-space: pre-line;
  }

  & .marker-link {
    color: #fff;
    font-family: $font-bold;
    text-decoration: none;
  }
}
