@charset "UTF-8";
/* site */
/* environment config */
/** Mixins **/
.border-box {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.component {
  border: dashed 1px #ccc;
  border-radius: 2px;
  padding: .5rem;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

@font-face {
  font-family: 'Manulife JH Sans Light';
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-light.woff2") format("woff2"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans Regular';
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-regular.woff2") format("woff2"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Sans Bold';
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-bold.woff2") format("woff2"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-sans-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Manulife JH Serif Italic';
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-serif-italic.woff2") format("woff2"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/manulife/manulife-jh-serif-italic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Noto Sans VN vi';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.ttf") format("truetype"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.woff") format("woff"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: 'Noto Sans Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-700.ttf") format("truetype"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-700.woff") format("woff"), url("/etc.clientlibs/corewam/clientlibs/main/resources/fonts/noto-sans-vn/noto-sans-v8-vietnamese_latin-700.woff2") format("woff2");
}

.no-transition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.no-overflow {
  overflow: hidden;
}

.global-padding-bottom {
  padding-bottom: 80px !important;
}

@media (max-width: 1919.98px) {
  .global-padding-bottom {
    padding-bottom: 70px !important;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .global-padding-bottom {
    padding-bottom: 30px !important;
  }
}

.margin-header {
  margin-top: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .margin-header {
    margin-top: 10px;
  }
}

.loader {
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  border: 10px solid rgba(0, 105, 64, 0.2);
  border-top: 10px solid #006940;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 0.6s linear infinite;
  /* Safari */
  animation: spin 0.6s linear infinite;
}

.loader.hide {
  display: none;
}

.loader.show {
  display: block;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.invisible {
  opacity: 0 !important;
  z-index: -1;
}

.visible {
  opacity: 1 !important;
  z-index: 1;
}

@media (max-width: 1439.98px) {
  .d-1440-none {
    display: none !important;
  }
}

@media (max-width: 1279.98px) {
  .d-1280-none {
    display: none !important;
  }
}

@media (max-width: 1023.98px) {
  .d-1024-none {
    display: none !important;
  }
}

@media (max-width: 767.98px) {
  .d-768-none {
    display: none !important;
  }
}

.d-none {
  display: none !important;
}

.text-center {
  text-align: center;
}

.center-ele {
  float: none !important;
  display: inline-block !important;
}

/* update the footer logo size */
.xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--copyright.big-footer-image .cmp-image__image, .footer .aem-GridColumn.xfpage-footer__layoutcontainer--copyright.big-footer-image .cmp-image__image {
  width: 220px;
  height: 40px;
}

/* override the orderlist style */
.ol-num.text ol {
  counter-reset: none;
}

.ol-num ol:first-of-type {
  counter-reset: item-counter;
}

/* update the table style */
.cmp-text ul.datatable-rowHeader li {
  padding: 10px 20px;
  font-size: 1.5rem;
  font-weight: normal;
}

@media (max-width: 767.98px) {
  .cmp-text ul.datatable-rowHeader li {
    font-size: 0.875rem;
  }
}

.cmp-text ul.datatable-rowHeader li:first-child {
  font-weight: 600;
}

.cmp-text ul.datatable-rowHeader h5 {
  margin: 0;
}

.text li a {
  display: inline-block;
}

.cmp-text table td:first-child {
  font-weight: normal;
}

/* update the banner teaser */
.bannerteaser .cmp-teaser__description {
  max-height: 210px;
}

@media (max-width: 767.98px) {
  .bannerteaser .cmp-teaser__description {
    max-height: 160px;
    -webkit-line-clamp: 5;
  }
}

.cmp-bannerteaser--blue .cmp-bannerteaser__wrapper, .cmp-bannerteaser--green .cmp-bannerteaser__wrapper, .cmp-bannerteaser--purple .cmp-bannerteaser__wrapper, .cmp-bannerteaser--purple-dark .cmp-bannerteaser__wrapper, .cmp-bannerteaser--orange .cmp-bannerteaser__wrapper, .cmp-bannerteaser--cyan .cmp-bannerteaser__wrapper, .cmp-bannerteaser--carol .cmp-bannerteaser__wrapper {
  border: 0;
}

.cmp-bannerteaser--blue .cmp-teaser__description, .cmp-bannerteaser--blue .cmp-teaser__uppertext, .cmp-bannerteaser--blue .cmp-teaser__subtext,
.cmp-bannerteaser--blue .cmp-teaser__action-link a, .cmp-bannerteaser--green .cmp-teaser__description, .cmp-bannerteaser--green .cmp-teaser__uppertext, .cmp-bannerteaser--green .cmp-teaser__subtext,
.cmp-bannerteaser--green .cmp-teaser__action-link a, .cmp-bannerteaser--purple .cmp-teaser__description, .cmp-bannerteaser--purple .cmp-teaser__uppertext, .cmp-bannerteaser--purple .cmp-teaser__subtext,
.cmp-bannerteaser--purple .cmp-teaser__action-link a, .cmp-bannerteaser--purple-dark .cmp-teaser__description, .cmp-bannerteaser--purple-dark .cmp-teaser__uppertext, .cmp-bannerteaser--purple-dark .cmp-teaser__subtext,
.cmp-bannerteaser--purple-dark .cmp-teaser__action-link a, .cmp-bannerteaser--orange .cmp-teaser__description, .cmp-bannerteaser--orange .cmp-teaser__uppertext, .cmp-bannerteaser--orange .cmp-teaser__subtext,
.cmp-bannerteaser--orange .cmp-teaser__action-link a, .cmp-bannerteaser--cyan .cmp-teaser__description, .cmp-bannerteaser--cyan .cmp-teaser__uppertext, .cmp-bannerteaser--cyan .cmp-teaser__subtext,
.cmp-bannerteaser--cyan .cmp-teaser__action-link a, .cmp-bannerteaser--carol .cmp-teaser__description, .cmp-bannerteaser--carol .cmp-teaser__uppertext, .cmp-bannerteaser--carol .cmp-teaser__subtext,
.cmp-bannerteaser--carol .cmp-teaser__action-link a {
  color: #ffffff !important;
}

.cmp-bannerteaser--blue.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--blue.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--green.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--green.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple-dark.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple-dark.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--orange.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--orange.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--cyan.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--cyan.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--carol.cmp-bannerteaser--belt .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--carol.cmp-bannerteaser--adbanner .cmp-bannerteaser .cmp-teaser__action-link a {
  color: #ffffff !important;
}

.cmp-bannerteaser--blue.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--blue.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--green.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--green.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple-dark.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--purple-dark.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--orange.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--orange.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--cyan.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--cyan.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--carol.cmp-bannerteaser--notice__contact .cmp-bannerteaser .cmp-teaser__action-link a, .cmp-bannerteaser--carol.cmp-bannerteaser--notice__product .cmp-bannerteaser .cmp-teaser__action-link a {
  border: 0;
}

.cmp-bannerteaser--carol .cmp-bannerteaser__wrapper {
  background-color: #FF7769;
}

.text li a {
  display: inline-block;
}

.cmp-text table td:first-child {
  font-weight: normal;
}

/* update the alignment of the fund doc items */
.funddocuments .aem-GridColumn {
  padding: 0;
}

/* over css for the logo in 2nd nav bar*/
.cmp-navigation__submenu .cmp-navigation__submenu-logo {
  padding-left: 5px;
}

@media (min-width: 1920px) {
  .cmp-navigation__submenu .cmp-navigation__submenu-logo {
    padding-left: 25px;
  }
}

.cmp-navigation__secondary .cmp-navigation__submenu-logo {
  padding-left: 5px;
}

@media (min-width: 1920px) {
  .cmp-navigation__secondary .cmp-navigation__submenu-logo {
    padding-left: 25px;
  }
}

/*
.cmp-template--left-rail .cmp-navigation__submenu .cmp-navigation__submenu-logo .header-logo{
	display:none
}*/
.cmp-template--left-rail .cmp-header .cmp-header__container-logo {
  padding-left: 5px;
}

@media (max-width: 1024px) {
  .cmp-template--left-rail .cmp-header .cmp-header__container-logo {
    padding-left: 40px;
  }
}

@media (min-width: 1920px) {
  .cmp-template--left-rail .cmp-header .cmp-header__container-logo {
    padding-left: 25px;
  }
}

/* formula class for formula in the investchoice */
.invest-form-formula {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin: 0 auto;
  text-align: left;
}

.formula-block {
  padding: 10px 10px;
}

.formula-block.formula-block-left {
  padding-left: 0;
}

.formula-block p:not(.formula-divider) {
  padding-top: 1px;
}

.formula-block p {
  padding-bottom: 0;
}

.formula-block p.smaller-screens {
  display: none;
}

.formula-block.formula-block-right {
  padding-right: 0;
  margin-top: 3px;
}

.formula-block .formula-divider {
  padding-bottom: 2px;
  border-bottom: 1px solid #333333;
}

@media only screen and (max-width: 1024px) {
  .formula-block p.smaller-screens {
    display: block;
    text-align: center;
  }
  .formula-block p.desktop {
    displa: none;
  }
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-right-10 {
  padding-right: 10px;
}

#cmp-navigation__mobile .cmp-navigation__mobile-logo {
  padding-top: 15px;
}

#cmp-navigation__mobile .cmp-navigation__mobile-logo .mobile-logo {
  height: 30px;
}

.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line .js-profile-update {
  color: #fff;
  cursor: pointer;
}

.cmp-header .cmp-header__dropdown-component-options.cmp-header__dropdown-component--expanded .js-profile-update {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  padding: 19px 20px;
  height: 60px;
  margin: 0;
  align-items: center;
  color: #282b3e;
  text-decoration: none;
  text-align: left;
  font-size: 16px;
  font-weight: 300;
}

.cmp-header .cmp-header__dropdown-component-options.cmp-header__dropdown-component--expanded .js-profile-update:hover {
  background-color: #fafafa;
}

.dropdown-menu > li > .js-profile-update {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #282b3e;
  white-space: nowrap;
}

.custom-selectbox-nav .dropdown-menu li .js-profile-update {
  height: 80px;
  width: 100%;
  line-height: 1.43;
  color: #282b3e;
  font-weight: inherit;
  display: table;
  white-space: normal;
  cursor: pointer;
}

/* drop down of log in */
.cmp-header__nav-login {
  position: relative;
  z-index: 1;
}

.cmp-header__nav-login .btn.btn-secondary {
  border: 0;
  background: none;
  font-weight: normal;
}

.cmp-header__nav-login .btn.btn-secondary:hover {
  color: inherit;
}

div.cmp-header__nav-login[aria-expanded="true"] .icon-dropdown::before {
  transform: rotate(-90deg);
}

.cmp-header__nav-login .btn.btn-secondary:hover .icon-dropdown::before {
  background: url("/etc.clientlibs/coremfc/clientlibs/clientlib-site/resources/arrow-right-navy-hover.svg") no-repeat center;
}

.cmp-header__nav-login ul {
  position: absolute;
  top: 38px;
  right: 0;
  z-index: 10;
  margin: 0;
  padding: 0;
  display: none;
  overflow-y: auto;
  list-style-type: none;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.cmp-header__nav-login ul li {
  background-color: #fff;
  border: 1px solid #ededed;
  min-width: 125px;
  max-height: 300px;
  z-index: 1;
  padding: 23px 20px;
  margin: 0;
  height: 60px;
  cursor: pointer;
}

.cmp-header__nav-login ul li:hover {
  background-color: #fafafa;
}

.cmp-header__nav-login ul li.active {
  border-left: 5px solid #00a758;
}

.cmp-header__nav-login ul li a {
  color: #282b3e;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 300;
  text-align: left;
  text-decoration: none;
}

/* update mobile login dropdown */
#cmp-navigation__mobile.open-profile {
  overflow: unset;
}

#cmp-navigation__mobile .cmp-navigation__mobile-contactusheader {
  width: calc(100% - 60px);
  display: none;
}

#cmp-navigation__mobile .cmp-navigation__mobile-user {
  cursor: pointer;
}

ul.profile-mobile-drop-down {
  position: fixed;
  z-index: 1006;
  top: 60px;
  left: 0;
  width: calc(100% - 60px);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.profile-mobile-drop-down li {
  height: 60px;
  padding: 23px 20px;
  background: #fff;
  border: 1px solid #ededed;
  cursor: pointer;
}

ul.profile-mobile-drop-down li:hover {
  background-color: #fafafa;
}

ul.profile-mobile-drop-down li a {
  color: inherit;
}

ul.profile-mobile-drop-down li.active {
  border-left: 5px solid #00a758;
}

/* update the mobile profile selection */
.custom-selectbox-nav .dropdown-menu.custom-selectbox-nav {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.custom-selectbox-nav .dropdown-menu.custom-selectbox-nav .business-line {
  min-width: 50%;
}

/* override aem quick link personalisation style */
.homepage-top-area .aem-GridColumn.target {
  padding: 0;
}

/* update the style for fund risk overlay*/
.ui-dialog .ui-dialog-content.cmp-internal-link-disclaimer__modal {
  overflow: hidden !important;
  background: #fff !important;
  padding: 0;
  max-width: 80vw;
}

.cmp-link-disclaimer__modal .cmp-link-disclaimer__modal-footer {
  border: 0;
}

@media (max-width: 767.98px) {
  .cmp-link-disclaimer__modal .cmp-link-disclaimer__modal-footer {
    max-width: 80vw;
  }
}

/* updte style for target mode component */
.target.parbase {
  margin: 0;
  padding: 0;
}

/* update logo */
.cmp-template--home-page .cmp-header .cmp-header__container-logo {
  padding: 0 0 0 40px;
  height: 80px;
  display: flex;
  align-items: center;
}

.cmp-template--home-page .cmp-header .white-logo {
  height: auto;
  font-size: 0;
  line-height: 0;
}

.cmp-template--home-page .cmp-header .cmp-header.navbar-sticky .header-logo {
  max-height: 33px;
  height: auto;
}

#cmp-navigation__mobile .cmp-navigation__mobile-logo.has-logo {
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 0;
  line-height: 0;
  height: auto;
}

#cmp-navigation__mobile .cmp-navigation__mobile-logo.has-logo .header-logo {
  height: auto;
}

#cmp-navigation__mobile .cmp-navigation__mobile-logo.has-logo .mobile-logo {
  height: auto;
  max-width: 160px;
}

.container-inner-full.insurance-homepage .cmp-header.navbar-sticky .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line .js-profile-update {
  color: #202336;
}

.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line:not(:first-child) {
  padding-left: 7px;
}

.container-inner-full.insurance-homepage .cmp-header__container--businessline .cmp-header__nav .cmp-header__nav-right .business-line:not(:last-child) {
  padding-right: 7px;
}

/*override fund datepicker*/
.ui-datepicker .ui-datepicker-header {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--terms .cmp-text p {
    line-height: 25px;
  }
}

@media screen and (min-width: 769px) {
  .xfpage-footer__layout-container .aem-GridColumn.xfpage-footer__layoutcontainer--copyright .text:first-child {
    padding: 0;
  }
}

/* override the style of Coremfc dialog*/
.ui-widget.ui-widget-content:not(.ui-datepicker) {
  height: 100vh !important;
  margin: 0;
  background: none;
}

.no-m {
  margin: 0 !important;
}

.no-tm {
  margin-top: 0 !important;
}

.no-bm {
  margin-bottom: 0 !important;
}

.no-p {
  padding: 0 !important;
}

.no-lp {
  padding-left: 0 !important;
}

.clear-fix {
  clear: both;
}

.container {
  margin: 0 30px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .container {
    margin: 0 20px;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

@media (min-width: 1920px) {
  .container {
    margin: 0 60px;
  }
}

.row, .fund-tile {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  min-height: 0;
  margin-top: 0;
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 0;
}

.row.margin-bottom-small, .margin-bottom-small.fund-tile {
  margin-bottom: 20px;
}

.row.margin-bottom-medium, .margin-bottom-medium.fund-tile {
  margin-bottom: 50px;
}

.row.margin-bottom-large, .margin-bottom-large.fund-tile {
  margin-bottom: 80px;
}

.row.margin-top-small, .margin-top-small.fund-tile {
  margin-top: 20px;
}

.row.margin-top-medium, .margin-top-medium.fund-tile {
  margin-top: 50px;
}

.row.margin-top-large, .margin-top-large.fund-tile {
  margin-top: 80px;
}

.row.line-top, .line-top.fund-tile, .row.line-bottom, .line-bottom.fund-tile {
  position: relative;
  z-index: 0;
}

.row.padding-normal, .padding-normal.fund-tile {
  padding: 20px 0;
}

.row.padding-medium, .padding-medium.fund-tile {
  padding: 30px 0;
}

.row.padding-large, .padding-large.fund-tile {
  padding: 50px 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .row, .fund-tile {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.row::before, .fund-tile::before, .row::after, .fund-tile::after {
  content: '';
  position: absolute;
  left: calc(-20px);
  right: calc(-20px);
  z-index: -1;
}

@media (min-width: 1920px) {
  .row::before, .fund-tile::before, .row::after, .fund-tile::after {
    left: -50px;
    right: -50px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .row::before, .fund-tile::before, .row::after, .fund-tile::after {
    left: -20px;
    left: calc(-20px - env(safe-area-inset-left));
    right: -20px;
    right: calc(-20px - env(safe-area-inset-right));
  }
}

.row.line-top::before, .line-top.fund-tile::before {
  top: 0;
  border-top: 1px solid rgba(66, 69, 89, 0.1);
}

.row.line-bottom::after, .line-bottom.fund-tile::after {
  bottom: 0;
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
}

.col, .fund-tile__col {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  max-width: 100%;
  min-height: 0;
  min-width: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .col, .fund-tile__col {
    padding-right: 0px;
    padding-left: 0px;
  }
  .col.hidden-small, .hidden-small.fund-tile__col {
    display: none;
  }
}

.row.margin-top-small, .margin-top-small.fund-tile, .col.margin-top-small, .margin-top-small.fund-tile__col {
  margin-top: 20px;
}

.row.margin-top-medium, .margin-top-medium.fund-tile, .col.margin-top-medium, .margin-top-medium.fund-tile__col {
  margin-top: 50px;
}

.row.margin-top-large, .margin-top-large.fund-tile, .col.margin-top-large, .margin-top-large.fund-tile__col {
  margin-bottom: 80px;
}

.row.margin-bottom-small, .margin-bottom-small.fund-tile, .col.margin-bottom-small, .margin-bottom-small.fund-tile__col {
  margin-bottom: 20px;
}

.row.margin-bottom-medium, .margin-bottom-medium.fund-tile, .col.margin-bottom-medium, .margin-bottom-medium.fund-tile__col {
  margin-bottom: 50px;
}

.row.margin-bottom-large, .margin-bottom-large.fund-tile, .col.margin-bottom-large, .margin-bottom-large.fund-tile__col {
  margin-bottom: 80px;
}

.filter-row.hidden {
  display: none;
}

@media (min-width: 320px) {
  .col-1-320px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-2-320px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-3-320px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 320px) {
  .col-4-320px {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-5-320px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-6-320px {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 320px) {
  .col-7-320px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-8-320px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-9-320px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 320px) {
  .col-10-320px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-11-320px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-12-320px, .fund-tile__col, .page-calendar .container.footnotes-page .col, .page-calendar .container.footnotes-page .fund-tile__col {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 320px) {
  .col-auto-320px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 414px) {
  .col-1-414px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 414px) {
  .col-2-414px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 414px) {
  .col-3-414px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 414px) {
  .col-4-414px {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 414px) {
  .col-5-414px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 414px) {
  .col-6-414px {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 414px) {
  .col-7-414px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 414px) {
  .col-8-414px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 414px) {
  .col-9-414px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 414px) {
  .col-10-414px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 414px) {
  .col-11-414px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 414px) {
  .col-12-414px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 414px) {
  .col-auto-414px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 568px) {
  .col-1-568px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-2-568px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-3-568px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 568px) {
  .col-4-568px {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-5-568px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-6-568px {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 568px) {
  .col-7-568px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-8-568px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-9-568px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 568px) {
  .col-10-568px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 568px) {
  .col-11-568px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 568px) {
  .col-12-568px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 568px) {
  .col-auto-568px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 768px) {
  .col-1-768px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-2-768px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-3-768px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 768px) {
  .col-4-768px, .fund-tile__col-type-3 {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-5-768px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-6-768px, .fund-tile__col {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 768px) {
  .col-7-768px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-8-768px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-9-768px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 768px) {
  .col-10-768px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-11-768px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-12-768px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  .col-auto-768px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 1024px) {
  .col-1-1024px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-2-1024px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-3-1024px, .fund-tile__col {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 1024px) {
  .col-4-1024px, .fund-tile__col-type-3 {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-5-1024px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-6-1024px, .fund-tile__col-type-1, .fund-tile__col-type-2 {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1024px) {
  .col-7-1024px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-8-1024px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-9-1024px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 1024px) {
  .col-10-1024px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-11-1024px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-12-1024px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .col-auto-1024px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 1280px) {
  .col-1-1280px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-2-1280px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-3-1280px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 1280px) {
  .col-4-1280px {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-5-1280px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-6-1280px {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1280px) {
  .col-7-1280px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-8-1280px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-9-1280px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 1280px) {
  .col-10-1280px, .page-calendar .container.footnotes-page .col, .page-calendar .container.footnotes-page .fund-tile__col {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-11-1280px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-12-1280px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .col-auto-1280px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 1920px) {
  .col-1-1920px {
    flex-grow: 0;
    flex-basis: 8.3333333333%;
    max-width: 8.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-2-1920px {
    flex-grow: 0;
    flex-basis: 16.6666666667%;
    max-width: 16.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-3-1920px {
    flex-grow: 0;
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media (min-width: 1920px) {
  .col-4-1920px {
    flex-grow: 0;
    flex-basis: 33.3333333333%;
    max-width: 33.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-5-1920px {
    flex-grow: 0;
    flex-basis: 41.6666666667%;
    max-width: 41.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-6-1920px {
    flex-grow: 0;
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media (min-width: 1920px) {
  .col-7-1920px {
    flex-grow: 0;
    flex-basis: 58.3333333333%;
    max-width: 58.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-8-1920px {
    flex-grow: 0;
    flex-basis: 66.6666666667%;
    max-width: 66.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-9-1920px {
    flex-grow: 0;
    flex-basis: 75%;
    max-width: 75%;
  }
}

@media (min-width: 1920px) {
  .col-10-1920px {
    flex-grow: 0;
    flex-basis: 83.3333333333%;
    max-width: 83.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-11-1920px {
    flex-grow: 0;
    flex-basis: 91.6666666667%;
    max-width: 91.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-12-1920px {
    flex-grow: 0;
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1920px) {
  .col-auto-1920px {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100;
  }
}

@media (min-width: 320px) {
  .col-offsets-0-320px {
    margin-left: 0%;
  }
}

@media (min-width: 320px) {
  .col-offsets-1-320px {
    margin-left: 8.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-offsets-2-320px {
    margin-left: 16.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-offsets-3-320px {
    margin-left: 25%;
  }
}

@media (min-width: 320px) {
  .col-offsets-4-320px {
    margin-left: 33.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-offsets-5-320px {
    margin-left: 41.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-offsets-6-320px {
    margin-left: 50%;
  }
}

@media (min-width: 320px) {
  .col-offsets-7-320px {
    margin-left: 58.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-offsets-8-320px {
    margin-left: 66.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-offsets-9-320px {
    margin-left: 75%;
  }
}

@media (min-width: 320px) {
  .col-offsets-10-320px {
    margin-left: 83.3333333333%;
  }
}

@media (min-width: 320px) {
  .col-offsets-11-320px {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 320px) {
  .col-offsets-12-320px {
    margin-left: 100%;
  }
}

@media (min-width: 320px) {
  .col-offsets-auto-320px {
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .col-offsets-0-768px {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .col-offsets-1-768px {
    margin-left: 8.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-offsets-2-768px {
    margin-left: 16.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-offsets-3-768px {
    margin-left: 25%;
  }
}

@media (min-width: 768px) {
  .col-offsets-4-768px {
    margin-left: 33.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-offsets-5-768px {
    margin-left: 41.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-offsets-6-768px {
    margin-left: 50%;
  }
}

@media (min-width: 768px) {
  .col-offsets-7-768px {
    margin-left: 58.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-offsets-8-768px {
    margin-left: 66.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-offsets-9-768px {
    margin-left: 75%;
  }
}

@media (min-width: 768px) {
  .col-offsets-10-768px {
    margin-left: 83.3333333333%;
  }
}

@media (min-width: 768px) {
  .col-offsets-11-768px {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 768px) {
  .col-offsets-12-768px {
    margin-left: 100%;
  }
}

@media (min-width: 768px) {
  .col-offsets-auto-768px {
    margin-left: auto;
  }
}

@media (min-width: 1024px) {
  .col-offsets-0-1024px {
    margin-left: 0%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-1-1024px {
    margin-left: 8.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-2-1024px {
    margin-left: 16.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-3-1024px {
    margin-left: 25%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-4-1024px {
    margin-left: 33.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-5-1024px {
    margin-left: 41.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-6-1024px {
    margin-left: 50%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-7-1024px {
    margin-left: 58.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-8-1024px {
    margin-left: 66.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-9-1024px {
    margin-left: 75%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-10-1024px {
    margin-left: 83.3333333333%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-11-1024px {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-12-1024px {
    margin-left: 100%;
  }
}

@media (min-width: 1024px) {
  .col-offsets-auto-1024px {
    margin-left: auto;
  }
}

@media (min-width: 1280px) {
  .col-offsets-0-1280px {
    margin-left: 0%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-1-1280px, .page-calendar .container.footnotes-page .col, .page-calendar .container.footnotes-page .fund-tile__col {
    margin-left: 8.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-2-1280px {
    margin-left: 16.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-3-1280px {
    margin-left: 25%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-4-1280px {
    margin-left: 33.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-5-1280px {
    margin-left: 41.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-6-1280px {
    margin-left: 50%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-7-1280px {
    margin-left: 58.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-8-1280px {
    margin-left: 66.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-9-1280px {
    margin-left: 75%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-10-1280px {
    margin-left: 83.3333333333%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-11-1280px {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-12-1280px {
    margin-left: 100%;
  }
}

@media (min-width: 1280px) {
  .col-offsets-auto-1280px {
    margin-left: auto;
  }
}

@media (min-width: 1920px) {
  .col-offsets-0-1920px {
    margin-left: 0%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-1-1920px {
    margin-left: 8.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-2-1920px, .page-calendar .container.footnotes-page .col, .page-calendar .container.footnotes-page .fund-tile__col {
    margin-left: 16.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-3-1920px {
    margin-left: 25%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-4-1920px {
    margin-left: 33.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-5-1920px {
    margin-left: 41.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-6-1920px {
    margin-left: 50%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-7-1920px {
    margin-left: 58.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-8-1920px {
    margin-left: 66.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-9-1920px {
    margin-left: 75%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-10-1920px {
    margin-left: 83.3333333333%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-11-1920px {
    margin-left: 91.6666666667%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-12-1920px {
    margin-left: 100%;
  }
}

@media (min-width: 1920px) {
  .col-offsets-auto-1920px {
    margin-left: auto;
  }
}

@media (min-width: 320px) {
  .col-order--2-320px {
    order: -2;
  }
}

@media (min-width: 320px) {
  .col-order--1-320px {
    order: -1;
  }
}

@media (min-width: 768px) {
  .col-order-0-768px {
    order: 0;
  }
}

@media (min-width: 1024px) {
  .col-order-0-1024px {
    order: 0;
  }
}

/** Mixins **/
/* coremfc text component style */
.border-box {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.component {
  border: dashed 1px #ccc;
  border-radius: 2px;
  padding: .5rem;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
}

/* Text default styling */
.text {
  color: #424559;
  /*h1 {
        margin: 80px 0 0px;
    }
    h1 + p {
        margin: 20px 0 0px;
    }
    h2, h3, h4 {
        margin: 60px 0 0px;
    }
    h2 + p, h3 + p, h4 + p {
        margin: 10px 0 0px;
    }
    h5 {
        margin: 30px 0 0px;
    }
    h5 + p {
        margin: 10px 0 0px;
    }
    h6 {
        margin: 20px 0 0;
    }
	h6 + p {
        margin: 10px 0 0px;
    }
    h4, h5, h6 {
        font-weight: $font-weight-semibold;
    }*/
}

.text h1 b {
  font-weight: 600;
}

.text .cmp-text {
  padding-top: 10px;
  padding-bottom: 10px;
}

.text .cmp-text > :first-child {
  margin-top: 0;
}

.text .cmp-text > :last-child {
  margin-bottom: 0;
}

.text > :first-child {
  margin-top: 0;
}

.text h1, .text .h1,
.text h2, .text .h2,
.text h3, .text .h3,
.text h4, .text .h4,
.text h5, .text .h5,
.text h6, .text .h6 {
  margin-bottom: 20px;
}

.text h1 + p, .text .h1 + p,
.text h2 + p, .text .h2 + p,
.text h3 + p, .text .h3 + p,
.text h4 + p, .text .h4 + p,
.text h5 + p, .text .h5 + p,
.text h6 + p, .text .h6 + p {
  margin-top: -10px;
}

.text h1 {
  margin-top: 80px;
}

.text h1 + p {
  margin: 10px 0px 6px 0px;
}

.text h4, .text .h4,
.text h5, .text .h5,
.text h6, .text .h6 {
  font-weight: 600;
}

.text h2, .text .h2,
.text h3, .text .h3,
.text h4, .text .h4 {
  margin-top: 60px;
}

.text h5, .text .h5,
.text h6, .text .h6 {
  margin-top: 20px;
}

.text + .image {
  margin-top: 60px;
  margin-bottom: 60px;
}

.text.cmp-quicklink-type {
  margin-bottom: 2px;
  padding: 0;
}

.text.cmp-quicklink-type.aem-GridColumn.aem-GridColumn--default--3 {
  width: auto;
}

.text.cmp-quicklink-type p {
  margin-top: 2px;
  margin-bottom: 0;
}

.text.cmp-quicklink-type .cmp-text {
  padding-top: 0;
  padding-bottom: 0;
}

.text p {
  margin: 0 0 10px;
  color: #282B3E;
  font-size: 1rem;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: normal;
}

.text p:last-child {
  margin-bottom: 0;
}

.text .footnote-class {
  font-size: 12px;
  line-height: 22px;
}

.text a {
  color: #282B3E;
  position: relative;
  font-size: 13px;
  letter-spacing: 0.3px;
  line-height: 18px;
  padding-bottom: 2px;
  margin: 8px 0;
}

@media (max-width: 768px) {
  .text a .cmp-text__cta--primarybtn, .text a .cmp-text__cta--secondarybtn, .text a .cmp-text__cta--whitebtn, .text a .cmp-text__cta--quicklink {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .text .cmp-text__cta--primarybtn a, .text .cmp-text__cta--secondarybtn a, .text .cmp-text__cta--whitebtn a, .text .cmp-text__cta--quicklink a {
    width: 100%;
  }
}

.text b > a,
.text a > b {
  color: #424559;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  letter-spacing: normal;
}

.text table {
  width: 100%;
}

.text table td {
  padding: 8px 0;
}

.text ol, .text ul {
  padding-left: 0;
}

.text ol li, .text ul li {
  line-height: 1.5rem;
  min-height: 1.5rem;
  font-weight: 300;
  color: #282B3E;
  position: relative;
  margin-bottom: 10px;
}

.text ol {
  list-style: none;
  counter-reset: item-counter;
}

.text ol li {
  padding-left: 25px;
}

.text ol li::before {
  content: counter(item-counter) ".";
  counter-increment: item-counter;
  width: 25px;
  display: inline-block;
  font-weight: 600;
  font-size: 1rem;
  color: #202336;
  position: absolute;
  left: 0;
}

.text ul li {
  display: block;
  list-style: none;
  padding-left: 25px;
}

.text ul li:before {
  content: "\25A0";
  color: #00bf66;
  margin-right: 0.875rem;
  position: absolute;
  left: 0;
}

.text ul li.has-custom-style {
  padding-left: 0px;
}

.text ul li.has-custom-style:before {
  content: "";
}

.text.txt-cmp__back {
  margin-bottom: 0px;
  padding-left: 20px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.text.txt-cmp__back p {
  margin-bottom: 0px;
  height: 20px;
}

.text.txt-cmp__back a {
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  padding-bottom: 0px;
  color: #202336;
  margin-top: 0px;
  margin-bottom: 0px;
}

.text.txt-cmp__back a:before {
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -webkit-transform: rotate(180deg);
  /* Safari 3-8 */
  transform: rotate(180deg);
}

.text.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #EDEDED;
}

.text .cmp-text__cta--secondarylink a, .text .cmp-text__cta--secondarylink b {
  font-size: 0.875rem;
  line-height: 1.29;
  font-style: normal;
  letter-spacing: normal;
  color: #202336;
}

.text .cmp-text__cta--secondarylink b {
  font-weight: 600;
}

.text.primarylink-right .cmp-text .cmp-text__cta--primarylink {
  padding-left: 0;
  padding-right: 70px;
  width: 100%;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
}

.text.primarylink-right .cmp-text .cmp-text__cta--primarylink:before {
  position: relative;
  float: right;
  top: 4px;
}

.text .cmp-text hr {
  height: 1px;
  background-color: #EDEDED;
  border: 0;
}

.nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 70px;
  background-color: #34384b;
}

.footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  background: #282b3e;
  color: #ffffff;
}

.footer:before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: #34384b;
  opacity: 0;
  transition: opacity 500ms ease;
  z-index: 0;
  pointer-events: none;
}

.footer.search-expanded:before {
  opacity: 1;
  transition: opacity 500ms ease;
}

.footer ul > li {
  display: inline-block;
}

.footer ul > li > a {
  display: block;
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer ul > li {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
  }
  .footer ul > li > a {
    width: auto;
  }
}

.footer .styled-link {
  margin-top: 0;
}

.footer .item-link::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .item-link a {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
    padding: 20px 20px;
  }
}

.footer .external-links {
  flex: 1 0;
  padding-left: 20px;
}

.footer .external-links > li {
  margin: 0 20px 30px 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .external-links > li {
    margin-right: 0;
  }
  .footer .external-links > li:last-child {
    margin-bottom: 0px;
  }
}

@media (max-width: 1023.98px) {
  .footer .external-links {
    flex: 1 0 auto;
    order: 1;
    margin-top: 20px;
    padding: 0 20px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .external-links {
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
}

@media (min-width: 1920px) {
  .footer .external-links {
    flex: 1 1 auto;
    padding-left: 60px;
  }
}

.footer .language-select {
  display: flex;
  flex: 0 0 auto;
  align-items: flex-start;
  margin-right: 100px;
  margin-left: auto;
  opacity: 1;
  transition: opacity 500ms ease;
  cursor: pointer;
}

.footer .language-select > p {
  line-height: 20px;
  white-space: nowrap;
}

.footer .language-select > svg {
  flex: 1 0 auto;
  position: static;
  transform: rotate(90deg);
  transition: transform 400ms ease;
}

@media (min-width: 1920px) {
  .footer .language-select {
    flex: 0 0 auto;
    margin-right: 130px;
  }
}

@media (max-width: 1023.98px) {
  .footer .language-select {
    margin-left: 0;
    padding-left: 20px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .language-select {
    margin-left: env(safe-area-inset-left);
  }
}

.footer .language-select.active > svg {
  transform: rotate(270deg);
}

.footer .language-select.hidden {
  opacity: 0;
}

.footer .search {
  position: absolute;
  right: 0;
  display: flex;
  padding-right: 20px;
  cursor: pointer;
  z-index: 10;
}

.footer .search > form {
  width: 100%;
}

.footer .search > form > label {
  display: flex;
  width: calc(100% - 30px);
  margin-left: 30px;
  cursor: pointer;
}

.footer .search > form > label > svg {
  visibility: visible;
  max-width: 20px;
  min-width: 20px;
}

.footer .search span {
  flex: 1 0 auto;
  font-size: 14px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 20px;
  color: #ffffff;
  opacity: 0.41;
  transform: translate3d(-10px, 0, 0);
  transition: transform 500ms ease;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .search {
    margin-right: env(safe-area-inset-right);
  }
}

@media (min-width: 1920px) {
  .footer .search {
    flex: 0 0 auto;
    padding-right: 60px;
  }
}

.footer .search > form svg.js-search-submit {
  visibility: hidden;
  position: absolute;
  left: 0px;
}

.footer .search.active {
  width: calc(100% - 20px);
}

.footer .search.active > form svg.js-search-expand {
  visibility: hidden;
}

.footer .search.active > form svg.js-search-submit {
  visibility: visible;
}

.footer .search.active .button-reset {
  display: block;
}

.footer .search.active .input-search {
  width: calc(100% - 20px);
  pointer-events: auto;
  opacity: 1;
  transition: opacity 500ms ease;
}

@media (min-width: 1920px) {
  .footer .search.active {
    width: calc(100% - 60px);
  }
}

.footer .input-search {
  width: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #ffffff;
  letter-spacing: 1px;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0ms ease;
}

.footer .input-search[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.footer .input-search[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.footer .input-search[type='search']::-webkit-search-decoration, .footer .input-search[type='search']::-webkit-search-cancel-button, .footer .input-search[type='search']::-webkit-search-results-button, .footer .input-search[type='search']::-webkit-search-results-decoration {
  display: none;
}

@media (min-width: 1024px) {
  .footer .input-search {
    margin-left: 0;
  }
  .footer .input-search::placeholder {
    color: transparent;
  }
}

.footer .button-reset {
  position: absolute;
  right: 20px;
  display: none;
  width: 20px;
  height: 20px;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  z-index: 12;
}

@media (min-width: 1920px) {
  .footer .button-reset {
    right: 60px;
  }
}

.search.active .footer .button-reset {
  display: block;
}

.footer .wrapper-location-links {
  flex: 1 0 100%;
  background-color: #34384b;
  border-top: 1px solid #282b3e;
  border-left: 1px solid #282b3e;
  max-height: 0;
  transition: max-height 0.25s ease;
  overflow: hidden;
}

.footer .wrapper-location-links.active {
  max-height: 100%;
  transition: max-height 0.25s ease;
}

.footer .wrapper-location-links.active .location-links {
  transform: translate3d(0, 0, 0);
  transition: transform 0.25s ease;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .wrapper-location-links.active .location-links li {
    background-color: #34384b;
  }
}

@media (max-width: 1023.98px) {
  .footer .wrapper-location-links.active {
    margin-top: 30px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .wrapper-location-links {
    border-top: 0;
  }
}

.footer .location-links {
  padding: 30px 30px;
  transform: translate3d(0, -70px, 0);
  transition: transform 0.25s ease;
}

@media (min-width: 1920px) {
  .footer .location-links {
    padding: 30px 60px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .location-links {
    padding: 0;
    transform: translate3d(0, 0, 0);
  }
}

.footer .location-links li {
  margin-right: 20px;
}

.footer .location-links li:last-child {
  margin-right: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .location-links li::before {
    border-bottom: 1px solid #282b3e;
  }
}

.footer .info-links {
  margin: 20px 0 20px;
  padding: 0 20px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .info-links {
    flex: 1 0 100%;
  }
}

@media (min-width: 1920px) {
  .footer .info-links {
    padding: 0 60px;
  }
}

@media (max-width: 1023.98px) {
  .footer .info-links {
    order: 1;
  }
}

.footer .info-links li {
  margin-bottom: 30px;
  margin-right: 20px;
}

.footer .info-links > li > button {
  top: 0;
  bottom: 0;
  margin: auto 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .info-links {
    margin-top: 0px;
    margin-bottom: 0;
    padding: 0;
  }
  .footer .info-links li {
    margin: 0;
  }
  .footer .info-links li:last-child {
    transition: background 250ms;
    transition-delay: 250ms;
  }
  .footer .info-links li::before {
    border-top: 1px solid #34384b;
  }
  .footer .info-links > li > button {
    margin-right: env(safe-area-inset-right);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .info-links.active li:last-child {
    background-color: #34384b;
    transition: background 100ms;
    transition-delay: 0;
  }
}

.footer .important-info::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.footer .important-info > button {
  right: 30px;
}

.footer .important-info p {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .important-info {
    padding: 20px 20px;
  }
  .footer .important-info > a {
    display: none;
  }
  .footer .important-info p {
    display: block;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
}

.footer .info-links-mobile {
  flex: 1 0 100%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .info-links-mobile {
    margin-bottom: 0;
    order: 1;
  }
  .footer .info-links-mobile li {
    background-color: #34384b;
  }
  .footer .info-links-mobile li::before {
    border-top: 1px solid #282b3e;
  }
}

.footer .wrapper-info-links-mobile {
  flex: 1 0 100%;
  order: 1;
  max-height: 0;
  transition: max-height 0.25s ease;
  overflow: hidden;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .wrapper-info-links-mobile.active {
    max-height: 300px;
    transition: max-height 0.25s ease;
  }
}

.footer .copyright {
  position: relative;
  width: 100%;
  padding: 30px 20px 0;
  font-size: 12px;
  opacity: 0.8;
}

.footer .copyright::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #34384b;
}

@media (min-width: 1920px) {
  .footer .copyright {
    padding: 30px 60px 0;
  }
}

@media (max-width: 1279.98px) {
  .footer .copyright {
    order: 1;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .footer .copyright {
    margin-top: 0;
    margin-left: env(safe-area-inset-left);
    margin-right: env(safe-area-inset-right);
  }
  .footer .copyright::before {
    right: calc(env(safe-area-inset-right) * -1);
    left: calc(env(safe-area-inset-left) * -1);
  }
}

.styled-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-right: 0;
}

.styled-page.has-side-image {
  margin-right: 15vw;
}

@media (max-width: 1023.98px) {
  .styled-page.has-side-image {
    margin-right: 0;
  }
}

@media (max-width: 1279.98px) {
  .styled-page {
    margin-left: 0;
  }
}

@media (max-width: 1023.98px) {
  .styled-page {
    margin-right: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.content h1 {
  margin-top: 80px;
  font-size: 40px;
  line-height: 1.25;
}

@media (min-width: 1920px) {
  .content h1 {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media (max-width: 1919.98px) {
  .content h1 {
    margin-top: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .content h1 {
    margin-top: 30px;
    font-size: 30px;
    line-height: 38px;
  }
}

.wrapper-top {
  position: relative;
}

.container-wrapper {
  display: flex;
  min-height: 100vh;
}

.gallery {
  position: relative;
  min-height: 604px;
  color: #ffffff;
  background-color: #5e6073;
  overflow: hidden;
}

@media (min-width: 1280px) {
  .gallery {
    margin-right: 300px;
  }
}

@media (min-width: 1920px) {
  .gallery {
    margin-right: 400px;
  }
}

.gallery-header {
  margin-top: 100px;
  font-size: 50px;
  line-height: 50px;
  white-space: pre-line;
}

@media (min-width: 1920px) {
  .gallery-header {
    margin-top: 200px;
    font-size: 72px;
    line-height: 75px;
  }
}

@media (min-width: 1280px) {
  .gallery-header {
    font-size: 60px;
    line-height: 65px;
    letter-spacing: -1.5px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .gallery-header {
    font-size: 34px;
    letter-spacing: -0.7px;
  }
}

.gallery-text {
  margin-top: 30px;
  margin-bottom: 100px;
  font-size: 15px;
  line-height: 26px;
  white-space: pre-line;
}

@media (min-width: 1920px) {
  .gallery-text {
    margin-top: 60px;
    font-size: 22px;
    line-height: 34px;
  }
}

@media (max-width: 1023.98px) {
  .gallery-text {
    margin-bottom: 50px;
  }
}

.gallery-button {
  margin-bottom: 130px;
}

@media (min-width: 1920px) {
  .gallery-button {
    margin-bottom: 290px;
  }
}

@media (max-width: 1023.98px) {
  .gallery-button {
    margin-bottom: 100px;
  }
}

.slider {
  display: flex;
  background-color: #424559;
}

.slider-control {
  position: absolute;
  bottom: 40px;
  display: flex;
  align-items: baseline;
  z-index: 3;
}

.slider-control > p:last-child {
  margin-right: 0;
}

.slide-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #282b3e;
  opacity: 0.2;
}

.gallery-wrapper {
  padding-top: 80px;
  opacity: 0;
  transform: translateX(0);
  transition: transform 700ms ease, opacity 700ms ease;
  transition-delay: 200ms;
}

@media (max-width: 1919.98px) {
  .gallery-wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .gallery-wrapper {
    padding-top: 30px;
    transition-delay: 0ms;
  }
}

.slide {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
}

.slide:first-child {
  transform: translateX(0);
}

.slide:first-child .slider-wrapper:first-child {
  transform: translateX(0);
  opacity: 1;
}

.slide-count {
  margin-right: 45px;
  font-family: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  transition: transform 400ms ease;
  transform-origin: bottom;
}

.progress-bar {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2;
}

.bar {
  width: 100%;
  height: 100%;
  transform-origin: left;
  transition: transform 100ms linear;
  background-color: #00a758;
}

.button, .gallery-button {
  display: inline-block;
  padding: 20px 50px 20px 30px;
  color: #424559;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1;
  text-decoration: none;
}

.button:hover, .gallery-button:hover {
  color: #fff;
}

.button:hover svg g, .gallery-button:hover svg g {
  fill: #424559;
}

.button > svg, .gallery-button > svg {
  margin-right: 10px;
  vertical-align: middle;
}

.button.button-info, .button-info.gallery-button {
  padding: 19px 30px;
  background-color: #ff7769;
  color: #fff;
}

.button.button-info:hover, .button-info.gallery-button:hover {
  background-color: #ec6453;
}

.button.button-download, .button-download.gallery-button {
  color: #fff;
  background-color: #ff5d55;
  min-width: 150px;
  white-space: normal;
  border-width: 0px;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 0px;
  padding: 20px;
}

.button.button-download:hover, .button-download.gallery-button:hover {
  background-color: #f8453c;
  border-color: #f8453c;
}

.button-expand {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .button-expand {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    outline: 0;
    appearance: button;
    user-select: none;
    cursor: pointer;
  }
}

.button-expand .minus {
  display: none;
}

.button-expand .plus {
  display: block;
}

.button-expand.active .minus {
  display: block;
}

.button-expand.active .plus {
  display: none;
}

.link {
  display: block;
  width: fit-content;
  margin-top: 30px;
  padding-left: 0;
  font-size: 12px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  line-height: 20px;
  color: #424559;
  text-decoration: none;
}

.link > svg {
  position: absolute;
  left: 0;
  min-width: 20px;
}

h1 + .link {
  margin-top: 50px;
}

p > .link {
  margin: 0;
}

.link + a {
  margin-top: 20px;
}

.link + p {
  font-size: 12px;
  line-height: 1.67;
}

.link:hover > span::before {
  opacity: 0;
}

@media (max-width: 567.98px) {
  .link.download {
    background: #ff7769;
    text-align: center;
    padding: 5px 10px;
    margin-right: 0 !important;
  }
  .link.download:hover {
    background: #ec6453;
  }
}

.link.download > span {
  background: #ff7769;
  padding: 5px 20px;
  border-bottom: none;
  color: #ffffff;
}

@media (max-width: 567.98px) {
  .link.download > span {
    background: none;
    padding: 0;
  }
}

.link.download > span:hover {
  background: #ec6453;
}

.link > span {
  position: relative;
  border-bottom: 1px solid #00c46e;
  display: inline;
  transition: border-bottom 400ms ease;
}

.link.not-underline > span {
  border-bottom: none;
}

@media (min-width: 1920px) {
  .link {
    font-size: 14px;
    line-height: 1.71;
  }
}

.link.icon {
  position: relative;
  position: relative;
  padding-left: 30px;
}

.link.inline {
  display: inline-block;
  margin-right: 20px;
}

.link.underline {
  display: block;
}

.block-text {
  margin-top: 0;
}

.block-text h2 {
  margin-top: 50px;
  margin-bottom: 20px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  line-height: 32px;
}

.block-text > p {
  margin-top: 30px;
  color: #282b3e;
}

.block-text sup {
  font-size: 12px;
  line-height: 2;
}

.block-text a {
  position: relative;
  display: inline-block;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
}

.block-text a:hover::before {
  opacity: 0;
}

.block-text a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: #00c46e;
  opacity: 1;
  transition: opacity 400ms ease;
}

.block-text.text-center-align > p {
  text-align: center;
}

.block-text.text-left-align > p {
  text-align: left;
}

.block-text.text-right-align > p {
  text-align: right;
}

.block-text.text-justify-align > p {
  text-align: justify;
}

.block-text.text-left-border {
  margin-top: 50px;
}

.block-text.text-left-border > p {
  position: relative;
}

.block-text.text-left-border > p::before {
  content: '';
  position: absolute;
  display: flex;
  align-items: center;
  left: -20px;
  width: 6px;
  height: 100%;
  background-color: #00a758;
}

.block-text.text-left-border + p {
  margin: 20px 0;
}

.block-text.bold-header h2 {
  font-family: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: bold;
}

.block-text.theme-bold > p {
  margin-top: 0;
  font-size: 50px;
  /*70px*/
  font-family: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  line-height: 1;
}

@media (min-width: 1920px) {
  .block-text.theme-bold > p.column-0 {
    font-size: 90px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .block-text.theme-bold > p {
    margin-bottom: 30px;
  }
}

.top-links-wrapper {
  display: flex;
  flex-direction: column;
}

.top-links-wrapper > a {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  min-height: 90px;
  padding: 20px 30px 20px 90px;
  margin: 0;
  color: #282b3e;
  text-decoration: none;
}

.top-links-wrapper > a + a {
  margin: 0;
}

.top-links-wrapper > a > span {
  position: absolute;
  display: inherit;
}

.top-links-wrapper > a:hover::after {
  background: #fafafa;
}

.top-links-wrapper > a::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 60px;
}

.top-links-wrapper > a.calendar::before {
  background-color: #361558;
}

.top-links-wrapper > a.notification::before {
  background-color: #00a758;
}

.top-links-wrapper > a::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
  z-index: -1;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .top-links-wrapper > a {
    width: 50%;
  }
  .top-links-wrapper > a::after {
    border: none;
  }
}

@media (min-width: 1920px) {
  .top-links-wrapper > a {
    min-height: 90px;
    padding: 20px 30px 20px 90px;
    font-size: 16px;
  }
  .top-links-wrapper > a::before {
    width: 70px;
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .top-links-wrapper {
    position: relative;
    flex-direction: row;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
  .top-links-wrapper::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid rgba(66, 69, 89, 0.1);
  }
}

.top-links-icon {
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
}

@media (min-width: 1920px) {
  .top-links-icon {
    width: 70px;
  }
}

.bottom-links-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
}

.bottom-links-wrapper > a {
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  min-height: 120px;
  width: 100%;
  margin: 0;
  display: flex;
  padding: 30px;
  color: #424559;
  text-decoration: none;
  transition: opacity 200ms ease;
}

.bottom-links-wrapper > a + a {
  margin: 0;
}

.bottom-links-wrapper > a:hover {
  opacity: 1 !important;
  background: #ffffff;
}

.bottom-links-wrapper > a:hover::before {
  background: #fafafa;
}

.bottom-links-wrapper > a::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-right: 1px solid rgba(66, 69, 89, 0.1);
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
  z-index: -1;
}

@media (min-width: 1920px) {
  .bottom-links-wrapper > a {
    padding: 30px;
  }
}

@media (min-width: 1280px) {
  .bottom-links-wrapper > a::before {
    border-right: none;
  }
}

@media (max-width: 1279.98px) {
  .bottom-links-wrapper > a {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .bottom-links-wrapper > a::before {
    border-bottom: none;
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .bottom-links-wrapper > a {
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .bottom-links-wrapper > a {
    flex: 0 0 270px;
    width: 270px;
  }
}

@media (max-width: 767.98px) {
  .bottom-links-wrapper {
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .bottom-links-wrapper {
    width: 100%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .bottom-links-wrapper {
    width: auto;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.bottom-links-icon {
  display: flex;
  flex: 0 0 auto;
  margin-left: auto;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .bottom-links-icon {
    margin-top: 5px;
  }
}

@media (max-width: 1279.98px) {
  .bottom-links-icon {
    margin-top: auto;
    margin-left: 0;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .bottom-links-icon {
    margin-left: auto;
  }
}

.bottom-links-icon > svg {
  margin-right: 25px;
}

.bottom-links-icon > p {
  font-size: 12px;
  letter-spacing: 1px;
  color: #424559;
}

@media (min-width: 1280px) {
  .bottom-links-icon > p {
    display: none;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .bottom-links-icon > p {
    display: none;
  }
}

.bottom-links-title {
  margin-bottom: 10px;
  color: #8e90a2;
  font-size: 12px;
}

@media (min-width: 1920px) {
  .bottom-links-title {
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.bottom-links-date {
  width: 100%;
  align-self: stretch;
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 0;
}

.bottom-links-date p {
  font-size: 16px !important;
}

.bottom-links-text {
  align-self: stretch;
  margin-top: 0;
  margin-right: 10px;
  margin-bottom: 0;
  color: #282b3e;
  font-size: 1.125rem;
  line-height: 1.44;
  font-weight: 300;
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .bottom-links-text {
    min-width: 100%;
    margin: 0;
    padding: 0;
  }
}

.bottom-links-text > p {
  margin: 0;
}

@media (min-width: 1920px) {
  .bottom-links-text {
    margin-bottom: 30px;
  }
}

/* overright the clientlib-base.css  */
@media (max-width: 768px) {
  .aem-Grid > .aem-GridColumn.aem-GridColumn--phone--hide.right-panel-override {
    display: block;
  }
}

.right-panel {
  position: relative;
  display: flex;
  background: #fafafa;
  z-index: 1;
}

.right-panel::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  border-left: 1px solid rgba(66, 69, 89, 0.1);
  z-index: 2;
}

.right-panel p {
  font-size: 18px;
  margin: 0;
  font-family: 'Manulife JH Sans',sans-serif;
}

@media (min-width: 1280px) {
  .right-panel {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    width: 100%;
    background: #ffffff;
  }
}

.right-content {
  position: sticky;
  top: 0;
  width: 100%;
  height: 565px;
  overflow-x: hidden;
  overflow-y: auto;
}

.right-content__wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

@media (max-width: 767.98px) {
  .right-content {
    height: auto;
    overflow: hidden;
  }
  .right-content::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid rgba(66, 69, 89, 0.1);
    z-index: 1;
  }
}

.right-scrollbar {
  position: absolute;
  top: 0;
  left: 0;
  right: -17px;
  bottom: -17px;
  overflow: scroll;
  padding-right: 17px;
}

.right-scrollbar::-webkit-scrollbar {
  width: 0;
}

.right-scrollbar:not(.view) {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .right-scrollbar {
    padding-right: 0px;
  }
}

@media (max-width: 767.98px) {
  .right-scrollbar {
    padding-right: 0;
  }
  .right-scrollbar.view {
    position: relative !important;
    margin-bottom: 0 !important;
    overflow: hidden !important;
  }
}

/* Carousel styles */
.aem-GridColumn.carousel {
  padding: 0;
  position: relative;
}

.aem-GridColumn.carousel .cmp-carousel__videolayer {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: black;
}

.aem-GridColumn.carousel.hidden {
  height: 0;
  overflow: hidden;
}

.cmp-carousel__videolayer {
  transition: opacity 0.25s;
  opacity: 0;
  pointer-events: none;
  z-index: 3;
}

.cmp-carousel__videolayer .close-button {
  height: 40px;
  width: 40px;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  background-color: black;
  cursor: pointer;
  z-index: 4;
}

.cmp-carousel__videolayer .close-button:before, .cmp-carousel__videolayer .close-button:after {
  transform: rotate(-45deg);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -1.5px;
  margin-left: -12.5px;
  display: block;
  height: 3px;
  width: 25px;
  background-color: #fff;
  transition: all 0.25s ease-out;
}

.cmp-carousel__videolayer .close-button:after {
  transform: rotate(-135deg);
}

.cmp-carousel__videolayer .close-button:hover:before, .cmp-carousel__videolayer .close-button:hover:after {
  transform: rotate(0deg);
  background-color: red;
}

.cmp-carousel__videolayer #cmp-carousel__ytplayer,
.cmp-carousel__videolayer .cmp-carousel__ytplayer {
  position: absolute;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 75px;
  width: 100%;
  height: calc(100% - 150px);
}

.cmp-carousel__videolayer.active {
  opacity: 1;
  pointer-events: auto;
}

@media (max-width: 1024px) {
  .aem-GridColumn.carousel {
    width: 100vw !important;
  }
}

@media (max-width: 767px) {
  .aem-GridColumn.carousel {
    height: 460px;
    overflow: hidden;
  }
  .cmp-carousel:not(.no-js) {
    height: 100%;
  }
  html[class*='aem-AuthorLayer'] .aem-GridColumn.carousel {
    height: 645px;
  }
}

.cmp-carousel:not(.no-js) {
  max-height: 645px;
}

.cmp-carousel .slick-dots {
  bottom: 40px;
  text-align: left;
  padding: 0 70px;
}

.cmp-carousel .slick-dots button::before {
  color: white;
  opacity: 0.5;
  font-size: 30px;
}

.cmp-carousel .slick-dots li.slick-active button::before {
  opacity: 1;
  color: white;
}

@media (min-width: 1920px) {
  .cmp-carousel .slick-dots {
    padding: 0 90px;
  }
}

@media (max-width: 1024px) {
  .cmp-carousel .slick-dots {
    padding: 0 30px;
  }
}

.cmp-carousel__slide {
  position: relative;
  display: block;
  height: 645px;
  max-height: 645px;
  padding: 120px 80px 40px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.cmp-carousel__slide .cmp-carousel__imagelayer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  overflow: hidden;
}

.cmp-carousel__slide .cmp-carousel__imagelayer img {
  position: relative;
  left: auto;
  top: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  transform: none;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media (min-width: 1920px) {
  .cmp-carousel__slide .cmp-carousel__imagelayer img.cmp-carousel__image-desktop {
    display: block;
  }
  .cmp-carousel__slide .cmp-carousel__imagelayer img.cmp-carousel__image-mobile {
    display: none;
  }
}

.cmp-carousel__slide .cmp-carousel__imagelayer::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #5e6073;
  opacity: 0.2;
  pointer-events: none;
}

.cmp-carousel__slide .cmp-carousel__contentlayer {
  position: relative;
  height: 100%;
}

.cmp-carousel__slide .cmp-carousel__slideTitle {
  max-width: 84.617%;
  width: 84.617%;
  max-height: 204px;
  display: block;
  overflow: hidden;
  word-break: break-word;
}

.cmp-carousel__slide .cmp-carousel__slideTitle p {
  font-weight: 300;
}

.cmp-carousel__slide .cmp-carousel__slideTitle b {
  font-weight: 600;
}

.cmp-carousel__slide .cmp-carousel__slideTitle,
.cmp-carousel__slide .cmp-carousel__slideSubtitle {
  width: 100%;
  max-width: 100%;
}

.cmp-carousel__slide .cmp-carousel__slideTitle p,
.cmp-carousel__slide .cmp-carousel__slideSubtitle p {
  margin: 0;
}

.cmp-carousel__slide .cmp-carousel__slideTitle {
  font-size: 3.625rem;
  line-height: 1.17;
}

.cmp-carousel__slide .cmp-carousel__slideSubtitle {
  margin-top: 20px;
  font-size: 1rem;
  line-height: 1.63;
  word-break: break-word;
}

.cmp-carousel__slide .cmp-carousel__slideCTA {
  display: inline-block;
  margin-top: 40px;
}

.cmp-carousel__slide .cmp-carousel__playVideoBtn {
  width: 80px;
  height: 80px;
  margin-top: 40px;
  cursor: pointer;
  background: transparent url("/etc.clientlibs/coremfc/clientlibs/clientlib-site/resources/carousel-video-play-initial.svg") no-repeat center/contain;
}

.cmp-carousel__slide .cmp-carousel__playVideoBtn:hover,
.cmp-carousel__slide .cmp-carousel__playVideoBtn:focus {
  background-image: url("/etc.clientlibs/coremfc/clientlibs/clientlib-site/resources/carousel-video-play-hover.svg");
}

@media (min-width: 1920px) {
  .cmp-carousel__slide {
    padding: 120px 100px 40px;
  }
}

@media (max-width: 1024px) {
  .cmp-carousel__slide {
    padding: 100px 40px 40px;
  }
  .cmp-carousel__slide .cmp-carousel__slideTitle {
    font-size: 2.25rem;
    max-height: 168px;
    display: block;
    overflow: hidden;
  }
  .cmp-carousel__slide .cmp-carousel__slideSubtitle {
    display: none;
  }
}

/* Dots */
.slick-dotted .slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.cmp-carousel--billboard.aem-GridColumn {
  padding-right: 10px;
}

.cmp-carousel--billboard .cmp-carousel:not(.no-js) {
  max-height: 400px;
  height: 400px;
}

.cmp-carousel--billboard .cmp-carousel__slide {
  height: 400px;
  max-height: 400px;
  padding: 40px 85px 40px 40px;
}

.cmp-carousel--billboard .cmp-carousel__slide .cmp-carousel__slideTitle {
  width: 98.667%;
  max-width: 98.667%;
  max-height: 116px;
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.21;
}

.cmp-carousel--billboard .cmp-carousel__slide .cmp-carousel__slideSubtitle {
  width: 98.685%;
  max-width: 98.685%;
}

.cmp-carousel--billboard .cmp-carousel__slide .cmp-carousel__slideCTA {
  margin-top: 20px;
}

.cmp-carousel--billboard .cmp-carousel__slide .cmp-carousel__slideCTA .cmp-text__cta--primarybtn {
  padding-top: 16.5px;
  padding-bottom: 16.5px;
}

.cmp-carousel--billboard .cmp-carousel__slide .cmp-carousel__playVideoBtn {
  margin-top: 20px;
}

.cmp-carousel--billboard .cmp-carousel__imagelayer {
  background-color: #5e6073;
}

.cmp-carousel--billboard .slick-dots {
  padding: 0 30px;
}

.funds-content {
  margin-top: 20px;
}

.funds-content .hidden {
  display: none;
}

.fund-title-section {
  display: flex;
  align-items: center;
}

.list-header {
  position: relative;
  padding: 30px 0 35px;
}

.list-header::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
}

.puls-icon {
  display: inline-block;
  padding-right: 10px;
  vertical-align: super;
  cursor: pointer;
}

.open-btn g {
  fill: #ff7769;
}

.open-btn path {
  fill: #ffffff;
}

.platform + .platform {
  margin-top: 20px;
}

.list-title {
  font-size: 40px;
  line-height: 1;
  display: inline-block;
}

.last-update {
  margin-top: 30px;
  font-size: 12px;
}

@media (min-width: 1920px) {
  .last-update {
    font-size: 14px;
  }
}

.funds-details {
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;
  transition: opacity 400ms ease;
  opacity: 1;
}

@media (min-width: 568px) and (max-width: 767.98px) {
  .funds-details {
    align-items: flex-end;
    margin-bottom: 0.2em;
  }
}

.funds-details span {
  color: #424559;
  font-size: 14px;
  letter-spacing: 1px;
  text-align: right;
}

.funds-details svg {
  margin-right: 10px;
}

@media (min-width: 1280px) {
  .funds-details {
    opacity: 1;
  }
  .funds-details svg {
    margin-right: 0;
  }
  .funds-details span {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .funds-details {
    width: 50%;
    margin-top: 0;
    order: -1;
  }
}

.funds-no-results {
  display: none;
}

.funds-no-results.active {
  display: block;
}

.fund-actions-column {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

@media (min-width: 1280px) {
  .fund-actions-column {
    align-items: flex-start;
  }
}

@media (max-width: 1279.98px) {
  .fund-actions-column {
    margin-top: 20px;
    flex-direction: row;
  }
}

.funds-tick-section {
  display: flex;
  align-items: stretch;
}

.funds-tick-section.dim .funds-checkbox-section {
  cursor: default;
}

.funds-tick-section.dim .funds-checkbox-section input {
  pointer-events: none;
  cursor: default;
}

.funds-tick-section.dim .funds-checkbox-section svg {
  cursor: default;
}

.funds-tick-section.dim .funds-checkbox-section svg polygon {
  fill: #8e90a2 !important;
}

.funds-tick-section.dim > span {
  color: #8e90a2;
}

@media (max-width: 1279.98px) {
  .funds-tick-section {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.funds-tick-section > span {
  font-weight: 600;
  line-height: 35px;
  padding-left: 5px;
  font-size: 14px;
  color: #424559;
}

@media (max-width: 1279.98px) {
  .funds-tick-section > span {
    display: none;
  }
}

.funds-tick {
  position: relative;
  width: 18px;
}

.js-tick-changing:checked + svg polygon {
  fill: #00a758 !important;
}

.funds-checkbox-section {
  cursor: pointer;
  position: relative;
  width: 20px;
  height: 50px;
  padding: 10px 0;
}

.funds-checkbox-section svg {
  width: 18px;
  height: 18px;
}

.funds-checkbox-section.dim {
  pointer-events: none;
  cursor: default;
}

.funds-checkbox-section.dim .js-tick-changing {
  cursor: default;
}

.funds-checkbox-section:hover input ~ .checkmark {
  border: 2px solid #00a758;
}

.funds-checkbox-section input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  top: 10px;
  left: 0px;
  margin: 0;
  width: 20px;
  height: 20px;
}

.funds-checkbox-section input:checked ~ .checkmark {
  border: 2px solid #00a758;
}

.funds-checkbox-section input:checked ~ .checkmark:after {
  display: block;
}

.funds-checkbox-section .checkmark {
  position: absolute;
  top: 10px;
  left: 0px;
  height: 18px;
  width: 18px;
  margin: 0 1px;
  border: 2px solid #8e90a2;
  opacity: 0;
}

.funds-checkbox-section .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 3.6px;
  top: 0px;
  width: 7px;
  height: 12px;
  border: solid #00a758;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox {
  display: flex;
  cursor: pointer;
  z-index: 3;
}

@media (max-width: 1279.98px) {
  .checkbox {
    margin-left: auto;
    justify-content: flex-end;
  }
}

@media (min-width: 568px) and (max-width: 767.98px) {
  .checkbox {
    align-items: flex-end;
    margin-bottom: 0.2em;
  }
}

.checkbox > svg {
  transition: opacity 400ms ease;
  opacity: 1;
  width: 18px;
  height: 18px;
}

.funds-pin {
  display: flex;
  align-items: stretch;
}

@media (max-width: 1279.98px) {
  .funds-pin {
    align-items: center;
    position: absolute;
    top: 30px;
    right: 50px;
  }
}

.funds-pin span {
  font-weight: 600;
  padding-left: 5px;
  font-size: 14px;
  color: #424559;
}

@media (max-width: 1279.98px) {
  .funds-pin span {
    padding-left: 0px;
    display: none;
  }
}

.fund-pin-button {
  display: none;
}

.fund-pin-button:checked + svg path {
  stroke: #00a758;
  fill: #00a758;
}

.more-btn {
  margin: 0 auto;
  background: #ededed;
  padding: 15px 10px;
  width: 25%;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .more-btn {
    width: calc(100% - 40px);
  }
}

.list-more-btn.accordion {
  padding: 0;
  height: 0px;
  opacity: 0;
  text-align: center;
}

.list-more-btn.expand {
  padding: 30px 0;
  height: auto;
  opacity: 1;
}

.list-item {
  position: relative;
  display: block;
  padding: 30px 0;
  transition: background 400ms ease;
  color: inherit;
  text-decoration: none;
}

.list-item.loadmore {
  display: none;
}

.list-item.accordion {
  padding: 0;
  height: 0px;
  opacity: 0;
}

.list-item.expand {
  padding: 30px 0;
  height: auto;
  opacity: 1;
}

.list-item::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  border-top: 1px solid rgba(66, 69, 89, 0.1);
}

.list-item:last-child::before {
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
}

.list-item:hover {
  background: #ffffff;
}

.list-item:hover .funds-details, .list-item:hover .checkbox > svg {
  opacity: 1;
  pointer-events: auto;
}

.list-item .checkbox > input:checked + svg {
  opacity: 1;
  pointer-events: auto;
}

.list-item .funds-details .arrow-list:hover circle {
  fill: #ff7769;
}

.list-item .funds-details .arrow-list:hover path {
  fill: #fff;
}

@media (max-width: 1279.98px) {
  .list-item .funds-details, .list-item .checkbox > svg {
    opacity: 1;
    pointer-events: auto;
  }
}

.funds-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.fund-name {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.fund-column-header {
  width: 100%;
  margin: 0;
  margin-bottom: 9px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #282b3e;
}

.fund-column-header.right {
  text-align: right;
  padding-right: 25%;
}

@media (max-width: 1279.98px) {
  .fund-column-header.right {
    padding-right: 10%;
  }
}

@media (max-width: 567.98px) {
  .fund-column-header.right {
    text-align: left;
  }
}

.fund-column-header .js-funds-last-update {
  display: inline-block;
  line-height: 18px;
}

.fundbase .fund-value {
  margin: 0;
}

.fund-inline {
  display: inline-block;
}

.fund-inline.margin-right {
  min-width: 60%;
}

@media (max-width: 1279.98px) {
  .fund-inline.margin-right {
    margin-right: 5%;
  }
}

.fund-inline.right {
  text-align: right;
}

@media (max-width: 567.98px) {
  .fund-inline-100 {
    display: inline-block;
    min-width: 60%;
  }
}

.fund-value {
  font-size: 34px;
  line-height: 36px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
}

@media (max-width: 567.98px) {
  .fund-value {
    font-size: 30px;
  }
}

.fund-value.right {
  text-align: right;
  margin-right: 25%;
}

@media (max-width: 1279.98px) {
  .fund-value.right {
    margin-right: 10%;
  }
}

@media (max-width: 567.98px) {
  .fund-value.right {
    text-align: left;
    margin-right: 0;
  }
}

.fund-value.right .currency {
  width: 50px;
  display: none;
}

.fund-value.right .currency-back {
  display: inline-block;
}

@media (max-width: 567.98px) {
  .fund-value.right .currency {
    width: 30%;
  }
}

@media (max-width: 567.98px) {
  .fund-value .value {
    display: inline-block;
    width: 65%;
  }
}

.fund-value.hidden {
  display: none;
}

.fund-value.front .currency-front {
  display: inline-block;
}

.fund-value.front .currency-back {
  display: none;
}

.fund-value.back .currency-front {
  display: none;
}

.fund-value.back .currency-back {
  display: inline-block;
}

.fund-value .currency {
  font-size: 16px;
  display: none;
}

.fund-value .currency-back {
  display: inline-block;
}

@media (max-width: 567.98px) {
  .fund-value .currency {
    width: 30%;
  }
}

.fund-text-heading {
  margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 1px;
  color: #282b3e;
  white-space: nowrap;
}

.fund-text {
  font-size: 16px;
  line-height: 14px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
  margin: 0;
}

@media (max-width: 567.98px) {
  .fund-text {
    font-size: 14px;
  }
}

.currency {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

.icon-funds-styled {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}

.change {
  position: relative;
  margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.change.change-up {
  color: #00bf66;
}

.change.change-up > svg:nth-child(2) {
  display: none;
}

.change.change-down {
  color: red;
}

.change.change-down > svg:nth-child(1) {
  display: none;
}

.fund-blocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0;
}

.fund-blocks > div:nth-child(1) {
  flex: 1 0;
}

.fund-blocks > div:nth-child(3) {
  position: relative;
}

.fund-blocks > div:nth-child(3)::before, .fund-blocks > div:nth-child(3)::after {
  content: '';
  position: absolute;
  top: 24px;
  display: block;
  display: none;
  width: 25px;
  height: 1px;
  opacity: 0.2;
  border-top: 1px dashed #979797;
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(3)::before, .fund-blocks > div:nth-child(3)::after {
    width: 18px;
  }
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(3)::before, .fund-blocks > div:nth-child(3)::after {
    display: none;
  }
}

.fund-blocks > div:nth-child(3)::before {
  left: calc(-3vw - 10% + 12px);
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(3)::before {
    left: calc(-3vw - 10% + 14px);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-blocks > div:nth-child(3)::before {
    left: calc(-12vw - 10% + 14px);
  }
}

.fund-blocks > div:nth-child(3)::after {
  display: none;
  right: calc(-3vw + 85% - 12px);
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(3)::after {
    right: calc(-3vw + 85% - 14px);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-blocks > div:nth-child(3)::after {
    right: calc(-12vw + 85% + 14px);
  }
}

.fund-blocks > div:nth-child(4) {
  position: relative;
}

.fund-blocks > div:nth-child(4)::before {
  content: '';
  position: absolute;
  top: 24px;
  display: block;
  display: none;
  width: 25px;
  height: 1px;
  opacity: 0.2;
  border-top: 1px dashed #979797;
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(4)::before {
    width: 18px;
  }
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(4)::before {
    display: none;
  }
}

.fund-blocks > div:nth-child(4)::before {
  left: calc(-4vw - 10% + 12px);
}

@media (max-width: 1279.98px) {
  .fund-blocks > div:nth-child(4)::before {
    left: calc(-3vw - 10% + 14px);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-blocks > div:nth-child(4)::before {
    left: calc(-14vw - 10% + 14px);
  }
}

.fund-blocks .change {
  font-size: 14px;
}

.border-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #00a758;
  transform: translate3d(-6px, 0, 0);
}

.border-left.pinned {
  transform: translate3d(0, 0, 0);
}

.fund-info {
  display: flex;
  flex: 2 0;
  flex-wrap: wrap;
}

.fund-info > div {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 0 auto;
  text-align: right;
  width: 15%;
}

@media (max-width: 1279.98px) {
  .fund-info > div {
    width: 20%;
  }
}

.fund-info > div::after {
  content: '';
  position: relative;
}

.fund-info > div:first-child {
  min-width: 20%;
  width: auto;
}

@media (max-width: 1279.98px) {
  .fund-info > div:first-child {
    width: 20%;
  }
}

.fund-info > div:first-child::after {
  display: none;
}

.fund-info > div:last-child {
  flex: 0 0 auto;
}

.fund-info .fund-column-header {
  white-space: nowrap;
}

@media (max-width: 1279.98px) {
  .fund-info .fund-column-header {
    width: 500% !important;
    white-space: normal;
    text-align: left;
  }
}

.fund-item-link {
  color: #282b3e;
  text-decoration: none;
}

.fund-list-btn {
  position: relative;
  z-index: 100;
}

.fund-sort {
  padding: 30px 0;
  border-bottom: 2px solid rgba(66, 69, 89, 0.1);
}

.fund-sort__item {
  margin: 5px 10px 10px 0px;
  display: inline-block;
}

.fund-sort__label, .fund-sort__action {
  display: inline-block;
  vertical-align: middle;
}

.fund-sort__label {
  margin-right: 6px;
}

.fund-sort__action {
  line-height: 0;
  font-size: 0;
  min-width: 90px;
}

.fund-sort__sort-link {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: solid 2px #00AA59;
  border-radius: 100%;
  position: relative;
  margin: 0 1px;
  box-sizing: border-box;
  cursor: pointer;
}

.fund-sort__sort-link::after, .fund-sort__sort-link::before {
  content: '';
  position: absolute;
  top: 5px;
  left: 4px;
  width: 8px;
  height: 9px;
  background-size: contain;
  background-repeat: no-repeat;
}

.fund-sort__sort-link::before {
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-filter-sort.svg");
}

.fund-sort__sort-link::after {
  opacity: 0;
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-filter-active.svg");
}

.fund-sort__sort-link--down::after, .fund-sort__sort-link--down::before {
  transform: rotate(180deg);
  top: 2px;
}

.fund-sort__sort-link.active {
  border: 2px solid transparent;
  background-color: #00AA59;
}

.fund-sort__sort-link.active::before {
  opacity: 0;
}

.fund-sort__sort-link.active::after {
  opacity: 1;
}

.list {
  margin-top: 30px;
  padding-left: 1.75em;
  list-style: none;
}

.list > li {
  position: relative;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 20px;
  padding-left: 0;
  color: #424559;
}

.list > li > p {
  display: inline-block;
}

@media (min-width: 1920px) {
  .list > li {
    font-size: 12px;
    line-height: 20px;
  }
}

.list > li + li {
  margin-top: 0;
}

.list > li > ul, .list > li > ol {
  margin: 20px 0;
  list-style: none;
  padding-left: 1.75em;
}

.list > li > ul > li::before, .list > li > ol > li::before {
  content: '';
  position: absolute;
  left: -1.75em;
  display: inline-block;
}

.list > li > ul li + li, .list > li > ol li + li {
  margin-top: 0;
}

.list > li::before {
  content: '';
  position: absolute;
  left: -1.75em;
  display: inline-block;
}

.list > p {
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 20px;
  margin-left: 0;
  color: #424559;
}

.list > li + div > p {
  margin-bottom: 30px;
}

.list > li > div,
.list > li > a {
  margin-left: -1.75em;
}

.list.footnote {
  margin-top: 50px;
}

.list.bullet-decimal, .list.bullet-rome, .list.bullet-point {
  padding-left: 1em;
}

.list.bullet-decimal > li > a, .list.bullet-rome > li > a, .list.bullet-point > li > a {
  margin-left: -1em;
}

.list.bullet-decimal {
  list-style: decimal;
}

.list.bullet-decimal > li {
  padding-left: 0.5em;
}

.list.bullet-decimal > li::before {
  display: none;
}

.list.bullet-decimal > p {
  margin-left: -1em;
}

.list.bullet-komejirushi > li::before {
  content: '※';
}

.list.bullet-point > li::before {
  content: '.';
  left: -1em;
}

.list.bullet-note > li::before {
  content: '注)';
}

.list.bullet-children-komejirushi > li > ul > li::before, .list.bullet-children-komejirushi > li > ol > li::before {
  content: '※';
}

.list.bullet-children-decimal > li > ul > li::before, .list.bullet-children-decimal > li > ol > li::before, .list.bullet-children-none > li > ul > li::before, .list.bullet-children-none > li > ol > li::before {
  display: none;
}

.list.bullet-children-point > li > ul > li::before, .list.bullet-children-point > li > ol > li::before {
  content: '.';
  left: -1em;
}

.list.bullet-children-note > li > ul > li::before, .list.bullet-children-note > li > ol > li::before {
  content: '注)';
}

.list.bullet-children-rome {
  list-style: lower-roman;
}

.list.bullet-children-rome > li > ul, .list.bullet-children-rome > li > ol {
  list-style: lower-roman;
}

.list.bullet-children-decimal > li > ul, .list.bullet-children-decimal > li > ol, .list.bullet-children-point > li > ul, .list.bullet-children-point > li > ol, .list.bullet-children-rome > li > ul, .list.bullet-children-rome > li > ol, .list.bullet-children-none > li > ul, .list.bullet-children-none > li > ol {
  padding-left: 1em;
}

.list.bullet-none {
  padding-left: 0;
}

.list.bullet-none > li > a {
  margin-left: 0;
}

.list.margin-small > li + li {
  margin-top: 20px;
}

.list.margin-medium > li + li {
  margin-top: 50px;
}

.list.type-list > li {
  font-size: 14px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  line-height: 24px;
}

@media (min-width: 1920px) {
  .list.type-list > li {
    font-size: 16px;
    line-height: 26px;
  }
}

.list.type-list > p {
  font-size: 14px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  line-height: 24px;
}

.list.no-title > li:nth-child(1)::before {
  display: none;
}

.body-bullet-disc-list ul:not(.list) {
  padding-left: 1.1em;
  list-style: disc;
}

.custom-marker {
  position: relative;
}

.custom-marker .manulife-icon {
  transform: translate3d(-50%, -100%, 0);
  cursor: pointer;
}

.custom-marker .marker-tooltip {
  position: absolute;
  top: -20px;
  background-color: rgba(35, 37, 55, 0.9);
  width: 220px;
  padding: 30px 45px 30px 30px;
  color: #fff;
  font-size: 16px;
  transform: translate3d(-50%, -93%, 0);
  opacity: 0;
  transition: transform 600ms ease-out, opacity 300ms ease-out;
  z-index: -1;
  pointer-events: none;
}

.custom-marker .marker-tooltip.opened {
  transform: translate3d(-50%, -100%, 0);
  opacity: 1;
  pointer-events: auto;
}

.custom-marker .marker-button-close {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 10px;
  height: 10px;
  background: none;
  border: 0;
  border-radius: 0;
  padding: 0;
  outline: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.custom-marker .marker-button-close svg {
  display: block;
}

.custom-marker .marker-title {
  width: 146px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 1.33;
  white-space: pre-line;
}

.custom-marker .marker-text {
  font-size: 14px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 1.43;
  white-space: pre-line;
}

.custom-marker .marker-link {
  color: #fff;
  font-family: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  text-decoration: none;
}

.side-image {
  position: fixed;
  width: 15vw;
  min-height: 100vh;
  height: 100%;
  top: 0;
  right: 0;
}

.side-image > div {
  position: sticky;
  top: 0;
  right: 0;
  height: 100vh;
  background-size: cover;
  background-color: #ededed;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

@media (max-width: 1023.98px) {
  .side-image {
    display: none;
  }
}

.filter-pin-button {
  width: 30px;
  height: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-pin-button {
    width: 40px;
    min-width: 40px;
  }
}

.filter-pin-number {
  margin-left: 5px;
  opacity: 0.5;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  color: #282b3e;
}

.filter-field {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  height: 40px;
  padding: 0 20px 0 0;
  z-index: 2;
  border: solid 2px #ededed;
  border-radius: 30px;
  background-color: #ffffff;
  transition: flex 500ms ease;
  cursor: pointer;
}

.group-active .filter-field {
  min-width: 60px;
}

.group-active .filter-field .filter-pin-text {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .group-active .filter-field .filter-pin-text {
    display: block;
  }
}

.group-active .filter-field .filter-pin-number {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-field {
    flex: 1 0;
    margin-bottom: 5px;
  }
}

.filter-pin-text {
  margin-left: 10px;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-field-pin {
    display: flex;
  }
  .filter-field-pin.collapsed {
    display: none;
  }
}

.filter-field-pin > .filter-pin-button {
  background-color: transparent;
}

.filter-field-pin > .filter-pin-button > svg path:last-child {
  stroke: #424559;
  fill: #424559;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-field-pin > .filter-pin-button {
    background-color: transparent;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-field-pin.filtered-by-pin {
    background-color: #00a758;
  }
  .filter-field-pin > .filter-pin-number {
    color: #282b3e;
    opacity: 0.5;
  }
  .filter-field-pin > .filter-pin-number .filtered-by-pin {
    color: #ffffff;
    opacity: 1;
  }
}

.filter-field-pin:hover {
  border: solid 2px #00a758;
}

.filter-field-pin.active {
  border: solid 2px #00a758;
  background: #00a758;
  color: #ffffff;
}

.filter-field-pin.active > button > svg path {
  green: #5e6073;
}

.filter-field-pin.active > button > svg path:last-child {
  stroke: #ffffff;
  fill: #ffffff;
}

.filter-field-pin.active .filter-pin-number {
  color: #ffffff;
}

.filter-group-button {
  width: 30px;
  height: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;
  min-width: 30px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-button {
    width: 40px;
    min-width: 40px;
  }
}

.filter-group-number {
  margin-left: 5px;
  opacity: 0.5;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  color: #282b3e;
  white-space: nowrap;
}

.filter-group-text {
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.filter-group-button-reset {
  position: relative;
  left: 0;
  margin-left: auto;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  white-space: nowrap;
}

.filter-group-button-reset > p {
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 1.33;
  color: #8e90a2;
}

@media (min-width: 1280px) {
  .filter-group-button-reset svg {
    display: none;
  }
  .filter-group-button-reset p {
    display: block;
  }
}

@media (max-width: 1279.98px) {
  .filter-group-button-reset p {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .filter-group-button-reset {
    display: none;
  }
}

.filter-group-field {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  height: 40px;
  padding: 0 20px 0 0;
  z-index: 2;
  border: solid 2px #ededed;
  border-radius: 30px;
  background-color: #ffffff;
  transition: flex 500ms ease;
  cursor: pointer;
  margin: 0 5px;
}

.filter-group-field:hover {
  border-color: #00a758;
}

.filter-group-field.disabled {
  border-color: #ededed;
}

.filter-group-field.active {
  flex: 2 0;
  border: solid 2px #00a758;
  height: auto;
  padding: 40px 0 30px 0;
}

.filter-group-field.active .filter-group-text {
  position: absolute;
  top: 17px;
  left: 50px;
}

.filter-group-field.active .filter-group-number {
  position: absolute;
  top: 17px;
  left: 110px;
}

.filter-group-field.active .filter-group-button-reset {
  position: absolute;
  top: 17px;
  left: auto;
  right: 15px;
  display: none;
}

.filter-group-field.active .filter-group-button {
  position: absolute;
  top: 15px;
  left: 15px;
}

.filter-group-field.active .filter-group-button > svg path {
  stroke: #282b3e;
  fill: #282b3e;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-field {
    flex: 1 0;
    margin-bottom: 5px;
  }
  .filter-group-field.collapsed {
    display: flex !important;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-category {
    display: flex;
  }
}

@media (max-width: 1279.98px) {
  .filter-group-category .filter-group-text {
    opacity: 1;
    transition-delay: 300ms;
  }
}

.filter-group-category .filter-group-button {
  background-color: transparent;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-category .filter-group-button {
    background-color: transparent;
  }
}

.filter-group-category > button > svg path {
  stroke: #424559;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-category > button > svg path {
    stroke: #424559;
  }
}

.filter-group-category.disable-reset .filter-group-button-reset {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-category.collapsed {
    display: none;
  }
}

@media (max-width: 1279.98px) {
  .filter-group-category.collapsed .filter-group-text {
    transition-delay: 0;
  }
}

@media (min-width: 1280px) {
  .filter-group-category.collapsed .filter-group-button-reset svg {
    display: block;
  }
  .filter-group-category.collapsed .filter-group-button-reset p {
    display: none;
  }
}

.filter-btn-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 50px 120px;
  width: 100%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-btn-section {
    position: relative;
    top: 0px;
    left: auto;
    right: auto;
    width: calc(100% - 25px);
    padding: 30px 30px 50px 30px;
  }
}

.filter-clear {
  cursor: pointer;
  padding: 15px 40px;
  border: 1px solid #8e90a2;
  color: #8e90a2;
  margin-right: 15px;
}

.filter-clear:hover {
  border: 1px solid #00a758;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-clear {
    position: relative;
    width: 100%;
    margin-top: 20px;
    right: auto;
    text-align: center;
    margin-right: 0;
  }
}

.filter-apply {
  cursor: pointer;
  padding: 15px 40px;
  border: 1px solid #ff7769;
  color: #ffffff;
  background: #ff7769;
}

.filter-apply:hover {
  border: 1px solid #ec6453;
  background: #ec6453;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-apply {
    position: relative;
    width: 100%;
    margin-top: 20px;
    right: auto;
    text-align: center;
  }
}

.filter-search-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  padding-left: 10px;
  min-width: 30px;
}

.filter-search-field {
  position: relative;
  display: flex;
  align-items: center;
  flex: 2 0;
  height: 40px;
  z-index: 2;
  border: solid 2px #ededed;
  border-radius: 30px;
  background-color: #ffffff;
  transition: flex 500ms ease;
  cursor: pointer;
}

.filter-search-field:hover {
  border-color: #00a758;
}

.filter-search-field > label {
  width: 100%;
  padding: 20px 20px 20px 0;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-search-field {
    flex: 1 0;
  }
}

.filter-search {
  z-index: 1;
  border-color: #ededed;
  background-color: #ffffff;
  transition: flex 500ms ease;
  cursor: pointer;
  flex: 0 1 auto;
}

.group-active .filter-search {
  min-width: 60px;
  z-index: 1;
  width: 60px;
}

.filter-search > label {
  display: flex;
  align-items: center;
  height: 100%;
}

.filter-search > svg {
  min-width: 20px;
  cursor: pointer;
}

@media (max-width: 1023.98px) {
  .filter-search {
    flex: 0 0 60px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-search {
    transition: flex 0ms;
    flex: 1 0;
  }
}

.filter-search.disabled {
  border-color: #ededed;
}

.filter-search.expanded {
  border-color: #00a758;
  flex: 4 0;
}

.filter-search.expanded .filter-search-input {
  width: 100%;
}

@media (min-width: 1920px) {
  .filter-search.expanded {
    flex: 4 0;
  }
}

@media (max-width: 1023.98px) {
  .filter-search.expanded {
    flex: 4 0;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-search.expanded {
    flex: 4 0;
  }
}

.filter-search-input {
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  margin-left: 10px;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
}

.group-active .filter-search-input {
  width: 100%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-search-input {
    width: 100%;
  }
}

.filter-search-input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.filter-search-input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.filter-search-input[type='search']::-webkit-search-decoration, .filter-search-input[type='search']::-webkit-search-cancel-button, .filter-search-input[type='search']::-webkit-search-results-button, .filter-search-input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-search-input {
    width: 100%;
  }
}

.filter-search-button-reset {
  position: relative;
  left: -15px;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  opacity: 0;
}

.filter-search-button-reset.active {
  opacity: 1;
}

.js-main {
  z-index: 1003;
}

.filter-group-panel-wrapper {
  width: 100%;
  overflow: hidden;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel-wrapper {
    overflow: visible;
  }
}

.filter-group-panel {
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 1005;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  padding: 0 0 50px 0;
  background-color: #ffffff;
  transform: translate3d(0, -100%, 0);
  transition: transform 500ms ease;
  opacity: 0;
  display: none;
  align-content: flex-start;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel {
    position: fixed;
    width: calc(100% - 40px);
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    padding: 30px 20px 100px 20px;
    background-color: #fafafa;
    border: 0;
    transition: none;
    overflow-y: auto;
    z-index: 12;
  }
}

.filter-group-panel.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  display: flex;
  position: fixed;
  overflow-y: auto;
}

.filter-group-panel .filter-group-text {
  font-size: 40px;
}

.filter-group-panel .filter-group-number {
  font-size: 40px;
}

.filter-group-panel-content {
  background-color: #fafafa;
  padding: 0 120px;
  width: 100%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel-content {
    padding: 0;
  }
}

.panel-close-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.panel-close-btn svg {
  width: 15px;
  height: 15px;
}

.filter-result-section {
  width: 100%;
  display: flex;
  padding: 30px 0 0 0;
}

.filter-group-item {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);
}

.filter-group-item > div {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-item {
    width: 100%;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }
}

.filter-group-item > p {
  width: 100%;
}

.filter-group-panel-label {
  margin: 10px 10px 0 0;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #424559;
  line-height: 1;
  cursor: pointer;
}

.filter-group-panel-label > label {
  cursor: pointer;
}

.filter-group-panel-label > label > input {
  display: none;
}

.filter-group-panel-button {
  padding: 14px 30px;
  border-radius: 21px;
  border: solid 2px #d8d8d8;
  font-size: 12px;
  background-color: #fff;
}

@media (min-width: 1920px) {
  .filter-group-panel-button {
    padding: 10px 30px;
  }
}

.filter-group-panel-button > p {
  line-height: 1;
  margin: 0;
  font-size: 16px;
}

input:checked + .filter-group-panel-button {
  color: #ffffff;
  background-color: #00a758;
  border: solid 2px #00a758;
}

@media (max-width: 1919.98px) {
  .filter-group-panel-button {
    padding: 9px 20px;
  }
}

.filter-group-panel-button:hover {
  border: solid 2px #00a758;
}

.filter-group-panel-distributor {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin-top: 30px;
}

.filter-group-panel-distributor > p {
  width: 100%;
  line-height: 1;
}

.filter-group-panel-distributor > button {
  margin: 10px 20px 0 0;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #424559;
  line-height: 1;
  cursor: pointer;
  outline: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel-distributor {
    width: 100%;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    z-index: 12;
  }
}

.filter-group-panel-button-popup {
  position: relative;
  flex: 0 0 auto;
  margin-right: 20px;
  margin-top: 4px;
  padding: 0 0 5px;
  font-size: 14px;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  overflow-x: hidden;
}

.filter-group-panel-button-popup.hidden {
  display: none;
}

.filter-group-panel-button-popup:hover::before {
  transform: translate3d(100%, 0, 0);
}

.filter-group-panel-button-popup::before {
  content: '';
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 2px;
  border-bottom: 2px dashed #00a758;
  transition: transform 500ms ease;
  transform: translate3d(0, 0, 0);
}

.filter-group-panel-button-back {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel-button-back {
    position: fixed;
    top: 60px;
    left: 0;
    display: block;
    width: 100%;
    max-height: 70px;
    margin: 0;
    padding: 0 0 0 20px;
    background: #ffffff;
    border: 0;
    border-radius: 0;
    outline: 0;
    color: #333;
    font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
    font-size: 14px;
    line-height: 70px;
    text-align: left;
    cursor: pointer;
    z-index: 14;
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transform: translate3d(0, -100%, 0);
  }
  .filter-group-panel-button-back.active {
    transform: translate3d(0, 0, 0);
  }
  .filter-group-panel-button-back::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid rgba(26, 27, 35, 0.1);
    z-index: 1;
  }
  .filter-group-panel-button-back > span {
    display: inline-block;
    margin-left: 10px;
  }
  .filter-group-panel-button-back > p {
    display: inline-block;
    margin-left: 9px;
    opacity: 0.5;
    font-size: 12px;
    font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    color: #282b3e;
  }
  .filter-group-panel-button-back > span,
  .filter-group-panel-button-back > svg {
    vertical-align: middle;
  }
  .filter-group-panel-button-back > svg {
    margin-left: env(safe-area-inset-left);
  }
}

.filter-group-panel-button-reset {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-group-panel-button-reset {
    position: fixed;
    top: 60px;
    right: 0;
    width: 35%;
    max-height: 70px;
    margin: 0;
    padding: 0 20px 0 0;
    background: #ffffff;
    border: 0;
    border-radius: 0;
    outline: 0;
    color: #333;
    font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
    font-size: 14px;
    line-height: 70px;
    text-align: left;
    cursor: pointer;
    z-index: 14;
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transform: translate3d(0, -100%, 0);
    display: none;
  }
  .filter-group-panel-button-reset.active {
    transform: translate3d(0, 0, 0);
  }
  .filter-group-panel-button-reset::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid rgba(26, 27, 35, 0.1);
    z-index: 1;
  }
  .filter-group-panel-button-reset > span {
    display: inline-block;
    margin-left: 10px;
  }
  .filter-group-panel-button-reset > p {
    display: inline-block;
    margin-left: 9px;
    opacity: 0.5;
    font-size: 12px;
    font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    color: #282b3e;
  }
  .filter-group-panel-button-reset > span,
  .filter-group-panel-button-reset > svg {
    vertical-align: middle;
  }
}

.filter-distributor-button-popup {
  position: relative;
  flex: 0 0 auto;
  margin-right: 20px;
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  border-bottom: 1px dashed #00a758;
  background: none;
}

.filter-distributor-button-popup.active .filter-distributor-button-popup-line {
  opacity: 1;
}

.filter-distributor-button-popup-line {
  width: 100%;
  height: 4px;
  background-color: #00a758;
  margin-top: 4px;
  opacity: 0;
}

.distributor-list {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 80px);
  height: 100vh;
  padding-top: 80px;
  background-color: #ffffff;
  z-index: 3;
  transform: translate3d(0, -100vh, 0);
  transition: transform 750ms ease;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-scrolling: touch;
  transform: translate3d(0, -100vh, 0);
  margin-left: 80px;
  opacity: 0;
}

@media (max-width: 1919.98px) {
  .distributor-list {
    padding-top: 70px;
  }
}

.distributor-list .filter-distributor-button-popup {
  border-bottom: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .distributor-list {
    padding-top: 0px;
    z-index: 13;
    margin-left: 0;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .distributor-list {
    margin-left: 0;
    width: 100%;
  }
}

.distributor-list.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.filter-distributor-group {
  width: 50%;
  margin-top: 30px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-distributor-group {
    width: 100%;
  }
}

.filter-distributor-group button {
  margin: 10px 30px 0 0;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #424559;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  outline: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-distributor-group button {
    margin: 10px 30px 0 0;
  }
}

.filter-distributor-group button > span {
  margin-left: 5px;
  opacity: 0.4;
}

.filter-distributor-search {
  position: relative;
  width: 100%;
  height: 80px;
  margin: 0;
  outline: 0;
  border: 0;
}

.filter-distributor-search > label {
  display: flex;
  align-items: center;
  height: 100%;
}

.filter-distributor-search > svg {
  min-width: 20px;
  cursor: pointer;
}

.filter-distributor-search-input {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
}

.filter-distributor-search-input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.filter-distributor-search-input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.filter-distributor-search-input[type='search']::-webkit-search-decoration, .filter-distributor-search-input[type='search']::-webkit-search-cancel-button, .filter-distributor-search-input[type='search']::-webkit-search-results-button, .filter-distributor-search-input[type='search']::-webkit-search-results-decoration {
  display: none;
}

@media (max-width: 1023.98px) {
  .filter-distributor-search-input {
    margin-left: 0;
  }
  .filter-distributor-search-input::-webkit-input-placeholder {
    color: transparent;
  }
  .filter-distributor-search-input:-moz-placeholder {
    color: transparent;
  }
  .filter-distributor-search-input::-moz-placeholder {
    color: transparent;
  }
  .filter-distributor-search-input:-ms-input-placeholder {
    color: transparent;
  }
}

.filter-distributor-button-clear {
  position: relative;
  left: -20px;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  display: none;
}

.filter-distributor-button-clear.active {
  display: block;
}

.filter-distributor-button-close {
  background: none;
  border: 0;
  outline: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-distributor-button-close {
    margin-top: 60px;
  }
}

.filter-distributor-button-close > svg,
.filter-distributor-button-close > p {
  display: inline-block;
}

.filter-distributor-button-close > p {
  margin-left: 15px;
}

.filter-distributor-heading-popup {
  font-size: 40px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 1.25;
  margin-top: 30px;
}

@media (min-width: 1920px) {
  .filter-distributor-heading-popup {
    font-size: 50px;
    line-height: 1.2;
  }
}

.filter-distributor-line {
  position: absolute;
  top: 0;
  left: -30px;
  right: -30px;
  height: 100%;
  border-top: 1px solid #eee;
  z-index: -1;
  transition: background 400ms ease;
}

.filter-distributor-line.distributor-line-bottom {
  border-bottom: 1px solid #eee;
}

@media (min-width: 1920px) {
  .filter-distributor-line {
    left: -60px;
    right: -60px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-distributor-line {
    left: -20px;
    left: calc(-20px - env(safe-area-inset-left));
    right: -20px;
    right: calc(-20px - env(safe-area-inset-left));
  }
}

.filter-distributor-list-popup {
  position: relative;
}

.filter-distributor-list-popup > li {
  position: relative;
  padding: 30px 30px 30px 0;
  cursor: pointer;
}

.filter-distributor-list-popup > li.hidden {
  display: none;
}

.filter-distributor-list-popup > li:hover .filter-distributor-line {
  background: #ffffff;
}

.filter-icon-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.select-field {
  width: 100%;
  padding: 20px 40px 15px 15px;
  cursor: pointer;
  border: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-items-doc {
  width: calc(100% + 6px);
  background-color: #fafafa;
  position: absolute;
  top: 60px;
  left: -3px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: none;
  overflow: auto;
  max-height: 30vh;
}

.select-items-doc div {
  border-bottom: 1px solid #ededed;
  padding: 20px 15px;
  display: block;
}

.select-items-doc div.active-ele {
  background: #ededed;
  border-left: 5px solid #00a758;
  padding-left: 10px;
}

.select-items-doc div:hover {
  background: #ededed;
  border-left: 5px solid #00a758;
  padding-left: 10px;
}

.filter-arrow-span {
  position: absolute;
  right: 20px;
  top: 17px;
  transform: rotate(90deg);
}

.filter-select {
  z-index: 2;
  transition: flex 500ms ease;
  cursor: pointer;
  flex: 1 0;
  width: 50%;
}

.filter-select > svg {
  min-width: 20px;
  cursor: pointer;
}

.filter-select-checkbox .select-items-doc {
  /*
        input[type="checkbox"] {
            display:none;
        }
        
        input[type="checkbox"] + label span{
            display:inline-block;
            width:15px;
            height:15px;
            background:url(/images/tickbox.png) no-repeat;
            background-size: contain;
            margin-right: 5px;
            cursor:pointer;
        }
        input[type="checkbox"]:checked + label span {
            background:url(/images/tickbox_tick.png) no-repeat;
            background-size: contain;
        }
        */
  /* Show the checkmark when checked */
  /* Style the checkmark/indicator */
}

.filter-select-checkbox .select-items-doc div {
  padding: 20px 15px;
  padding-left: 35px;
  display: block;
}

.filter-select-checkbox .select-items-doc div.active-ele {
  background: #ededed;
  border-left: 0;
  padding-left: 35px;
}

.filter-select-checkbox .select-items-doc div:hover {
  background: #ededed;
  border-left: 0;
  padding-left: 35px;
}

.filter-select-checkbox .select-items-doc .check-container {
  position: relative;
}

.filter-select-checkbox .select-items-doc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  top: 22px;
  left: 10px;
  margin: 0;
  width: 20px;
  height: 20px;
}

.filter-select-checkbox .select-items-doc .checkmark {
  position: absolute;
  top: 22px;
  left: 10px;
  height: 18px;
  width: 18px;
  border: 2px solid #8e90a2;
}

.filter-select-checkbox .select-items-doc .check-container:hover input ~ .checkmark {
  border: 2px solid #00a758;
}

.filter-select-checkbox .select-items-doc .check-container input:checked ~ .checkmark {
  border: 2px solid #00a758;
}

.filter-select-checkbox .select-items-doc .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.filter-select-checkbox .select-items-doc .check-container input:checked ~ .checkmark:after {
  display: block;
}

.filter-select-checkbox .select-items-doc .check-container .checkmark:after {
  left: 4.5px;
  top: -0.5px;
  width: 8px;
  height: 14px;
  border: solid #00a758;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.tile {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: calc(100% / 3 - 20px);
  max-width: calc(50% - 20px);
  margin: 10px;
  padding: 30px;
  background-color: #fff;
  color: #282b3e;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

@media (min-width: 1920px) {
  .tile {
    padding: 50px;
    max-width: calc(50% - 20px);
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .tile {
    flex: 1;
    min-width: calc(50% - 20px);
    max-width: 50%;
  }
}

@media (max-width: 767.98px) {
  .tile {
    flex: 1 0 auto;
    width: calc(100% - 20px);
    height: calc((100vw - 20px * 2));
    max-width: none;
    padding: 20px;
    color: #282b3e;
  }
}

.tile.type-simple {
  border: solid 1px #ededed;
}

.tile.large-tile {
  flex: 10;
  min-width: calc(50% - 20px);
  max-width: calc(100% / 3 * 2 - 20px);
}

@media (min-width: 1920px) {
  .tile.large-tile {
    max-width: calc(100% / 3 * 2 - 20px);
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .tile.large-tile {
    min-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .tile.large-tile {
    max-width: none;
    flex: 1 0 auto;
  }
}

.tile.only-video {
  padding: 20px;
}

@media (min-width: 1920px) {
  .tile.only-video {
    padding: 30px;
  }
}

@media (max-width: 767.98px) {
  .tile.only-video {
    height: calc((100vw - 20px * 2) / 1.7777777);
  }
}

.tile.type-image-1, .tile.type-image-2 {
  padding-right: calc((100% / 3 - 20px) / 3);
}

@media (min-width: 1920px) {
  .tile.type-image-1, .tile.type-image-2 {
    padding-right: calc((100% / 3 - 20px) / 3);
  }
}

@media (min-width: 768px) and (max-width: 1023.98px) {
  .tile.type-image-1, .tile.type-image-2 {
    padding-right: calc((100% / 2 - 20px) / 3);
  }
}

@media (max-width: 767.98px) {
  .tile.type-image-1, .tile.type-image-2 {
    padding-right: calc((100% - 20px) / 3);
  }
}

.tile.type-video {
  color: #ffffff;
}

.tile.type-image-1 .tile-title, .tile.type-image-2 .tile-title {
  max-width: none;
  margin-top: auto;
  font-size: 20px;
  line-height: 28px;
}

@media (min-width: 1920px) {
  .tile.type-image-1 .tile-title, .tile.type-image-2 .tile-title {
    max-width: none;
    font-size: 24px;
    line-height: 30px;
  }
}

@media (max-width: 1279.98px) {
  .tile.type-image-1 .tile-title, .tile.type-image-2 .tile-title {
    max-width: none;
    font-size: 20px;
    line-height: 28px;
  }
}

.tile.type-image-1, .tile.type-color {
  color: #ffffff;
}

@media (max-width: 767.98px) {
  .tile.type-image-1, .tile.type-image-2, .tile.type-color, .tile.type-video {
    color: #ffffff;
  }
}

.tile.type-color-blue {
  background-color: #0000c1;
}

.tile.type-color-violet {
  background-color: #361558;
}

.tile.type-color-green {
  background-color: #00a758;
}

.tile .hidden {
  display: none;
}

.tile.hidden {
  display: none;
}

.tile-date {
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 1;
  z-index: 1;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .tile-date {
    margin-bottom: 5px;
  }
}

@media (min-width: 1920px) {
  .tile-date {
    max-width: 450px;
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.tile-icon {
  width: 40px;
  height: 40px;
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/html-page-black.svg");
  z-index: 10;
  position: absolute;
  right: 30px;
  top: 27px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .tile-icon {
    right: 20px;
  }
}

.tile-icon.html-page {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/html-page-black.svg");
}

.tile-icon.html-page.black {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/html-page-black.svg");
}

.tile-icon.html-page.white {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/html-page-white.svg");
}

.tile-icon.pdf-file {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/pdf-file-black.svg");
}

.tile-icon.pdf-file.black {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/pdf-file-black.svg");
}

.tile-icon.pdf-file.white {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/pdf-file-white.svg");
}

.tile-icon.video-file {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/video-black.svg");
}

.tile-icon.video-file.black {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/video-black.svg");
}

.tile-icon.video-file.white {
  background: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/insights/icon/video-white.svg");
}

.tile-title {
  position: relative;
  max-width: 100%;
  font-size: 32px;
  line-height: 48px;
  overflow: hidden;
  z-index: 1;
}

@media (min-width: 1920px) {
  .tile-title {
    font-size: 40px;
    line-height: 68px;
  }
}

@media (max-width: 1279.98px) {
  .tile-title {
    font-size: 24px;
    line-height: 38px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .has-color-image .tile-title {
    margin-top: auto;
    order: 1;
  }
}

.tile-sub-title {
  max-width: 100%;
  margin-top: auto;
  font-size: 20px;
  line-height: 28px;
  z-index: 1;
  margin: 0;
}

@media (min-width: 1920px) {
  .tile-sub-title {
    font-size: 24px;
    line-height: 30px;
  }
}

.tile-icon-video {
  margin-top: auto;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
}

.tile-icon-video--large {
  width: 80px;
  height: 80px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .tile-icon-video--large {
    width: 60px;
    height: 60px;
  }
}

.tile-image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1920px) {
  .tile-image {
    bottom: 0;
  }
}

@media (max-width: 1023.98px) {
  .tile-image {
    bottom: 0;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .tile-image {
    left: 0;
    bottom: 0;
    background-position: 50% 50%;
  }
}

.type-image-2 .tile-image {
  left: 75%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .type-image-2 .tile-image {
    left: 0;
  }
}

.has-color-image .tile-image {
  top: auto;
  bottom: -32%;
  left: auto;
  width: 50%;
  height: 114%;
  background-size: contain;
}

@media (min-width: 1920px) {
  .has-color-image .tile-image {
    bottom: -34%;
  }
}

@media (max-width: 1023.98px) {
  .has-color-image .tile-image {
    bottom: -28%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .has-color-image .tile-image {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-position: -17% 50%;
  }
}

/* varbiles */
/*  default style */
.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser:hover .cmp-icon-teaser__wrapper {
  background-color: #ffffff;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__wrapper {
  height: 162px;
  background-color: #fafafa;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: color 0 ease-in;
  transition: none;
  border: solid 1px #ededed;
}

@media (max-width: 1025px) and (min-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__wrapper {
    height: 220px;
  }
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__content {
  margin: 30px 40px 25px 40px;
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title-icon {
  width: 50px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: inline-block;
  position: relative;
}

@media (max-width: 1025px) and (min-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title-icon {
    display: block;
    width: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title-icon > span {
  display: none !important;
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title {
  margin: 0;
  display: flex;
  align-items: center;
  height: 23px;
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: top left;
  padding-left: 30px;
  margin-left: 75px;
}

@media (max-width: 1025px) and (min-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title {
    margin-left: 0;
  }
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title > .cmp-content-teaser__title-link {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
}

.icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__description {
  margin: 0 0 15px 0;
  height: auto;
  max-height: 55px;
  width: calc(100% - 75px);
  display: inline-block;
  position: relative;
  vertical-align: top;
}

@media (max-width: 1025px) and (min-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__description {
    width: 100%;
  }
}

@media (max-width: 1025px) and (min-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser {
    border-top: none;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__wrapper {
    height: auto;
    border: solid 1px #ededed;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__content {
    margin: 40px 20px 40px 40px;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__content-wrapper {
    flex-direction: column;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__icon {
    margin-right: 0px;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title-icon {
    width: 50px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__description {
    width: calc(100% - 75px);
    margin-bottom: 20px;
    display: inline-block;
    position: relative;
    vertical-align: top;
  }
  .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__title {
    margin-left: 74px;
    height: unset;
    min-height: 23px;
    max-height: 55px;
  }
}

@media (max-width: 768px) {
  .icon-teaser.cmp-icon-teaser--quicklink + .icon-teaser.cmp-icon-teaser--quicklink .cmp-icon-teaser__wrapper {
    border-top: none;
  }
}

/** Icon teaser type - Simple **/
.icon-teaser.cmp-icon-teaser--simple .cmp-icon-teaser:hover .cmp-icon-teaser__wrapper {
  margin-top: 0;
  margin-bottom: 0;
  /*-webkit-box-shadow: @icon-teaser-simple-hover-box-shadow;
            -moz-box-shadow: @icon-teaser-simple-hover-box-shadow;
            box-shadow: @icon-teaser-simple-hover-box-shadow;*/
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.icon-teaser.cmp-icon-teaser--simple .cmp-icon-teaser__wrapper {
  /*-moz-transition: none;
                -webkit-transition: none;
                -o-transition: color 0 ease-in;
                transition: none;*/
  -moz-transition: all 0.25s;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.icon-teaser.cmp-icon-teaser--simple .cmp-icon-teaser__title-icon span {
  padding-right: 0px;
}

.icon-teaser.cmp-icon-teaser--simple .cmp-icon-teaser__title-icon span::before {
  background: none;
  background-size: 0px;
  width: 0px;
  height: 0px;
  display: none;
  right: 0px;
  background-color: none;
}

/* for developing only*/
/* for developing only*/
.img-figure {
  margin: 0;
}

.img-figure-medium {
  margin: 50px 0 0;
}

.img-figure-small {
  margin: 20px 0 0;
}

.img-figure img {
  margin-bottom: 0;
}

.img-figure img.image-margin {
  margin-bottom: 20px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .hide-mobile {
    display: none;
  }
}

.img-border-grey {
  border: 2px solid #ededed;
}

.img-border img {
  display: block;
}

.text-center div {
  float: none !important;
  display: inline-block !important;
}

.text-center .image {
  float: none !important;
  display: inline-block !important;
  margin: 5px 0;
}

.tabs-wrapper {
  background-color: #ffffff;
}

@media (min-width: 1024) {
  .tabs-wrapper {
    padding: 0 30px;
  }
}

@media (min-width: 1920) {
  .tabs-wrapper {
    padding: 0 60px;
  }
}

@media (max-width: 1023.98) {
  .tabs-wrapper {
    height: 122px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .scroll {
    overflow-x: scroll !important;
  }
  .scroll::after, .scroll::before {
    content: " ";
    z-index: 10;
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.6);
  }
  .scroll.tabs::after {
    height: 38px;
    width: 10%;
    right: 20px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), white);
  }
  .scroll.tabs::before {
    height: 38px;
    width: 10%;
    left: 20px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.3), white);
  }
  .scroll.graph-wrapper::after {
    height: 35px;
    width: 20%;
    right: 0px;
    top: -2px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), #fafafa 90%);
  }
  .scroll.graph-wrapper::before {
    height: 35px;
    width: 15%;
    left: 0px;
    top: -2px;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.3), #fafafa 90%);
  }
  .scroll.date-shortcut-column::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff 60%);
    height: 38px;
    width: 15%;
    right: 0px;
  }
  .scroll.date-shortcut-column::before {
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 60%);
    height: 38px;
    width: 15%;
    left: 0px;
  }
  .scroll.hidden-before::before {
    display: none;
  }
  .scroll.hidden-after::after {
    display: none;
  }
  .scroll.destop::before, .scroll.desktop::after {
    display: none;
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  box-pack: justify;
  -webkit-box-pack: justify;
  padding-top: 80px;
}

.tabs .hidden {
  display: none !important;
}

@media (max-width: 1023.98px) {
  .tabs {
    padding: 80px 0 10px;
    overflow-x: scroll;
  }
}

.tabs > p {
  position: relative;
  cursor: pointer;
  width: 13%;
  border: 1px solid #ededed;
  text-align: center;
  padding: 15px 0;
  margin: 0;
  flex-basis: 100%;
}

@media (max-width: 1279.98px) {
  .tabs > p {
    width: auto;
    padding: 0 7px;
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

@media (max-width: 1023.98px) {
  .tabs > p {
    display: inline-block;
    margin-right: 20px;
    flex: 1 0 auto;
    padding: 0 10px;
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

@media (min-width: 1024px) {
  .tabs > p:hover span {
    opacity: 1;
  }
}

.tabs > p.active {
  pointer-events: none;
  background: #fafafa;
}

.tabs > p.active > span {
  opacity: 1;
}

.tabs > p span {
  position: absolute;
  bottom: 0px;
  left: 0;
  display: block;
  opacity: 0;
  width: 100%;
  height: 4px;
  background-color: #00a758;
  transition: opacity 500ms ease;
}

.hidden-tab {
  display: none;
}

.services-list {
  margin-top: 0;
  margin-bottom: 0;
}

.services-list.list-margin {
  margin-top: 50px;
  margin-bottom: 30px;
}

.services-list-icon-details {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 12;
}

@media (max-width: 1023.98px) {
  .services-list-icon-details {
    justify-content: flex-start;
    opacity: 1;
  }
}

.services-list-row {
  z-index: 12;
}

@media (min-width: 1024px) {
  .services-list-row:hover .services-list-icon-details {
    opacity: 1;
  }
}

.services-list-funds-tag {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding: 4px 15px;
  border-radius: 21px;
  border: solid 1px #d8d8d8;
  pointer-events: none;
  z-index: 12;
}

@media (max-width: 1023.98px) {
  .services-list-funds-tag {
    margin-bottom: 20px;
  }
}

.services-list-details {
  margin-left: 10px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

@media (min-width: 1024px) {
  .services-list-details {
    display: none;
  }
}

.services-list-date {
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  pointer-events: none;
  z-index: 12;
}

.services-list-heading {
  position: relative;
  margin: 0 0 20px 0;
  pointer-events: none;
  z-index: 12;
}

.services-list-heading h2 {
  margin-top: 0;
}

.services-list-subHeading {
  position: relative;
  z-index: 12;
  margin: 0 0 20px 0;
  pointer-events: none;
}

.services-list-item {
  position: absolute;
  top: 1px;
  left: calc(-20px);
  width: calc(100% + 40px);
  height: calc(100% - 2px);
  text-decoration: none;
  z-index: 10;
}

@media (min-width: 1920px) {
  .services-list-item {
    left: calc(-50px);
    width: calc(100% + 100px);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .services-list-item {
    left: calc(-20px);
    width: calc(100% + 40px);
  }
}

.services-list-item:hover {
  background-color: #ffffff;
}

.tags-filter-wrapper {
  display: flex;
  flex: 1 0 50%;
  align-items: center;
  flex-wrap: wrap;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .tags-filter-wrapper {
    width: auto;
    justify-content: flex-start;
    margin: 30px 0;
  }
}

.tags-filter-wrapper > button:last-child {
  margin-right: 0;
}

.tags-filter-button {
  flex: 0 0 auto;
  margin-right: 10px;
  padding: 14px 30px;
  color: #424559;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  text-decoration: none;
  line-height: 1;
  cursor: pointer;
  outline: 0;
  border-radius: 21px;
  border: solid 1px #d8d8d8;
  background-color: transparent;
}

@media (max-width: 1919.98px) {
  .tags-filter-button {
    padding: 9px 20px;
  }
}

.tags-filter-button:hover {
  border: solid 1px #00a758;
}

.tags-filter-button > span {
  margin-left: 5px;
  opacity: 0.4;
}

.tags-filter-button.active {
  border: solid 1px #00a758;
  background-color: #00a758;
  color: #ffffff;
}

.details-datepicker {
  z-index: 107;
}

.ui-widget.ui-widget-content {
  margin-top: 20px;
}

.ui-widget.ui-widget-content .ui-datepicker-next.ui-state-hover, .ui-widget.ui-widget-content .ui-datepicker-prev.ui-state-hover {
  border: 0;
  background: transparent;
}

.ui-widget-header {
  background: transparent;
  border: 0;
}

.ui-widget-header .ui-datepicker-next .ui-icon {
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-right.png");
}

.ui-widget-header .ui-icon {
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-left.png");
  background-size: cover;
  background-position: center;
}

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 0;
  background: transparent;
  text-align: center;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  line-height: 25px;
}

.ui-state-default.ui-state-hover {
  background: #00a758;
  color: #ffffff;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.ui-state-active, .ui-widget-content .ui-state-active {
  background: #00a758;
  border-radius: 100%;
  width: 30px;
  height: 30px;
}

.ui-widget.ui-widget-content {
  margin-top: 20px;
}

.ui-widget.ui-widget-content .ui-datepicker-next.ui-state-hover, .ui-widget.ui-widget-content .ui-datepicker-prev.ui-state-hover {
  border: 0;
  background: transparent;
}

.ui-widget-header {
  background: transparent;
  border: 0;
}

.ui-widget-header .ui-datepicker-next .ui-icon {
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-right.png");
}

.ui-widget-header .ui-icon {
  background-image: url("/etc.clientlibs/corewam/clientlibs/main/resources/images/arrow-left.png");
  background-size: cover;
  background-position: center;
}

.ui-state-default, .ui-widget-content .ui-state-default {
  border: 0;
  background: transparent;
  text-align: center;
}

.ui-state-default.ui-state-hover {
  background: #00a758;
  border-radius: 50px;
  color: #ffffff;
}

.ui-state-active, .ui-widget-content .ui-state-active {
  background: #00a758;
  border-radius: 50px;
}

.date-picker {
  position: relative;
}

.date-picker-column {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}

@media (max-width: 1023.98px) {
  .date-picker-column {
    flex-basis: 100%;
    justify-content: center;
  }
}

.date-icon {
  margin-right: 25px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-icon {
    display: none;
  }
}

.date-bar {
  position: relative;
  padding: 40px 0;
  background: #ffffff;
  z-index: 2;
}

.date-bar::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-top: 1px solid rgba(40, 43, 62, 0.1);
}

.date-bar::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-top: 1px solid rgba(40, 43, 62, 0.1);
}

.date-bar .row, .date-bar .fund-tile {
  justify-content: space-between;
}

.date-bar .container {
  padding-right: 0;
  padding-left: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-bar .container {
    margin: 0px 0px;
  }
}

.bar-scroll {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow-x: auto;
  overflow-y: hidden;
  margin-right: 0px;
  margin-bottom: 0px;
}

.date-input {
  cursor: pointer;
  background-color: #fafafa;
  padding: 5px 5px;
}

@media (min-width: 768px) {
  .date-input {
    padding: 10px 15px;
  }
}

@media (min-width: 1440px) {
  .date-input {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.date-input svg {
  transform: rotate(90deg);
  margin-top: 2px;
  vertical-align: top;
}

.date-input-wrapper {
  position: relative;
  margin-left: 9px;
  font-size: 14px;
  line-height: 24px;
  float: left;
}

.date-input-wrapper.active::before {
  border-bottom: 2px dashed transparent;
}

.date-input-wrapper:first-of-type {
  margin-left: 0;
}

.date-input-wrapper > input {
  max-width: 3em;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  color: #34384b;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 14px;
  line-height: 24px;
  -webkit-appearance: none;
  float: left;
  box-shadow: none;
  cursor: pointer;
}

.date-input-wrapper > input.inactive {
  color: #8e90a2;
}

.date-input-wrapper > span {
  color: #34384b;
  float: left;
  cursor: pointer;
}

.date-input-wrapper > span.inactive {
  color: #8e90a2;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-input-wrapper,
  .date-input-wrapper > input {
    font-size: 12px;
  }
}

.date-input input.input-date-range {
  border: none;
  background: none;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  vertical-align: middle;
}

.date-input input.input-date-range:focus {
  background: none;
  outline: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-input input.input-date-range {
    width: 80%;
  }
}

.date-separator {
  width: 19px;
  margin: 0 18px;
  border-top: 2px solid #34384b;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-separator {
    width: 10px;
  }
}

.date-shortcut {
  padding: 10px 25px;
  margin: 0px;
  background-color: #ededed;
  border: 0;
  border-radius: 0;
  outline: 0;
  color: #34384b;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-size: 12px;
  cursor: pointer;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  word-break: keep-all;
}

.date-shortcut.active {
  color: #00a758;
  pointer-events: none;
}

.date-shortcut + .date-shortcut {
  border-left: 1px solid #34384b;
}

@media (max-width: 1279.98px) {
  .date-shortcut {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
  }
}

@media (min-width: 1440px) {
  .date-shortcut {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.date-shortcut-column {
  padding: 0px;
  margin: 0px;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-shortcut-column {
    overflow-x: scroll;
  }
}

@media (max-width: 1279.98px) {
  .date-shortcut-column {
    margin-top: 20px;
    flex-basis: 100%;
  }
}

.date-calendar {
  margin-top: -1px;
  background: #ffffff;
  z-index: 1;
  max-height: 0;
  overflow: hidden;
  transition: max-height ease-in 200ms;
}

.date-calendar::before {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  border-bottom: 1px solid rgba(40, 43, 62, 0.1);
}

.date-calendar.active {
  max-height: 500px;
  height: auto;
}

.date-range-picker {
  transform: translate3d(0, -50px, 0);
  opacity: 0;
  transition: transform 500ms ease, opacity 250ms ease;
  padding: 40px 0 40px;
  width: 100%;
}

.date-range-picker.active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.date-range-picker .ui-datepicker-inline {
  width: auto !important;
  border: none !important;
}

.date-range-picker .ui-datepicker-inline * {
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
}

.date-range-picker .ui-datepicker-inline::after, .date-range-picker .ui-datepicker-inline::before {
  content: none;
}

.date-range-picker .ui-datepicker-inline.ui-widget-content {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-range-picker .ui-datepicker-inline.ui-widget-content {
    flex-direction: column;
  }
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-group {
  width: auto !important;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-buttonpane {
  display: none;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-title {
  position: relative;
  background: #ffffff;
  border: none;
  margin: 0;
  text-align: left;
  height: 80px;
  visibility: collapse;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-title .ui-datepicker-month,
.date-range-picker .ui-datepicker-inline .ui-datepicker-title .ui-datepicker-year {
  position: absolute;
  left: 0;
  visibility: visible;
  display: inline-block;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-title .ui-datepicker-year {
  top: 0;
  font-size: 22px;
  line-height: 26px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  opacity: 0;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-title .ui-datepicker-year.show {
  opacity: 1;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-title .ui-datepicker-month {
  bottom: 0;
  font-weight: 400;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-header {
  background: #ffffff;
  border: none;
  width: 100%;
  max-width: 260px;
  margin: 0 20px 0;
}

@media (min-width: 1280px) {
  .date-range-picker .ui-datepicker-inline .ui-datepicker-header {
    max-width: 300px;
  }
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar {
  width: 100% !important;
  max-width: 260px;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar thead th {
  opacity: 0.5;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-highlight,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-widget-content .ui-state-highlight,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-widget-header .ui-state-highlight {
  background-color: #ffffff;
  color: #424559;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-default {
  position: relative;
  background-color: #ffffff;
  border: none;
  padding: 4px 11px;
  margin: 0 -1px;
  text-align: center;
  vertical-align: middle;
  color: #424559;
  line-height: 13px;
  font-size: 12px;
  width: 40px;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-active {
  color: #424559;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .init-range {
  position: relative;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-hover,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .init-range .ui-state-default,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .end-range .ui-state-default,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .start-range .ui-state-default, .date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-active {
  color: #ffffff;
  font-weight: bold;
  background-color: transparent;
  z-index: 1;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-hover::after,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .init-range .ui-state-default::after,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .end-range .ui-state-default::after,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .start-range .ui-state-default::after, .date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .ui-state-active::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #06874e;
  transform: translateX(-50%);
  z-index: -1;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .start-range .ui-state-default::before,
.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .end-range .ui-state-default::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #00c46e;
  z-index: -1;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .start-range .ui-state-default::before {
  left: 50%;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .end-range .ui-state-default::before {
  right: 50%;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-calendar tbody .in-range .ui-state-default {
  position: relative;
  background-color: #00c46e;
  color: #ffffff;
}

@media (min-width: 1280px) {
  .date-range-picker .ui-datepicker-inline .ui-datepicker-calendar {
    max-width: 300px;
  }
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-header {
  position: static;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-prev,
.date-range-picker .ui-datepicker-inline .ui-datepicker-next {
  position: absolute;
  top: calc(50% - 20px);
  right: auto;
  left: auto;
  width: 30px;
  height: 40px;
  margin-top: 0;
  background-size: 12px 20px;
  color: inherit;
  outline: none;
  display: inline-block;
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-color: #ededed;
  display: flex;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-prev span,
.date-range-picker .ui-datepicker-inline .ui-datepicker-next span {
  display: none;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-prev:hover,
.date-range-picker .ui-datepicker-inline .ui-datepicker-next:hover {
  background-color: #ffffff;
  border: none;
  outline: none;
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-prev {
  left: 0;
  margin-right: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUBAMAAABL3sEiAAAAJ1BMVEUAAABVVapER1xDRltDRltDRVtERlpCRVpDRllCRVlDRllDRlpCRVnaCnosAAAADHRSTlMAA1ZXX2t0oqOm/P2ih2lqAAAAN0lEQVQI12NgwAAeAiCSZc9EEOV95jiYc2YxmHPKAM6JBnPYzoA5TBAxqAqYEkzucSSjoRYhAAC6URyN4glPGAAAAABJRU5ErkJggg==");
}

.date-range-picker .ui-datepicker-inline .ui-datepicker-next {
  right: 0;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAUBAMAAABL3sEiAAAAAXNSR0IArs4c6QAAACdQTFRFAAAAVVWqREdcQ0ZbQ0ZbQ0VbREZaQkVaQ0ZZQkVZQ0ZZQ0ZaQkVZ2gp6LAAAAAx0Uk5TAANWV19rdKKjpvz9oodpagAAADZJREFUCNdjYEABjC1gSvK0A4iqObMFRFmdAXOZ1+DiKoCpMwlgwa1wJVAVUPVQw6BGQy1CAQDQEBxvDVLpwwAAAABJRU5ErkJggg==");
}

@media (max-width: 1023.98px) {
  .date-range-picker {
    border-bottom: 1px solid rgba(40, 43, 62, 0.1);
  }
}

/* override coremfc */
.ui-datepicker-prev, .ui-datepicker-next {
  display: flex;
}

.ui-datepicker-month, .ui-datepicker-year {
  display: inline-block;
}

.ui-datepicker .ui-datepicker-title {
  min-height: auto;
  font-weight: bold;
}

.ui-datepicker table thead {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.chart-container {
  width: 100%;
  min-height: 600px;
  overflow: hidden;
}

.bar-label {
  word-wrap: break-word;
  white-space: normal;
  width: 200px;
}

@media (max-width: 1023.98px) {
  .bar-label {
    width: 110px;
  }
}

/*

g.highcharts-range-selector-group{
    transform: translate(0, -10px);
}
*/
.handle-bar-section {
  display: flex;
  width: 100%;
}

@media (max-width: 1023.98px) {
  .handle-bar-section {
    flex-wrap: wrap;
  }
}

.date-section {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1023.98px) {
  .date-section {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    padding-right: 15px;
    justify-content: center;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-section {
    padding-right: 0;
  }
}

.date-section svg {
  vertical-align: bottom;
  margin-right: 5px;
}

.date-section .line-break {
  font-size: 25px;
  font-weight: 600;
  padding: 0 10px;
  margin: 10px 0;
}

.date-section .datepick {
  border-bottom: 1px dashed #282b3e;
  position: relative;
  margin: 10px 0;
}

.date-section .datepick-input {
  width: 100%;
  border: 0;
  background: transparent;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.date-section .datepick-input:focus {
  outline: none;
}

.date-section .date-arrow-span {
  position: absolute;
  right: 0;
}

.date-section .date-arrow-span svg {
  transform: rotate(90deg);
}

.period-btn-section {
  display: flex;
  width: 65%;
  z-index: 10;
  overflow-x: auto;
  align-items: flex-end;
}

@media (max-width: 1023.98px) {
  .period-btn-section {
    width: 100%;
  }
}

.period-btn-section .period-btn {
  border: 1px solid #ededed;
  border-radius: 20px;
  padding: 10px 25px;
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.period-btn-section .period-btn + .period-btn {
  margin-left: 10px;
}

.period-btn-section .period-btn:hover {
  background: #00a758;
  color: #ffffff;
}

.period-btn-section .period-btn.active {
  pointer-events: none;
  background: #00a758;
  color: #ffffff;
}

.tooltip {
  position: relative;
}

.tooltip .baseline-section {
  width: auto;
}

.tooltip .baseline {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.animated-height.active {
  height: auto;
  display: block;
}

.animated-height:not(.active) {
  height: 0;
  display: none !important;
}

.video-player {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  z-index: 2000;
}

.video-player.fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.video-player.hidden {
  display: none;
}

.video-player-html, .video-player-youtube {
  width: 100%;
  height: 90%;
  position: absolute;
  top: 5%;
}

.video-player-html.hidden, .video-player-youtube.hidden {
  display: none;
}

.video-player-html video {
  width: 100%;
  height: 100%;
}

.video-player-youtube iframe {
  width: 100%;
  height: 100%;
}

.video-player-controls {
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease;
}

.video-player-controls.active {
  opacity: 1;
  pointer-events: auto;
}

.video-player-progress-bar {
  position: absolute;
  bottom: 54px;
  left: 30px;
  right: 30px;
  height: 3px;
  background-color: #ffffff;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .video-player-progress-bar {
    bottom: 105px;
  }
}

.video-player-progress-bar > progress {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  color: #00c870;
  -webkit-appearance: none;
  border: 0;
  cursor: pointer;
}

.video-player-progress-bar > progress::-webkit-progress-value {
  height: 100%;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: #00c870;
}

.video-player-progress-bar > progress::-webkit-progress-bar {
  height: 100%;
  border: 0;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
}

.video-player-progress-bar > progress::-moz-progress-bar {
  height: 100%;
  border: 0;
  background-color: #ffffff;
  border-radius: 0;
  box-shadow: none;
}

.video-player-range {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 0px;
  opacity: 0;
  cursor: pointer;
}

.video-player-range::-webkit-slider-thumb, .video-player-range::-moz-range-thumb, .video-player-range::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0;
  height: 100%;
  width: 0px;
  border-radius: 0px;
  background: rgba(255, 255, 255, 0.21);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 2.7px;
}

.video-player-pause {
  position: absolute;
  bottom: 12px;
  left: 30px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .video-player-pause {
    bottom: 65px;
  }
}

.video-player-pause img {
  width: 30px;
  height: 30px;
}

.video-player-volume-button {
  position: absolute;
  bottom: 12px;
  left: 80px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .video-player-volume-button {
    bottom: 65px;
  }
}

.video-player-volume-button img {
  width: 30px;
  height: 30px;
}

.video-player-time {
  position: absolute;
  bottom: 12px;
  left: 140px;
  color: #ffffff;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: -0.4px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .video-player-time {
    bottom: 65px;
  }
}

.video-player-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  opacity: 0;
  pointer-events: none;
  z-index: 2003;
  transition: opacity 500ms ease;
}

.video-player-close-button > svg {
  vertical-align: bottom;
  margin-bottom: -3px;
}

.video-player-close-button > span {
  margin: 0 10px 0 5px;
  line-height: 29px;
  text-shadow: -1px 2px 2px rgba(0, 0, 0, 0.8);
}

.video-player-close-button.active {
  opacity: 1;
  pointer-events: auto;
}

.video-player-close-button.hidden {
  display: none;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.75rem;
  line-height: 1;
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-chart-pie .ct-label,
.ct-chart-donut .ct-label {
  dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
  -webkit-box-align: flex-end;
  -webkit-align-items: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  text-align: right;
  text-anchor: end;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: end;
}

.ct-grid {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}

.ct-grid-background {
  fill: none;
}

.ct-point {
  stroke-width: 10px;
  stroke-linecap: round;
}

.ct-line {
  fill: none;
  stroke-width: 4px;
}

.ct-area {
  stroke: none;
  fill-opacity: 0.1;
}

.ct-bar {
  fill: none;
  stroke-width: 10px;
}

.ct-slice-donut {
  fill: none;
  stroke-width: 60px;
}

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #d70206;
}

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #d70206;
}

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #f05b4f;
}

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #f05b4f;
}

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #f4c63d;
}

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #f4c63d;
}

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #d17905;
}

.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: #d17905;
}

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #453d3f;
}

.ct-series-e .ct-slice-pie, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-area {
  fill: #453d3f;
}

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #59922b;
}

.ct-series-f .ct-slice-pie, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-area {
  fill: #59922b;
}

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #0544d3;
}

.ct-series-g .ct-slice-pie, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-area {
  fill: #0544d3;
}

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #6b0392;
}

.ct-series-h .ct-slice-pie, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-area {
  fill: #6b0392;
}

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #f05b4f;
}

.ct-series-i .ct-slice-pie, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-area {
  fill: #f05b4f;
}

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #dda458;
}

.ct-series-j .ct-slice-pie, .ct-series-j .ct-slice-donut-solid, .ct-series-j .ct-area {
  fill: #dda458;
}

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #eacf7d;
}

.ct-series-k .ct-slice-pie, .ct-series-k .ct-slice-donut-solid, .ct-series-k .ct-area {
  fill: #eacf7d;
}

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #86797d;
}

.ct-series-l .ct-slice-pie, .ct-series-l .ct-slice-donut-solid, .ct-series-l .ct-area {
  fill: #86797d;
}

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #b2c326;
}

.ct-series-m .ct-slice-pie, .ct-series-m .ct-slice-donut-solid, .ct-series-m .ct-area {
  fill: #b2c326;
}

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #6188e2;
}

.ct-series-n .ct-slice-pie, .ct-series-n .ct-slice-donut-solid, .ct-series-n .ct-area {
  fill: #6188e2;
}

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #a748ca;
}

.ct-series-o .ct-slice-pie, .ct-series-o .ct-slice-donut-solid, .ct-series-o .ct-area {
  fill: #a748ca;
}

.ct-square {
  display: block;
  position: relative;
  width: 100%;
}

.ct-square:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 100%;
}

.ct-square:after {
  content: "";
  display: table;
  clear: both;
}

.ct-square > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 93.75%;
}

.ct-minor-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-second {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-second:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 88.8888888889%;
}

.ct-major-second:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-second > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 83.3333333333%;
}

.ct-minor-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-third {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-third:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 80%;
}

.ct-major-third:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-third > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fourth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fourth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 75%;
}

.ct-perfect-fourth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fourth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-perfect-fifth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-perfect-fifth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 66.6666666667%;
}

.ct-perfect-fifth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-perfect-fifth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-golden-section {
  display: block;
  position: relative;
  width: 100%;
}

.ct-golden-section:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 61.804697157%;
}

.ct-golden-section:after {
  content: "";
  display: table;
  clear: both;
}

.ct-golden-section > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-sixth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-sixth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 60%;
}

.ct-major-sixth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-sixth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-minor-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-minor-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-minor-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-seventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-seventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 53.3333333333%;
}

.ct-major-seventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-seventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 50%;
}

.ct-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-tenth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-tenth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 40%;
}

.ct-major-tenth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-tenth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-eleventh {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-eleventh:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-eleventh > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-major-twelfth {
  display: block;
  position: relative;
  width: 100%;
}

.ct-major-twelfth:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 33.3333333333%;
}

.ct-major-twelfth:after {
  content: "";
  display: table;
  clear: both;
}

.ct-major-twelfth > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.ct-double-octave {
  display: block;
  position: relative;
  width: 100%;
}

.ct-double-octave:before {
  display: block;
  float: left;
  content: "";
  width: 0;
  height: 0;
  padding-bottom: 25%;
}

.ct-double-octave:after {
  content: "";
  display: table;
  clear: both;
}

.ct-double-octave > svg {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

/**
 * Chartist.js plugin to display a tooltip on top of a chart.
 * @author  Antonia Ciocodeica
 * @version 0.1 22 Nov 2016
 */
.chartist-tooltip {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  display: block;
  padding: 1em 2em;
  visibility: hidden;
  transform: translateY(3em);
  opacity: 0;
  border-radius: 0.25em;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease-in-out;
}

.chartist-tooltip:not([hidden]) {
  margin: 0;
  visibility: visible;
  transform: none;
  opacity: 1;
}

/* Tooltip arrow */
.chartist-tooltip::before {
  content: '\25BC';
  position: absolute;
  left: calc(50% - .5em);
  top: 100%;
  z-index: -1;
  font-size: 1.3em;
  line-height: .5em;
  font-family: Arial, sans-serif;
  color: rgba(255, 255, 255, 0.9);
  transform: scaleY(0.7);
  text-shadow: 0 0.25em 0.35em rgba(0, 0, 0, 0.1);
}

.chartist-tooltip--left::before {
  left: 0.75em;
}

.chartist-tooltip--right::before {
  left: auto;
  right: 0.75em;
}

/* Adds a small point transition (line charts) when the point is active */
.ct-point {
  transition: all 0.2s ease-in-out;
}

/* Increased specificity intended to overwrite the default chartist style */
.ct-chart-line.ct-chart-line .ct-point--hover {
  stroke-width: 1.25em;
}

.fund-details-characteristics-text {
  margin-top: 50px;
  margin-bottom: 20px;
}

.fund-details-characteristics-text.no-body {
  margin-top: 0;
  margin-bottom: -20px;
}

.js-container-chart {
  /* position: absolute; */
  width: 100%;
}

@media (max-width: 1023.98px) {
  .js-container-chart {
    display: inherit;
    width: auto;
  }
}

.char-chart-title {
  padding-left: 10px;
  font-size: 22px;
  line-height: 32px;
}

.title-section {
  display: flex;
  background: #fff;
  padding: 10px 0;
  border-bottom: 1px solid #ededed;
}

.title-section .char-name-title {
  padding-left: 10px;
  width: 200px;
  min-width: 200px;
}

@media (max-width: 1023.98px) {
  .title-section .char-name-title {
    width: 110px;
    min-width: 110px;
  }
}

.title-section .char-weight-title {
  padding-left: 3%;
  width: 100%;
}

@media (max-width: 1023.98px) {
  .title-section .char-weight-title {
    padding-left: 4%;
  }
}

.char-table-section {
  padding-bottom: 20px;
}

.char-table-section .char-table-tilte {
  font-size: 22px;
  line-height: 32px;
  padding-left: 10px;
  padding-bottom: 15px;
}

.char-table-section .char-table .table-line {
  display: flex;
}

.char-table-section .char-table .table-line:nth-child(odd) {
  background: #ededed;
}

.char-table-section .char-table .table-line:first-child {
  background-color: #ffffff;
}

.char-table-section .char-table .table-line div {
  width: 50%;
  padding-left: 10px;
}

.char-table-section .char-table .table-title {
  background-color: #ffffff;
  padding-top: 20px;
  padding-bottom: 20px;
}

.char-table-section .char-table .table-name {
  padding-top: 15px;
  padding-bottom: 15px;
}

.char-table-section .char-table .table-weight {
  padding-top: 15px;
  padding-bottom: 15px;
}

.fund-risk-main-title, .fund-risk-text {
  margin-bottom: 20px;
}

.fund-risk-main-title > p, .fund-risk-text > p {
  margin: 0;
}

.fund-risk-main-title + ul, .fund-risk-text + ul {
  margin-top: 0;
}

.fund-risk-text {
  margin-top: 20px;
}

.fund-risk-main-title {
  margin-top: 50px;
}

.fund-risk-main-title > h2 {
  margin-bottom: 0;
}

.fund-details-ditributor-tab-header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.fund-details-ditributor-tab-header > svg {
  margin-right: 20px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .distributor-header .fund-details-ditributor-tab-header {
    margin-top: 50px;
  }
  .distributor-header:first-of-type .fund-details-ditributor-tab-header {
    margin-top: 0;
  }
}

.distribution-info {
  margin: 0 -30px;
  padding-top: 50px;
  background-color: #ffffff;
}

@media (min-width: 1920px) {
  .distribution-info {
    margin: 0 -60px;
  }
}

.fund-details-distribution-info {
  margin-top: 20px;
  margin-bottom: 50px;
}

.distribution-row {
  border-bottom: 1px solid #fafafa;
  margin: 0px -30px;
  padding: 20px 30px;
  display: inline-block;
  width: calc(100% + 60px);
  background: #fafafa;
}

.distribution-row:nth-child(even) {
  background: #ededed;
}

@media (min-width: 1920px) {
  .distribution-row {
    margin: 0px -60px;
    width: calc(100% + 120px);
    padding: 20px 60px;
  }
}

@media (max-width: 1279.98px) {
  .distribution-row {
    width: auto;
    min-width: calc(150px * 9);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .distribution-row {
    width: auto;
    min-width: calc(150px * 9);
  }
}

.distribution-info-fund-value {
  font-size: 32px;
  line-height: 36px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
}

.distribution-info-currency {
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

.js-info-header.active {
  position: fixed;
}

@media (max-width: 1279.98px) {
  .distribution-info {
    overflow-x: auto;
  }
}

.info-header {
  display: flex;
  width: calc(100% + 60px);
  padding: 0 30px;
  padding-bottom: 20px;
}

@media (min-width: 1920px) {
  .info-header {
    padding: 0 60px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .info-header {
    width: auto;
    min-width: calc(150px * 9);
  }
}

.info-header p {
  width: calc(100% / 10);
  padding-right: 10px;
}

.info-header p:last-child {
  padding-right: 0px;
}

@media (max-width: 1279.98px) {
  .info-header p {
    min-width: 150px;
  }
}

.info-details {
  display: inline-flex;
  width: 100%;
  padding: 0 30px;
}

@media (min-width: 1920px) {
  .info-details {
    padding: 0 60px;
  }
}

.info-details div {
  width: calc(100% / 10);
  padding-right: 10px;
}

.info-details div:last-child {
  padding-right: 0;
}

@media (max-width: 1279.98px) {
  .info-details div {
    min-width: 150px;
  }
}

.nav-history-section {
  width: 100%;
  margin-top: 50px;
}

.chart-container {
  width: 100%;
  min-height: 600px;
  overflow: hidden;
}

.bar-label {
  word-wrap: break-word;
  white-space: normal;
  width: 200px;
}

@media (max-width: 1023.98px) {
  .bar-label {
    width: 110px;
  }
}

/*

g.highcharts-range-selector-group{
    transform: translate(0, -10px);
}
*/
.handle-bar-section {
  display: flex;
  width: 100%;
}

@media (max-width: 1023.98px) {
  .handle-bar-section {
    flex-wrap: wrap;
  }
}

.date-section {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1023.98px) {
  .date-section {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    padding-right: 15px;
    justify-content: center;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .date-section {
    padding-right: 0;
  }
}

.date-section svg {
  vertical-align: bottom;
  margin-right: 5px;
}

.date-section .line-break {
  font-size: 25px;
  font-weight: 600;
  padding: 0 10px;
  margin: 10px 0;
}

.date-section .datepick {
  border-bottom: 1px dashed #282b3e;
  position: relative;
  margin: 10px 0;
}

.date-section .datepick-input {
  width: 100%;
  border: 0;
  background: transparent;
  font-size: 14px;
  line-height: 25px;
  text-align: center;
  position: relative;
  z-index: 100;
  cursor: pointer;
}

.date-section .datepick-input:focus {
  outline: none;
}

.date-section .date-arrow-span {
  position: absolute;
  right: 0;
}

.date-section .date-arrow-span svg {
  transform: rotate(90deg);
}

.period-btn-section {
  display: flex;
  width: 65%;
  z-index: 10;
  overflow-x: auto;
  align-items: flex-end;
}

@media (max-width: 1023.98px) {
  .period-btn-section {
    width: 100%;
  }
}

.period-btn-section .period-btn {
  border: 1px solid #ededed;
  border-radius: 20px;
  padding: 10px 25px;
  background: #ffffff;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.period-btn-section .period-btn + .period-btn {
  margin-left: 10px;
}

.period-btn-section .period-btn:hover {
  background: #00a758;
  color: #ffffff;
}

.period-btn-section .period-btn.active {
  pointer-events: none;
  background: #00a758;
  color: #ffffff;
}

.tooltip {
  position: relative;
}

.tooltip .baseline-section {
  width: auto;
}

.tooltip .baseline {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
}

.chart-wrapper {
  position: relative;
  padding-top: 50px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .container > .chart-wrapper {
    padding-top: 30px;
  }
}

.chart-legend,
.chart-legend--unit {
  display: flex;
  padding: 0 0px;
  font-size: 12px;
  line-height: 14px;
}

.chart-legend--unit,
.chart-legend--unit--unit {
  margin-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chart-legend {
    flex-direction: column;
    padding: 0 0;
  }
}

@media (min-width: 768px) {
  .chart-axis-label--left {
    margin-left: 30px;
  }
  .chart-axis-label--right {
    margin-right: 20px;
  }
}

.chart-label + .chart-label {
  margin-left: 40px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chart-label + .chart-label {
    margin-left: 0px;
    margin-top: 20px;
  }
}

.chart-label > span {
  padding-bottom: 2px;
}

.chart-svg {
  font-size: 12px;
}

.chart-svg .line {
  fill: none;
  stroke-width: 2px;
}

.chart-svg .axis-x .domain {
  stroke: #eee;
}

.chart-svg .axis-y .domain {
  stroke: none;
}

.chart-svg .axis-y .tick {
  font-size: 10px;
  opacity: 0.7;
}

.chart-svg .grid .domain {
  stroke: none;
}

.chart-footnote {
  padding: 0 26px;
  font-size: 12px;
}

/* override chartist */
@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  #ct-chart {
    margin: 0px -20px;
  }
  .ct-label {
    font-size: 11px;
  }
}

.ct-label {
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

.ct-label.ct-horizontal {
  padding-top: 10px;
}

.ct-grid.ct-horizontal {
  stroke-width: 0;
}

.ct-grid.ct-vertical {
  stroke-dasharray: 0;
  stroke-width: 0.5;
}

.ct-label {
  color: #282b3e;
  font-weight: 500;
  font-size: 12px;
}

.ct-grids {
  overflow: hidden;
  position: relative;
}

.ct-chart {
  position: relative;
  margin-top: 100px;
  height: 450px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .ct-chart {
    margin-top: 60px;
  }
}

.ct-chart-line.ct-chart-line .ct-line {
  stroke-width: 2px;
}

.ct-chart-line.ct-chart-line .ct-point {
  stroke-width: 0px;
  display: none;
}

.ct-chart-line.ct-chart-line .ct-point.ct-point--hover {
  display: block;
}

.ct-series-a .ct-area {
  fill: #00aa30;
  fill-opacity: 0.7;
}

.ct-series-a .ct-point {
  fill: #00c46e;
  stroke: #00c46e;
}

.ct-series-a .ct-point.ct-point--hover {
  fill: url("absolute:#ChartPointGradient-a");
}

.ct-series-a .ct-line {
  fill: transparent;
  stroke: transparent;
}

.ct-series-c .ct-point {
  fill: #dc5a44;
  stroke: #dc5a44;
}

.ct-series-c .ct-point.ct-point--hover {
  fill: url("absolute:#ChartPointGradient-c");
}

.ct-series-c .ct-line {
  stroke: #dc5a44;
}

.ct-series-b .ct-point {
  fill: #0000c1;
  stroke: #0000c1;
}

.ct-series-b .ct-point.ct-point--hover {
  fill: url("absolute:#ChartPointGradient-b");
}

.ct-series-b .ct-line {
  stroke: #0000c1;
}

.ct-series-b .ct-area, .ct-series-c .ct-area {
  fill: transparent;
  fill-opacity: 0;
}

.crosshairCursor-x {
  margin-left: -20px;
  z-index: 0 !important;
  height: calc(100% - 60px);
  bottom: 60px;
}

@media (min-width: 768px) {
  .crosshairCursor-x {
    margin-left: -110px;
  }
}

@media (min-width: 1920px) {
  .crosshairCursor-x {
    margin-left: -140px;
  }
}

.chartist-tooltip {
  z-index: 10000;
  text-align: center;
  padding: 5px 10px;
  background: #ededed;
}

.chartist-tooltip--right::before {
  right: 0.25em;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .chartist-tooltip--right::before {
    right: 2px;
  }
}

.chartist-tooltip--left::before {
  left: 0.5em;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .chartist-tooltip--left::before {
    left: 10px;
  }
}

.chartist-tooltip::before {
  color: #ededed;
  font-size: 2em;
  line-height: 0.5em;
  text-shadow: none;
  transform: none;
  z-index: 1000;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chartist-tooltip {
    padding: 2px 5px;
  }
}

.chartist-tooltip__title {
  font-size: 9pt;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 1.25;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chartist-tooltip__title {
    font-size: 6pt;
  }
}

.chartist-tooltip__value {
  font-size: 18pt;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  line-height: 1.25;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chartist-tooltip__value {
    font-size: 11pt;
  }
}

.chartist-tooltip td {
  padding: 5px 10px;
  vertical-align: bottom;
  text-align: center;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chartist-tooltip td {
    padding: 2px 5px;
  }
}

.chartist-tooltip td > div {
  padding-bottom: 5px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chartist-tooltip td > div {
    padding-bottom: 2px;
  }
}

.compare-fund-section {
  text-align: right;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-fund-section {
    text-align: center;
  }
}

.js-tab-graph-detail-msg {
  display: none;
}

.compare-fund {
  background: #ff7769;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 20px;
}

.compare-fund span {
  border-bottom: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-fund {
    width: 60%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .right-align-class {
    text-align: right;
  }
}

.graph-header-basic-price {
  margin-top: 50px;
  margin-bottom: 20px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .graph-header-basic-price {
    margin-top: 0;
    padding-top: 50px;
  }
}

.graph-fund-value {
  font-size: 32px;
  line-height: 36px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
}

.graph-fund-currency {
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

.graph-fund-text-heading {
  margin-bottom: 5px;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 1px;
  color: #282b3e;
  white-space: nowrap;
  align-self: flex-start;
}

.graph-fund-text {
  font-size: 14px;
  line-height: 14px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
  align-self: flex-end;
}

.graph-wrapper {
  display: flex;
  padding-bottom: 10px;
  overflow: auto;
  align-items: stretch;
}

.graph-wrapper > div {
  position: relative;
  flex: 1 0;
  text-align: right;
  width: 14%;
  display: flex;
  flex-wrap: wrap;
}

.graph-wrapper > div > p {
  width: 100%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .graph-wrapper > div {
    min-width: 80px;
  }
}

.graph-wrapper > div::after {
  content: '';
  position: relative;
}

.graph-wrapper > div:last-child {
  flex: 0 0 auto;
}

.graph-wrapper > div:last-child::after {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .graph-wrapper > div:last-child {
    flex: 1 0;
  }
}

.graph-fund-block {
  position: relative;
  flex: 1 0;
}

@media (max-width: 1023.98px) {
  .graph-fund-block {
    height: 40px;
  }
}

.graph-year-block {
  position: relative;
  flex: 1 0;
}

@media (max-width: 1023.98px) {
  .graph-year-block {
    height: 40px;
  }
}

.year-returns {
  display: flex;
  padding-bottom: 10px;
  overflow: auto;
  justify-content: flex-start;
}

.year-returns > div {
  position: relative;
  text-align: right;
  width: 20%;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .year-returns > div {
    min-width: 80px;
  }
}

.year-returns > div::after {
  content: '';
  position: relative;
}

.year-returns > div:last-child {
  flex: 0 0 auto;
}

.year-returns > div:last-child::after {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .year-returns > div:last-child {
    flex: 1 0;
  }
}

.graph-link {
  margin-top: 101px;
}

@media (max-width: 1023.98px) {
  .graph-link {
    margin-top: 50px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .chart-section.chart1 {
    padding-top: 50px;
  }
}

.fund-tile__col-type-1 .tile-date,
.fund-tile__col-type-1 .tile-title,
.fund-tile__col-type-1 .tile-sub-title, .fund-tile__col-type-2 .tile-date,
.fund-tile__col-type-2 .tile-title,
.fund-tile__col-type-2 .tile-sub-title {
  display: block;
}

.fund-tile__col .tile-date {
  width: calc(100% - 40px);
}

.fund-tile__item {
  position: relative;
  width: 100%;
  padding-bottom: 66.67%;
  margin: 10px 0;
}

.fund-tile__container, .fund-tile__image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.fund-tile .tile {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (max-width: 1439.98px) {
  .fund-tile__col .tile-title {
    font-size: 20px;
  }
}

.activitystream .tile-title {
  font-size: 22px;
  line-height: 28px;
  margin: 0;
}

@media (max-width: 1439.98px) {
  .activitystream .tile-title {
    font-size: 16px;
    line-height: 20px;
  }
}

.filter-icon-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.select-field {
  width: 100%;
  padding: 20px 40px 15px 15px;
  cursor: pointer;
  border: none;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-items-doc {
  width: calc(100% + 6px);
  background-color: #fafafa;
  position: absolute;
  top: 60px;
  left: -3px;
  box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
  display: none;
  overflow: auto;
  max-height: 30vh;
}

.select-items-doc div {
  border-bottom: 1px solid #ededed;
  padding: 20px 15px;
  display: block;
}

.select-items-doc div.active-ele {
  background: #ededed;
  border-left: 5px solid #00a758;
  padding-left: 10px;
}

.select-items-doc div:hover {
  background: #ededed;
  border-left: 5px solid #00a758;
  padding-left: 10px;
}

.filter-arrow-span {
  position: absolute;
  right: 20px;
  top: 17px;
  transform: rotate(90deg);
}

.filter-select {
  z-index: 2;
  transition: flex 500ms ease;
  cursor: pointer;
  flex: 1 0;
  width: 50%;
}

.filter-select > svg {
  min-width: 20px;
  cursor: pointer;
}

.cookie {
  position: fixed;
  left: 80px;
  bottom: 0;
  right: 0;
  padding: 30px 60px;
  color: white;
  background-color: #282b3e;
  z-index: 999;
  display: none;
  opacity: 1;
}

.cookie.show {
  opacity: 1;
  display: block;
}

.cookie--wrapper {
  display: flex;
  align-items: center;
}

.cookie__message {
  margin-right: 10px;
}

.cookie__message span {
  font-size: 14px;
}

.cookie__message a {
  color: white;
  text-decoration: none;
  padding-bottom: 2px;
  border-bottom: 1px solid white;
}

.cookie p,
.cookie b,
.cookie a,
.cookie span,
.cookie input {
  font-size: 14px !important;
}

.cookie__action {
  margin-left: auto;
}

.cookie__action input {
  border: 1px solid white;
  outline: none;
  color: white;
  padding: 15px 30px;
  background-color: #282b3e;
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 14px;
}

@media (max-width: 1919.98px) {
  .cookie {
    padding: 30px 20px;
  }
  .cookie p,
  .cookie b,
  .cookie a,
  .cookie span,
  .cookie input {
    font-size: 12px !important;
  }
}

@media (max-width: 768px) {
  .cookie {
    left: 0;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cookie {
    left: 80px;
  }
}

.cmp-ng-facets__filter-btn.hidden {
  display: none;
}

#overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

#overlay.active {
  display: block;
}

.content-teaser .cmp-content-teaser__header {
  min-height: 0px;
  margin: 0 0 15px 0;
  width: calc(100% - 50px);
}

.content-teaser .insight-date {
  color: #282b3e;
  min-height: 20px;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 10px 0;
  overflow: hidden;
  max-height: 52px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: -o-ellipsis-lastline;
}

.content-teaser .insight-icon {
  width: 40px;
  height: 40px;
  background: url("/assets/icon/html-page-black.svg");
  z-index: 10;
  position: absolute;
  right: 30px;
  top: 20px;
}

.content-teaser .insight-icon.html-page {
  background: url("/assets/icon/html-page-black.svg");
}

.content-teaser .insight-icon.pdf-file {
  background: url("/assets/icon/pdf-file-black.svg");
}

.content-teaser .insight-icon.video-file {
  background: url("/assets/icon/video-black.svg");
}

.cmp-content-teaser--blue .insight-date, .cmp-content-teaser--cyan .insight-date, .cmp-content-teaser--jade .insight-date, .cmp-content-teaser--orange .insight-date, .cmp-content-teaser--purple .insight-date {
  color: #ffffff;
}

.cmp-content-teaser--blue .content-teaser-date, .cmp-content-teaser--cyan .content-teaser-date, .cmp-content-teaser--jade .content-teaser-date, .cmp-content-teaser--orange .content-teaser-date, .cmp-content-teaser--purple .content-teaser-date {
  color: #ffffff;
}

.cmp-content-teaser--blue .insight-icon, .cmp-content-teaser--cyan .insight-icon, .cmp-content-teaser--jade .insight-icon, .cmp-content-teaser--orange .insight-icon, .cmp-content-teaser--purple .insight-icon {
  background: url("/assets/icon/html-page-white.svg");
}

.cmp-content-teaser--blue .insight-icon.html-page, .cmp-content-teaser--cyan .insight-icon.html-page, .cmp-content-teaser--jade .insight-icon.html-page, .cmp-content-teaser--orange .insight-icon.html-page, .cmp-content-teaser--purple .insight-icon.html-page {
  background: url("/assets/icon/html-page-white.svg");
}

.cmp-content-teaser--blue .insight-icon.pdf-file, .cmp-content-teaser--cyan .insight-icon.pdf-file, .cmp-content-teaser--jade .insight-icon.pdf-file, .cmp-content-teaser--orange .insight-icon.pdf-file, .cmp-content-teaser--purple .insight-icon.pdf-file {
  background: url("/assets/icon/pdf-file-white.svg");
}

.cmp-content-teaser--blue .insight-icon.video-file, .cmp-content-teaser--cyan .insight-icon.video-file, .cmp-content-teaser--jade .insight-icon.video-file, .cmp-content-teaser--orange .insight-icon.video-file, .cmp-content-teaser--purple .insight-icon.video-file {
  background: url("/assets/icon/video-white.svg");
}

.cmp-content-teaser__image--background .insight-date {
  color: #ffffff;
}

.cmp-content-teaser__image--background .content-teaser-date {
  color: #ffffff;
}

.cmp-content-teaser__image--background .insight-icon {
  background: url("/assets/icon/html-page-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.html-page {
  background: url("/assets/icon/html-page-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.pdf-file {
  background: url("/assets/icon/pdf-file-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.video-file {
  background: url("/assets/icon/video-white.svg");
}

/* over ride coremfc style*/
.content-teaser .cmp-content-teaser__description {
  font-size: 1.1rem;
}

.content-teaser .cmp-content-teaser__link {
  z-index: 2;
}

#overlay {
  width: 100%;
  height: 100vh;
  background-color: #282b3e;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 1004;
  display: none;
}

#overlay.active {
  display: block;
}

.video-player-youtube iframe {
  width: 100%;
  height: 100%;
}

.video-player-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: #ffffff;
  pointer-events: none;
}

.video-player-close-button > span {
  margin: 0 10px 0 5px;
  line-height: 29px;
}

.video-player-close-button.active {
  opacity: 1;
  pointer-events: auto;
}

.video-player-close-button.hidden {
  display: none;
}

/* For perview only */
.content-teaser .cmp-content-teaser__header {
  min-height: 0px;
  margin: 0;
}

.content-teaser .insight-date {
  color: #282b3e;
  min-height: 20px;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 10px 0;
  overflow: hidden;
  max-height: 52px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: -o-ellipsis-lastline;
}

.content-teaser .insight-icon {
  width: 40px;
  height: 40px;
  background: url("icon/html-page-black.svg");
  z-index: 10;
  position: absolute;
  right: 30px;
  top: 20px;
}

.content-teaser .insight-icon.html-page {
  background: url("icon/html-page-black.svg");
}

.content-teaser .insight-icon.pdf-file {
  background: url("icon/pdf-file-black.svg");
}

.content-teaser .insight-icon.video-file {
  background: url("icon/video-black.svg");
}

.cmp-content-teaser--blue .insight-date, .cmp-content-teaser--cyan .insight-date, .cmp-content-teaser--jade .insight-date, .cmp-content-teaser--orange .insight-date, .cmp-content-teaser--purple .insight-date {
  color: #ffffff;
}

.cmp-content-teaser--blue .insight-icon, .cmp-content-teaser--cyan .insight-icon, .cmp-content-teaser--jade .insight-icon, .cmp-content-teaser--orange .insight-icon, .cmp-content-teaser--purple .insight-icon {
  background: url("icon/html-page-white.svg");
}

.cmp-content-teaser--blue .insight-icon.html-page, .cmp-content-teaser--cyan .insight-icon.html-page, .cmp-content-teaser--jade .insight-icon.html-page, .cmp-content-teaser--orange .insight-icon.html-page, .cmp-content-teaser--purple .insight-icon.html-page {
  background: url("icon/html-page-white.svg");
}

.cmp-content-teaser--blue .insight-icon.pdf-file, .cmp-content-teaser--cyan .insight-icon.pdf-file, .cmp-content-teaser--jade .insight-icon.pdf-file, .cmp-content-teaser--orange .insight-icon.pdf-file, .cmp-content-teaser--purple .insight-icon.pdf-file {
  background: url("icon/pdf-file-white.svg");
}

.cmp-content-teaser--blue .insight-icon.video-file, .cmp-content-teaser--cyan .insight-icon.video-file, .cmp-content-teaser--jade .insight-icon.video-file, .cmp-content-teaser--orange .insight-icon.video-file, .cmp-content-teaser--purple .insight-icon.video-file {
  background: url("icon/video-white.svg");
}

.cmp-content-teaser__image--background .insight-date {
  color: #ffffff;
}

.cmp-content-teaser__image--background .insight-icon {
  background: url("icon/html-page-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.html-page {
  background: url("icon/html-page-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.pdf-file {
  background: url("icon/pdf-file-white.svg");
}

.cmp-content-teaser__image--background .insight-icon.video-file {
  background: url("icon/video-white.svg");
}

/* For perview only END */
.column-container {
  margin: 0;
}

.colunm-header-section {
  background: #ffffff;
  padding: 20px 0;
  font-weight: 600;
  border-bottom: 2px solid #ededed;
}

.colunm-list-item {
  padding: 25px 0;
  border-bottom: 2px solid #ededed;
}

.colunm-list-item:hover {
  background: #ededed;
}

.colunm-list-item:hover .pink-arrow img {
  padding: 0;
}

.item-details {
  display: flex;
  align-items: center;
}

.colunm-list-date {
  color: #8e90a2;
}

.column-list-detail {
  flex: 1;
}

.pink-arrow {
  flex: 0;
  padding-left: 10px;
}

.pink-arrow img {
  padding: 2px;
  width: 22px;
  height: 22px;
  display: block;
}

.colunm-list-item-link {
  text-decoration: none;
  color: inherit;
}

.profile-selection-popup.fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  z-index: 1007;
  display: none;
}

.profile-selection-popup .profile-section-box {
  background: #fff;
  width: 40%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  min-height: 30vh;
  padding: 50px 40px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .profile-selection-popup .profile-section-box {
    width: 80%;
  }
}

.profile-selection-popup .profile-section-box ul {
  width: auto;
  list-style: none;
  margin: 0;
  padding: 0;
}

.profile-selection-popup .profile-section-box li {
  width: auto;
  padding: 10px 0;
}

.profile-selection-popup .profile-section-box li a {
  color: inherit;
}

.profile-selection-popup .profile-section-box li span {
  cursor: pointer;
}

.profile-selection-popup .profile-section-box li span:hover {
  border-bottom: 2px solid #00a758;
}

.profile-selection-popup .profile-section-box li.active span {
  border-bottom: 2px solid #00a758;
}

.profile-selection-popup .profile-title {
  font-size: 30px;
  line-height: 34px;
  padding-bottom: 10px;
}

.contacts .content {
  display: flex;
  padding-bottom: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .content {
    flex-direction: column;
  }
}

.contacts .wrapper-contacts {
  position: relative;
  flex: 0 0 auto;
  width: 320px;
  height: 100vh;
}

.contacts .wrapper-contacts::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 1px solid #eee;
}

@media (max-width: 1919.98) {
  .contacts .wrapper-contacts {
    width: 290px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .wrapper-contacts {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .wrapper-contacts {
    position: initial;
  }
  .contacts .wrapper-contacts::before {
    content: none;
  }
}

.contacts .contacts-info {
  width: 100%;
  margin-top: 70px;
  padding: 30px 0 30px 30px;
  border-top: solid 1px #eee;
  border-bottom: solid 1px #eee;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .contacts-info {
    margin-top: 0;
    padding: 30px 0 50px 20px;
  }
}

@media (min-width: 1920px) {
  .contacts .contacts-info {
    margin-top: 80px;
  }
}

.contacts .contacts-header {
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 1.75;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .contacts-header {
    margin-bottom: 40px;
    margin-left: env(safe-area-inset-left);
    font-size: 30px;
    font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
    font-weight: 300;
    line-height: normal;
  }
}

.contacts .contacts-address {
  margin-bottom: 30px;
  font-size: 14px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 1.71;
}

.contacts .contacts-address > span {
  display: block;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .contacts-address {
    margin-left: env(safe-area-inset-left);
    font-size: 16px;
    line-height: 1.5;
  }
}

.contacts .contacts-label {
  display: none;
  font-size: 9px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  letter-spacing: 1px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .contacts-label {
    display: block;
    margin-left: env(safe-area-inset-left);
  }
}

.contacts .phone-wrapper {
  display: flex;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .phone-wrapper {
    margin-left: env(safe-area-inset-left);
  }
}

.contacts .contacts-phone {
  margin-left: 18px;
  font-size: 18px;
  color: #00a758;
  text-decoration: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .contacts-phone {
    margin-left: 0;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .icon-wrapper {
    display: none;
  }
}

.contacts .open-map {
  display: none;
  width: 100%;
  background: none;
  border: 0;
  border-bottom: solid 1px #eee;
  outline: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 20px 0 20px 20px;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .open-map {
    display: flex;
  }
  .contacts .open-map > svg {
    margin-left: env(safe-area-inset-left);
  }
}

.contacts .open-map .minus {
  display: none;
}

.contacts .open-map.opened .minus {
  display: block;
}

.contacts .open-map.opened .plus {
  display: none;
}

.contacts .open-map-text {
  margin-left: 20px;
  font-size: 12px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.contacts .map-container {
  flex: 1 0 auto;
  background-color: grey;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .contacts .map-container {
    height: 506px;
    display: none;
  }
  .contacts .map-container.expanded {
    display: block;
  }
}

.contacts .contacts-map {
  width: 100%;
  height: 100%;
}

.document-download-section {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document-download-section {
    padding-bottom: 30px;
  }
}

.document-download-section .cart-btn {
  cursor: pointer;
}

.document-download-section .cart-btn:first-child {
  padding-right: 10px;
}

.document-download-section .cart-download-btn {
  border: 1.5px solid #ff7769;
}

.document-download-section .clear-doc-btn {
  padding: 19px 30px;
  border: 1.5px solid #ff7769;
}

.document-download-section .clear-doc-btn:hover {
  background: #ff7769;
  color: #ffffff;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-btn {
    width: 100%;
    padding-top: 10px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .filter-btn .compare-filter-btn {
    width: 100%;
    text-align: center;
  }
}

.filter-btn .compare-filter-btn.dim {
  cursor: none;
  pointer-events: none;
  color: #ffffff;
  border: none;
}

.document-filter-input {
  position: absolute;
  padding: 10px 30px 10px 10px;
  top: 13px;
  width: calc(100% - 80px);
  left: 25px;
  z-index: 100;
  background: #fafafa;
  border: 0;
  color: #282b3e;
  font-size: 16px;
  display: none;
}

.document-filter-input.active {
  display: block;
}

.document-filter-input:focus {
  outline: none;
}

.document {
  min-height: 100vh;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document {
    margin-top: 0;
  }
}

.document .no-result-section {
  display: none;
}

.document .compare-filter-btn {
  cursor: pointer;
}

.document .document-filters {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

@media (max-width: 1023.98px) {
  .document .document-filters {
    width: 100%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document .document-filters {
    flex-wrap: wrap;
    width: 100%;
  }
}

.document .document-header {
  position: relative;
  padding: 30px 0 35px;
  font-size: 40px;
  line-height: 1;
}

.document .result {
  margin-bottom: 80px;
}

.document .result-item {
  padding-right: 20px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.document .result-item::before, .document .result-item:after {
  content: '';
  position: absolute;
  left: calc(-30px);
  right: calc(-30px);
  z-index: -1;
}

.document .result-item::before {
  border-top: 2px solid #ededed;
  top: 0;
}

.document .result-item:last-child::after {
  border-bottom: 2px solid #ededed;
  bottom: 0;
}

.document .result-item:hover::after {
  background: #ededed;
  height: 100%;
}

.document .result-item:hover .document-title {
  color: #34384b;
}

.document .result-item:hover .document-icon path {
  fill: #34384b;
}

.document .result-item:hover .document-icon path:first-child {
  stroke: #34384b;
  fill: none;
}

.document .result-item:hover .document-arrow circle {
  fill: #ff7769;
}

.document .result-item:hover .document-arrow path {
  fill: #fff;
}

.document .result-item.hidden {
  display: none;
}

.document .result-item a {
  display: flex;
  text-decoration: none;
  color: #8e90a2;
  width: 100%;
  padding: 15px 0;
}

.document .doc-checkbox-section {
  position: relative;
  width: 15px;
  height: 15px;
}

.document .doc-checkbox-section input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 10;
  margin: 0;
  width: 20px;
  height: 20px;
}

.document .doc-checkbox-section .checkmark {
  position: absolute;
  height: 18px;
  width: 18px;
  border: 2px solid #8e90a2;
}

.document .doc-checkbox-section .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4.5px;
  top: -0.5px;
  width: 8px;
  height: 14px;
  border: solid #00a758;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.document .doc-checkbox-section:hover input ~ .checkmark {
  border: 2px solid #00a758;
}

.document .doc-checkbox-section input:checked ~ .checkmark {
  border: 2px solid #00a758;
}

.document .doc-checkbox-section input:checked ~ .checkmark:after {
  display: block;
}

.document .document-icon {
  display: inline-block;
  vertical-align: text-bottom;
  width: 25px;
  height: 25px;
  display: flex;
  max-width: 30px;
  flex: 1 0 30px;
  margin-top: -5px;
}

.document .document-icon path {
  fill: #8e90a2;
}

.document .document-icon path:first-child {
  stroke: #8e90a2;
  fill: none;
}

.document .document-title {
  display: inline-block;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  flex: 2 1 auto;
}

.document .document-description {
  padding-top: 10px;
  padding-bottom: 10px;
}

.document .document-arrow {
  display: inline-block;
  margin: 5px 0;
  display: flex;
  max-width: 30px;
  vertical-align: middle;
  margin: auto 0;
  flex: 1 0 30px;
}

.document .filter-type-span > svg {
  width: 25px;
  height: 25px;
}

.document .documnet-filiter {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0;
  height: 60px;
  z-index: 2;
  border-bottom: dashed 3px #282b3e;
  background-color: transparent;
  transition: flex 500ms ease;
  cursor: pointer;
  margin-right: 30px;
}

.document .documnet-filiter:last-child {
  margin-right: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document .documnet-filiter {
    min-width: 100%;
    margin-right: 0px;
  }
}

.document .documnet-filiter.filter-type-field.active .select-items-doc {
  display: block;
}

.document .documnet-filiter.filter-type-field.active .filter-type-span > svg > path:first-child {
  fill: none;
  stroke: #00a758;
}

.document .documnet-filiter.filter-type-field.active .filter-type-span > svg > path {
  fill: #00a758;
}

.document .documnet-filiter.filter-type-field.active .filter-arrow-span {
  transform: rotate(270deg);
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document .documnet-filiter.filter-fund-field {
    z-index: 3;
  }
}

.document .documnet-filiter.filter-fund-field.active .select-items-doc {
  display: block;
}

.document .documnet-filiter.filter-fund-field.active .filter-fund-span > svg > path {
  fill: #00a758;
  stroke: #00a758;
}

.document .documnet-filiter.filter-fund-field.active .filter-arrow-span {
  transform: rotate(270deg);
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document .documnet-filiter.filter-provider-field {
    z-index: 10;
  }
}

.document .documnet-filiter.filter-provider-field.active .select-items-doc {
  display: block;
}

.document .documnet-filiter.filter-provider-field.active .filter-provider-span > svg > path {
  fill: #00a758;
  stroke: #00a758;
}

.document .documnet-filiter.filter-provider-field.active .filter-arrow-span {
  transform: rotate(270deg);
}

.document .filter-provider-field {
  flex: 0.8 0;
}

.document .filter-type-field {
  flex: 0.8 0;
}

.document .dim {
  pointer-events: none;
  color: #8e90a2;
  border-color: #8e90a2;
}

.document .dim .filter-arrow-span svg path {
  fill: #8e90a2;
}

.document .dim.filter-type-field .filter-type-span > svg > path:first-child {
  fill: none;
  stroke: #8e90a2;
}

.document .dim.filter-type-field .filter-type-span > svg > path {
  fill: #8e90a2;
}

.document .dim.filter-fund-field .filter-fund-span > svg > path {
  fill: #8e90a2;
  stroke: #8e90a2;
}

/*doc msg box overlay*/
.doc-msg-overlay-section {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1007;
  top: 0;
  left: 0;
  display: none;
}

.doc-overlay-msg-box {
  background: #fff;
  width: 40%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  min-height: 30vh;
  padding: 50px 40px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .doc-overlay-msg-box {
    width: 80%;
  }
}

.doc-overlay-msg-box .doc-msg {
  padding-bottom: 50px;
}

.doc-overlay-msg-box .button-info {
  cursor: pointer;
}

.funds {
  margin-top: 0px;
}

@media (min-width: 1920px) {
  .funds {
    padding: 0;
  }
}

.funds .fund-compare-btn-scetion {
  text-align: right;
  align-self: flex-start;
  margin-left: auto;
  padding-left: 15px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funds .fund-compare-btn-scetion {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    margin-left: 0;
    width: 100%;
    align-items: stretch;
  }
}

.funds .fund-compare-btn-scetion .fund-compare-btn {
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funds .fund-compare-btn-scetion .fund-compare-btn {
    width: 100%;
    box-sizing: border-box;
    text-align: center;
  }
}

.funds .funds-filters {
  position: relative;
  display: flex;
  align-items: flex-end;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funds .funds-filters {
    flex-wrap: wrap;
  }
}

.funds .funds-wrapper-content {
  transition: transform 500ms ease;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funds .funds-wrapper-content {
    padding-bottom: 0;
    transform: translate3d(0, 0px, 0);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funds {
    margin-top: 30px;
  }
}

.bottom-btn-link {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  margin-top: 50px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .bottom-btn-link {
    flex-wrap: wrap;
  }
}

.bottom-btn-link a {
  width: 100%;
}

.compare-bottom-btn {
  align-self: flex-end;
  margin-left: 0;
  text-align: right;
  width: 100%;
  flex: 1 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-bottom-btn {
    min-width: 100%;
    flex: 0;
    margin: 0;
    padding: 0 40px;
    text-align: center;
    margin-top: 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-bottom-btn .fund-compare-btn {
    width: 100%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .details-bottom-btn {
    min-width: 100%;
    flex: 0;
    margin: 0;
    padding: 0 40px;
    text-align: center;
    box-sizing: border-box;
  }
}

.details-bottom-btn .col, .details-bottom-btn .fund-tile__col {
  margin: 0;
}

.details-bottom-btn .button.button-info, .details-bottom-btn .button-info.gallery-button {
  text-align: center;
}

/* update the white box style */
.funds-page .responsivegrid.cmp-layout-container--full-width {
  position: inherit;
}

.fund-details-line {
  border-left: 1px solid #ededed;
  margin: 0;
  padding-bottom: 60px;
}

@media (max-width: 1023.98px) {
  .fund-details-line {
    border-left: none;
    padding-bottom: 30px;
  }
}

div > .fund-details-header {
  margin-top: -5px;
  margin-bottom: 30px;
}

.details-info {
  text-align: right;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .details-info {
    text-align: left;
  }
}

.details-info-box p {
  margin-top: 0;
}

.details-info-box .infobox-header p {
  color: #8e90a2;
}

.fund-details-column-header {
  width: 100%;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  color: #282b3e;
}

.fund-details-asofDate {
  font-size: 14px;
  line-height: 20px;
  margin-top: 0;
  margin-bottom: 5px;
}

.fund-details-currency {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.6px;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
}

.fund-details-icon-fund {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}

.fund-details-fund-text {
  font-size: 14px;
  line-height: 14px;
  font-family: "Manulife JH Sans Regular", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
  margin: 0;
}

.fund-details-pin {
  display: none;
}

.fund-details-pin:checked + svg path {
  stroke: #00a758;
  fill: #00a758;
}

.fund-details-wrapper {
  position: relative;
  padding-top: 80px;
  background-color: #fff;
}

.header-page .fund-details-wrapper {
  padding-top: 30px;
}

@media (max-width: 1919.98px) {
  .fund-details-wrapper {
    padding-top: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-details-wrapper {
    padding-top: 30px;
  }
}

.fund-details-fund-value {
  display: inline-block;
  font-size: 50px;
  line-height: 1;
  margin: 0;
}

@media (max-width: 1023.98px) {
  .fund-details-fund-value {
    width: auto;
    margin-top: 0;
    margin-right: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-details-fund-value {
    margin-right: 20px;
    padding-top: 20px;
    position: relative;
    font-size: 36px;
  }
  .fund-details-fund-value.front .fund-details-currency {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: 320px) {
  .fund-details-fund-value {
    font-size: 30px;
  }
}

.fund-details-stats-section {
  display: flex;
  margin-bottom: 7px;
}

.fund-details-stats {
  display: inline-block;
}

@media (max-width: 1023.98px) {
  .fund-details-stats {
    margin-bottom: 50px;
  }
}

.fund-details-change {
  position: relative;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;
}

.fund-details-icon-back {
  margin-top: 0;
  margin-bottom: 50px;
}

@media (max-width: 1023.98px) {
  .fund-details-icon-back {
    margin-bottom: 20px;
  }
}

.fund-details-checkbox {
  display: flex;
  margin-top: 10px;
}

.fund-details-checkbox > svg {
  cursor: pointer;
}

.fund-details-checkbox > span {
  padding-left: 10px;
  cursor: pointer;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .fund-details-text {
    margin-bottom: 30px;
    display: inline-block;
  }
}

.fund-details-margin {
  margin: 0;
  padding-bottom: 60px;
}

@media (max-width: 1023.98px) {
  .fund-details-margin {
    padding-bottom: 30px;
  }
}

.yield-cal-btn {
  background: #ff7769;
  padding: 10px 20px;
  border-bottom: none;
  color: #ffffff;
}

.yield-cal-btn.link.inline {
  margin-right: 0;
}

.yield-cal-btn > span {
  border-bottom: none;
}

.yield-cal-btn:hover {
  background: #ec6453;
}

.hidden {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .funddetailsdocuments .aem-Grid.aem-Grid--default--12 > .aem-GridColumn.aem-GridColumn--default--3 {
    width: 50%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .aem-Grid.aem-Grid--default--12 > .funddetailsdocuments.aem-GridColumn.aem-GridColumn--default--3 {
    width: 50%;
  }
}

.rdb-box {
  padding: 20px 30px;
  background: #ededed;
  margin-bottom: 10px;
}

.rdb-box a {
  text-decoration: none;
  border-bottom: 1.5px solid #00a758;
  color: inherit;
  font-weight: bold;
}

.rdb-box a:hover {
  text-decoration: none;
  border-bottom: none;
  color: #00a758;
}

.insights-header {
  display: flex;
  align-items: flex-end;
  padding: 0 0 20px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .insights-header {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
  }
}

.insights-header > div,
.insights-header > h1 {
  flex: 1 0 auto;
}

.insights-filter {
  flex: 0 0 auto;
  margin-left: auto;
  justify-content: flex-end;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .insights-filter {
    justify-content: flex-start;
    height: auto;
    margin: 30px 0 0;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .calendar-row {
    display: none;
  }
}

.calendar-animated-content {
  display: none;
  transition: height 400ms ease 0ms;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .calendar-animated-content {
    display: block;
  }
}

.calendar-animated-content h2 {
  margin-top: 20px;
}

.calendar-text h2 {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .calendar-text h2 {
    display: block;
  }
}

.calendar-dates {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.calendar-dates > ul {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.calendar-dates > ul li {
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
  line-height: 22px;
}

.calendar-dates > ul li:not(:last-child) {
  padding-right: 5px;
}

.calendar-dates > ul.item-6 {
  max-height: 66px;
}

.calendar-dates > ul.item-7 {
  max-height: 99px;
}

.calendar-dates > ul.item-8 {
  max-height: 88px;
}

.calendar-dates > ul.item-9 {
  max-height: 121px;
}

.calendar-dates > ul.item-10 {
  max-height: 110px;
}

.calendar-dates > ul.item-11 {
  max-height: 143px;
}

.calendar-dates > ul.item-12 {
  max-height: 132px;
}

.calendar-dates > ul.item-13 {
  max-height: 165px;
}

.calendar-dates > ul.item-14 {
  max-height: 154px;
}

.calendar-dates > ul.item-15 {
  max-height: 187px;
}

.calendar-dates > ul.item-16 {
  max-height: 176px;
}

.calendar-dates > ul.item-17 {
  max-height: 209px;
}

.calendar-dates > ul.item-18 {
  max-height: 198px;
}

.calendar-dates > ul.item-19 {
  max-height: 231px;
}

.calendar-dates > ul.item-20 {
  max-height: 220px;
}

.calendar-dates > ul.item-21 {
  max-height: 253px;
}

.calendar-dates > ul.item-22 {
  max-height: 242px;
}

.calendar-dates > ul.item-23 {
  max-height: 275px;
}

.calendar-dates > ul.item-24 {
  max-height: 264px;
}

.calendar-dates > ul.item-25 {
  max-height: 297px;
}

.calendar-dates > ul.item-26 {
  max-height: 286px;
}

.calendar-dates > ul.item-27 {
  max-height: 319px;
}

.calendar-dates > ul.item-28 {
  max-height: 308px;
}

.calendar-dates > ul.item-29 {
  max-height: 341px;
}

.calendar-dates > ul.item-30 {
  max-height: 330px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .calendar-dates > ul.item-4 {
    max-height: 51.3333333333px;
  }
  .calendar-dates > ul.item-5 {
    max-height: 58.6666666667px;
  }
  .calendar-dates > ul.item-6 {
    max-height: 44px;
  }
  .calendar-dates > ul.item-7 {
    max-height: 73.3333333333px;
  }
  .calendar-dates > ul.item-8 {
    max-height: 80.6666666667px;
  }
  .calendar-dates > ul.item-9 {
    max-height: 66px;
  }
  .calendar-dates > ul.item-10 {
    max-height: 95.3333333333px;
  }
  .calendar-dates > ul.item-11 {
    max-height: 102.666666667px;
  }
  .calendar-dates > ul.item-12 {
    max-height: 88px;
  }
  .calendar-dates > ul.item-13 {
    max-height: 117.333333333px;
  }
  .calendar-dates > ul.item-14 {
    max-height: 124.666666667px;
  }
  .calendar-dates > ul.item-15 {
    max-height: 110px;
  }
  .calendar-dates > ul.item-16 {
    max-height: 139.333333333px;
  }
  .calendar-dates > ul.item-17 {
    max-height: 146.666666667px;
  }
  .calendar-dates > ul.item-18 {
    max-height: 132px;
  }
  .calendar-dates > ul.item-19 {
    max-height: 161.333333333px;
  }
  .calendar-dates > ul.item-20 {
    max-height: 168.666666667px;
  }
  .calendar-dates > ul.item-21 {
    max-height: 154px;
  }
  .calendar-dates > ul.item-22 {
    max-height: 183.333333333px;
  }
  .calendar-dates > ul.item-23 {
    max-height: 190.666666667px;
  }
  .calendar-dates > ul.item-24 {
    max-height: 176px;
  }
  .calendar-dates > ul.item-25 {
    max-height: 205.333333333px;
  }
  .calendar-dates > ul.item-26 {
    max-height: 212.666666667px;
  }
  .calendar-dates > ul.item-27 {
    max-height: 198px;
  }
  .calendar-dates > ul.item-28 {
    max-height: 227.333333333px;
  }
  .calendar-dates > ul.item-29 {
    max-height: 234.666666667px;
  }
  .calendar-dates > ul.item-30 {
    max-height: 220px;
  }
}

.calendar-dates > p {
  width: 100%;
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

.calendar-dates > p .date-footnote {
  width: 100%;
  margin: 10px 0;
  display: inline-block;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .president-image {
    margin-bottom: 50px;
  }
}

.responsible-investment-custom-text {
  font-family: "Manulife JH Sans Light", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: 300;
}

.responsible-investment-styled-image {
  width: 240px;
  margin-top: 20px;
}

.counter-antisocial-forces-styled-text {
  margin-top: 50px;
}

.proxy-voting-results-styled-link, .proxy-voting-results-styled-text {
  margin-top: 50px;
}

.services-statement-header-mobile {
  display: none;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .services-statement-header-mobile {
    display: block;
  }
}

.services-notice-header {
  display: flex;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .services-notice-header {
    flex-direction: column;
  }
  .services-notice-header button {
    margin-bottom: 10px;
  }
}

.services-notice-header > div {
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .services-notice-header > div {
    justify-content: flex-start;
  }
}

.services-notice-header > div,
.services-notice-header > h1 {
  flex: 1 0 auto;
}

.article-1-header {
  margin-top: 80px;
  margin-bottom: 10px;
}

@media (max-width: 1919.98px) {
  .article-1-header {
    margin-top: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .article-1-header {
    margin-top: 30px;
  }
}

.article-1-header + h1 {
  margin-top: 0;
}

.article-1-text {
  margin-top: 50px;
}

.article-2-header {
  margin-top: 80px;
  margin-bottom: 10px;
}

@media (max-width: 1919.98px) {
  .article-2-header {
    margin-top: 70px;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .article-2-header {
    margin-top: 30px;
  }
}

.article-2-header + h1 {
  margin-top: 0;
}

.article-header-image {
  height: calc((100vw - (290px + 80px)) / 3);
  background: url("/images/home-gallery.jpg") 50% 50% no-repeat;
  background-size: cover;
  background-position: center;
}

@media (max-width: 1919.98px) {
  .article-header-image {
    height: calc((100vw - (290px + 70px)) / 2);
  }
}

@media (max-width: 1279.98px) {
  .article-header-image {
    height: calc((100vw - 70px) / 2);
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .article-header-image {
    height: calc(100vw / 2);
  }
}

.article-date-header {
  margin-bottom: 10px;
}

.article-date-header + h1 {
  margin-top: 0;
}

.article-video-container {
  height: 518px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .article-video-container {
    height: 156px;
  }
}

@media (max-width: 1023.98px) {
  .article-video-container {
    height: 295px;
  }
}

@media (max-width: 1279.98px) {
  .article-video-container {
    height: 400px;
  }
}

.article-number {
  font-size: 150px;
  font-family: "Manulife JH Sans Bold", "Hiragino Kaku Gothic Pro", "Yu Gothic", "Meiryo", sans-serif;
  font-weight: bold;
  line-height: 1;
}

@media (max-width: 1919.98px) {
  .article-number {
    font-size: 129px;
    line-height: 1.01;
  }
}

@media (max-width: 1279.98px) {
  .article-number {
    font-size: 113px;
    line-height: 0.97;
  }
}

@media (max-width: 1023.98px) {
  .article-number {
    font-size: 80px;
    line-height: 0.75;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .article-number {
    margin-bottom: 30px;
    font-size: 90px;
    line-height: 0.67;
  }
}

.search-result {
  margin-bottom: 50px;
}

.search-result__filters h1 {
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 40px;
}

.search-result__filters .filter-search {
  border-color: rgba(37, 38, 45, 0.2);
}

.search-result__filters .filter-search-input {
  font-size: 24px;
}

.search-result__header h1 {
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 26px;
  font-weight: 600;
}

.search-result-title {
  position: relative;
  z-index: 12;
}

.search-result-title .tile {
  background: none;
}

.search-result .highlight {
  font-weight: bold;
}

.search-result .tags-filter-button {
  margin-top: 10px;
  font-size: 16px;
}

.search-result .services-list {
  font-size: 16px;
}

.search-result .services-list-heading {
  margin-right: 25px;
  font-size: 16px;
}

.search-result .services-list-heading span {
  border-bottom: 1px solid #00a758;
}

.search-result .services-list-subHeading {
  margin-right: 25px;
  font-size: 16px;
}

.search-result .services-list-funds-tag, .search-result .services-list-date {
  font-size: 16px;
}

.search-result .fund-tile {
  position: relative;
  z-index: 12;
}

.search-result .tile-icon-video {
  min-width: 20px;
  min-height: 20px;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.search-result .tile-date {
  margin-bottom: 10px;
}

.search-result .fund-tile__col {
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .search-result .services-list-heading,
  .search-result .services-list-subHeading {
    margin-right: 25px;
  }
  .search-result .fund-tile__col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .search-result .margin-bottom-medium {
    margin-bottom: 20px;
  }
  .search-result .fund-tile__col {
    flex-basis: 85%;
    max-width: 100%;
  }
}

@media (max-width: 767.98px) {
  .search-result .search-result__filters h1 {
    margin-top: 50px;
  }
  .search-result .margin-bottom-medium {
    margin-bottom: 20px;
  }
  .search-result .fund-tile__col {
    flex-basis: 100%;
    max-width: 100%;
  }
  .search-result .services-list-heading,
  .search-result .services-list-subHeading {
    margin-right: 0;
  }
  .search-result .filter-search-field {
    height: 45px;
  }
}

.compare-page-title {
  margin: 30px 0;
  font-size: 30px;
}

.compare-bar {
  background: #00a758;
  color: #ffffff;
  display: flex;
  margin: 0 -30px;
  padding: 10px 30px;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-bar {
    flex-wrap: wrap;
  }
}

.compare-details {
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-details {
    width: 100%;
    justify-content: space-between;
  }
}

.compare-reset-btn {
  margin-left: 30px;
  background: #ffffff;
  padding: 10px 50px;
  color: #282b3e;
  cursor: pointer;
}

.document-filters-section {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 40%;
  z-index: 1001;
}

.document-filters-section.dim {
  cursor: no-drop;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .document-filters-section {
    width: 100%;
  }
}

.compare-filters {
  display: flex;
  align-items: stretch;
  z-index: 1007;
  width: 100%;
}

.compare-filters.dim {
  pointer-events: none;
}

@media (max-width: 1023.98px) {
  .compare-filters {
    width: 100%;
  }
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-filters {
    flex-wrap: wrap;
    width: 100%;
  }
}

.compare-filters .documnet-filiter {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 0;
  height: 60px;
  z-index: 2;
  background-color: transparent;
  transition: flex 500ms ease;
  cursor: default;
  margin-right: 30px;
}

.compare-filters .documnet-filiter:last-child {
  margin-right: 0;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-filters .documnet-filiter {
    min-width: 100%;
    margin-right: 0px;
  }
}

.compare-filters .documnet-filiter.filter-fund-field .filter-arrow-span svg circle {
  fill: #ffffff;
}

.compare-filters .documnet-filiter.filter-fund-field .filter-arrow-span svg path {
  fill: #00a758;
}

@media (max-width: 767.98px), (max-width: 767.98px) and (max-height: 414px) {
  .compare-filters .documnet-filiter.filter-fund-field {
    z-index: 3;
  }
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc div {
  cursor: pointer;
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc .added {
  cursor: default;
  background: #fafafa;
  color: #8e90a2;
  pointer-events: none;
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc .added::before {
  content: 'added';
  background: #00a758;
  color: #ffffff;
  margin-right: 5px;
  font-size: 12px;
  padding: 2px 5px;
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc .added:hover {
  cursor: default;
  border-left: 0;
  padding-left: 15px;
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc .dim {
  cursor: default;
  background: #fafafa;
  color: #8e90a2;
  pointer-events: none;
}

.compare-filters .documnet-filiter.filter-fund-field .select-items-doc .dim:hover {
  cursor: default;
  border-left: 0;
  padding-left: 15px;
}

.compare-filters .documnet-filiter.filter-fund-field.active .select-items-doc {
  display: block;
}

.compare-filters .documnet-filiter.filter-fund-field.active .filter-fund-span > svg > path {
  fill: #00a758;
  stroke: #00a758;
}

.compare-filters .documnet-filiter.filter-fund-field.active .filter-arrow-span {
  transform: rotate(270deg);
  right: 0px;
}

.compare-filters .select-field {
  border-bottom: dashed 3px #ffffff;
  padding: 10px 30px 10px 0;
}

.compare-filters .filter-arrow-span {
  right: 5px;
}

.compare-filters .select-items-doc {
  width: calc(100% + 6px - 25px);
  color: #282b3e;
  left: 17px;
  max-height: 35vh;
  overflow-y: auto;
}

.compare-chart-section {
  margin-top: 50px;
}

.compare-chart-details-section {
  margin: 30px 0;
}

@media (max-width: 1023.98px) {
  .compare-chart-details-section {
    overflow-x: auto;
  }
}

.compare-chart-details-section .chart-details-header {
  display: flex;
  width: 100%;
  background: #ffffff;
  font-weight: 600;
}

.compare-chart-details-section .chart-details-header .section-header {
  border-bottom: 1px solid #8e90a2;
  display: inline-block;
}

.compare-chart-details-section .chart-details-header .fund-name-section {
  background: #fff;
  padding: 10px;
  padding-bottom: 0;
  padding-left: 0;
}

.compare-chart-details-section .chart-details-header .fund-details-section {
  background: #fff;
  padding: 10px;
  padding-bottom: 0;
}

.compare-chart-details-section .chart-details-header .performance-section {
  background: #fff;
  padding: 10px;
  padding-bottom: 0;
}

.compare-chart-details-section .chart-details-sub-header {
  display: flex;
  font-weight: 600;
  background: #ffffff;
}

.compare-chart-details-section .chart-details-sub-header .fund-name-section {
  background: #fff;
  padding: 10px;
  padding-top: 20px;
}

.compare-chart-details-section .chart-details-sub-header .fund-details-section {
  background: #fff;
  padding: 10px;
  padding-top: 20px;
}

.compare-chart-details-section .chart-details-sub-header .performance-section {
  background: #fff;
  padding: 10px;
  padding-top: 20px;
}

.compare-chart-details-section .chart-details-item {
  display: flex;
  padding: 10px 0;
}

.compare-chart-details-section .chart-details-item .fund-name-section {
  border-width: 0 0 2px 0;
  border-style: solid;
  border-color: #00a758;
  color: #282b3e;
  text-decoration: none;
}

.compare-chart-details-section .asOfDate-section {
  font-size: 14px;
  font-weight: bold;
  padding: 20px 0;
}

.compare-chart-details-section .fund-remove-section {
  padding-right: 20px;
  min-width: 40px;
}

.compare-chart-details-section .fund-remove-section .btn-remove-fund {
  cursor: pointer;
}

.compare-chart-details-section .fund-remove-section .btn-remove-fund svg {
  width: 20px;
  height: 20px;
}

.compare-chart-details-section .fund-name-section {
  width: 20%;
  position: relative;
  padding-right: 5px;
  line-height: 22px;
}

.compare-chart-details-section .fund-name-section .baseline {
  width: calc(100% - 10px);
  height: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
}

@media (max-width: 1023.98px) {
  .compare-chart-details-section .fund-name-section {
    min-width: 200px;
  }
}

.compare-chart-details-section .fund-details-section {
  width: 30%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

@media (max-width: 1023.98px) {
  .compare-chart-details-section .fund-details-section {
    min-width: 300px;
  }
}

.compare-chart-details-section .fund-details-section .details-sub-header {
  width: calc(100% / 3);
}

.compare-chart-details-section .performance-section {
  width: 50%;
  display: flex;
  padding-left: 10px;
}

@media (max-width: 1023.98px) {
  .compare-chart-details-section .performance-section {
    min-width: 400px;
  }
}

.compare-chart-details-section .performance-section .performance-sub-header {
  width: calc(100% / 5);
}

.compare-filter-input {
  position: absolute;
  padding: 10px 30px 10px 0;
  top: 10px;
  width: calc(100% - 80px);
  left: 20px;
  z-index: 100;
  background: #00a758;
  border: 0;
  color: #ffffff;
  display: none;
}

.compare-filter-input.active {
  display: block;
}

.compare-filter-input:focus {
  outline: none;
}

.compare-fund-container {
  min-height: 500px;
}

.compare-chart-details-section.show-volatility .fund-volatility-section {
  display: flex;
}

.compare-chart-details-section.show-volatility .fund-details-section {
  width: 25%;
}

.compare-chart-details-section.show-volatility .performance-section {
  width: 35%;
}

.compare-chart-details-section .fund-volatility-section {
  display: none;
  width: 20%;
}

@media (max-width: 1023.98px) {
  .compare-chart-details-section .fund-volatility-section {
    min-width: 200px;
  }
}

.compare-chart-details-section .fund-volatility-section .details-sub-header {
  width: 50%;
}

.compare-chart-details-section .fund-volatility-head {
  background: #fff;
  padding: 10px;
  padding-top: 20px;
}
