.services-list {
  margin-top: 0;
  margin-bottom: 0;

  &.list-margin {
    margin-top: $grid-indentMediumPx;
    margin-bottom: 30px;
  }
}

.services-list-icon-details {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 12;

  @media #{mediaDown($break-point-1024)} {
    justify-content: flex-start;
    opacity: 1;
  }
}

.services-list-row {
  z-index: 12;

  @media #{mediaUp($break-point-1024)} {
    &:hover .services-list-icon-details {
      opacity: 1;
    }
  }
}

.services-list-funds-tag {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  padding: 4px 15px;
  border-radius: 21px;
  border: solid 1px #d8d8d8;
  pointer-events: none;
  z-index: 12;

  @media #{mediaDown($break-point-1024)} {
    margin-bottom: $grid-indentSmallPx;
  }
}

.services-list-details {
  margin-left: 10px;
  font-family: $font-light;
  font-weight: 300;

  @media #{mediaUp($break-point-1024)} {
    display: none;
  }
}

.services-list-date {
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  pointer-events: none;
  z-index: 12;
}

.services-list-heading {
  position: relative;
  margin: 0 0 $grid-indentSmallPx 0;
  pointer-events: none;
  z-index: 12;

  h2 {
    margin-top: 0;
  }
}

.services-list-subHeading {
  position: relative;
  z-index: 12;
  margin: 0 0 20px 0;
  pointer-events: none;
}

.services-list-item {
  position: absolute;
  top: 1px;
  left: calc(-#{$grid-sideMargin_px - $grid-gutter_px / 2});
  width: calc(100% + #{($grid-sideMargin_px - $grid-gutter_px / 2) * 2});
  height: calc(100% - 2px);
  text-decoration: none;
  z-index: 10;

  @media #{mediaUp($break-point-1920)} {
    left: calc(-#{$grid-sideMargin_1920_px - $grid-gutter_px / 2});
    width: calc(100% + #{($grid-sideMargin_1920_px - $grid-gutter_px / 2) * 2});
  }

  @media #{mediaMobile()} {
    left: calc(-#{$grid-sideMargin_320_px - $grid-gutter_320_px / 2});
    width: calc(100% + #{($grid-sideMargin_320_px - $grid-gutter_320_px / 2) * 2});
  }

  &:hover {
    background-color: dark(7);
  }
}
