.list {
  margin-top: 30px;
  padding-left: 1.75em;
  list-style: none;

  & > li {
    position: relative;
    font-size: 12px;
    font-family: $font-light;
    font-weight: 300;
    line-height: 20px;
    padding-left: 0;
    color: dark(2);

    & > p {
      display: inline-block;
    }

    @media #{mediaUp($break-point-1920)} {
      font-size: 12px;
      line-height: 20px;
    }

    & + li {
      margin-top: 0;
    }

    & > ul, & > ol {
      margin: $grid-indentSmallPx 0;

      list-style: none;
      padding-left: 1.75em;

      & > li {
        &::before {
          content: '';
          position: absolute;
          left: -1.75em;
          display: inline-block;
        }
      }

      li + li {
        margin-top: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: -1.75em;
      display: inline-block;
    }
  }

  & > p {
    font-size: 12px;
    font-family: $font-light;
    font-weight: 300;
    line-height: 20px;
    margin-left: 0;
    color: dark(2);
  }

  & > li + div > p {
    margin-bottom: 30px;
  }

  & > li > div,
  & > li > a {
    margin-left: -1.75em;
  }

  &.footnote {
    margin-top: $grid-indentMediumPx;
  }

  &.bullet-decimal, &.bullet-rome, &.bullet-point {
    padding-left: 1em;

    & > li > a {
      margin-left: -1em;
    }
  }

  &.bullet-decimal {
    list-style: decimal;

    & > li {
      padding-left: 0.5em;

      &::before {
        display: none;
      }
    }

    & > p {
      margin-left: -1em;
    }
  }

  &.bullet-komejirushi {
    & > li {
      &::before {
        content: '※';
      }
    }
  }

  &.bullet-point {
    & > li {
      &::before {
        content: '.';
        left: -1em;
      }
    }
  }

  &.bullet-note {
    & > li {
      &::before {
        content: '注)';
      }
    }
  }

  &.bullet-children-komejirushi {
    & > li {
      & > ul, & > ol {
        & > li {
          &::before {
            content: '※';
          }
        }
      }
    }
  }

  &.bullet-children-decimal, &.bullet-children-none {
    & > li {
      & > ul, & > ol {
        & > li {
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &.bullet-children-point {
    & > li {
      & > ul, & > ol {
        & > li {
            &::before {
            content: '.';
            left: -1em;
          }
        }
      }
    }
  }

  &.bullet-children-note {
    & > li {
      & > ul, & > ol {
        & > li {
          &::before {
            content: '注)';
          }
        }
      }
    }
  }

  &.bullet-children-rome {
    list-style: lower-roman;

    & > li {
      & > ul, & > ol {
        list-style: lower-roman;
      }
    }
  }

  &.bullet-children-decimal, &.bullet-children-point, &.bullet-children-rome, &.bullet-children-none {
    & > li {
      & > ul, & > ol {
        padding-left: 1em;
      }
    }
  }

  &.bullet-none {
    padding-left: 0;

    & > li > a {
      margin-left: 0;
    }
  }

  &.margin-small {
    & > li {
       & + li {
        margin-top: $grid-indentSmallPx;
      }
    }
  }

  &.margin-medium {
    & > li {
       & + li {
        margin-top: $grid-indentMediumPx;
      }
    }
  }

  &.type-list {
    & > li {
      font-size: 14px;
      font-family: $font-regular;
      font-weight: normal;
      line-height: 24px;

      @media #{mediaUp($break-point-1920)} {
        font-size: 16px;
        line-height: 26px;
      }
    }

    & > p {
      font-size: 14px;
      font-family: $font-regular;
      font-weight: normal;
      line-height: 24px;
    }
  }

  &.no-title {
    & > li {
      &:nth-child(1) {
        &::before {
          display: none;
        }
      }
    }
  }
}

.body-bullet-disc-list ul:not(.list){
  padding-left: 1.1em;
  list-style: disc;
}
