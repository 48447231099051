@mixin ellipsis ($font-size, $line-height, $lines-to-show) {
  display: -webkit-box;
  font-size: $font-size;
  line-height: $line-height;
  height: $font-size*$line-height*$lines-to-show;
  -webkit-line-clamp: $lines-to-show;
  -moz-line-clamp: $lines-to-show;
  -ms-line-clamp: $lines-to-show;
  -o-line-clamp: $lines-to-show;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin generate-columns ($break-point) {
  @for $columnIndex from 1 through $grid-columns {
    .col-#{$columnIndex}-#{$break-point} {
      @media #{mediaUp($break-point)} {
        flex-grow: 0;
        flex-basis: #{calcWidth($columnIndex) + '%'};
        max-width: #{calcWidth($columnIndex) + '%'};
      }
    }
  }

  @include generate-columns-auto($break-point);
}

@mixin generate-columns-auto ($break-point) {
  .col-auto-#{$break-point} {
    @media #{mediaUp($break-point)} {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 100;
    }
  }
}

@mixin generate-columns-offsets ($break-point) {

  @for $columnIndex from 0 through $grid-columns {
    .col-offsets-#{$columnIndex}-#{$break-point} {
      @media #{mediaUp($break-point)} {
        margin-left: #{calcWidth($columnIndex) + '%'};
      }
    }
  }

  @include generate-columns-offsets-auto($break-point);
}

@mixin generate-columns-offsets-auto ($break-point) {
  .col-offsets-auto-#{$break-point} {
    @media #{mediaUp($break-point)} {
      margin-left: auto;
    }
  }
}

@mixin generate-columns-order ($break-point, $order) {
  .col-order-#{$order}-#{$break-point} {
    @media #{mediaUp($break-point)} {
      order: $order;
    }
  }
}

@mixin generate-height-dates-box($heightOfItem: 22px, $startOnMobile: 4, $startOnDesktop: 6, $limitedItems: 30) {
  $colMobile: 3;
  $colDesktop: 2;

  @for $d from $startOnDesktop through $limitedItems {
    &.item-#{$d} {
      @if $d % $colDesktop == 0 {
        max-height: ($d * $heightOfItem) / $colDesktop;
      }
      @else {
        max-height: (($d * $heightOfItem) / $colDesktop) + $heightOfItem;
      }
    }
  }

  @media #{mediaMobile()} {
    @for $m from $startOnMobile through $limitedItems {
      &.item-#{$m} {
        @if $m % $colMobile == 0 {
          max-height: ($m * $heightOfItem) / $colMobile;
        }
        @else {
          max-height: (($m * $heightOfItem) / $colMobile) + $heightOfItem;
        }
      }
    }
  }
}
