.cmp-ng-facets__filter-btn {
	&.hidden {
		display: none;
	}
}

#overlay {background-color:rgba(0,0,0,.5);display: none;}
#overlay.active {display:block;  }

.content-teaser{
    .cmp-content-teaser__header{
        min-height: 0px;
        margin: 0 0 15px 0;
        width: calc(100% - 50px);
    }
    .insight-date{
        color: #282b3e;
        min-height: 20px;
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 10px 0;
        overflow: hidden;
        max-height: 52px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-overflow: -o-ellipsis-lastline;
    }
    
    .insight-icon {
            width: 40px;
            height: 40px;
            background: url("/assets/icon/html-page-black.svg");
            z-index: 10;
            position: absolute;
            right: 30px;
            top:20px;

            &.html-page{
                background: url("/assets/icon/html-page-black.svg");
            }

            &.pdf-file{
                background: url("/assets/icon/pdf-file-black.svg");
            }

            &.video-file{
                background: url("/assets/icon/video-black.svg");
            }
    }
}

.cmp-content-teaser {

    &--blue,
    &--cyan,
    &--jade,
    &--orange,
    &--purple {
        .insight-date{
            color:#ffffff;
        }
        
        .content-teaser-date{
            color: dark(7);
        }
        
        .insight-icon {
            background: url("/assets/icon/html-page-white.svg");
            
            
            &.html-page{
                background: url("/assets/icon/html-page-white.svg");
            }

            &.pdf-file{
                background: url("/assets/icon/pdf-file-white.svg");
            }

            &.video-file{
                background: url("/assets/icon/video-white.svg");
            }
        }
    }
}
.cmp-content-teaser {

    &__image--background {
        .insight-date{
            color:#ffffff;
        }
        
        .content-teaser-date{
            color: dark(7);
        }
        
        .insight-icon {
            background: url("/assets/icon/html-page-white.svg");
            
            
            &.html-page{
                background: url("/assets/icon/html-page-white.svg");
            }

            &.pdf-file{
                background: url("/assets/icon/pdf-file-white.svg");
            }

            &.video-file{
                background: url("/assets/icon/video-white.svg");
            }
        }
    }
}

/* over ride coremfc style*/
.content-teaser .cmp-content-teaser__description{
    font-size: 1.1rem;
}

.content-teaser .cmp-content-teaser__link{
    z-index: 2;
}

