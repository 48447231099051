.funds-content {
  margin-top: $grid-indentSmallPx;

  & .hidden {
    display: none;
  }
}

.fund-title-section{
    display: flex;
    align-items: center;
}

.list-header {
  position: relative;
  padding: 30px 0 35px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom: 1px solid rgba(66, 69, 89, 0.1);
  }
}

.puls-icon{
    display: inline-block;
    padding-right: 10px;
    vertical-align: super;
    cursor: pointer;
}

.open-btn{
    g{
        fill:#ff7769;
    }
    path{
        fill:#ffffff;
    }
}

.platform {
  & + & {
    margin-top: $grid-indentSmallPx;
  }
}

.list-title {
  font-size: 40px;
  line-height: 1;
    display: inline-block;
}

.last-update {
  margin-top: 30px;
  font-size: 12px;

  @media #{mediaUp($break-point-1920)} {
    font-size: 14px;
  }
}

.funds-details {
  display: flex;
  align-items: center;
  margin-top: $grid-indentSmallPx;
  text-decoration: none;
  transition: opacity 400ms ease;
  opacity: 1;

  @media #{mediaBetween($break-point-568, $break-point-768)} {
    align-items: flex-end;
    margin-bottom: 0.2em;
  }

  span {
    color: dark(2);
    font-size: 14px;
    letter-spacing: 1px;
    text-align: right;
  }

  svg {
    margin-right: 10px;
  }

  @media #{mediaUp($break-point-1280)} {
    opacity: 1;

    svg {
      margin-right: 0;
    }

    span {
      display: none;
    }
  }

  @media #{mediaDown($break-point-1280)} {
    width: 50%;
    margin-top: 0;
    order: -1;
  }
}

.funds-no-results {
  display: none;

  &.active {
    display: block;
  }
}

.fund-actions-column {
  display: flex;
  flex-direction: column;
    margin-top: 30px;

  @media #{mediaUp($break-point-1280)} {
    align-items: flex-start;
  }

  @media #{mediaDown($break-point-1280)} {
    margin-top: $grid-indentSmallPx;
    flex-direction: row;
  }
}
.funds-tick-section{
    display: flex;
    align-items: stretch;
    
    &.dim{
        .funds-checkbox-section{
            cursor: default;
            input{
                pointer-events: none;
                cursor: default;
            }
            svg{
                cursor: default;
                
                polygon{
                    fill: dark(4) !important;
                }
            }
        }
        
        > span{
            color: dark(4);
        }
    }
    
  @media #{mediaDown($break-point-1280)} {
      position: absolute;
      top: 20px;
      right: 20px;
      
        
    }
    > span{
        font-weight: 600;
        line-height: 35px;
        padding-left: 5px;
        font-size: 14px;
        color: #424559;
        
        @media #{mediaDown($break-point-1280)} {
        display: none;}
        }
}
.funds-tick{
    position: relative;
    width: 18px;
    //height: 40px;
    
    @media #{mediaDown($break-point-1280)} {
        
    }
    
}
.js-tick-changing:checked + svg polygon{
    fill:green(3) !important;
}
.funds-checkbox-section{
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 50px;
    padding: 10px 0;
    
    @media #{mediaDown($break-point-1280)} {
    }
    
    svg{
        width: 18px;
        height: 18px;
    }
    
    &.dim{
        pointer-events: none;
        cursor: default;
        
        .js-tick-changing{
            cursor: default;
        }
    }
        
    &:hover input ~ .checkmark {
//      background-color: #ccc;
        border: 2px solid  green(3);
    }
    input{
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 10;
        top: 10px;
        left: 0px;
        margin: 0;
        width: 20px;
        height: 20px;
        &:checked ~ .checkmark {
//          background-color: green(3);
            border: 2px solid  green(3);
        }
        &:checked ~ .checkmark:after {
          display: block;
        }
    }
    
    .checkmark {
        position: absolute;
        top: 10px;
        left: 0px;
        height: 18px;
        width: 18px;
        margin: 0 1px;
//            background-color: red;
        border: 2px solid dark(4);
        opacity: 0;
        
        &:after {
          content: "";
          position: absolute;
          display: none;
            left: 3.6px;
            top: 0px;
            width: 7px;
            height: 12px;
            border: solid green(3);
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}
.checkbox {
  display: flex;
  cursor: pointer;
  z-index: 3;

  @media #{mediaDown($break-point-1280)} {
    margin-left: auto;
    justify-content: flex-end;
  }

  @media #{mediaBetween($break-point-568, $break-point-768)} {
    align-items: flex-end;
    margin-bottom: 0.2em;
  }

  & > svg {
    transition: opacity 400ms ease;
    opacity: 1;
    width: 18px;
    height: 18px;
  }
}
.funds-pin{
    display: flex;
    align-items: stretch;
    
    @media #{mediaDown($break-point-1280)} {
        align-items: center;
          position: absolute;
          top: 30px;
          right: 50px;
    }
    span{
        font-weight: 600;
        padding-left: 5px;
        font-size: 14px;
        color: #424559;
        

      @media #{mediaDown($break-point-1280)} {
          padding-left: 0px;
          display: none;
          
      }
    }
}
.fund-pin-button {
  display: none;

  &:checked + svg path {
    stroke: green(3);
    fill:green(3);
  }
}
.more-btn{
    margin: 0 auto;
    background: dark(5);
    padding: 15px 10px;
    width: 25%;
    cursor: pointer;
    
    @media #{mediaMobile()} {
        width: calc(100% - 40px;)
    }
}
.list-more-btn{
    
    &.accordion{
        padding: 0;
        height: 0px;
        opacity: 0;
        text-align: center;
    }
    &.expand{
        padding: 30px 0;
        height: auto;
        opacity: 1;
    }
}
.list-item {
  position: relative;
  display: block;
  padding: 30px 0;
  transition: background 400ms ease;
  color: inherit;
  text-decoration: none;
    
//    display: none;
    &.loadmore{
        display: none;
    }
    &.accordion{
        padding: 0;
        height: 0px;
        opacity: 0;
    }
    &.expand{
        padding: 30px 0;
        height: auto;
        opacity: 1;
    }
    

  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-top: 1px solid rgba(66, 69, 89, 0.1);
  }
    
    &:last-child{
        &::before{
            border-bottom: 1px solid rgba(66, 69, 89, 0.1);
        }
    }

  &:hover {
    background: $color-white;

    .funds-details, .checkbox > svg {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .checkbox > input:checked + svg {
    opacity: 1;
    pointer-events: auto;
  }
    
    .funds-details .arrow-list{
        
        &:hover{
            circle{
                fill:$color-coral;
            }
            path{
                fill:#fff;
            }
        }
        
    }

  @media #{mediaDown($break-point-1280)} {
    .funds-details, .checkbox > svg {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.funds-link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.fund-name {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

.fund-column-header {
    width: 100%;
    margin: 0;
    margin-bottom: 9px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    font-family: $font-light;
    font-weight: 300;
    color: dark(0);
    
    
    &.right{
        text-align: right;
        padding-right: 25%;
        
        @media #{mediaDown($break-point-1280)} {
            padding-right: 10%;
        }
        @media #{mediaDown($break-point-768)} {
//            text-align: left;
        }
        
        @media #{mediaDown($break-point-568)} {
            text-align: left;
        }
        
    }
    
    .js-funds-last-update{
        display: inline-block;
        line-height: 18px;
    }
    
    
}

.fundbase .fund-value{
	margin:0;
}

.fund-inline{
    display: inline-block;
    
    &.margin-right{
        min-width: 60%;
        @media #{mediaDown($break-point-1280)} {
            margin-right: 5%;
        }
    }
    
    &.right{
        text-align: right;
    }
}

.fund-inline-100{
    
    
    @media #{mediaDown($break-point-568)} {
        display: inline-block;
        min-width: 60%;
    }
}
.fund-value {
  font-size: 34px;
  line-height: 36px;
  font-family: $font-regular;
  font-weight: normal;
    
    @media #{mediaDown($break-point-568)} {
        font-size: 30px;
    }

    &.right{
        text-align: right;
        margin-right: 25%;
        
        @media #{mediaDown($break-point-1280)} {
            margin-right: 10%;
        }
        
        @media #{mediaDown($break-point-568)} {
            text-align: left;
            margin-right: 0;
        }
        
        .currency {
            width: 50px;
            display: none;
//            
//            &-font{
//                display: inline-block;
//            }
            &-back{
                display: inline-block;
            }
            @media #{mediaDown($break-point-568)}{
                width: 30%;
//                text-align: right;
            }
        }
    }
    
    @media #{mediaDown($break-point-568)} {
        .value{
            display: inline-block;
            width:65%;
        }
    }
    
  &.hidden {
    display: none;
  }
    &.front{
        .currency-front {
            display: inline-block;
        }
        
        .currency-back {
            display: none;
        }
    }
    &.back{
        .currency-front {
            display: none;
        }
        
        .currency-back {
            display: inline-block;
        }
    }
    
  .currency {
    font-size: 16px;
    display: none;
      
            &-back{
                display: inline-block;
            }
    
      @media #{mediaDown($break-point-568)} {
        width: 30%;
//        text-align: right;
      }
  }
}

.fund-text-heading {
    margin: 0;
  margin-bottom: 5px;
  font-size: 14px;
  font-family: $font-light;
  font-weight: 300;
  line-height: 12px;
  letter-spacing: 1px;
  color: dark(0);
  white-space: nowrap;
}

.fund-text {
  font-size: 16px;
  line-height: 14px;
  font-family: $font-regular;
  font-weight: normal;
  letter-spacing: 1.2px;
  white-space: nowrap;
  margin: 0;
    
    @media #{mediaDown($break-point-568)} {
        font-size: 14px;
    }
}

.currency {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.6px;
  font-family: $font-light;
  font-weight: 300;
}

.icon-funds-styled {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}

.change {
  position: relative;
    margin: 0;
  margin-bottom: 5px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.4px;

  &.change-up {
    color: #00bf66;

    & > svg:nth-child(2) {
      display: none;
    }
  }

  &.change-down {
    color: red;

    & > svg:nth-child(1) {
      display: none;
    }
  }
}

.fund-blocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0;

  & > div:nth-child(1) {
    flex: 1 0;
  }

  & > div:nth-child(3) {
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 24px;
      display: block;
      display: none;
      width: 25px;
      height: 1px;
      opacity: 0.2;
      border-top: 1px dashed #979797;

      @media #{mediaDown($break-point-1280)} {
        width: 18px;
      }

      @media #{mediaDown($break-point-1280)} {
        display: none;
      }
    }

    &::before {
      left: calc(-3vw - 10% + 12px);

      @media #{mediaDown($break-point-1280)} {
        left: calc(-3vw - 10% + 14px);
      }

      @media #{mediaMobile()} {
        left: calc(-12vw - 10% + 14px);
      }
    }

    &::after {
      display: none;
      right: calc(-3vw + 85% - 12px);

      @media #{mediaDown($break-point-1280)} {
        right: calc(-3vw + 85% - 14px);
      }

      @media #{mediaMobile()} {
        right: calc(-12vw + 85% + 14px);
      }
    }
  }

  & > div:nth-child(4) {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 24px;
      display: block;
      display: none;
      width: 25px;
      height: 1px;
      opacity: 0.2;
      border-top: 1px dashed #979797;

      @media #{mediaDown($break-point-1280)} {
        width: 18px;
      }

      @media #{mediaDown($break-point-1280)} {
        display: none;
      }
    }

    &::before {
      left: calc(-4vw - 10% + 12px);

      @media #{mediaDown($break-point-1280)} {
        left: calc(-3vw - 10% + 14px);
      }

      @media #{mediaMobile()} {
        left: calc(-14vw - 10% + 14px);
      }
    }
  }

  .change {
    font-size: 14px;
  }
}

.border-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  background-color: #00a758;
  transform: translate3d(-6px, 0, 0);

  &.pinned {
    transform: translate3d(0, 0, 0);
  }
}

.fund-info {
  display: flex;
  flex: 2 0;
  flex-wrap: wrap;

  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 auto;
    text-align: right;
    width: 15%;
   @media #{mediaDown($break-point-1280)} {
        width: 20%;
    }

    &::after {
      content: '';
      position: relative;
    }
      
//    &:first-child,
//    &:last-child {
      &:first-child{
          min-width: 20%;
          width: auto;
          
          @media #{mediaDown($break-point-1280)} {
              width: 20%;
          }
      &::after {
        display: none;
      }
    }

    &:last-child {
      flex: 0 0 auto;
    }
  }
    
    .fund-column-header{
//        text-align: left;
        white-space: nowrap;
         @media #{mediaDown($break-point-1280)} {
             width: 500% !important;
            white-space: normal;
             text-align: left;
        }
    }
}

.fund-item-link {
  color: #282b3e;
  text-decoration: none;
}
.fund-list-btn{
    position: relative;
    z-index: 100;
}