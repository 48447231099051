.button-expand {
  display: none;

  @media #{mediaMobile()} {
    position: absolute;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    outline: 0;
    appearance: button;
    user-select: none;
    cursor: pointer;
  }

  .minus {
    display: none;
  }

  .plus {
    display: block;
  }

  &.active {
    .minus {
      display: block;
    }

    .plus {
      display: none;
    }
  }
}
