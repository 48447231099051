.js-main{
    z-index: 1003;
}
.filter-group-panel-wrapper {
//  position: absolute;
//  left: 0;
  width: 100%;
//  padding-top: $funds-top-panel-heightPx;
  overflow: hidden;

  @media #{mediaMobile()} {
    overflow: visible;
  }
}

.filter-group-panel {
    position: absolute;
    top: 0;
    left: 0px;
    z-index: 1005;
    //  display: flex;
    flex-wrap: wrap;
    //width: calc(100% - 40px);
    width: 100%;
    height: 100vh;
    padding: 0 0 50px 0;
    background-color: dark(7);
    //  border: solid 1px #eee;
    transform: translate3d(0, -100%, 0);
    transition: transform 500ms ease;
    opacity: 0;
    display: none;
    align-content: flex-start;

    @media #{mediaMobile()} {
        position: fixed;
        width: calc(100% - 40px);
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        padding: 30px 20px 100px 20px;
        background-color: dark(6);
        border: 0;
        transition: none;
        overflow-y: auto;
        z-index: 12;
    }

    &.active {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        display: flex;
        position: fixed;
        overflow-y: auto;
    }
    .filter-group-text{
        font-size: 40px;
    }
    .filter-group-number{
        font-size: 40px;
    }
}
.filter-group-panel-content {
  background-color: #fafafa;
  padding: 0 120px;
  width: 100%;

   @media #{mediaMobile()} {
    padding: 0;
   }
}
.panel-close-btn{
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    
    svg{
        width: 15px;
        height: 15px;
    }
}
.filter-result-section{
    width:100%;
    display:flex;
    padding:30px 0 0 0;
}
.filter-group-item {
  width: 100%;
 // margin-top: 30px;
  padding: 30px 0;
  border-bottom: 1px solid rgba(66, 69, 89, 0.1);

  & > div {
    display: flex;
    flex-wrap: wrap;
  }

  @media #{mediaMobile()} {
    width: 100%;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
  }

  & > p {
    width: 100%;
  }
}

.filter-group-panel-label {
  margin: 10px 10px 0 0;
  font-family: $font-light;
  font-weight: 300;
  color: dark(2);
  line-height: 1;
  cursor: pointer;

  & > label {
    cursor: pointer;
  }

  & > label > input {
    display: none;
  }
}

.filter-group-panel-button {
  padding: 14px 30px;
  border-radius: 21px;
  border: solid 2px #d8d8d8;
  font-size: 12px;
  background-color: #fff;

  @media #{mediaUp($break-point-1920)} {
    padding: 10px 30px;
  }

  & > p {
    line-height: 1;
    margin: 0;
    font-size: 16px;
  }

  input:checked + & {
    color: dark(7);
    background-color: green(3);
    border: solid 2px green(3);
  }

  @media #{mediaDown($break-point-1920)} {
    padding: 9px 20px;
  }

  &:hover {
    border: solid 2px green(3);
  }
}

.filter-group-panel-distributor {
  display: flex;
  flex-wrap: wrap;
  width: 50%;
  margin-top: 30px;

  & > p {
    width: 100%;
    line-height: 1;
  }

  & > button {
    margin: 10px 20px 0 0;
    font-family: $font-light;
    font-weight: 300;
    color: dark(2);
    line-height: 1;
    cursor: pointer;
    outline: 0;
  }

  @media #{mediaMobile()} {
    width: 100%;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    z-index: 12;
  }
}

.filter-group-panel-button-popup {
  position: relative;
  flex: 0 0 auto;
  margin-right: 20px;
  margin-top: 4px;
  padding: 0 0 5px;
  font-size: 14px;
  background: none;
  border: 0;
  outline: 0;
  cursor: pointer;
  overflow-x: hidden;

  &.hidden {
    display: none;
  }

  &:hover::before {
    transform: translate3d(100%, 0, 0);
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-bottom: 2px dashed green(3);
    transition: transform 500ms ease;
    transform: translate3d(0, 0, 0);
  }
}

.filter-group-panel-button-back {
  display: none;

  @media #{mediaMobile()} {
    position: fixed;
    top: $nav-size_320_px;
    left: 0;
    display: block;
    width: 100%;
    max-height: $nav-backButton-height_px;
    margin: 0;
    padding: 0 0 0 20px;
    background: $header-background-color-small;
    border: 0;
    border-radius: 0;
    outline: 0;
    color: $text-color;
    font-family: $nav-subMenu-fontFamily;
    font-size: $nav-subMenu-fontSize;
    line-height: $nav-backButton-height_px;
    text-align: left;
    cursor: pointer;
    z-index: $nav-zIndex - 1; // #TODO: ${props => styles.nav.zIndex - (props.subMenuOpened ? 0 : 1)};
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transform: translate3d(0, -100%, 0);

    &.active {
      transform: translate3d(0, 0, 0);
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid $border-color;
      z-index: 1;
    }

    & > span {
      display: inline-block;
      margin-left: 10px;
    }

    & > p {
      display: inline-block;
      margin-left: 9px;
      opacity: 0.5;
      font-size: 12px;
      font-family: $font-light;
      font-weight: 300;
      letter-spacing: 1px;
      color: dark(0);
    }

    & > span,
    & > svg {
      vertical-align: middle;
    }

    & > svg {
      margin-left: env(safe-area-inset-left);
    }
  }
}

.filter-group-panel-button-reset {
  display: none;

  @media #{mediaMobile()} {
    position: fixed;
    top: $nav-size_320_px;
    right: 0;
    width: 35%;
    max-height: $nav-backButton-height_px;
    margin: 0;
    padding: 0 20px 0 0;
    background: $header-background-color-small;
    border: 0;
    border-radius: 0;
    outline: 0;
    color: $text-color;
    font-family: $nav-subMenu-fontFamily;
    font-size: $nav-subMenu-fontSize;
    line-height: $nav-backButton-height_px;
    text-align: left;
    cursor: pointer;
    z-index: $nav-zIndex - 1; // #TODO: ${props => styles.nav.zIndex - (props.subMenuOpened ? 0 : 1)};
    -webkit-appearance: button;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    transform: translate3d(0, -100%, 0);
      display: none;

    &.active {
      transform: translate3d(0, 0, 0);
    }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid $border-color;
      z-index: 1;
    }

    & > span {
      display: inline-block;
      margin-left: 10px;
    }

    & > p {
      display: inline-block;
      margin-left: 9px;
      opacity: 0.5;
      font-size: 12px;
      font-family: $font-light;
      font-weight: 300;
      letter-spacing: 1px;
      color: dark(0);
    }

    & > span,
    & > svg {
      vertical-align: middle;
    }
  }
}