.filter-group-button {
  width: 30px;
  height: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  cursor: pointer;
  background-color: transparent;
  padding-left: 10px;
    min-width: 30px;

  @media #{mediaMobile()} {
    width: 40px;
    min-width: 40px;
  }
}

.filter-group-number {
  margin-left: 5px;
  opacity: 0.5;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;
  color: dark(0);
    white-space: nowrap;
}

.filter-group-text {
  //margin-left: 10px;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;

  @media #{mediaMobile()} {
//    display: none;
  }
}

.filter-group-button-reset {
  position: relative;
  left: 0;
  margin-left: auto;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
    white-space: nowrap;

  & > p {
    font-size: 12px;
    font-family: $font-light;
    font-weight: 300;
    line-height: 1.33;
    color: dark(4);
  }

  @media #{mediaUp($break-point-1280)} {
    svg {
      display: none;
    }

    p {
      display: block;
    }
  }

  @media #{mediaDown($break-point-1280)} {
    p {
      display: none;
    }
  }

  @media #{mediaDown($break-point-1280)} {
    display: none;
  }
}

.filter-group-field {
  position: relative;
  display: flex;
  align-items: center;
//  flex: 2 0;
    flex: 0 1 auto;
  height: 40px;
  padding: 0 20px 0 0;
  z-index: 2;
//  border-top: solid 3px;
//  border-bottom: solid 3px;
//  border-color: green(3);
  border: solid 2px dark(5);
  border-radius: 30px;
  background-color: dark(7);
  transition: flex 500ms ease;
  cursor: pointer;
    margin: 0 5px;
    
    &:hover{
        border-color: green(3);
    }

  &.disabled {
    border-color: dark(5);
  }

  &.active {
      flex: 2 0;
      border: solid 2px green(3);
      height: auto;
      padding: 40px 0 30px 0;
      
      .filter-group-text{
          position: absolute;
          top: 17px;
          left: 50px;
      }
      
      .filter-group-number{
          position: absolute;
          top: 17px;
          left: 110px;
      }
      
      .filter-group-button-reset{
          position: absolute;
          top: 17px;
          left: auto;
          right: 15px;
          display: none;
      }
      
    & .filter-group-button {
        position: absolute;
        top: 15px;
        left: 15px;
        
      & > svg path {
//        stroke: $color-white;
          stroke:dark(0);
          fill:dark(0);
      }
    }
  }

  @media #{mediaMobile()} {
    flex: 1 0;
      margin-bottom: 5px;
      
      &.collapsed{
          display: flex !important;
      }
  }

}

.filter-group-category {
  @media #{mediaMobile()} {
    display: flex;
  }

  & .filter-group-text {
    @media #{mediaDown($break-point-1280)} {
      opacity: 1;
      transition-delay: 300ms;
    }
  }

  & .filter-group-button {
    background-color: transparent;

    @media #{mediaMobile()} {
      background-color: transparent;
    }
  }

  & > button > svg path {
    stroke: dark(2);

    @media #{mediaMobile()} {
      stroke: dark(2);
    }
  }

  &.disable-reset {
     & .filter-group-button-reset {
      display: none;
     }
  }

  &.collapsed {
    @media #{mediaMobile()} {
      display: none;
    }

    & .filter-group-text {
      @media #{mediaDown($break-point-1280)} {
//        opacity: 0;
        transition-delay: 0;
      }
    }
    & .filter-group-button-reset {

      @media #{mediaUp($break-point-1280)} {
        svg {
          display: block;
        }

        p {
          display: none;
        }
      }
    }
  }

  &.active {
    & .filter-group-button {
//      background-color: green(3);
    }
  }
}
.filter-btn-section{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 50px 120px;
    width: 100%;
    
    @media #{mediaMobile()} {
        position: relative;
        top:0px;
        left: auto;
        right: auto;
        width: calc(100% - 25px);
        //margin: 30px 0;
        padding: 30px 30px 50px 30px;
    }
}
.filter-clear {
  cursor: pointer;
    padding: 15px 40px;
    border: 1px solid dark(4);
    color: dark(4);
    margin-right: 15px;
  

  &:hover {
    border: 1px solid green(3);
  }

  @media #{mediaMobile()} {
    position: relative;
      width: 100%;
      margin-top: 20px;
      right: auto;
      text-align: center;
      margin-right: 0;
      
  }
}
.filter-apply {
  cursor: pointer;
    padding: 15px 40px;
    border: 1px solid coral(3);
    color: dark(7);
    background: coral(3);
  

  &:hover {
    border: 1px solid coral(2);
    background: coral(2);
  }

  @media #{mediaMobile()} {
    position: relative;
      width: 100%;
      margin-top: 20px;
      right: auto;
      text-align: center;
      
  }
}
