.video-player {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  background-color: black;
  z-index: 2000;

  &.fullscreen {
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  &.hidden {
    display: none;
  }
}

.video-player-html, .video-player-youtube {
  width: 100%;
  height: 90%;
  position: absolute;
    top: 5%;

  &.hidden {
    display: none;
  }
}

.video-player-html {
  & video {
    width: 100%;
    height: 100%;
  }
}

.video-player-youtube {
  & iframe {
    width: 100%;
    height: 100%;
  }
}

.video-player-controls {
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease;

  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}

.video-player-progress-bar {
  position: absolute;
  bottom: 54px;
  left: 30px;
  right: 30px;
  height: 3px;
  background-color: dark(7);

  @media #{mediaMobile()} {
    bottom: 105px;
  }

  & > progress {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: dark(7);
    color: #00c870;
    -webkit-appearance: none;
    border: 0;
    cursor: pointer;

    &::-webkit-progress-value {
      height: 100%;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      background-color: #00c870;
    }

    &::-webkit-progress-bar {
      height: 100%;
      border: 0;
      background-color: dark(7);
      border-radius: 0;
      box-shadow: none;
    }

    &::-moz-progress-bar {
      height: 100%;
      border: 0;
      background-color: dark(7);
      border-radius: 0;
      box-shadow: none;
    }
  }
}

.video-player-range {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 0px;
  opacity: 0;
  cursor: pointer;

  &::-webkit-slider-thumb,
  &::-moz-range-thumb,
  &::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0;
    height: 100%;
    width: 0px;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.21);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: 2.7px;
  }
}

.video-player-pause {
  position: absolute;
  bottom: 12px;
  left: 30px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);

  @media #{mediaMobile()} {
    bottom: 65px;
  }

  img {
    width: 30px;
    height: 30px;
  }
}

.video-player-volume-button {
  position: absolute;
  bottom: 12px;
  left: 80px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;

  @media #{mediaMobile()} {
    bottom: 65px;
  }

  img {
    width: 30px;
    height: 30px;
  }
}

.video-player-time {
  position: absolute;
  bottom: 12px;
  left: 140px;
  color: dark(7);
  font-size: 12px;
  line-height: 30px;
  letter-spacing: -0.4px;

  @media #{mediaMobile()} {
    bottom: 65px;
  }
}

.video-player-close-button {
  position: absolute;
//  bottom: 100px;
  top: 10px;
  right: 20px;
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: dark(7);
  opacity: 0;
  pointer-events: none;
    z-index: 2003;
  transition: opacity 500ms ease;

  & > svg {
      vertical-align: bottom;
      margin-bottom: -3px;
  }

  & > span {
    margin: 0 10px 0 5px;
//    vertical-align: top;
    line-height: 29px;
      text-shadow: -1px 2px 2px rgba(0,0,0,0.8);
  }

  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  &.hidden {
    display: none;
  }
}
