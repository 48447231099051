$footerBackgroundColor: dark(0);
$footerColor: dark(7);
$footerBeforeBackgroundColor: dark(1);
$footerLinkColor: dark(7);

.footer {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  background: $footerBackgroundColor;
  color: $footerColor;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 80px;
    background-color: $footerBeforeBackgroundColor;
    opacity: 0;
    transition: opacity 500ms ease;
    z-index: 0;
    pointer-events: none;
  }

  &.search-expanded {
    &:before {
      opacity: 1;
      transition: opacity 500ms ease;
    }
  }

  ul > li {
    display: inline-block;
  }

  ul > li > a {
    display: block;
    color: $footerLinkColor;
    text-decoration: none;
  }

  @media #{mediaMobile()} {
    ul > li {
      position: relative;
      display: block;
      width: 100%;
      margin: 0;
    }

    ul > li > a {
      width: auto;
    }
  }

  .styled-link {
    margin-top: 0;
  }

  .item-link {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    @media #{mediaMobile()} {
      a {
        margin-left: env(safe-area-inset-left);
        margin-right: env(safe-area-inset-right);
        padding: 20px 20px;
      }
    }
  }

  .external-links {
    flex: 1 0;
    padding-left: 20px;

    & > li {
      margin: 0 20px 30px 0;

      @media #{mediaMobile()} {
        margin-right: 0;
        
        &:last-child {
          margin-bottom: 0px;
        }
      }

      
    }

    @media #{mediaDown($break-point-1024)} {
      flex: 1 0 auto;
      order: 1;
      margin-top: 20px;
      padding: 0 20px;
    }

    @media #{mediaMobile()} {
      margin-left: env(safe-area-inset-left);
      margin-right: env(safe-area-inset-right);
    }

    @media #{mediaUp($break-point-1920)} {
      flex: 1 1 auto;
      padding-left: 60px;
    }
  }

  .language-select {
    display: flex;
    flex: 0 0 auto;
    align-items: flex-start;
    margin-right: 100px;
    margin-left: auto;
    opacity: 1;
    transition: opacity 500ms ease;
    cursor: pointer;

    & > p {
      line-height: 20px;
      white-space: nowrap;
    }

    & > svg {
      flex: 1 0 auto;
      position: static;
      transform: rotate(90deg);
      transition: transform 400ms ease;
    }

    @media #{mediaUp($break-point-1920)} {
      flex: 0 0 auto;
      margin-right: 130px;
    }

    @media #{mediaDown($break-point-1024)} {
      margin-left: 0;
      padding-left: 20px;
    }

    @media #{mediaMobile()} {
      margin-left: env(safe-area-inset-left);
    }

    &.active {
      & > svg {
        transform: rotate(270deg);
      }
    }

    &.hidden {
      opacity: 0;
    }
  }

  .search {
    position: absolute;
    right: 0;
    display: flex;
    padding-right: 20px;
    cursor: pointer;
    z-index: 10;

    & > form {
      width: 100%;
    }

    & > form > label {
      display: flex;
      width: calc(100% - 30px);      
      margin-left: 30px;
      cursor: pointer;
    }

    & > form > label > svg {
      visibility: visible;
      max-width: 20px;
      min-width: 20px;
    }

    span {
      flex: 1 0 auto;
      font-size: 14px;
      font-family: $font-light;
      font-weight: 300;
      line-height: 20px;
      color: dark(7);
      opacity: 0.41;
      transform: translate3d(-10px, 0, 0);
      transition: transform 500ms ease;
    }

    @media #{mediaMobile()} {
      margin-right: env(safe-area-inset-right);
    }

    @media #{mediaUp($break-point-1920)} {
      flex: 0 0 auto;
      padding-right: 60px;
    }

    & > form svg.js-search-submit {
      visibility: hidden;
      position: absolute;
      left: 0px;      
    }

    &.active {
      width: calc(100% - 20px);

      & > form svg.js-search-expand {
        visibility: hidden;
      }
      & > form svg.js-search-submit {
        visibility: visible;
      }

      & .button-reset {
        display: block;
      }

      & .input-search {
        width: calc(100% - 20px);
        pointer-events: auto;
        opacity: 1;
        transition: opacity 500ms ease;
      }

      @media #{mediaUp($break-point-1920)} {
        width: calc(100% - 60px);
      }
    }
  }

  .input-search {
    width: 0;
    outline: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    -webkit-appearance: none;
    font-size: 12px;
    font-family: $font-light;
    font-weight: 300;
    color: dark(7);
    letter-spacing: 1px;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0ms ease;

    &[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &[type='search']::-webkit-search-decoration,
    &[type='search']::-webkit-search-cancel-button,
    &[type='search']::-webkit-search-results-button,
    &[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    @media #{mediaUp($break-point-1024)} {
      margin-left: 0;

      &::placeholder {
        color: transparent;
      }
    }
  }

  .button-reset {
    position: absolute;
    right: 20px;
    display: none;
    width: 20px;
    height: 20px;
    border: 0;
    padding: 0;
    outline: 0;
    background: none;
    cursor: pointer;
    z-index: 12;

    @media #{mediaUp($break-point-1920)} {
      right: 60px;
    }

    .search.active & {
      display: block;
    }
  }

  .wrapper-location-links {
    flex: 1 0 100%;
    background-color: dark(1);
    border-top: 1px solid $footerBackgroundColor;
    border-left: 1px solid $footerBackgroundColor;
    max-height: 0;
    transition: max-height 0.25s ease;
    overflow: hidden;

    &.active {
      max-height: 100%;
      transition: max-height 0.25s ease;

      & .location-links {
        transform: translate3d(0, 0, 0);
        transition: transform 0.25s ease;

        li {
          @media #{mediaMobile()} {
            background-color: dark(1);
          }
        }
      }
      @media #{mediaDown($break-point-1024)} {
        margin-top: 30px;
      }
    }

    

    @media #{mediaMobile()} {
      border-top: 0;
    }
  }

  .location-links {
    padding: 30px 30px;
    transform: translate3d(0, -70px, 0);
    transition: transform 0.25s ease;

    @media #{mediaUp($break-point-1920)} {
      padding: 30px 60px;
    }

    @media #{mediaMobile()} {
      padding: 0;
      transform: translate3d(0, 0, 0);
    }

    li {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }

      @media #{mediaMobile()} {
        &::before {
          border-bottom: 1px solid $footerBackgroundColor;
        }
      }
    }
  }

  .info-links {
    @media #{mediaMobile()} {      
      flex: 1 0 100%;
    }
    margin: 20px 0 20px;
    padding: 0 20px;

    @media #{mediaUp($break-point-1920)} {
      padding: 0 60px;
    }

    @media #{mediaDown($break-point-1024)} {
      order: 1;
    }

    li {
      margin-bottom: 30px;
      margin-right: 20px;
    }

    & > li > button {
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    @media #{mediaMobile()} {
      margin-top: 0px;
      margin-bottom: 0;
      padding: 0;

      li {
        margin: 0;

        &:last-child {
          transition: background 250ms;
          transition-delay: 250ms;
        }

        &::before {
          border-top: 1px solid dark(1);
        }
      }

      & > li > button {
        margin-right: env(safe-area-inset-right);
      }
    }

    &.active {
      @media #{mediaMobile()} {
        li {
          &:last-child {
            background-color: dark(1);
            transition: background 100ms;
            transition-delay: 0;
          }
        }
      }
    }
  }

  .important-info {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    & > button {
      right: 30px;
    }

    p {
      display: none;
    }

    @media #{mediaMobile()} {
      padding: 20px 20px;

      & > a {
        display: none;
      }

      p {
        display: block;
        margin-left: env(safe-area-inset-left);
        margin-right: env(safe-area-inset-right);
      }
    }
  }

  .info-links-mobile {
    flex: 1 0 100%;

    @media #{mediaMobile()} {
      margin-bottom: 0;
      order: 1;

      li {
        background-color: dark(1);

        &::before {
          border-top: 1px solid $footerBackgroundColor;
        }
      }
    }
  }

  .wrapper-info-links-mobile {
    flex: 1 0 100%;
    order: 1;
    max-height: 0;
    transition: max-height 0.25s ease;
    overflow: hidden;

    @media #{mediaMobile()} {
      &.active {
        max-height: 300px;
        transition: max-height 0.25s ease;
      }
    }
  }

  .copyright {
    position: relative;
    width: 100%;
    padding: 30px 20px 0;
    font-size: 12px;
    opacity: 0.8;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      border-top: 1px solid dark(1);
    }

    @media #{mediaUp($break-point-1920)} {
      padding: 30px 60px 0;
    }

    @media #{mediaDown($break-point-1280)} {
      order: 1;
    }

    @media #{mediaMobile()} {
      margin-top: 0;
      margin-left: env(safe-area-inset-left);
      margin-right: env(safe-area-inset-right);

      &::before {
        right: calc(env(safe-area-inset-right) * -1);
        left: calc(env(safe-area-inset-left) * -1);
      }
    }
  }
}
