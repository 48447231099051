.filter-distributor-button-popup {
  position: relative;
  flex: 0 0 auto;
  margin-right: 20px;
  border: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
  border-bottom: 1px dashed green(3);
  background: none;

  &.active {
    & .filter-distributor-button-popup-line {
      opacity: 1;
    }
  }
}

.filter-distributor-button-popup-line {
  width: 100%;
  height: 4px;
  background-color: green(3);
  margin-top: 4px;
  opacity: 0;
}

.distributor-list {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 80px); // fixed #107 for AEM
  height: 100vh;
  padding-top: $grid-indentLargePx;
  background-color: $background-color;
  z-index: 3;
  transform: translate3d(0, -100vh, 0);
  transition: transform 750ms ease;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-scrolling: touch;
  transform: translate3d(0, -100vh, 0);
  margin-left: 80px; // fixed #107 for AEM
  opacity: 0;

  @media #{mediaDown($break-point-1920)} {
    padding-top: 70px;
  }

  & .filter-distributor-button-popup {
    border-bottom: 0;
  }

  @media #{mediaMobile()} {
    padding-top: 0px;
    z-index: 13;
    margin-left: 0;
    width: 100%;
  }

  @media ('max-width': 768px) {
    margin-left: 0;
    width: 100%;
  }

  &.active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.filter-distributor-group {
  width: 50%;
  margin-top: 30px;

  @media #{mediaMobile()} {
    width: 100%;
  }

  & button {
    margin: 10px 30px 0 0;
    font-family: $font-light;
    font-weight: 300;
    color: dark(2);
    text-decoration: none;
    line-height: 1;
    cursor: pointer;
    outline: 0;

    @media #{mediaMobile()} {
      margin: 10px 30px 0 0;
    }

    & > span {
      margin-left: 5px;
      opacity: 0.4;
    }
  }
}

.filter-distributor-search {
  position: relative;
  width: 100%;
  height: 80px;
  margin: 0;
  outline: 0;
  border: 0;

  & > label {
    display: flex;
    align-items: center;
    height: 100%;
  }

  & > svg {
    min-width: 20px;
    cursor: pointer;
  }
}

.filter-distributor-search-input {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  font-size: 12px;
  font-family: $font-light;
  font-weight: 300;
  letter-spacing: 1px;
  cursor: pointer;
  outline: 0;
  border: 0;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;

  &[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &[type='search']::-webkit-search-decoration,
  &[type='search']::-webkit-search-cancel-button,
  &[type='search']::-webkit-search-results-button,
  &[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  @media #{mediaDown($break-point-1024)} {
    margin-left: 0;

    &::-webkit-input-placeholder {
      color: transparent;
    }

    &:-moz-placeholder {
      color: transparent;
    }

    &::-moz-placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }
  }
}

.filter-distributor-button-clear {
  position: relative;
  left: -$grid-sideMargin_320_px;
  border: 0;
  padding: 0;
  outline: 0;
  background: none;
  cursor: pointer;
  display: none;

  &.active {
    display: block;
  }
}

.filter-distributor-button-close {
  background: none;
  border: 0;
  outline: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  -webkit-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;

  @media #{mediaMobile()} {
    margin-top: 60px;
  }

  & > svg,
  & > p {
    display: inline-block;
  }

  & > p {
    margin-left: 15px;
  }
}

.filter-distributor-heading-popup {
  font-size: 40px;
  font-family: $font-light;
  font-weight: 300;
  line-height: 1.25;
  margin-top: 30px;

  @media #{mediaUp($break-point-1920)} {
    font-size: 50px;
    line-height: 1.2;
  }
}

.filter-distributor-line {
  position: absolute;
  top: 0;
  left: #{-($grid-sideMargin_px - $grid-gutter_px / 2 + $grid-sideMargin_320_px / 2)};
  right: #{-($grid-sideMargin_px - $grid-gutter_px / 2 + $grid_sideMargin_320_px / 2)};
  height: 100%;
  border-top: 1px solid #eee;
  z-index: -1;
  transition: background 400ms ease;

  &.distributor-line-bottom {
    border-bottom: 1px solid #eee;
  }

  @media #{mediaUp($break-point-1920)} {
    left: #{-($grid-sideMargin_1920_px - $grid-gutter_px / 2 + $grid-sideMargin_320_px / 2)};
    right: #{-($grid-sideMargin_1920_px - $grid-gutter_px / 2 + $grid-sideMargin_320_px / 2)};
  }

  @media #{mediaMobile()} {
    left: #{-($grid-sideMargin_320_px - $grid-gutter_320_px / 2)};
    left: calc(#{-($grid-sideMargin_320_px - $grid-gutter_320_px / 2)} - env(safe-area-inset-left));
    right: #{-($grid-sideMargin_320_px - $grid-gutter_320_px / 2)};
    right: calc(#{-($grid-sideMargin_320_px - $grid-gutter_320_px / 2)} - env(safe-area-inset-left));
  }
}

.filter-distributor-list-popup {
  position: relative;

  & > li {
    position: relative;
    padding: 30px 30px 30px 0;
    cursor: pointer;

    &.hidden {
      display: none;
    }

    &:hover .filter-distributor-line {
      background: dark(7);
    }
  }
}
