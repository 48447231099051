.filter-icon-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.select-field{
    width: 100%;
    padding: 20px 40px 15px 15px;
    cursor: pointer;
    border: none;
    margin: 0;
//    background-color: dark(6);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.select-items-doc{
    width: calc(100% + 6px);
    background-color: dark(6);
    position: absolute;
    top: 60px;
    left:-3px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
    display: none;
    overflow: auto;
    max-height: 30vh;

    div{
        border-bottom: 1px solid dark(5);
        padding: 20px 15px;
        display: block;

        &.active-ele{
            background: dark(5);
            border-left: 5px solid green(3);
            padding-left: 10px;
        }
        
        &:hover{
            background: dark(5);
            border-left: 5px solid green(3);
            padding-left: 10px;
        }
    }

    @media #{mediaMobile()} {
        
    }
    
}

.filter-arrow-span{
    position: absolute;
    right: 20px;
    top:17px;
    transform: rotate(90deg);
}

.filter-select {
  z-index: 2;
//  border: solid 3px;
//  border-color: green(3);
//  background-color: dark(6);
  transition: flex 500ms ease;
  cursor: pointer;
  flex: 1 0;
  width: 50%;
 
 
  & > svg {
    min-width: 20px;
    cursor: pointer;
  }
 
//  @media #{mediaDown($break-point-1024)} {
//    flex: 0 0 60px;
//  }
 
}